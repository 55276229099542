import React, { useState, useRef } from 'react';
import './customer-review.css'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import img1 from '../image/deliveroo-logo3.png'
import img2 from '../image/imperial-college-london-logo3.svg'
import img3 from '../image/Four_Seasons_logo3.png'
import Latticelogo from '../image/lattice-logo-img.svg';
import { customerReview } from '../Content/English-Content/CustomerReview';


function CustomerReview() {


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };





    return (
        <>



            <div className="upper-div-customer-review">


                <h1 className="Customer-Review-heading">
                    {customerReview.firstSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                    <p className="side-heading-become-customer-para">{customerReview.firstSectionPara}
                    </p>

                </h1>

            </div>


            <div className="customer-review-cards-container">

                <h1 className="customer-review-cards-container-heading-main">{customerReview.secondSectionHeading}</h1>

                <div className="customers-review-card-wrapper">

                    <div className="customer-review-card-1">

                        <div className="customer-review-card-1-img"><img src={img1} ></img></div>

                        <div className="customer-review-content-wraper-of-card">

                            <h1 className="customer-review-card-1-all-heading">{customerReview.secondSectionCard1Heading}</h1>

                            <p className="customer-review-card-1-all-para">{customerReview.secondSectionCard1Para}</p>

                            <a href="/customer-story" style={{ color: "inherit", textDecoration: "none" }}>  <button className="customer-review-card-1-all-btns">{customerReview.secondSectionCard1Button}</button></a>

                        </div>

                    </div>


                    <div className="customer-review-card-1">

                        <div className="customer-review-card-1-img"><img src={img2} ></img></div>

                        <div className="customer-review-content-wraper-of-card">

                            <h1 className="customer-review-card-1-all-heading">{customerReview.secondSectionCard2Heading}</h1>

                            <p className="customer-review-card-1-all-para">{customerReview.secondSectionCard2Para}</p>

                            <a href="/customer-story" style={{ color: "inherit", textDecoration: "none" }}> <button className="customer-review-card-1-all-btns">{customerReview.secondSectionCard2Button}</button> </a>

                        </div>

                    </div>



                    <div className="customer-review-card-1">

                        <div className="customer-review-card-1-img"><img src={img3} ></img></div>

                        <div className="customer-review-content-wraper-of-card">

                            <h1 className="customer-review-card-1-all-heading">{customerReview.secondSectionCard3Heading}</h1>

                            <p className="customer-review-card-1-all-para">{customerReview.secondSectionCard3Para}</p>

                            <a href="/customer-story" style={{ color: "inherit", textDecoration: "none" }}> <button className="customer-review-card-1-all-btns">{customerReview.secondSectionCard3Button}</button> </a>

                        </div>

                    </div>


                </div>

                <p className="customer-review-last-para">{customerReview.thirdSectionPara}</p>


            </div>











            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    );
};

export default CustomerReview;
