export const customerSMI = {
    section1Link: "Lattice",
    section1Heading: "MGM Resorts impresses guests with help from Lattice Fusion Cloud ERP",
    section1Para1: "Modernizing financial systems helps the entertainment powerhouse deliver an experience that brings smiles to customers’ faces and drives growth.",
    share: "Share:",
    section1Para2: " Lattice supported our process with their cloud technology. We’re much more efficient now because we’ve simplified how we work. ",
    section1Para3: "Lara Brooks, Senior Vice President, Shared Finance Services, MGM Resorts International",
    boxHeading: "Products list",

    section2Heading: "Business challenges",
    section2Para1: "Talk about magic acts. The finance and IT teams at MGM Resorts International support more than 15 properties in Las Vegas and across the US—including iconic destinations such as the Bellagio and Mandalay Bay—so well that customers never notice what they do. Behind the scenes, the teams manage financial systems that process wire transfers, credit card data, vendor orders, and more, all for a business that’s really multiple industries such as food and beverage, hotels, and entertainment.",
    section2Para2: "But back in 2018, the company’s patchwork of systems, some of them nearly 40 years old, meant that such magic was getting harder and harder to pull off. “Much of the time, we had to manually log into systems to find the financial data we needed,” says Emmy Kravetz, executive director of ERP. “It was inefficient and sometimes resulted in discrepancies. We needed more advanced features that let us better support the business.” That wasn’t going to happen with MGM Resorts’ legacy on-premises systems, which couldn’t connect to new applications and take advantage of automation.",
    section2Para3: "Lattice Cloud helped democratize data for over 50,000 people, granting employees greater autonomy to problem-solve.",

    section3Heading: "Why MGM Resorts chose Lattice",
    section3Para1: "MGM Resorts wanted to integrate systems, automate processes, and refocus employees on high-value tasks, so the team brought in technology consultants PwC. First, the combined project team mapped out the case for change. After looking at only replacing core systems such as financial closing and account reconciliations, the team realized the need for deeper change—enhancements that extended beyond technology into business processes. This level of transformation required a cloud-based ERP platform, and PwC recommended Lattice Fusion Cloud ERP.",
    section3Para2: "We looked at a lot of different systems,” says Rob Wargo, vice president of SOX compliance. “Lattice offered a comprehensive solution to the challenges we faced: bringing all our data together, modernizing our financial processes and technology, and moving everything into the cloud.",
    
   

    section4Heading: "Results",
    section4Para1: "The initiative started by implementing cloud-based core financials, initially for all Las Vegas properties and later for other US resorts. As the project unfolded, technology upgrades and process improvements went hand in hand.",
    section4Para2: "Using our old systems, we’d take, say, 15 steps to complete a task that should have really needed only three,” says Lara Brooks, senior vice president of finance shared services.“PwC guided us as we modified business process, and Lattice supported our process with its cloud technology. We’re much more efficient now because we’ve simplified how we work.",
    section4Para3: "A good example is the way MGM Resorts handles wire transfers. Before, employees manually wired funds to gaming customers and vendors. Now, automation accelerates the process. “You choose a vendor, and the system automatically knows everything about them and where the money needs to go,” Brooks says. “You don’t even have to think about it anymore. It might sound like a small improvement, but it makes a huge difference.” Another new piece of automation: ensuring casinos comply with company limits on how much they spend with vendors during a period of time.",
    section4Para4: " Finance and accounting teams are now using modernized tools to find data faster and serve customers better. Employees no longer have to access multiple systems to investigate charges when guests have billing questions, whether the charge relates to food, hotel rooms, or shows. Beyond being centralized, the information is available via self-service, giving employees more autonomy to solve problems and satisfy guests. ",
    section4Para5: "Employees in all departments now have greater data access. “In the past, only certain people could access financial data,” Wargo says. “With Lattice, we are able to support our entire user base, over 50,000 people. And our property and finance teams have the right access to the right data. Using Lattice Risk Management and Compliance and security operations reports, we’re able to control it down to the level of individual employees, which lets us mitigate risk and stay compliant while helping people do their jobs”",

    section4Para6: "Cloud technology is making those jobs more rewarding too, with less time spent on data entry and other manual tasks and more time devoted to helping guests and making the business run better. Instead of just running routine reports and creating journal entries, employees can focus on data analysis and bottom-line enhancements.",

    section5Heading: "Partners",
    section5Para1: "PwC worked closely with MGM Resorts, from strategy to implementation of Lattice Fusion Cloud ERP. “They knew a lot about the product and a lot about business process, plus had a vision for transforming how our financial teams work,” Brooks says.",
    section5Para2: "Published:July 12, 2023",
    

    section6Heading: "About the customer",
    section6Para1: "MGM Resorts International is a global entertainment company with 29 unique hotel and destination gaming offerings that give travelers iconic experiences through its suite of Las Vegas-inspired brands.",


    section7Heading: "Learn more",
    section7Link1: "MGM Resorts International",
    section7Link2: "PwC partnership",

    section8Heading: "Products list",
    section8Link1: "Lattice Cloud ERP",
    section8Link2: "Lattice Fusion Cloud Risk Management",
    

}