export const companyEvents = {
    section1Heading: "Explore Lattice Events",
    section1Para: "Discover and register for upcoming events tailored to your industry and interests. Stay informed with Lattice's exclusive partner events and global gatherings.",

    section2Button1: "Browse By Format",
    section2Button2: "Type",
    section2Button3: "Date",
    section2Button4: "Location",
    section2Button5: "Industry",
    section2Button6: "Product",
    section2Button7: "Language",

    browseByFormatCheckbox1: "On-Demand Events (16)",
    browseByFormatCheckbox2: "Virtual Events (30)",
    browseByFormatCheckbox3: "In-Person Events (14)",

    typeFilterCheckbox1: "Conferences (14)",
    typeFilterCheckbox2: "Demos & Labs (5)",
    typeFilterCheckbox3: "Hospitality Events (8)",
    typeFilterCheckbox4: "Roundtable Discussions (17)",
    typeFilterCheckbox5: "Seminars (15)",
    typeFilterCheckbox6: "User Groups or Associations (4)",

    dateFilterCheckbox1: "This Week (5)",
    dateFilterCheckbox2: "This Month (10)",
    dateFilterCheckbox3: "Next Month (14)",
    dateFilterCheckbox4: "Next 60 Days (30)",
    dateFilterCheckbox5: "Next 90 Days (30)",
    dateFilterCheckbox6: "On-Demand (18)",

    locationFilterCheckbox1: "Asia (5)",
    locationFilterCheckbox2: "Africa (15)",
    locationFilterCheckbox3: "South Pacific (20)",
    locationFilterCheckbox4: "Europe (15)",

    industryCheckbox1: "Construction and Engineering (1)",
    industryCheckbox2: "Life Sciences (2)",

    productCheckbox1: "AI and Machine Learning (1)",
    productCheckbox2: "Analytics (2)",
    productCheckbox3: "Cloud Applications (SaaS) (4)",

    languageCheckbox1: "English (14)",
    languageCheckbox2: "Finnish (8)",
    languageCheckbox3: "French (10)",
    languageCheckbox4: "German (17)",
    languageCheckbox5: "Japanese (5)",

    filter: "Filter",
    done: "Done",
    datefilter: "Date Filter",

    filteredBy: "Filtered by:",
    noFiltersApplied: "No filters applied",
    clearAll: "Clear All Filters",

    show: "Show",
    items: "items",
    items5: "5 items",
    items7: "7 items",
    items10: "10 items",

    results: "Results",

    event1Type: "IN-PERSON EVENT",
    event1title: "Lattice Cloud Tour ",
    event1Description:
        "Join us for an immersive one-day event in Riyadh, where you can explore the latest in blockchain, cloud, and AI innovations. Gain insights from experts and discover how Lattice's solutions can address complex business challenges.",
    event1date: "January 19, 2025, 09:00 ART",
    event1location: "San Francisco",

    event2Type: "VIRTUAL EVENT",
    event2title:
        "Lattice Blockchain Solutions for Cost-Effective Virtualization",
    event2Description:
        "With rising costs and limited flexibility in traditional solutions, discover how Lattice's blockchain-backed virtualization tools provide a powerful, cost-effective alternative.",
    event2date: "January 21, 2025, 14:00 EST",
    event2location: "Virtual",

    register: "Register",
};