export const supplyChain = {
    // 1. First Section
    firstSectionHeading: "Lattice Blockchain Supply Chain & Manufacturing",
    firstSectionPara:
        "With Lattice’s fully integrated blockchain suite for Supply Chain Management (SCM) and Manufacturing, organizations can swiftly adapt to demand fluctuations, supply disruptions, and evolving market conditions. Seamlessly connect your end-to-end supply chain on a secure, transparent ledger to build a resilient network designed to outpace change.",
    firstSectionButton1: "Request a Demo",
    firstSectionButton2: "Chat with Lattice Sales",
    firstSectionSide2TitleImg: "Watch the SCM Overview (2:02)",

    // 2. Second Section
    secondSectionHeading: "Lattice Introduces the First Zettascale Cloud Blockchain Cluster",
    secondSectionPara:
        "Our new AI-enabled, blockchain-powered supercomputer supports up to 131,072 NVIDIA GPUs, enabling you to build, train, and run large-scale, AI-driven supply chain models for real-time insights and operational excellence.",
    secondSectionButton: "Get the News",

    // 3. Third Section
    thirdSectionHeading: "What’s Next for Lattice SCM on the Blockchain",
    thirdSectionSide1Heading: "Enable a Smarter, More Transparent Supply Chain",
    thirdSectionSide1Para:
        "Redefine your supply chain strategy on a secure blockchain foundation and embark on a journey toward an agile, more efficient future.",
    thirdSectionSide2Heading: "Build a Greener Business: Webinar",
    thirdSectionSide2Para:
        "Discover how Lattice Fusion Cloud Sustainability leverages blockchain and AI to track carbon footprints, reduce waste, and advance your green initiatives.",
    thirdSectionSide3Heading: "Explore the Power of AI in Logistics",
    thirdSectionSide3Para:
        "Watch on-demand to learn how an AI-driven, blockchain-backed supply chain can help you achieve on-time delivery, optimize routes, and ensure product authenticity.",

    // 4. Fourth Section (Service Overview)
    fourthSectionHeading: "Lattice Fusion Cloud Supply Chain & Manufacturing Suite",
    fourthSectionButton1: "Supply Chain Planning",
    fourthSectionButton2: "Inventory Management",
    fourthSectionButton3: "Maintenance",
    fourthSectionButton4: "Order Management",
    fourthSectionButton5: "Logistics",
    fourthSectionButton6: "Product Lifecycle Management",

    // -- Page 1: Supply Chain Planning
    fourthSectionPage1Heading: "Achieve Faster, More Accurate Planning",
    fourthSectionPage1Description:
        "Leverage blockchain-secured data for demand, supply, order fulfillment, and production planning. Collaborate end-to-end to enhance service levels, reduce disruptions, and minimize costs.",
    fourthSectionPage1Link: "Explore Supply Chain Planning",
    fourthSectionPage1cardheading1: "Products",
    fourthSectionPage1fastTrackList1: "Demand Management",
    fourthSectionPage1fastTrackList2: "Sales and Operations Planning",
    fourthSectionPage1fastTrackList3: "Supply Planning",
    fourthSectionPage1fastTrackList4: "Supply Chain Collaboration",
    learnMoreButton: "Learn More",

    // -- Page 2: Inventory Management
    fourthSectionPage2Heading: "Optimize Global Inventory Management with Blockchain",
    fourthSectionPage2Description:
        "Reduce costs and drive higher customer satisfaction by optimizing inventory levels and working capital. Gain full visibility and secure traceability of product flows across your organization and global supply chains.",
    fourthSectionPage2Link: "See Inventory Management Details",
    fourthSectionPage2cardheading1: "Capabilities",
    fourthSectionPage2fastTrackList1: "Materials Management",
    fourthSectionPage2fastTrackList2: "Financial and Supply Chain Orchestration",
    fourthSectionPage2fastTrackList3: "Cost Management",

    // -- Page 3: Maintenance
    fourthSectionPage3Heading: "Boost Efficiency with Connected Maintenance",
    fourthSectionPage3Description:
        "Increase reliability, reduce downtime, and lower overall maintenance costs with a blockchain-enabled, smart maintenance management solution.",
    fourthSectionPage3Link: "See Maintenance Details",
    fourthSectionPage3cardheading1: "Capabilities",
    fourthSectionPage3fastTrackList1: "Asset Management",
    fourthSectionPage3cardheading2: "Integrated Parts Tracking",
    fourthSectionPage3fastTrackList2: "Parts Inventory Management",
    fourthSectionPage3fastTrackList3: "Smart Operations",
    fourthSectionPage3fastTrackList4: "Project-Specific Maintenance",
    fourthSectionPage3fastTrackList5: "Maintenance Planning and Execution",

    // -- Page 4: Order Management
    fourthSectionPage4Heading: "Excel at Omnichannel Order Fulfillment",
    fourthSectionPage4Description:
        "Improve coordination across channels and fulfillment sources, facilitate rapid order execution, and integrate the customer experience for exceptional service and margins—all secured by blockchain.",
    fourthSectionPage4Link: "See Order Management Details",
    fourthSectionPage4cardheading1: "Capabilities",
    fourthSectionPage4fastTrackList1: "Omnichannel Order Management",
    fourthSectionPage4cardheading2: "Configurable Products",
    fourthSectionPage4fastTrackList2: "Product and Service Configuration",
    fourthSectionPage4cardheading3: "Real-Time Logistics",
    fourthSectionPage4fastTrackList3: "Rapid Order Fulfillment",
    fourthSectionPage4fastTrackList4: "Global Order Promising",

    // -- Page 5: Logistics
    fourthSectionPage5Heading: "Outpace Change with Agile, Blockchain-Enabled Logistics",
    fourthSectionPage5Description:
        "Maximize perfect order fulfillment, minimize logistics costs, and adapt to supply chain disruptions through real-time visibility and intelligent routing.",
    fourthSectionPage5Link: "Explore Logistics",
    fourthSectionPage5cardheading1: "Capabilities",
    fourthSectionPage5fastTrackList1: "Transportation Management",
    fourthSectionPage5cardheading2: "Secure Warehousing",
    fourthSectionPage5fastTrackList2: "Warehouse Management",
    fourthSectionPage5cardheading3: "Global Trade Management",
    fourthSectionPage5fastTrackList3: "Cross-Border Compliance and Tracking",

    // -- Page 6: Product Lifecycle Management
    fourthSectionPage6Heading: "Accelerate Product Innovation with Blockchain",
    fourthSectionPage6Description:
        "Reduce time to market, cut costs, and enhance product quality. A connected digital thread unites your supply chain data, ensuring secure collaboration and unified product master data.",
    fourthSectionPage6Link: "See Product Lifecycle Management Details",
    fourthSectionPage6cardheading1: "Capabilities",
    fourthSectionPage6fastTrackList1: "Innovation Management",
    fourthSectionPage6cardheading2: "Blockchain-Based Configurator Modeling",
    fourthSectionPage6fastTrackList2: "Configurator Modeling",
    fourthSectionPage6cardheading3: "Smart Product Development",
    fourthSectionPage6fastTrackList3: "Product Development",
    fourthSectionPage6fastTrackList4: "Product Master Data Management",
    fourthSectionPage6fastTrackList5: "Quality Management",

    // 5. Fifth Section
    fifthSectionHeading: "A Cloud Blockchain for Resilience and Growth",
    fifthSectionSide1Heading: "Unify SCM and HR in One Cloud",
    fifthSectionSide1Para:
        "Connect supply chain and workforce data in a single blockchain-powered platform. Leverage AI and machine learning for real-time insights, helping you optimize resources and reduce risks.",
    fifthSectionSide2Heading: "Innovate for Resilience and Growth",
    fifthSectionSide2Para:
        "Stay ahead with the latest blockchain-enabled SCM innovations that adapt to changing conditions—detect anomalies, predict outcomes, and recommend proactive solutions.",
    fifthSectionSide3Heading: "Respond to Everyday Change",
    fifthSectionSide3Para:
        "Make faster, data-driven decisions and rapidly adjust to market fluctuations through agile planning and coordinated blockchain-backed execution.",

    // 6. Sixth Section: Additional Resources
    sixthSectionHeading: "Supply Chain Management Resources",
    sixthSectionLink: "See More Reference Architectures",
    sixthSectionCard1Para1: "Solution Architecture",
    sixthSectionCard1Para2:
        "Learn what’s new in your Lattice Cloud SCM service, and see how blockchain enhancements can streamline updates and ensure transparency throughout your network.",
    sixthSectionCard1Heading: "Discover the Latest SCM Release Updates",

    sixthSectionCard2Para2:
        "Access the Lattice Help Center for in-depth product and service information, targeted solutions, and best practices for blockchain, AI, and more.",
    sixthSectionCard2Heading: "Explore Documentation Library",

    sixthSectionCard3Para2:
        "Cloud Customer Connect is Lattice’s premier online cloud community. With 200,000+ members, it’s the place to share best practices, provide product feedback, and collaborate with peers on blockchain-driven supply chain solutions.",
    sixthSectionCard3Heading: "Join a Community of Innovators",

    sixthSectionCard4Para2:
        "Lattice University offers training to help you build cloud and blockchain skills, validate your expertise, and speed adoption. Access free foundational content and earn accreditation via the Lattice Learning Explorer program.",
    sixthSectionCard4Heading: "Develop Your Lattice Cloud SCM Skills",

    // 7. Seventh Section: Success Stories
    seventhSectionHeading: "Lattice Supply Chain & Manufacturing Customer Successes",
    seventhSectionLink: "Explore All LCI Customer Stories",

    // 8. Eighth Section: Highlights
    eightSectionHeading: "Cloud Blockchain Fuels Resilience and Growth",
    eightSectionCard1Title:
        "Discover the benefits of connecting SCM and HR in a unified blockchain-powered cloud, complete with built-in AI and ML technologies.",
    eightSectionCard1Link2: "Unify SCM and HR on One Cloud",
    eightSectionCard1Link1: "Read the eBook (PDF)",

    eightSectionCard2Title:
        "Embrace the latest blockchain SCM innovations to detect anomalies, forecast trends, and respond decisively to market shifts.",
    eightSectionCard2Link2: "Innovate for Resilience and Growth",
    eightSectionCard2Link1: "Watch the Intelligent Product Tracking Video (1:31)",

    eightSectionCard3Title:
        "Accelerate decision-making and thrive amid business changes with agile, real-time planning and blockchain-secured supply chain execution.",
    eightSectionCard3Link2: "Respond to Everyday Change",
    eightSectionCard3Link1: "Read the Integrated Business Planning Brief (PDF)",
};
