export const storage = {
    firstSectionHeading: "Lattice Cloud Infrastructure (LCI) Storage Services",
    firstSectionPara: "Experience high-performance, scalable, and secure storage solutions for any workload in the cloud. With LCI Storage, scale up to 1.3 million IOPS and 1 PB per instance, achieve eleven 9s of durability, and implement security policies tailored to your organization’s needs.",
    firstSectionButton1: "Try LCI Storage",
    firstSectionSide2TitleImg: "Discover how LCI Storage optimizes costs and boosts performance.",

    thirdSectionHeading: "Industry-Leading Performance at a Fraction of the Cost",
    thirdSectionSide1Heading: "Save up to 50x on Cloud Storage Costs",
    thirdSectionSide1Para: "LCI Storage offers significantly lower prices compared to other providers, along with automatic performance tuning to meet your workload requirements, ensuring unmatched value.",
    thirdSectionSide2Heading: "Performance Beyond the Million IOPS Barrier",
    thirdSectionSide2Para: "Achieve unmatched storage performance to accelerate demanding workloads, including artificial intelligence, machine learning, and 3D modeling.",
    thirdSectionSide3Heading: "Effortless Data Management and Compliance",
    thirdSectionSide3Para: "Automate tiering, ensure compliance, and enable global data sharing with tools like replication and lifecycle management policies, designed to streamline your storage operations.",

    sixthSectionHeading: "Reference Architectures for LCI Storage",
    sixthSectionCard1Para1: "Reference Architecture",
    sixthSectionCard1Para2: "Learn how to automate virtual disk image imports into LCI Block Volumes with ease.",
    sixthSectionCard1Heading: "Import Virtual Disk Images to LCI Block Volumes using Apache Airflow",
    sixthSectionCard2Para2: "Explore customer-inspired configurations that leverage LCI Object Storage with third-party solutions to create an NFS cluster for high availability and flexibility.",
    sixthSectionCard2Heading: "Implement an NFS Cluster Server on LCI using Object Storage",
    sixthSectionCard3Para2: "Enable shared storage access for Windows servers to support high availability and distributed processing by integrating LCI File Storage with Microsoft Windows Active Directory.",
    sixthSectionCard3Heading: "Configure File Storage for Microsoft Windows Active Directory Users",

    seventhSectionHeading: "LCI Storage Success Stories",
    seventhSectionLink: "Explore all LCI customer stories",

    ninthSectionHeading: "Get Started with LCI Storage",
    ninthSectionPage1Side1Heading: "Access Free Cloud Storage with Lattice Cloud Free Tier",
    ninthSectionPage1Side1Para: "Lattice offers a Free Tier with no time limits on essential services such as Storage, Compute, and Autonomous Database, along with US$300 in free credits for advanced services. Start optimizing your workloads today.",
    ninthSectionPage1Side1button: "Try LCI for Free",
    ninthSectionPage1Side2Heading: "What’s Included in the Free Tier?",
    ninthSectionPage1Side2List1: "200 GB total block storage",
    ninthSectionPage1Side2List2: "10 GB object storage",
    ninthSectionPage1Side2List3: "AMD and Arm Compute VMs",
    ninthSectionPage1Side2List4: "2 Autonomous Databases, 20 GB each",
    ninthSectionPage1Side2List5: "10 TB outbound data transfer per month",
    ninthSectionPage1Side2List6: "10+ additional Always Free services",
    ninthSectionPage1Side2List7: "US$300 in free credits for 30 days",

    ninthSectionPage2Side1Heading: "Certify Your Expertise in LCI Storage",
    ninthSectionPage2Side1Para: "Boost your career prospects by earning a recognized Lattice certification. Expand your skill set and demonstrate proficiency in leveraging LCI storage services for real-world applications.",
    ninthSectionPage2Side1button: "Learn More About Certifications",

    ninthSectionPage2Side2Heading1: "Certification Benefits",
    ninthSectionPage2Side2List1: "Master advanced LCI Storage configurations and features",
    ninthSectionPage2Side2List2: "Expand your earning potential",
    ninthSectionPage2Side2List3: "Gain hands-on experience with LCI services",
    ninthSectionPage2Side2List4: "Leverage LCI’s powerful storage for modern workloads",

    ninthSectionPage3Side1Heading: "Maximize Your Savings with LCI",
    ninthSectionPage3Side1Para: "LCI pricing is globally consistent and competitively low. Use our cost estimator to configure your workloads and see how much you can save.",
    ninthSectionPage3Side1button: "Try Cost Estimator",

    ninthSectionPage4Side1Heading: "Contact Our Experts",
    ninthSectionPage4Side1Para: "Ready to explore LCI Storage solutions? Our team of experts is here to answer your questions and help you get started.",
    ninthSectionPage4Side1button: "Get in Touch",
    ninthSectionPage4Side2Heading: "We Can Help Answer Questions Like:",
    ninthSectionPage4Side2List1: "How do I start using LCI Storage?",
    ninthSectionPage4Side2List2: "What workloads are best suited for LCI?",
    ninthSectionPage4Side2List3: "How does LCI Storage compare to other cloud providers?",

    ninthSectionButton1: "Free Trial",
    ninthSectionButton2: "Certifications",
    ninthSectionButton3: "Estimate Your Costs",
    ninthSectionButton4: "Contact Sales",
};