export const albaniaTech = {
    // Top Section
    section1Link: "Albanian Tech",
    section1Heading: "Albanian Tech spearheads nationwide digital transformation with Lattice Cloud",
    section1Para1:
        "Albanian Tech—an innovation hub established by the Albanian government—modernizes its core services and expands opportunities for local startups and tech communities through the power of Lattice’s blockchain-enabled cloud solutions.",
    share: "Share:",
    section1Para2:
        "Our collaboration with Lattice Cloud solutions is helping us create a more agile, transparent, and inclusive tech environment across Albania. As we continue growing our network and supporting new ventures, Lattice’s secure, scalable platform lets us enhance efficiency, drive innovation, and better serve our communities.",
    section1Para3: "Arta Krasniqi, Director of Innovation, Albanian Tech",
    boxHeading: "Products list",

    // Business Challenges
    section2Heading: "Business challenges",
    section2Para1:
        "Albanian Tech was formed to accelerate Albania’s digital growth by supporting entrepreneurs, tech startups, and educational programs. As an organization backed by the Albanian government, Albanian Tech’s mandate is to foster innovation, bridge skill gaps, and scale technology-driven solutions for citizens and businesses.",
    section2Para2:
        "Over time, however, Albanian Tech found itself juggling multiple legacy platforms to manage grants, tech clusters, and community outreach. This led to a reliance on manual processes—like spreadsheets and paper-based documentation—creating operational inefficiencies and inconsistencies in data management and reporting.",
    section2Para3:
        "These aging systems also hindered Albanian Tech’s goal of providing seamless support for startups and increasing transparency for government stakeholders. As initiatives scaled, leaders struggled to get timely insights and coordinate resources effectively.",
    section2Para4:
        "To fulfill its mission of catalyzing innovation, Albanian Tech needed a modern, unified platform that could reduce manual workloads, streamline processes, and offer real-time visibility across all programs—from talent development to infrastructure investments.",
    section2BottomPara1:
        "By harnessing Lattice Cloud solutions, we can bring together key players—government agencies, startups, NGOs, and universities—onto a single innovation platform that drives Albania’s digital future.",
    section2Footer: "Marin Bardhi, Deputy Director, Albanian Tech",

    // Why Choose Lattice
    section3Heading: "Why Albanian Tech chose Lattice",
    section3Para1:
        "Albanian Tech selected Lattice Fusion Cloud to enhance collaboration across governmental programs, empower tech entrepreneurs, and maintain the highest standards of transparency. Lattice’s modern, blockchain-based environment aligned perfectly with Albanian Tech’s ambition to standardize operations while nurturing a culture of innovation.",
    section3Para2:
        "Consolidating disparate legacy applications into Lattice Cloud created a robust foundation for scaling projects across the country. With a single, unified platform, Albanian Tech gained the ability to connect multiple stakeholders, streamline funding processes, and capture real-time performance data—all while enabling compliance with government regulations.",
    section3Para3:
        "Ultimately, Albanian Tech trusted Lattice Cloud to serve as the technological backbone for Albania’s burgeoning startup ecosystem, enabling seamless data sharing and sustainable growth for future generations.",
    section3BottomPara1:
        "Having everything under one integrated system ensures we can respond faster to policy changes, funding requests, and new opportunities that shape Albania’s digital horizon.",

    // Results
    section4Heading: "Results",
    section4Para1:
        "By migrating core processes to Lattice Cloud, Albanian Tech significantly reduced manual tasks. Staff members now spend less time on administrative paperwork and more time driving high-impact initiatives, such as startup mentorship, events, and ecosystem building.",
    section4Para2:
        "The newly unified platform also supports end-to-end tracking of grants and public-private partnerships. Through Lattice Recruiting and matching services, Albanian Tech can identify talent pools—from budding developers to seasoned entrepreneurs—and connect them with suitable opportunities, fueling continuous innovation.",
    section4Para3:
        "Lattice’s self-service capabilities empower local tech hubs to register new ideas and track milestones. These features let Albanian Tech gather real-time feedback, ensuring that growth strategies remain aligned with on-the-ground needs. Meanwhile, government leaders gain immediate visibility into progress, simplifying compliance checks and reporting.",
    section4Para4:
        "With Lattice Journeys, an integral part of Lattice ME within Lattice Cloud, Albanian Tech orchestrates the entire lifecycle of funded initiatives—from initial application to final execution. This streamlined workflow replaces outdated paper-driven processes, enabling deeper engagement with participants and more efficient resource utilization.",
    section4Para5:
        "Building on its foundational Lattice deployment, Albanian Tech added features like Lattice Payroll and integrated budgeting tools to better manage financial disbursements and staff compensation. With more accurate data and reduced overhead, the organization can now channel additional resources into scholarships, accelerators, and tech projects that benefit Albania’s broader community.",

    // Partners
    section5Heading: "Partners",
    section5Para1:
        "Albanian Tech collaborates closely with Lattice not just as a customer, but also as a champion for bringing blockchain-based solutions to public sector institutions. With deep expertise in digital transformation, Albanian Tech helps other organizations within the Albanian government adopt and optimize Lattice Cloud solutions. This ensures a cohesive and future-proof framework for nationwide innovation.",
    section5Para2: "Published: July 12, 2024",

    // About the Customer
    section6Heading: "About the customer",
    section6Para1:
        "Albanian Tech is a government-backed organization dedicated to transforming Albania into a premier tech hub. By offering resources for startups, facilitating public-private partnerships, and driving policy improvements, Albanian Tech accelerates digital growth across the country—empowered by Lattice’s robust cloud and blockchain technologies.",

    // Learn More
    section7Heading: "Learn more",
    section7Link1: "Albanian Tech",

    // Product List
    section8Heading: "Products list",
    section8Link1: "Lattice Fusion Cloud",
    section8Link2: "Lattice ME",
    section8Link3: "Lattice Recruiting",
    section8Link4: "Lattice Journeys",
};


