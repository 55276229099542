import React, { useState, useEffect } from 'react';
import "./EmployPage.css";
import { useParams, Link } from 'react-router-dom';
import { footerSections, cookieText } from '../Content/eng_content';
import emp1 from '../image/slider-helth-care-img.jpg';
import { employees } from '../Content/Content-of-employ';
import Latticelogo from '../image/lattice-logo-img.svg';
import { executiveLeadership } from '../Content/English-Content/ExecutiveLeadership';

const EmployPage = () => {

    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };


    const { id } = useParams(); // Get the employee ID from the URL
    const [employee, setEmployee] = useState(null);

    useEffect(() => {
        // Find the employee with the matching ID
        const selectedEmployee = employees.find((emp) => emp.id === parseInt(id));
        setEmployee(selectedEmployee);
    }, [id]);

    if (!employee) {
        return <div>Employee not found</div>;
    }

    // cookies




    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };


    return (
        <>
            <div className="emp-bg-img-1">
                <div className="emp-transperant-navbar">
                    <Link className="emp-Link-of-empoly" to="/">
                        <span>
                            {executiveLeadership.employPageLinkText}
                            <span className="emp-arrow-emp">
                                <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 4l4 4-4 4" stroke="#ffffff" strokeWidth="1.5" fill="none" />
                                </svg>
                            </span>
                        </span>
                    </Link>
                </div>
            </div>

            <div className="emp-bg-img-2"></div>

            <div className="emp-overlay-div">
                <div className="img-and-info-wrapper">
                    <div className="emp-img">
                        <img className="emp-img" src={employee.img} alt={employee.name} />
                    </div>
                    <div className="emp-info">
                        <h1 className="emp-name">{employee.name}</h1>
                        <p className="info-of-employ">{employee.role}</p>
                    </div>
                </div>
                <p className="text1">{employee.description}</p>
                <p className="text2">{employee.description2}</p>
                <p className="text2">{employee.description3}</p>
                <p className="text2">{employee.description4}</p>
            </div>


            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <p href={link.to}>{link.text}</p>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}


        </>
    );
};

export default EmployPage;
