import React from 'react';
import { useChatWidget } from './ChatWidgetContext.jsx';

export default function ChatBot() {
    const { isExpanded, toggleWidget } = useChatWidget();

    return (
        <>
            <div className={`chat-widget ${isExpanded ? "expanded" : "collapsed"}`}>
                {isExpanded ? (
                    <div className="chat-widget-content">
                        {/* Section 1: Header */}
                        <div className="chat-widget-header">
                            <div className="chat-widget-header-left">
                                {/* Chat Icon */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    width="35"
                                    fill="red"
                                    className="chat-icon"
                                >
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path
                                        d="M12 3C6.48 3 2 6.82 2 11c0 2.28 1.33 4.31 3.44 5.74L5 20l4.28-2.07C10.36 18.64 11.16 19 12 19c5.52 0 10-3.82 10-8.5S17.52 3 12 3zm-1 11H8v-2h3v2zm5 0h-3v-2h3v2zm0-3H8V9h8v2z"
                                    />
                                </svg>
                                <h3 className="chat-title">Chat now</h3>
                            </div>
                            {/* Close Button */}
                            <button onClick={toggleWidget} className="close-btn">
                                &#x2715;
                            </button>
                        </div>

                        {/* Section 2: Content */}
                        <div className="chat-widget-body">
                            <p className="chat-heading">Call us sales</p>
                            <h3 className="contact-number">+1-234-567-890</h3>
                        </div>
                    </div>
                ) : (
                    // Collapsed View
                    <div onClick={toggleWidget} className="chat-widget-collapsed">
                        {/* Call Icon */}
                        <div className="call-icon-container">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="25"
                                viewBox="0 0 24 24"
                                width="25"
                                fill="currentColor"
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path
                                    d="M6.62 10.79a15.05 15.05 0 006.59 6.59l2.2-2.2a1.005 1.005 0 011.11-.24 9.03 9.03 0 003.4.64c.55 0 1 .45 1 1v3.01c0 .55-.45 1-1 1C11.4 21.97 2 12.56 2 4.01 2 3.46 2.45 3 3 3H6c.55 0 1 .45 1 1 0 1.18.21 2.32.64 3.4.17.39.09.85-.24 1.11l-2.2 2.2z"
                                />
                            </svg>
                        </div>
                        {/* Separator */}
                        <div className="separator" />
                        {/* Chat Icon */}
                        <div className="chat-icon-container">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="25"
                                viewBox="0 0 24 24"
                                width="25"
                                fill="red"
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path
                                    d="M12 3C6.48 3 2 6.82 2 11c0 2.28 1.33 4.31 3.44 5.74L5 20l4.28-2.07C10.36 18.64 11.16 19 12 19c5.52 0 10-3.82 10-8.5S17.52 3 12 3zm-1 11H8v-2h3v2zm5 0h-3v-2h3v2zm0-3H8V9h8v2z"
                                />
                            </svg>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
