import React, { useState, useRef, useEffect } from 'react';
import '../nav-bar-product-pages/Lattice-cloud-infrasturcture-compute-template-page'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import img1 from '../image/cloud/yt-video-thumbnail-tRnXMKJWqqc.jpg'
import img2 from '../image/german-federal-ministry-logo.svg'
import img3 from '../image/imperial-college-london-logo3.svg'
import svg1 from '../image/The_Related_Companies-Logo.svg'
import svg2 from '../image/deliveroo-logo3.png'
import svg4 from '../image/Four_Seasons_logo3.png'
import svg5 from '../image/AlbaniaTech-logo.svg'
// import svg6 from '../image/cloud/customerlogo-mosaic-clr.svg'
// import svg7 from '../image/cloud/customerlogo-bearingpoint.svg'
// import svg8 from '../image/cloud/customerlogo-specialized.svg'
// import svg9 from '../image/cloud/customerlogo-cohere-clr.svg'
// import svg10 from '../image/cloud/toyota-clr.svg'
// import svg11 from '../image/cloud/customerlogo-suno-clr.svg'
import svg12 from '../image/svg/view_week_24dp_E8EAED.svg'
import svg13 from '../image/svg/speed_24dp_E8EAED.svg'
import svg14 from '../image/svg/lock_open_24dp_E8EAED.svg'

import img5 from '../image/cloud/rc06-cloud-compute-flexible-instances.avif'
import img6 from '../image/cloud/rc06-cloud-compute-nvidia-v1.jpg'
import img7 from '../image/cloud/rc06-cloud-compute-amd-v2.webp'
import Latticelogo from '../image/lattice-logo-img.svg';
import { fileManagementSuite } from '../Content/English-Content/file-management-suite';

import { useChatWidget } from "../ChatWidgetContext";




function ProductSection2Page10() {



     const { toggleWidget } = useChatWidget(); 
    
    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };





    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: fileManagementSuite.fourthSectionPage1Heading,
            description:
            fileManagementSuite.fourthSectionPage1Description,
            link: fileManagementSuite.fourthSectionPage1Link,

            fastTrackList: [fileManagementSuite.fourthSectionPage1fastTrackList1, fileManagementSuite.fourthSectionPage1fastTrackList2, fileManagementSuite.fourthSectionPage1fastTrackList3, fileManagementSuite.fourthSectionPage1fastTrackList4],
            cardheading: [fileManagementSuite.fourthSectionPage1cardheading1, fileManagementSuite.fourthSectionPage1cardheading2, fileManagementSuite.fourthSectionPage1cardheading3, fileManagementSuite.fourthSectionPage1cardheading4],
        },
        documentation: {
            heading: fileManagementSuite.fourthSectionPage2Heading,
            description:
            fileManagementSuite.fourthSectionPage2Description,
            link: fileManagementSuite.fourthSectionPage2Link,
            cardheading: [fileManagementSuite.fourthSectionPage2cardheading1, fileManagementSuite.fourthSectionPage2cardheading2, fileManagementSuite.fourthSectionPage2cardheading3, fileManagementSuite.fourthSectionPage2cardheading4],
            fastTrackList: [fileManagementSuite.fourthSectionPage2fastTrackList1, fileManagementSuite.fourthSectionPage2fastTrackList2, fileManagementSuite.fourthSectionPage2fastTrackList3],
            headingof3div: 'Kick-start your app development with SDKs and the command-line interface.',
        },
        events: {
            heading: fileManagementSuite.fourthSectionPage3Heading,
            description:
            fileManagementSuite.fourthSectionPage3Description,
            link: fileManagementSuite.fourthSectionPage3Link,
            cardheading: [fileManagementSuite.fourthSectionPage3cardheading1, fileManagementSuite.fourthSectionPage3cardheading2],
            fastTrackList: [fileManagementSuite.fourthSectionPage3fastTrackList1, fileManagementSuite.fourthSectionPage3fastTrackList2],
            headingof3div: 'Access developer resources and learn about future events designed just for you. Hear from experts in their field and gain practical hands-on experience.',
        },
        technologies: {
            heading: fileManagementSuite.fourthSectionPage4Heading,
            description:
            fileManagementSuite.fourthSectionPage4Description,
            link: fileManagementSuite.fourthSectionPage4Link,

            fastTrackList: [fileManagementSuite.fourthSectionPage4fastTrackList1, fileManagementSuite.fourthSectionPage4fastTrackList2, fileManagementSuite.fourthSectionPage4fastTrackList3],
            cardheading: [fileManagementSuite.fourthSectionPage4cardheading1, fileManagementSuite.fourthSectionPage4cardheading2, fileManagementSuite.fourthSectionPage4cardheading3],
            headingof3div: 'Technologies',
        },
        // languages: {
        //     heading: fileManagementSuite.fourthSectionPage5Heading,
        //     description:
        //     fileManagementSuite.fourthSectionPage5Description,
        //     link: fileManagementSuite.fourthSectionPage5Link,

        //     fastTrackList: [fileManagementSuite.fourthSectionPage5fastTrackList1, fileManagementSuite.fourthSectionPage5fastTrackList2, fileManagementSuite.fourthSectionPage5fastTrackList3, fileManagementSuite.fourthSectionPage5fastTrackList4],
        //     cardheading: [fileManagementSuite.fourthSectionPage5cardheading1, fileManagementSuite.fourthSectionPage5cardheading2, fileManagementSuite.fourthSectionPage5cardheading3, fileManagementSuite.fourthSectionPage5cardheading4],
        //     headingof3div: 'languages:',
        // },
        // learn: {
        //     heading: 'AI Resource Hub',
        //     description:
        //         'Discover, learn and build. Access a range of AI resources to help you get ahead on your AI journey.',
        //     link: 'Learn more',
        //     cardheading: ['Flexible VMs', 'Dense I/O VMs', 'Optimized VMs', 'Dedicated VM hosts'],
        //     fastTrackList: ['VMs are customizable from 1–94 cores for AMD EPYC processors, 1–32 cores for Intel, and 1–80 cores for Ampere processors, with 1–1,094 GB RAM and network bandwidth that scales with the number of cores.', 'Dense I/O VMs, with 8–24 cores and 128–512 GB RAM, offer 6.8–27.2 TB high performance, locally attached NVMe storage for big data and analytics workloads.', 'Optimized VM shapes provide 1–18 high-frequency processor cores and support cluster networking with 4–40 Gb/sec of network bandwidth.', 'Dedicated VM host shapes offer bare metal instances with 36–128 cores and 512–2,048 GB memory per host with software to host VMs.'],
        //     headingof3div: 'Learn',
        // },

    };




    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];




    const [currentSlide, setCurrentSlide] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

    const cards = [
        {
            id: 1,
            title: fileManagementSuite.eightSectionCard1Title,
            link: fileManagementSuite.eightSectionCard1Link1,
            link2: fileManagementSuite.eightSectionCard1Link2,
            imgs: img5
        },
        {
            id: 2,
            title: fileManagementSuite.eightSectionCard2Title,
            link: fileManagementSuite.eightSectionCard2Link1,
            link2: fileManagementSuite.eightSectionCard2Link2,
            imgs: img6
        },
        {
            id: 3,
            title: fileManagementSuite.eightSectionCard3Title,
            link: fileManagementSuite.eightSectionCard3Link1,
            link2: fileManagementSuite.eightSectionCard3Link2,
            imgs: img7
        },
    ];

    const handleNext = () => {
        if (currentSlide < cards.length - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth <= 600;
            setIsSmallScreen(isSmall);

            // Reset slide index if switching back to large screen
            if (!isSmall) {
                setCurrentSlide(0);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const [activeSection, setActiveSection] = useState("Free trial");

    // Handle click to update active section
    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

     // cookies
    
    
        const [showCookieModal, setShowCookieModal] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
    
        const handleCookieClick = () => {
            setShowCookieModal(true);
            setIsLoading(true);
    
    
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        };
    
        const handleAcceptAll = () => {
            console.log("Cookies Accepted");
            setShowCookieModal(false);
        };
    
        const handleDenyAll = () => {
            console.log("Cookies Denied");
            setShowCookieModal(false);
        };






    return (
        <>

            <div className="product-infra-compute-first-div">



                <h1 className="product-infra-compute-first-div-heading">

                    {fileManagementSuite.firstSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                    <p className="product-infra-compute-first-div-paragraph">{fileManagementSuite.firstSectionPara}</p>

                    {/* <button className="product-infra-compute-first-div-btn-1">{fileManagementSuite.firstSectionButton1}</button> */}

                    <button className="product-infra-compute-first-div-btn-2" onClick={toggleWidget} >{fileManagementSuite.firstSectionButton2}</button>

                </h1>

                {/* <div className="product-infra-compute-first-div-img-and-para">

                    <img src={img1} className="product-infra-compute-img-of-first-div"></img>

                    <p className="product-infra-compute-title-of-img">{fileManagementSuite.firstSectionSide2TitleImg}</p>

                </div> */}


            </div>



            <div className="product-infra-compute-sec-div">

                <h1 className="product-infra-compute-sec-div-heading">{fileManagementSuite.secondSectionHeading}

                    <p className="product-infra-compute-sec-div-para">
                    {fileManagementSuite.secondSectionPara}
                    </p>

                </h1>

                {/* <button className="product-infra-compute-sec-div-btn" >{fileManagementSuite.secondSectionButton} </button> */}

            </div>



            <div className="product-infra-compute-third-div">

                <h1 className="product-infra-compute-third-div-heading">

                {fileManagementSuite.thirdSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="product-infra-compute-third-div-cards-wrapper">

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg12} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {fileManagementSuite.thirdSectionSide1Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{fileManagementSuite.thirdSectionSide1Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg13} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {fileManagementSuite.thirdSectionSide2Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{fileManagementSuite.thirdSectionSide2Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg14} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {fileManagementSuite.thirdSectionSide3Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{fileManagementSuite.thirdSectionSide3Para}</p>

                        </div>

                    </div>



                </div>


            </div>



            <div className="product-infrs-div-four-content-of-resources">
                <h1 className="product-infrs-div-four-solution-heading">

                    {fileManagementSuite.fourthSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="product-infrs-div-four-content-wrapper-of-resource">
                    <div className="product-infrs-div-four-content-1-of-resource">
                        <ul>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('downloads')}
                                >
                                    {fileManagementSuite.fourthSectionButton1}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('documentation')}
                                >
                                   {fileManagementSuite.fourthSectionButton2}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'events' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('events')}
                                >
                                    {fileManagementSuite.fourthSectionButton3}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('technologies')}
                                >
                                    {fileManagementSuite.fourthSectionButton4}
                                </button>
                            </li>
                            <li>
                                {/* <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'languages' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('languages')}
                                >
                                    {fileManagementSuite.fourthSectionButton5}
                                </button> */}
                            </li>
                            {/* <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'learn' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('learn')}
                                >
                                    Learn
                                </button>
                            </li> */}
                        </ul>
                    </div>

                    {/* Combined Content */}
                    <div className="product-infrs-div-four-combined-content">
                        {/* Top Section */}
                        <div className="product-infrs-div-four-top-content">
                            <h1 className="product-infrs-div-four-resource-heading">{currentResource.heading}</h1>
                            <p className="product-infrs-div-four-resource-description">{currentResource.description}</p>
                            {/* <button className="product-infrs-div-four-resource-btn">{currentResource.link}</button> */}
                        </div>


                        {/* Grid Section */}
                        <div className="product-infrs-div-four-grid-content">
                            {currentResource.fastTrackList.map((item, index) => (
                                <div className="product-infrs-div-four-grid-item" key={index}>
                                    <h2 className="product-infrs-div-four-grid-heading">{currentResource.cardheading[index]}</h2>
                                    <p className="product-infrs-div-four-grid-para">{item}</p>
                                    {/* <a href="#" className="product-infrs-div-four-grid-link">{fileManagementSuite.learnMoreButton}</a> */}
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>



            <div className="product-infra-div-five">

                <h1 className="product-infrs-div-four-solution-heading">

                    {fileManagementSuite.fifthSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                {/* <p className="product-infra-div-five-small-para">{fileManagementSuite.fifthSectionPara1}</p>

                <p className="product-infra-div-five-small-para">{fileManagementSuite.fifthSectionPara2}</p> */}

                <div className="product-infra-div-five-cards-wrapper">

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                        {fileManagementSuite.fifthSectionCard1Heading}</h1>

                        <p className="product-infra-div-five-all-card-para">{fileManagementSuite.fifthSectionCard1Para}</p>

                    </div>

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                        {fileManagementSuite.fifthSectionCard2Heading}%</h1>

                        <p className="product-infra-div-five-all-card-para">{fileManagementSuite.fifthSectionCard2Para}</p>

                    </div>

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                        {fileManagementSuite.fifthSectionCard3Heading}</h1>

                        <p className="product-infra-div-five-all-card-para">{fileManagementSuite.fifthSectionCard3Para}</p>

                    </div>

                </div>

                <div className="product-infra-div-five-btns-wrapper">

                    {/* <button className="product-infra-div-five-btn-1">{fileManagementSuite.fifthSectionButton1}</button>

                    <button className="product-infra-div-five-btn-2">{fileManagementSuite.fifthSectionButton2}</button> */}

                </div>

                {/* <p className="product-infra-div-five-very-small-para">{fileManagementSuite.fifthSectionPara3}</p> */}


            </div>



            {/* <div className="product-infra-div-six">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="product-infra-div-six-heading-oftxt-wrapper">{fileManagementSuite.sixthSectionHeading}</h1>

                    <a className="product-infra-div-six-link-of-txt-wrapper">{fileManagementSuite.sixthSectionLink}</a>

                </div>

                <div className="product-infra-div-six-cards-wrapper">

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">{fileManagementSuite.sixthSectionCard1Para1}
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                        {fileManagementSuite.sixthSectionCard1Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{fileManagementSuite.sixthSectionCard1Para2}</p>

                    </div>

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">{fileManagementSuite.sixthSectionCard1Para1}
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                        {fileManagementSuite.sixthSectionCard2Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{fileManagementSuite.sixthSectionCard2Para2}</p>

                    </div>


                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">{fileManagementSuite.sixthSectionCard1Para1}
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                        {fileManagementSuite.sixthSectionCard3Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{fileManagementSuite.sixthSectionCard3Para2}</p>

                    </div>

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">{fileManagementSuite.sixthSectionCard1Para1}
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                        {fileManagementSuite.sixthSectionCard3Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{fileManagementSuite.sixthSectionCard3Para2}</p>

                    </div>

                </div>

            </div> */}


            <div className="product-infra-div-seven">

                {/* <div className="product-infra-div-six-text-wrapper">

                    <h1 className="product-infrs-div-four-solution-heading">

                        {fileManagementSuite.seventhSectionHeading}
                        <span
                            style={{
                                display: "block",
                                width: "40px",
                                height: "4px",
                                background: "#f0cc71",
                                marginBottom: "3vh",
                                marginTop: "3vh",
                            }}
                        ></span>

                    </h1>

                    <a href='/customer-story' className="product-infra-div-six-link-of-txt-wrapper">{fileManagementSuite.seventhSectionLink}</a>

                </div> */}

                <div className="product-infra-div-seven-img-wrapper">

                    <img src={svg1} className="product-infra-div-seven-img-1"></img>

                    <img src={svg2} className="product-infra-div-seven-img-1"></img>

                    <img src={img2} className="product-infra-div-seven-img-1"></img>

                    <img src={svg4} className="product-infra-div-seven-img-1"></img>

                    <img src={svg5} className="product-infra-div-seven-img-1"></img>

                    {/* <img src={svg6} className="product-infra-div-seven-img-1"></img>

                    <img src={svg7} className="product-infra-div-seven-img-1"></img>

                    <img src={svg8} className="product-infra-div-seven-img-1"></img>

                    <img src={svg9} className="product-infra-div-seven-img-1"></img>

                    <img src={svg10} className="product-infra-div-seven-img-1"></img>

                    <img src={svg11} className="product-infra-div-seven-img-1"></img> */}

                    <img src={img3} className="product-infra-div-seven-img-1"></img>

                </div>

            </div>


            {/* <div className="product-infra-div-eight">
                <h1 className="product-infra-div-eight-heading">{fileManagementSuite.eightSectionHeading}</h1>
                <div className="product-infra-div-eight-cards-wrapper">
                    {cards.map((card, index) => (
                        <div
                            key={card.id}
                            className="product-infra-div-eight-card-1"
                            style={{
                                transform: isSmallScreen
                                    ? `translateX(-${currentSlide * 100}%)`
                                    : "none",
                                transition: "transform 0.5s ease-in-out",
                            }}
                        >
                            <div className="product-infra-div-eight-card-1-content-div">
                                <a className="product-infra-div-eight-card-1-content-div-all-links">
                                    {card.link2}
                                </a>
                                <h1 className="product-infra-div-eight-card-1-content-div-all-heading">
                                    {card.title}
                                </h1>
                                <a className="product-infra-div-eight-card-1-content-div-all-links">
                                    {card.link}
                                </a>
                            </div>
                            <div className="product-infra-div-eight-card-1-img-div">
                                <img  className="product-infra-div-eight-card-1-img-div" src= {card.imgs}></img>
                            </div>
                        </div>
                    ))}
                </div>

                {isSmallScreen && (
                    <div className="carousel-controls">
                        <button onClick={handlePrev} disabled={currentSlide === 0}>
                            &lt;
                        </button>
                        <span>
                            {currentSlide + 1} / {cards.length}
                        </span>
                        <button onClick={handleNext} disabled={currentSlide === cards.length - 1}>
                            &gt;
                        </button>
                    </div>
                )}
            </div> */}


            













            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
               <div className="footer-bottom">
                              
                                                  <span className="footer-separator"> © 2024 Lattice</span> |
                              
                                                  <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
                              
                                                  <span className="footer-separator"
                                                      style={{ cursor: "pointer", color: "inherit" }}
                                                      onClick={handleCookieClick}> Cookie Preferences  </span>
                              
                                                  <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
                              
                                                  <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                                              </div>
                              
                                          </div>
                              
                              
                                          {showCookieModal && (
                                              <div
                                                  className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                                                      }`}
                                              >
                                                  <div className="cookie-modal">
                              
                                                      {isLoading ? (
                                                          <div className="loading-spinner">{cookieText.loading}</div>
                                                      ) : (
                                                          <div className="cookie-content">
                              
                                                              <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                                                  &times;
                                                              </button>
                                                              <div className="heading-of-cookies-with-logo">
                              
                                                                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>
                              
                                                                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>
                              
                                                              </div>
                              
                                                              <div className="div-2-heading-below-text-and-list">
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>
                              
                                                                  <ul>
                                                                  {cookieText.listItems.map((item, index) => (
                                                                      <li key={index} className="div-2-cookies-list-text">
                                                                          <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                                                      </li>
                                                                      ))}
                                                                  </ul>
                              
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>
                              
                                                              </div>
                              
                                                              <div className="button-group">
                                                                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                                                                     {cookieText.btn1}
                                                                  </button>
                                                                  <button className="btn deny-btn" onClick={handleDenyAll}>
                                                                  {cookieText.btn2}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          )}

        </>
    );
};

export default ProductSection2Page10;
