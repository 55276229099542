import React, { useState, useRef, useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import './investor-relation-ship.css'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import svg1 from '../image/svg/article_24dp_E8EAED.svg'
import svg2 from '../image/svg/assured_workload_24dp_E8EAED.svg'
import svg3 from '../image/svg/description_24dp_E8EAED.svg'
import svg5 from '../image/svg/integration_instructions_24dp_E8EAED.svg'
import svg4 from '../image/svg/mic_none_24dp_E8EAED.svg'
import svg6 from '../image/svg/call_24dp_E8EAED.svg'
import svg7 from '../image/svg/email_24dp_E8EAED.svg'
import img1 from '../image/developer-img/event-card-bg.jpg'
import img2 from '../image/developer-img/events-background-card-2.jpg'
import { investorRelationPage } from '../Content/English-Content/investor-relationship';

function DeveloperEvent() {


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };


    // Intersection observer hook
    const { ref, inView } = useInView({ threshold: 0.3 });

    // State to store animated numbers
    const [numbers, setNumbers] = useState({
        workdays: 0,
        manualAccounting: 0,
        bankTransactions: 0,
        managementReports: 0,
        transactionsAutomated: 0,
    });

    // Final numbers
    const finalNumbers = {
        workdays: 10,
        manualAccounting: 54,
        bankTransactions: 97,
        managementReports: 25,
        transactionsAutomated: 80,
    };

    // Function to animate the numbers
    useEffect(() => {
        if (inView) {
            const interval = 50; // Speed of animation (lower = faster)
            const steps = 50; // Number of steps for animation
            const increments = {
                workdays: finalNumbers.workdays / steps,
                manualAccounting: finalNumbers.manualAccounting / steps,
                bankTransactions: finalNumbers.bankTransactions / steps,
                managementReports: finalNumbers.managementReports / steps,
                transactionsAutomated: finalNumbers.transactionsAutomated / steps,
            };

            let currentStep = 0;

            const intervalId = setInterval(() => {
                if (currentStep >= steps) {
                    clearInterval(intervalId);
                    setNumbers(finalNumbers); // Ensure final values are accurate
                    return;
                }

                setNumbers((prev) => ({
                    workdays: Math.min(finalNumbers.workdays, prev.workdays + increments.workdays),
                    manualAccounting: Math.min(finalNumbers.manualAccounting, prev.manualAccounting + increments.manualAccounting),
                    bankTransactions: Math.min(finalNumbers.bankTransactions, prev.bankTransactions + increments.bankTransactions),
                    managementReports: Math.min(finalNumbers.managementReports, prev.managementReports + increments.managementReports),
                    transactionsAutomated: Math.min(finalNumbers.transactionsAutomated, prev.transactionsAutomated + increments.transactionsAutomated),
                }));

                currentStep++;
            }, interval);

            return () => clearInterval(intervalId); // Cleanup on unmount
        }
    }, [inView]); // Re-run animation when `inView` changes

     // cookies
    
    
        const [showCookieModal, setShowCookieModal] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
    
        const handleCookieClick = () => {
            setShowCookieModal(true);
            setIsLoading(true);
    
    
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        };
    
        const handleAcceptAll = () => {
            console.log("Cookies Accepted");
            setShowCookieModal(false);
        };
    
        const handleDenyAll = () => {
            console.log("Cookies Denied");
            setShowCookieModal(false);
        };



    return (
        <>


            <div className="parent-investor-div">

                <div className="child-one-of-invesotr">

                    <h1 className="main-heading-of-investor">
                        {investorRelationPage.firstSectionHeading}
                        <span
                            style={{
                                display: "block",
                                width: "40px",
                                height: "5px",
                                background: "#AE562C",
                                marginBottom: "3vh",
                                marginTop: "3vh",
                            }}
                        ></span>

                       <a href="/about" style={{ color:"inherit" , textDecoration:"none"}}> <button className="investor-btns1">{investorRelationPage.firstSectionButton} </button> </a> 

                    </h1>

                </div>

                <div className="child-two-of-invesotr">

                    <span className="bold-investor-text">{investorRelationPage.secondSectionInvestorText}</span>   <span className="bold-investor-text">{investorRelationPage.secondSectionBoldInvestorText}</span><br></br>

                    <h1 className="bold-investor-heading">{investorRelationPage.secondSectionBoldInvestorHeading}</h1>

                    <h2 className="bold-investor-para">{investorRelationPage.secondSectionBoldInvestorPara}</h2>

                    <div className="para-and-bold-value">

                        <p className="para-normal-inv">{investorRelationPage.secondSectionNormalPara1} <br></br>
                        {investorRelationPage.secondSectionNormalPara2}<br></br>
                        {investorRelationPage.secondSectionNormalPara3} <br></br></p>

                        <p className="para-bold-inv">{investorRelationPage.secondSectionBoldPara1}<br></br>
                        {investorRelationPage.secondSectionBoldPara2}<br></br>
                            {investorRelationPage.secondSectionBoldPara3}<br></br>
                        </p>

                    </div>

                    <p className="small-para-inv">{investorRelationPage.secondSectionSmallPara1}<br></br></p>

                    <a className="link-of-inv">{investorRelationPage.secondSectionButton}</a>

                </div>

            </div>


            <div className="gradient-div-wrapper">

                <div className="gradient-img">

                    <span className="bold-investor-text">{investorRelationPage.thirdSectionBoldInvestorText}</span>

                    <h1 className="div-wraper-heading">
                        Latest quarter
                        <span
                            style={{
                                display: "block",
                                width: "45px",
                                height: "6px",
                                background: "#AE562C",
                                marginBottom: "3vh",
                                marginTop: "3vh",
                            }}
                        ></span>

                    </h1>

                </div>

                <div className="wrapper-card-five">

                    <div className="inv-wrapper-card-1">

                        <img src={svg1} className="img-of-card-1"></img>

                        <a className="inv-div-card-links">{investorRelationPage.thirdSectionBox1Text}
                        </a>

                    </div>

                    <div className="inv-wrapper-card-1">

                        <img src={svg2} className="img-of-card-1"></img>

                        <a className="inv-div-card-links">{investorRelationPage.thirdSectionBox2Text}
                        </a>

                    </div>

                    <div className="inv-wrapper-card-1">

                        <img src={svg3} className="img-of-card-1"></img>

                        <a className="inv-div-card-links">{investorRelationPage.thirdSectionBox3Text}
                        </a>

                    </div>


                    <div className="inv-wrapper-card-1">

                        <img src={svg4} className="img-of-card-1"></img>

                        <a href='/investor-relationship/web-cast' className="inv-div-card-links">{investorRelationPage.thirdSectionBox4Text}
                        </a>

                    </div>

                    <div className="inv-wrapper-card-1">

                        <img src={svg5} className="img-of-card-1"></img>

                        <a className="inv-div-card-links">{investorRelationPage.thirdSectionBox5Text}</a>

                    </div>

                </div>

                <div className="inv-div-side-by-side">

                    <div className="one-div-of-bottom">

                        <h1 className="one-div-of-bottom-heading1">{investorRelationPage.fourthSectionSide1Heading}</h1><br></br>

                        <h2 className="one-div-of-bottom-heading2">{investorRelationPage.fourthSectionSide1SubHeading}</h2>

                        <a className="one-div-of-bottom-link">{investorRelationPage.fourthSectionSide1Para}</a><br></br><br></br>

                        <a className="one-div-of-bottom-link">{investorRelationPage.seeAll}</a>

                    </div>

                    <div className="second-div-of-bottom">

                        <h1 className="one-div-of-bottom-heading1">{investorRelationPage.fourthSectionSide2Heading}</h1><br></br>

                        <h2 className="one-div-of-bottom-heading2">{investorRelationPage.fourthSectionSide2SubHeading}</h2>

                        <a className="one-div-of-bottom-link">{investorRelationPage.fourthSectionSide2Para}</a><br></br><br></br>

                        <h2 className="one-div-of-bottom-heading2">{investorRelationPage.fourthSectionSide2SubHeading2}</h2>

                        <a className="one-div-of-bottom-link">{investorRelationPage.fourthSectionSide2Para2}</a><br></br><br></br>

                        <a className="one-div-of-bottom-link">{investorRelationPage.seeAll}</a>


                    </div>

                </div>

            </div>


            <div className="value-changing-div">

                <h1 className="value-changing-heading">
                    {investorRelationPage.fifthSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "5px",
                            background: "#AE562C",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="value-changing-div-cards" ref={ref}>
                    <div className="value-cahnging-div-card-1">
                        <h1 className="dynamic-heading-of-changing-div-arrow">
                            {Math.round(numbers.workdays)} {investorRelationPage.fifthSectionSide1Heading}
                        </h1>
                        <p className="dynamic-heading-normal-para">
                           {investorRelationPage.fifthSectionSide1Para}
                        </p>
                    </div>

                    <div className="value-cahnging-div-card-1">
                        <h1 className="dynamic-heading-of-changing-div">
                            {Math.round(numbers.manualAccounting)}%
                        </h1>
                        <p className="dynamic-heading-normal-para">
                        {investorRelationPage.fifthSectionSide2Para}
                        </p>
                    </div>

                    <div className="value-cahnging-div-card-1">
                        <h1 className="dynamic-heading-of-changing-div">
                            {Math.round(numbers.bankTransactions)}%
                        </h1>
                        <p className="dynamic-heading-normal-para">
                        {investorRelationPage.fifthSectionSide3Para}
                        </p>
                    </div>

                    <div className="value-cahnging-div-card-1">
                        <h1 className="dynamic-heading-of-changing-div">
                            {Math.round(numbers.managementReports)}%
                        </h1>
                        <p className="dynamic-heading-normal-para">
                        {investorRelationPage.fifthSectionSide4Para}
                        </p>
                    </div>

                    <div className="value-cahnging-div-card-1">
                        <h1 className="dynamic-heading-of-changing-div">
                            {Math.round(numbers.transactionsAutomated)}%
                        </h1>
                        <p className="dynamic-heading-normal-para">
                        {investorRelationPage.fifthSectionSide5Para}
                        </p>
                    </div>


                </div>

                <a className="one-div-of-bottom-link">{investorRelationPage.learnMoreButton}</a>

            </div>


            <div className="sec-last-div">

                <h1 className="sec-last-div-heading">{investorRelationPage.sixthSectionHeading}</h1>

                <div className="sec-last-flex-div">

                    <div className="sec-last-first-div">

                        <img src={img1} className="sec-last-first-div-img"></img>

                        <ul className="ul-heading">{investorRelationPage.sixthSectionSide1Heading}
                            {/* <li>  <a className="one-div-of-bottom-link">{investorRelationPage.sixthSectionSide1List1}</a></li>
                            <li>  <a className="one-div-of-bottom-link">{investorRelationPage.sixthSectionSide1List2}</a></li>
                            <li>  <a className="one-div-of-bottom-link">{investorRelationPage.sixthSectionSide1List3}</a></li>
                            <li>  <a className="one-div-of-bottom-link">{investorRelationPage.sixthSectionSide1List4}</a></li> */}
                        </ul>

                    </div>

                    <div className="sec-last-sec-div">

                        <img src={img2} className="sec-last-first-div-img"></img>

                        <ul className="ul-heading">{investorRelationPage.sixthSectionSide2Heading}
                            {/* <li>  <a className="one-div-of-bottom-link">{investorRelationPage.sixthSectionSide2List1}</a></li>
                            <li>  <a className="one-div-of-bottom-link">{investorRelationPage.sixthSectionSide2List2}</a></li>
                            <li>  <a className="one-div-of-bottom-link">{investorRelationPage.sixthSectionSide2List3}</a></li> */}
                            {/* <li>  <a className="one-div-of-bottom-link">Social impact</a></li> */}
                        </ul>

                    </div>


                </div>

            </div>


            <div className="last-div-of-investor">

                <h1 className="heading1-of-last-div">{investorRelationPage.seventhSectionHeading}</h1>

                <div className="sing-up-email-box">

                    <div className="content-and-email-box">

                        <h2 className="heading2-of-last-div">{investorRelationPage.seventhSectionSubHeading}</h2>



                        <div className="last-sign-in-btn-and-box">

                            <input
                                type="email"
                                placeholder="Enter your email"
                                className="email-input-of-investor"
                                required
                            />
                           <a href="/sign-in" style={{ color:"inherit" , textDecoration:"none"}}> <button type="submit" className="sign-in-btn-investor">
                             {investorRelationPage.signInButton} 
                            </button></a>
                        </div>

                    </div>


                    {/* <div className="sing-in-2-div-investor">

                        <h2 className="heading2-of-last-div">Contact information</h2>

                        <span className="img-span-investor"><img src={svg6} ></img></span> <p className="last-para-of-investor1">{investorRelationPage.seventhSectionPara1}</p>

                        <span className="img-span-investor"><img src={svg7} ></img></span> <p className="last-para-of-investor2">{investorRelationPage.seventhSectionPara2}</p>

                    </div> */}

                </div>


                {/* <tbody className="check-box-investor">
                    <tr>
                        <td>
                            <input
                                id="press-releases-checkbox"
                                type="checkbox"
                                name="checkbox-list-press-releases"
                                value="31"
                                className="checkbox-input"
                            />
                            <label htmlFor="press-releases-checkbox" className="checkbox-label">
                            {investorRelationPage.seventhSectionCheckbox1}
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                id="events-checkbox"
                                type="checkbox"
                                name="checkbox-list-events"
                                value="33"
                                className="checkbox-input"
                            />
                            <label htmlFor="events-checkbox" className="checkbox-label">
                            {investorRelationPage.seventhSectionCheckbox2}
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                id="sec-filings-checkbox"
                                type="checkbox"
                                name="checkbox-list-sec-filings"
                                value="35"
                                className="checkbox-input"
                            />
                            <label htmlFor="sec-filings-checkbox" className="checkbox-label">
                            {investorRelationPage.seventhSectionCheckbox3}
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                id="stock-quote-checkbox"
                                type="checkbox"
                                name="checkbox-list-stock-quote"
                                value="36"
                                className="checkbox-input"
                            />
                            <label htmlFor="stock-quote-checkbox" className="checkbox-label">
                            {investorRelationPage.seventhSectionCheckbox4}
                            </label>
                        </td>
                    </tr>

                    <p className="unsubscribe">{investorRelationPage.seventhSectionUnsubscribeButton}</p>
                </tbody> */}

            </div>

            <div className="last-flat-img-investor"></div>







            <div className="footer-bottom">

                <span className="footer-separator"> © 2024 Lattice</span> |

                <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                <span className="footer-separator"
                    style={{ cursor: "pointer", color: "inherit" }}
                    onClick={handleCookieClick}> Cookie Preferences  </span>

                <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
            </div>







        </>
    );
};

export default DeveloperEvent;
