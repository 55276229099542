export const becomeCustomer = {
    firstSectionLink: "Customer",
    firstSectionHeading: "Become a Lattice Customer",
    firstSectionPara: "Join Lattice and leverage a fully integrated stack of blockchain cloud applications and platform services designed to drive innovation, scalability, and efficiency. As a Lattice customer, your organization can harness cutting-edge blockchain technology to transform operations and stay ahead in a rapidly evolving digital landscape.",
    firstSectionButton1: "Get Started",

    thirdSectionHeading: "Unlock the Benefits of Being a Lattice Customer",
    thirdSectionSide1Heading: "Foster Collaboration",
    thirdSectionSide1Para: "Shape the future of blockchain innovation by collaborating with the Lattice community, including thought leaders, developers, and our executive team.",
    thirdSectionSide2Heading: "Achieve Recognition",
    thirdSectionSide2Para: "Showcase your organization's achievements in leveraging blockchain solutions through Lattice's global platform and gain recognition for your digital transformation efforts.",
    thirdSectionSide3Heading: "Expand Your Network",
    thirdSectionSide3Para: "Connect with industry peers, innovators, and prospective customers across local, regional, and global events to strengthen your professional network.",
    thirdSectionSide4Heading: "Elevate Your Brand",
    thirdSectionSide4Para: "Promote your organization's blockchain success story, enhancing your brand visibility and positioning yourself as a leader in digital transformation.",

    fifthSectionHeading: "Share Your Blockchain Success",
    fifthSectionPara: "Explore various opportunities to highlight your achievements with Lattice. Choose the options that work best for your organization.",
    fifthSectionCard1Heading: "Brand Showcase",
    fifthSectionCard1Para: "Display your organization's name and logo on Lattice platforms, events, and presentations to highlight your leadership in blockchain innovation.",
    fifthSectionCard2Heading: "Blockchain Milestone Story",
    fifthSectionCard2Para: "Celebrate your key achievements in adopting blockchain solutions and share your journey with the world.",
    fifthSectionCard3Heading: "Case Study",
    fifthSectionCard3Para: "Detail how Lattice's blockchain solutions helped overcome your organization's challenges and drive success.",
    fifthSectionCard4Heading: "Customer Reference",
    fifthSectionCard4Para: "Engage with potential and existing customers who want to learn from your blockchain expertise and experiences.",
    fifthSectionCard5Heading: "Speaking Engagement",
    fifthSectionCard5Para: "Present as a subject-matter expert at Lattice events, webinars, or industry conferences, sharing your blockchain insights.",
    fifthSectionCard6Heading: "Video Story",
    fifthSectionCard6Para: "Tell your blockchain success story in a visually engaging format to inspire others.",
    fifthSectionCard7Heading: "Analyst and Media Features",
    fifthSectionCard7Para: "Share your blockchain insights through interviews with key industry analysts or media outlets.",
    fifthSectionCard8Heading: "Press Announcements",
    fifthSectionCard8Para: "Gain media coverage for your blockchain achievements and digital transformation milestones.",

    sixthSectionHeading: "Getting Started Is Simple",
    sixthSectionButton: "Share Your Blockchain Success Story",
}