import React, { useState, useRef, useEffect } from 'react';
import './Lattice-cloud-infrasturcture-compute-template-page.css'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import img1 from '../image/cloud/yt-video-thumbnail-tRnXMKJWqqc.jpg'
import img2 from '../image/The_Related_Companies-Logo.svg'
import img3 from '../image/Four_Seasons_logo3.png'
import svg1 from '../image/AlbaniaTech-logo.svg'
import svg2 from '../image/german-federal-ministry-logo.svg'
import svg4 from '../image/imperial-college-london-logo3.svg'
// import svg5 from '../image/cloud/customerlogo-dropbox-clr.svg'
import svg6 from '../image/deliveroo-logo3.png'
// import svg7 from '../image/cloud/customerlogo-fedex-color.svg'
// import svg8 from '../image/cloud/customerlogo-grupo-bimbo-color.svg'
// import svg9 from '../image/cloud/customerlogo-lyft-color.svg'
// import svg10 from '../image/cloud/customerlogo-maxim-caterers-clr.svg'
// import svg11 from '../image/cloud/customerlogo-oracle-red-bull-racing.svg'
import svg12 from '../image/svg/view_week_24dp_E8EAED.svg'
import svg13 from '../image/svg/speed_24dp_E8EAED.svg'
import svg14 from '../image/svg/lock_open_24dp_E8EAED.svg'

import img5 from '../image/cloud/rc46-gartner-peer-insights.avif'
import img6 from '../image/cloud/rc46-g2.avif'
import img7 from '../image/cloud/rc46-trustradius.avif'
import Latticelogo from
    // import svg14 from '../image/svg/customerlogo-university-melbourne-clr.svg'
    '../image/lattice-logo-img.svg';
import { reporting } from '../Content/English-Content/ProductInfraReporting';


import { useChatWidget } from "../ChatWidgetContext";


function ProductInfraReporting() {


     const { toggleWidget } = useChatWidget(); 
    

    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };





    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: reporting.fourthSectionPage1Heading,
            description:
                reporting.fourthSectionPage1Description,
            link: reporting.fourthSectionPage1Link,

            fastTrackList: [reporting.fourthSectionPage1fastTrackList1, reporting.fourthSectionPage1fastTrackList2, reporting.fourthSectionPage1fastTrackList3],
            cardheading: [reporting.fourthSectionPage1cardheading1, reporting.fourthSectionPage1cardheading2, reporting.fourthSectionPage1cardheading3],
        },
        documentation: {
            heading: reporting.fourthSectionPage2Heading,
            description:
                reporting.fourthSectionPage2Description,
            link: reporting.fourthSectionPage2Link,
            cardheading: [reporting.fourthSectionPage2cardheading1, reporting.fourthSectionPage2cardheading2, reporting.fourthSectionPage2cardheading3],
            fastTrackList: [reporting.fourthSectionPage2fastTrackList1, reporting.fourthSectionPage2fastTrackList2, reporting.fourthSectionPage2fastTrackList3],
            headingof3div: 'Kick-start your app development with SDKs and the command-line interface.',
        },
        events: {
            heading: reporting.fourthSectionPage3Heading,
            description:
                reporting.fourthSectionPage3Description,
            link: reporting.fourthSectionPage3Link,
            cardheading: [reporting.fourthSectionPage3cardheading1, reporting.fourthSectionPage3cardheading2],
            fastTrackList: [reporting.fourthSectionPage3fastTrackList1, reporting.fourthSectionPage3fastTrackList2],
            headingof3div: 'Access developer resources and learn about future events designed just for you. Hear from experts in their field and gain practical hands-on experience.',
        },
        technologies: {
            heading: ' Cloud native and containers',
            description:
                'LCI offers a comprehensive set of managed services across containers, serverless, APIs, and DevOps with support for open source to help customers build cloud native applications.',
            link: 'Learn more about cloud native and containers',
            cardheading: ['Functions', 'Containers', 'Kubernetes', 'Container images'],
            fastTrackList: ['LCI Functions is a serverless platform that lets developers create, run, and scale applications without managing infrastructure.', 'LCI Container Instances is a serverless compute service that lets you instantly run containers without managing any servers.', 'LCI Kubernetes Engine is a managed Kubernetes service for operating containerized applications at scale while reducing the time, cost, and operational burden of managing a Kubernetes infrastructure.', 'LCI Container Registry is an open standards–based, Lattice-managed Docker registry service for securely storing and sharing container images.'],
            headingof3div: 'Technologies',
        },
        languages: {
            heading: ' VMware and other services',
            description:
                'Lattice Cloud VMware Solution enables customers to move VMware estates to the cloud without changing operations or processes.',
            link: 'Learn more about Lattice Cloud VMware Solution',
            cardheading: ['VMware-based compute', 'Operating system (OS) images', 'Virtual desktops', 'Lattice OS Management Service'],
            fastTrackList: ['Lattice Cloud VMware Solution lets you create and manage VMware-enabled, software-defined data centers in LCI.', 'LCI supports several prebuilt Lattice-provided images and user-created custom images.', 'Virtual desktops deliver an improved experience and a lower administration cost.', 'Lattice OS Management Service lets you manage updates and patches for the OS on your instances.'],
            headingof3div: 'languages:',
        },
        // learn: {
        //     heading: 'AI Resource Hub',
        //     description:
        //         'Discover, learn and build. Access a range of AI resources to help you get ahead on your AI journey.',
        //     link: 'Learn more',
        //     cardheading: ['Flexible VMs', 'Dense I/O VMs', 'Optimized VMs', 'Dedicated VM hosts'],
        //     fastTrackList: ['VMs are customizable from 1–94 cores for AMD EPYC processors, 1–32 cores for Intel, and 1–80 cores for Ampere processors, with 1–1,094 GB RAM and network bandwidth that scales with the number of cores.', 'Dense I/O VMs, with 8–24 cores and 128–512 GB RAM, offer 6.8–27.2 TB high performance, locally attached NVMe storage for big data and analytics workloads.', 'Optimized VM shapes provide 1–18 high-frequency processor cores and support cluster networking with 4–40 Gb/sec of network bandwidth.', 'Dedicated VM host shapes offer bare metal instances with 36–128 cores and 512–2,048 GB memory per host with software to host VMs.'],
        //     headingof3div: 'Learn',
        // },

    };




    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];




    const [currentSlide, setCurrentSlide] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

    const cards = [
        {
            id: 1,
            title: reporting.eightSectionCard1Title,
            link: reporting.eightSectionCard1Link1,
            // link2: "Analysis report",
            imgs: img5
        },
        {
            id: 2,
            title: reporting.eightSectionCard2Title,
            link: reporting.eightSectionCard2Link1,
            // link2: "Announcement",
            imgs: img6
        },
        {
            id: 3,
            title: reporting.eightSectionCard3Title,
            link: reporting.eightSectionCard3Link1,
            // link2: "Blog",
            imgs: img7
        },
    ];

    const handleNext = () => {
        if (currentSlide < cards.length - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth <= 600;
            setIsSmallScreen(isSmall);

            // Reset slide index if switching back to large screen
            if (!isSmall) {
                setCurrentSlide(0);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const [activeSection, setActiveSection] = useState("Free trial");

    // Handle click to update active section
    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };






    return (
        <>

            <div className="product-infra-compute-first-div">



                <h1 className="product-infra-compute-first-div-heading">

                    {reporting.firstSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                    <p className="product-infra-compute-first-div-paragraph">{reporting.firstSectionPara}</p>

                    {/* <button className="product-infra-compute-first-div-btn-1">{reporting.firstSectionButton1}</button> */}

                    <button className="product-infra-compute-first-div-btn-2" onClick={toggleWidget} >{reporting.firstSectionButton2}</button>

                </h1>

                {/* <div className="product-infra-compute-first-div-img-and-para">

                    <img src={img1} className="product-infra-compute-img-of-first-div"></img>

                    <p className="product-infra-compute-title-of-img">Suno AI Generates Beautiful Songs with Lattice Cloud (2:06)</p>

                </div> */}


            </div>



            <div className="product-infra-compute-sec-div">

                <h1 className="product-infra-compute-sec-div-heading">{reporting.secondSectionHeading}

                    <p className="product-infra-compute-sec-div-para">
                        {reporting.secondSectionPara}
                    </p>

                </h1>

                {/* <button className="product-infra-compute-sec-div-btn">{reporting.secondSectionButton}</button> */}

            </div>



            <div className="product-infra-compute-third-div">

                <h1 className="product-infra-compute-third-div-heading">

                    {reporting.thirdSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="product-infra-compute-third-div-cards-wrapper">

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg12} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {reporting.thirdSectionSide1Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{reporting.thirdSectionSide1Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg13} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {reporting.thirdSectionSide2Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{reporting.thirdSectionSide2Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg14} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {reporting.thirdSectionSide3Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{reporting.thirdSectionSide3Para}</p>

                        </div>

                    </div>



                </div>


            </div>



            <div className="product-infrs-div-four-content-of-resources">
                <h1 className="product-infrs-div-four-solution-heading">

                    LCI Compute services
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="product-infrs-div-four-content-wrapper-of-resource">
                    <div className="product-infrs-div-four-content-1-of-resource">
                        <ul>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('downloads')}
                                >
                                    {reporting.fourthSectionButton1}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('documentation')}
                                >
                                    {reporting.fourthSectionButton2}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'events' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('events')}
                                >
                                    {reporting.fourthSectionButton3}
                                </button>
                            </li>
                            {/* <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('technologies')}
                                >
                                    {reporting.fourthSectionButton4}
                                </button>
                            </li> */}
                            {/* <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'languages' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('languages')}
                                >
                                    {reporting.fourthSectionButton5}
                                </button>
                            </li> */}

                        </ul>
                    </div>


                    <div className="product-infrs-div-four-combined-content">

                        <div className="product-infrs-div-four-top-content">
                            <h1 className="product-infrs-div-four-resource-heading">{currentResource.heading}</h1>
                            <p className="product-infrs-div-four-resource-description">{currentResource.description}</p>
                            {/* <button className="product-infrs-div-four-resource-btn">{currentResource.link}</button> */}
                        </div>



                        <div className="product-infrs-div-four-grid-content">
                            {currentResource.fastTrackList.map((item, index) => (
                                <div className="product-infrs-div-four-grid-item" key={index}>
                                    <h2 className="product-infrs-div-four-grid-heading">{currentResource.cardheading[index]}</h2>
                                    <p className="product-infrs-div-four-grid-para">{item}</p>
                                    {/* <a href="#" className="product-infrs-div-four-grid-link">Learn More</a> */}
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>



            <div className="product-infra-div-five">

                <h1 className="product-infrs-div-four-solution-heading">

                    {reporting.fifthSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <p className="product-infra-div-five-small-para">{reporting.fifthSectionPara1}</p>

                <p className="product-infra-div-five-small-para">{reporting.fifthSectionPara2}</p>

                {/* <div className="product-infra-div-five-cards-wrapper">

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            57%</h1>

                        <p className="product-infra-div-five-all-card-para">less than AWS m6a.xlarge</p>

                    </div>

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            57%</h1>

                        <p className="product-infra-div-five-all-card-para">less than AWS m6a.xlarge</p>

                    </div>

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            51%</h1>

                        <p className="product-infra-div-five-all-card-para">less than Google Cloud n2d-standard-4</p>

                    </div>

                </div> */}

                <div className="product-infra-div-five-btns-wrapper">

               <button className="product-infra-div-five-btn-1"> <a href="/create-account" style={{ color:"inherit" , textDecoration:"none"}}> {reporting.fifthSectionButton1}</a></button> 

                    <button className="product-infra-div-five-btn-2" onClick={toggleWidget} >{reporting.fifthSectionButton2}</button>

                </div>

                {/* <p className="product-infra-div-five-very-small-para">* Compared with the monthly cost of a two OCPU (four vCPU) AMD (E4) with 16 GB in US eastern regions. On-demand prices are as of June 5, 2024.</p> */}


            </div>



            {/* <div className="product-infra-div-six">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="product-infra-div-six-heading-oftxt-wrapper">LCI Compute reference architectures</h1>

                    <a className="product-infra-div-six-link-of-txt-wrapper">See more reference architectures</a>

                </div>

                <div className="product-infra-div-six-cards-wrapper">

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                            Migrate workloads to Lattice Cloud VMware Solution and Lattice Exadata Database Service
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">This architecture shows a three-tier application stack in LCI with the application tier deployed in a VMware software-defined data center created by Lattice Cloud VMware Solution.</p>

                    </div>

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                        Deploy a bare metal GPU cluster for AI workloads in a dedicated cloud
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">This architecture shows a three-tier application stack in See how you can deploy an AI solution on a cluster of bare metal servers with GPUs and cluster networking.</p>

                    </div>


                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                        Deploy serverless Kubernetes with LCI Kubernetes Engine virtual nodes
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">This architecture shows a three-tier application stack in This architecture depicts an application server deployed in an OKE cluster with virtual nodes that let you perform create, read, update, and delete operations on Lattice MySQL Database Service.</p>

                    </div>

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                        Deploy an end-to-end application stack with Lattice Compute Cloud@Customer
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">This architecture shows a three-tier application stack in In this architecture, you’ll see how Lattice Compute Cloud@Customer can run applications and middleware on flexible VM shapes.</p>

                    </div>

                </div>

            </div> */}


            <div className="product-infra-div-seven">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="product-infrs-div-four-solution-heading">

                        {reporting.sixthSectionHeading}
                        <span
                            style={{
                                display: "block",
                                width: "40px",
                                height: "4px",
                                background: "#f0cc71",
                                marginBottom: "3vh",
                                marginTop: "3vh",
                            }}
                        ></span>

                    </h1>

                    <a href='/customer-story' className="product-infra-div-six-link-of-txt-wrapper">{reporting.sixthSectionLink}</a>

                </div>

                <div className="product-infra-div-seven-img-wrapper">

                    <img src={svg1} className="product-infra-div-seven-img-1"></img>

                    <img src={svg2} className="product-infra-div-seven-img-1"></img>

                    <img src={img2} className="product-infra-div-seven-img-1"></img>

                    <img src={svg4} className="product-infra-div-seven-img-1"></img>

                    {/* <img src={svg5} className="product-infra-div-seven-img-1"></img> */}

                    <img src={svg6} className="product-infra-div-seven-img-1"></img>

                    {/* <img src={svg7} className="product-infra-div-seven-img-1"></img>

                    <img src={svg8} className="product-infra-div-seven-img-1"></img>

                    <img src={svg9} className="product-infra-div-seven-img-1"></img> */}

                    {/* <img src={svg10} className="product-infra-div-seven-img-1"></img> */}

                    {/* <img src={svg11} className="product-infra-div-seven-img-1"></img> */}

                    <img src={img3} className="product-infra-div-seven-img-1"></img>

                </div>

            </div>


            {/* <div className="product-infra-div-eight">
                <h1 className="product-infra-div-eight-heading">{reporting.eightSectionHeading}</h1>
                <div className="product-infra-div-eight-cards-wrapper">
                    {cards.map((card, index) => (
                        <div
                            key={card.id}
                            className="product-infra-div-eight-card-1"
                            style={{
                                transform: isSmallScreen
                                    ? `translateX(-${currentSlide * 100}%)`
                                    : "none",
                                transition: "transform 0.5s ease-in-out",
                            }}
                        >
                            <div className="product-infra-div-eight-card-1-content-div">
                                <a className="product-infra-div-eight-card-1-content-div-all-links">
                                    {card.link2}
                                </a>
                                <h1 className="product-infra-div-eight-card-1-content-div-all-heading">
                                    {card.title}
                                </h1>
                                <a className="product-infra-div-eight-card-1-content-div-all-links">
                                    {card.link}
                                </a>
                            </div>
                            <div className="product-infra-div-eight-card-1-img-div">
                                <img className="product-infra-div-eight-card-1-img-div" src={card.imgs}></img>
                            </div>
                        </div>
                    ))}
                </div>

                {isSmallScreen && (
                    <div className="carousel-controls">
                        <button onClick={handlePrev} disabled={currentSlide === 0}>
                            &lt;
                        </button>
                        <span>
                            {currentSlide + 1} / {cards.length}
                        </span>
                        <button onClick={handleNext} disabled={currentSlide === cards.length - 1}>
                            &gt;
                        </button>
                    </div>
                )}
            </div> */}

            <div className="product-infra-last-div">

                <div className="product-infra-last-div-wrapper">

                    <h1 className="product-infra-last-div-heading">{reporting.ninthSectionHeading}</h1>

                    <div className="become-partners-dynamic-island" style={{ marginTop: "5vh" }}>


                        <button
                            onClick={() => handleSectionClick("Free trial")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Free trial" ? "active-section" : ""}`}
                        >
                            {reporting.ninthSectionButton1}
                        </button>

                        {/* <button
                            onClick={() => handleSectionClick("Hands-on labs")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Hands-on labs" ? "active-section" : ""}`}
                        >
                            {reporting.ninthSectionButton2}
                        </button>

                        <button
                            onClick={() => handleSectionClick("Estimate your costs")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Estimate your costs" ? "active-section" : ""}`}
                        >
                            {reporting.ninthSectionButton3}
                        </button>

                        <button
                            onClick={() => handleSectionClick("Contact sales")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Contact sales" ? "active-section" : ""}`}
                        >

                            {reporting.ninthSectionButton4}
                        </button> */}


                    </div>


                    <div className="become-partners-dynamic-content-area">


                        <div className="become-partners-sec-div-cards">
                            {activeSection === "Free trial" && (
                                <>

                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{reporting.ninthSectionPage1Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{reporting.ninthSectionPage1Side1Para}</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn"><a href="/create-account" style={{ color:"inherit" , textDecoration:"none"}}>{reporting.ninthSectionPage1Side1button}</a></button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{reporting.ninthSectionPage1Side2Heading}</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage1Side2List1}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage1Side2List2}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage1Side2List3}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> {reporting.ninthSectionPage1Side2List4}</li>
                                                {/* <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage1Side2List5}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> {reporting.ninthSectionPage1Side2List6}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage1Side2List7}</li> */}
                                            </ul>

                                        </div>



                                    </div>

                                </>
                            )}

                            {/* {activeSection === "Hands-on labs" && (
                                <>

                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-2-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{reporting.ninthSectionPage2Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{reporting.ninthSectionPage2Side1Para}</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn">{reporting.ninthSectionPage2Side1button}</button>

                                        </div>

                                        <div className="product-infra-last-div-2-list-card-2">

                                            <div className="product-infra-last-div-2-list-card-2-child-1">

                                                <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{reporting.ninthSectionPage2Side2Heading1}</h1>

                                                <p className="product-infra-last-div-1-list-card-1-all-sub-para">{reporting.ninthSectionPage2Side2Para1}</p>

                                                <a className="product-infra-last-div-1-list-card-1-all-sub-link">{reporting.ninthSectionPage2Side2Button}</a>

                                            </div>

                                            <div className="product-infra-last-div-2-list-card-2-child-1">

                                                <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{reporting.ninthSectionPage2Side2Heading2}</h1>

                                                <p className="product-infra-last-div-1-list-card-1-all-sub-para">{reporting.ninthSectionPage2Side2Para2}</p>

                                                <a className="product-infra-last-div-1-list-card-1-all-sub-link">Start this lab now</a>

                                            </div>

                                            <div className="product-infra-last-div-2-list-card-2-child-1">

                                                <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{reporting.ninthSectionPage2Side2Heading3}</h1>

                                                <p className="product-infra-last-div-1-list-card-1-all-sub-para">{reporting.ninthSectionPage2Side2Para3}</p>

                                                <a className="product-infra-last-div-1-list-card-1-all-sub-link">{reporting.ninthSectionPage2Side2Button}</a>

                                            </div>

                                            <div className="product-infra-last-div-2-list-card-2-child-1">

                                                <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{reporting.ninthSectionPage2Side2Heading4}</h1>

                                                <p className="product-infra-last-div-1-list-card-1-all-sub-para">{reporting.ninthSectionPage2Side2Para4}</p>

                                                <a className="product-infra-last-div-1-list-card-1-all-sub-link">{reporting.ninthSectionPage2Side2Button}</a>

                                            </div>



                                        </div>

                                    </div>

                                </>
                            )}


                            {activeSection === "Estimate your costs" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{reporting.ninthSectionPage3Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{reporting.ninthSectionPage3Side1Para}</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn">{reporting.ninthSectionPage3Side1button}</button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{reporting.ninthSectionPage3Side2Heading}</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage3Side2List1}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage3Side2List2}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage3Side2List3}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> {reporting.ninthSectionPage3Side2List4}</li> */}
                                                {/* <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                            <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                            <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li> */}
                                            {/* </ul>

                                        </div>



                                    </div>






                                </>
                            )}

                            {activeSection === "Contact sales" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{reporting.ninthSectionPage4Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{reporting.ninthSectionPage4Side1Para}</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn">{reporting.ninthSectionPage4Side1button}</button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{reporting.ninthSectionPage4Side2Heading}</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage4Side2List1}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage4Side2List2}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{reporting.ninthSectionPage4Side2List3}</li> */}
                                                {/* <li className="product-infra-last-div-list-1-card-1-list-all"> 2 Autonomous Databases, 20 GB each</li>
                                                            <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                            <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                            <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li> */}
                                            {/* </ul>

                                        </div>



                                    </div>




                                </>
                            )}
 */}

                        </div>

                    </div>


                </div>

            </div>
















            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    );
};

export default ProductInfraReporting;
