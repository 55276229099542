export const latticeOverview = {

    Lcimenuename1: "About",
    Lcimenuename2: "Services",
    Lcimenuename3: "Solutions",
    Lcimenuename4: "Pricing",
    Lcimenuename5: "Partners",
    Lcimenuename6: "Resources",




    firstSectionHeading: "Lattice Cloud Infrastructure (LCI)",
    firstSectionPara: "The foundational cloud platform designed to power your blockchain applications or seamlessly transition existing operations into blockchain-enabled solutions.",
    firstSectionButton1: "Try LCI for free",
    firstSectionButton2: "Chat with Lattice Sales",
    firstSectionSide2TitleImg: "Transforming industries with Lattice Cloud Infrastructure",

    thirdSectionHeading: "A Blockchain-Ready Cloud for Every Need",
    thirdSectionSide1Heading: "Multicloud Integration",
    thirdSectionSide1Para: "Seamlessly integrate blockchain applications across multiple clouds. Lattice enables direct database integration with Microsoft Azure and high-performance interconnection for blockchain workflows.",
    thirdSectionSide2Heading: "Public Cloud Scalability",
    thirdSectionSide2Para: "Deploy blockchain solutions globally with consistent services and pricing across commercial and government public cloud regions.",
    thirdSectionSide3Heading: "Hybrid Blockchain Solutions",
    thirdSectionSide3Para: "Run blockchain-enabled applications on-premises or in the cloud, including the highly optimized Lattice Exadata Cloud@Customer already deployed in over 60 countries.",

    fifthSectionHeading: "Cost-Effective Blockchain Enablement",
    fifthSectionPara1: "LCI offers significant cost savings for compute, storage, and networking services required for blockchain deployments. With consistent low prices globally, including dedicated regions, LCI ensures that transitioning to or scaling blockchain solutions is both affordable and efficient.",
    fifthSectionCard1Heading: "50% less",
    fifthSectionCard1Para: "For compute",
    fifthSectionCard2Heading: "70% less",
    fifthSectionCard2Para: "For block storage",
    fifthSectionCard3Heading: "80% less",
    fifthSectionCard3Para: "For networking",
    fifthSectionPara3: "Compute: compared with the cost of a two OCPU (4 vCPU) AMD (E4) with 16 GB over a period of a month.",
    fifthSectionPara4: "Block storage: compared with the cost of 1 TB, 25K IOPS, and 240 MB/sec throughput over a period of a month.",
    fifthSectionPara5: "Networking egress: compared with the cost of 50 TB of egress over the public internet over a period of a month.",
    fifthSectionPara6: "On-demand prices are as of September 13, 2024, in the eastern US region. See Cloud Economics for details.",

    sixthSectionHeading: "Industry-Specific Blockchain Solutions",
    sixthSectionCard1Para2: "Financial institutions leverage LCI’s blockchain capabilities to enhance transaction transparency, meet regulatory requirements, and deploy secure multicloud platforms for decentralized finance.",
    sixthSectionCard1Heading: "Blockchain for Financial Services",
    sixthSectionCard2Para2: "Lattice Exadata Cloud@Customer provides a high-performance, secure database cloud for on-premises blockchain deployments, ensuring compliance and scalability.",
    sixthSectionCard2Heading: "Lattice Exadata Cloud@Customer",
    sixthSectionCard3Para2: "Enable blockchain-powered healthcare solutions to enhance patient data security, streamline operations, and improve care delivery.",
    sixthSectionCard3Heading: "Blockchain in Healthcare",
    sixthSectionCard4Para2: "Integrate blockchain to unlock the value of real-time data, ensuring improved patient outcomes and operational efficiencies.",
    sixthSectionCard4Heading: "Modernize Healthcare with Blockchain",

    eightSectionHeading: "What’s New with LCI?",
    eightSectionCard1Title: "Lattice is named a nominee for 2025 Gartner®️ Magic Quadrant™️ Leader for Strategic Blockchain Cloud Platform Services and Distributed Hybrid Blockchain Infrastructure",
    eightSectionCard1Link1: "Read the Gartner®️ Magic Quadrant™️ story",
    eightSectionCard1redirectPath1: "/news/announcement/lattice-nominated-in-the-2025-gartner-magic-quadrant-reports-for-strategic-cloud-blockchain-platform-services-and-distributed-hybrid-blockchain-infrastructure-2024-11-12",

    eightSectionCard2Title: "Lattice unveils next-gen EHR",
    eightSectionCard2Link1: "Read cloud-based blockchain story",
    eightSectionCard1redirectPath2: "/news/announcement/lattice-unveils-next-generation-ehr-2024-07-18",

    eightSectionCard3Title: "Lattice Database@Azure expands to meet global demand",
    eightSectionCard3Link1: "Read the Lattice Database@Azure story",
    eightSectionCard1redirectPath3: "/news/announcement/lattice-database-at-azure-adds-regions-and-services-to-meet-global-demand-2024-11-11",

    ninthSectionHeading: "Get Started with LCI Blockchain",
    ninthSectionPage1Side1Heading: "Access 20+ Always Free Blockchain-Ready Services",
    ninthSectionPage1Side1Para: "Explore LCI with no time limits on services like Compute, Storage, and Autonomous Database, plus US$300 in free credits to try additional blockchain-enabling services.",
    ninthSectionPage1Side1button: "Try LCI for free",
    ninthSectionPage1Side2Heading: "What’s Included in the Free Tier?",
    ninthSectionPage1Side2List1: "Blockchain-ready Compute VMs",
    ninthSectionPage1Side2List2: "200 GB total block storage",
    ninthSectionPage1Side2List3: "10 GB object storage",
    ninthSectionPage1Side2List4: "2 Autonomous Databases, 20 GB each",
    ninthSectionPage1Side2List5: "10 TB outbound data transfer per month",
    ninthSectionPage1Side2List6: "10+ Always Free services",
    ninthSectionPage1Side2List7: "US$300 in free credits for 30 days",

    ninthSectionPage2Side1Heading: "Learn Blockchain Deployment with Hands-On Labs",
    ninthSectionPage2Side1Para: "Get step-by-step guidance to deploy and scale blockchain applications with LCI. Our labs run on the Free Tier or an Lattice-provided free lab environment.",
    ninthSectionPage2Side1button: "See all tutorials and labs",

    ninthSectionPage2Side2Heading1: "Deploy Core Blockchain Services",
    ninthSectionPage2Side2Para1: "Learn the basics of deploying blockchain-ready services, including virtual networks, compute, and storage on LCI.",
    ninthSectionPage2Side2Button1: "Start blockchain services lab now",
    ninthSectionPage2Side2Heading2: "Quick Start: Autonomous Blockchain Database",
    ninthSectionPage2Side2Para2: "Walk through the steps to deploy a blockchain-enabled Autonomous Database.",
    ninthSectionPage2Side2Button2: "Start quick start lab now",
    ninthSectionPage2Side2Heading3: "Build Blockchain Apps from Data",
    ninthSectionPage2Side2Para3: "Upload data and create blockchain-enabled apps using LCI’s Autonomous Database.",
    ninthSectionPage2Side2Button3: "Start this lab now",
    ninthSectionPage2Side2Heading4: "Deploy Blockchain HA Applications",
    ninthSectionPage2Side2Para4: "Deploy high-availability blockchain applications on LCI with built-in failover support.",
    ninthSectionPage2Side2Button4: "Start HA lab now",

    ninthSectionPage3Side1Heading: "Explore Blockchain Architectures",
    ninthSectionPage3Side1Para: "Discover best practices and ready-to-deploy designs for blockchain solutions, including enterprise apps, AI/ML integrations, and data lakes.",
    ninthSectionPage3Side1button: "See all reference architectures",
    ninthSectionPage3Side2Heading: "Popular Blockchain Architectures",
    ninthSectionPage3Side2List1: "Blockchain-backed finance solutions",
    ninthSectionPage3Side2List2: "Decentralized healthcare data sharing",
    ninthSectionPage3Side2List3: "AI-enhanced supply chain verification",
    ninthSectionPage3Side2List4: "Tokenized asset platforms",
    ninthSectionPage3Side2List5: "Blockchain-powered customer management",
    ninthSectionPage3Side2List6: "High-performance consensus networks",

    ninthSectionPage4Side1Heading: "Contact Lattice Blockchain Experts",
    ninthSectionPage4Side1Para: "Learn how LCI can empower your blockchain applications. Our experts are ready to guide you.",
    ninthSectionPage4Side1button: "Get in touch",
    ninthSectionPage4Side2Heading: "Get answers to questions like:",
    ninthSectionPage4Side2List1: "How does LCI enable blockchain innovation?",
    ninthSectionPage4Side2List2: "What are the best blockchain use cases for my business?",
    ninthSectionPage4Side2List3: "How does LCI compare to other platforms for blockchain?",
    ninthSectionPage4Side2List4: "What is the migration process for blockchain-ready solutions?",

    ninthSectionButton1: "Free Blockchain Services",
    ninthSectionButton2: "Hands-On Labs",
    ninthSectionButton3: "Reference Architectures",
    ninthSectionButton4: "Contact Sales",
};
