import React, { useState } from 'react';
import "./Genral-story.css"; 
import { Link } from 'react-router-dom';
import { footerSections, cookieText } from '../Content/eng_content';
import Latticelogo from '../image/lattice-logo-img.svg';
import { storyPage4Content } from '../Content/English-Content/StoryPage4';

const StoryPage4 = () => {



    
    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

     // cookies
            
            
                const [showCookieModal, setShowCookieModal] = useState(false);
                const [isLoading, setIsLoading] = useState(true);
            
                const handleCookieClick = () => {
                    setShowCookieModal(true);
                    setIsLoading(true);
            
            
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 500);
                };
            
                const handleAcceptAll = () => {
                    console.log("Cookies Accepted");
                    setShowCookieModal(false);
                };
            
                const handleDenyAll = () => {
                    console.log("Cookies Denied");
                    setShowCookieModal(false);
                };





    return (
        <>
            <div className="genralStory-bg-img-1">

                <div className="genralStory-transperant-navbar">

                <Link className="genralStory-Link-of-story" to="/">
                <span>
                    {storyPage4Content.linkText} 
                    <span className="genralStory-arrow-StoryPage4">
                    <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 4l4 4-4 4" stroke="#ffffff" strokeWidth="1.5" fill="none"/>
                    </svg>
                    </span>
                </span>
                </Link>

                </div>

            </div>

            <div className="sp4-bg-img-2">

            </div>

            <div className="genralStory-overlay-div">

               <span className="genralStory-title-story">{storyPage4Content.titleText} </span>

                <h1 className="genralStory-Storyheader-text">
                {storyPage4Content.headerText} 
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>
                

                <div className="sp4-icon-div">

                    
                    {/* <!-- Facebook Icon --> */}
                    <a
                        aria-label="Share on Facebook"
                        title="Share on Facebook"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="genralStory-facebook"
                        >
                        <path
                            d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.407.593 24 1.324 24H12.82v-9.294H9.692V11.53h3.128V8.785c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.098 2.794.142v3.24l-1.918.001c-1.503 0-1.795.714-1.795 1.762v2.31h3.587l-.467 3.176h-3.12V24h6.116C23.407 24 24 23.407 24 22.676V1.324C24 .593 23.407 0 22.676 0z"
                        />
                        </svg>
                       
                    </a>


                    {/* <!-- Twitter Icon --> */}
                    <a
                        aria-label="Share on Twitter"
                        title="Share on Twitter"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M22.46 6c-.77.35-1.6.58-2.46.69a4.27 4.27 0 001.88-2.36c-.83.49-1.75.83-2.72 1.02a4.27 4.27 0 00-7.3 3.9 12.1 12.1 0 01-8.77-4.45 4.27 4.27 0 001.32 5.7A4.23 4.23 0 012 9.71v.05a4.27 4.27 0 003.42 4.18c-.4.11-.82.17-1.25.17a4.25 4.25 0 01-.8-.07 4.27 4.27 0 003.99 2.96 8.56 8.56 0 01-5.3 1.83c-.34 0-.68-.02-1.01-.06a12.06 12.06 0 006.55 1.92c7.88 0 12.2-6.53 12.2-12.2 0-.18 0-.36-.01-.54A8.8 8.8 0 0022.46 6z"
                        />
                        </svg>
                       
                    </a>

                    {/* <!-- LinkedIn Icon --> */}
                    <a
                        aria-label="Share on LinkedIn"
                        title="Share on LinkedIn"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M19 0H5C2.239 0 0 2.239 0 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5V5c0-2.761-2.239-5-5-5zM8.339 20.5H5.337V9h3.002v11.5zm-1.502-13.002a1.501 1.501 0 110-3.002 1.501 1.501 0 010 3.002zM20.5 20.5h-3v-5.621c0-1.341-.025-3.065-1.867-3.065-1.867 0-2.153 1.459-2.153 2.964V20.5h-3.002V9h2.883v1.562h.041c.402-.762 1.384-1.562 2.85-1.562 3.048 0 3.613 2.008 3.613 4.618V20.5z"
                        />
                        </svg>
                       
                    </a>

                    {/* <!-- Email Icon --> */}
                    <a
                        aria-label="Share via Email"
                        title="Share via Email"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M19.5 4h-15A2.5 2.5 0 002 6.5v11A2.5 2.5 0 004.5 20h15a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0019.5 4zm.5 2.5V6l-7.25 5.25a.75.75 0 01-.75 0L5 6v.5h15zM4.5 18a.5.5 0 01-.5-.5v-9.5L12 14.5l8-6.5v9.5a.5.5 0 01-.5.5h-15z"
                        />
                        </svg>
                      
                    </a>
                    </div>




                {/* <p className="genralStory-special-text-para">
                The AI company uses Lattice Cloud Infrastructure (LCI) for the massive computing resources needed to train video foundation models on hours of video.<br></br><br></br>

                

                </p>
                <p className="genralStory-normal-text">
                “By training on Lattice Cloud Infrastructure, I would say we gained 5X to 10X training efficiencies, which allowed us to go to market at a faster pace than we had expected.”
                </p> */}

                <strong className="genralStory-strong-text">{storyPage4Content.specialParaText1} </strong>

                <div className="genralStory-para-and-img">


                <p className="genralStory-normal-text mt-2">
                {storyPage4Content.NormalPara1}  <br></br><br></br>

                {storyPage4Content.NormalPara2} 
                </p>

                



                <div className="sp4-side-img-of-para">

                </div>

                </div>


                <h1 className="genralStory-heading-story mb-3 ">
                {storyPage4Content.headerText2} 
                </h1>

                <ul className="genralStory-normal-text  mb-5">
                <li>{storyPage4Content.ListItem1} </li>
                <li>{storyPage4Content.ListItem2} </li>
                <li>{storyPage4Content.ListItem3} </li>
                <li>{storyPage4Content.ListItem4} </li>
                </ul>

    
                <h1 className="genralStory-heading-story mb-3">
                {storyPage4Content.headerText3} 
                </h1>

                <p className="genralStory-normal-text  mb-5">
                {storyPage4Content.NormalPara3} 
                </p>

                <h1 className="genralStory-heading-story mb-3">
                {storyPage4Content.headerText4} 
                </h1>

                <p className="genralStory-normal-text  mb-5">
                {storyPage4Content.NormalPara4} 
                </p>  
                        
                <h1 className="genralStory-heading-story mb-3">
                {storyPage4Content.headerText5} 
                </h1>

                <p className="genralStory-normal-text  mb-5">
                {storyPage4Content.NormalPara5} 
                </p>


            </div>


            <div className="footer">
                    <div className="f_list">
                        {footerSections.map((section) => (
                            <div key={section.id} className="list-section">
                                <h3
                                    className="footer-heading"
                                    onClick={() => toggleDropdown(section.id)}
                                >
                                    {section.heading}
                                    <span
                                        className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                    >
                                        &#8595;
                                    </span>
                                </h3>
                                <div
                                    className={`resource-list ${openSections[section.id] ? 'show' : ''
                                        }`}
                                >
                                    <ul>
                                        {section.links.map((link, index) => (
                                            <li key={index}>
                                                {link.to.startsWith('/') ? (
                                                    <Link to={link.to}>{link.text}</Link>
                                                ) : (
                                                    <a href={link.to}>{link.text}</a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="footer-bottom">
                              
                                                  <span className="footer-separator"> © 2024 Lattice</span> |
                              
                                                  <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
                              
                                                  <span className="footer-separator"
                                                      style={{ cursor: "pointer", color: "inherit" }}
                                                      onClick={handleCookieClick}> Cookie Preferences  </span>
                              
                                                  <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
                              
                                                  <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                                              </div>
                              
                                          </div>
                              
                              
                                          {showCookieModal && (
                                              <div
                                                  className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                                                      }`}
                                              >
                                                  <div className="cookie-modal">
                              
                                                      {isLoading ? (
                                                          <div className="loading-spinner">{cookieText.loading}</div>
                                                      ) : (
                                                          <div className="cookie-content">
                              
                                                              <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                                                  &times;
                                                              </button>
                                                              <div className="heading-of-cookies-with-logo">
                              
                                                                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>
                              
                                                                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>
                              
                                                              </div>
                              
                                                              <div className="div-2-heading-below-text-and-list">
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>
                              
                                                                  <ul>
                                                                  {cookieText.listItems.map((item, index) => (
                                                                      <li key={index} className="div-2-cookies-list-text">
                                                                          <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                                                      </li>
                                                                      ))}
                                                                  </ul>
                              
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>
                              
                                                              </div>
                              
                                                              <div className="button-group">
                                                                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                                                                     {cookieText.btn1}
                                                                  </button>
                                                                  <button className="btn deny-btn" onClick={handleDenyAll}>
                                                                  {cookieText.btn2}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          )}

        </>
    );
};

export default StoryPage4;
