import React, { useState, useRef } from 'react';
import "./Healthcare.css"
import svg1 from '../image/deliveroo-logo3.png'
import svg2 from '../image/AlbaniaTech-logo.svg'
import svg4 from '../image/german-federal-ministry-logo.svg'
import svg5 from '../image/Four_Seasons_logo3.png'
import svg6 from '../image/The_Related_Companies-Logo.svg'
import svg7 from '../image/imperial-college-london-logo3.svg'
import { professionalServices } from '../Content/English-Content/industry/professional-services';
import { useChatWidget } from "../ChatWidgetContext";

export default function ProfessionalServices () {

      const { toggleWidget } = useChatWidget();


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };


    return (


        <>

            <div className="product-infra-compute-first-div">

                <h1 className="industry-health-care-first-div-heading">
                
                                    {professionalServices.firstSectionHeading}
                                    <span
                                        style={{
                                            display: "block",
                                            width: "40px",
                                            height: "4px",
                                            background: "#f0cc71",
                                            marginBottom: "3vh",
                                            marginTop: "3vh",
                                        }}
                                    ></span>
                
                                    <p className="industry-health-care--first-div-paragraph">{professionalServices.firstSectionPara}</p>
                
                                    <a href="/create-account" style={{ color: "inherit", textDecoration: "none" }}><button className="product-infra-compute-first-div-btn-1">{professionalServices.firstSectionButton}</button></a>
                
                
                                </h1>



            </div>

            {/* 2-div */}

            <div className="last-flat-img-industry"></div>



            {/* 3-div */}


            <div className="product-infra-compute-third-div">

                <h1 className="product-infra-compute-third-div-heading">{professionalServices.secondSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>

                <div className="product-infra-compute-third-div-cards-wrapper">

                    <div className="product-infra-compute-third-div-card-1">

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {professionalServices.secondSectionSide1Heading}

                            </h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{professionalServices.secondSectionSide1Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {professionalServices.secondSectionSide2Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{professionalServices.secondSectionSide2Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">
                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {professionalServices.secondSectionSide3Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{professionalServices.secondSectionSide3Para}</p>

                        </div>

                    </div>

                </div>

            </div>



           


            <div className="product-infra-div-seven">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="become-partners-list-1-sec-last-div-heading">
                    {professionalServices.fifthSectionHeading}
                    </h1>

                    <a href='/customer-story' className="product-infra-div-six-link-of-txt-wrapper">{professionalServices.fifthSectionlink}</a>

                </div>

                <div className="product-infra-div-seven-img-wrapper">

                    <img src={svg1} className="product-infra-div-seven-img-1"></img>

                    <img src={svg2} className="product-infra-div-seven-img-1"></img>

                    <img src={svg4} className="product-infra-div-seven-img-1"></img>

                    <img src={svg5} className="product-infra-div-seven-img-1"></img>

                    <img src={svg6} className="product-infra-div-seven-img-1"></img>

                    <img src={svg7} className="product-infra-div-seven-img-1"></img>


                </div>

            </div>


            {/* div-6 */}

            <div className="industry-div-6-all-wrapper">
            
                            <h1 className="become-partners-list-1-sec-last-div-heading">
                            {professionalServices.sixthSectionHeading}
                            </h1>
            
                            <div className="childs-of-industry-div-6">
            
                                <div className="first-child-of-industry-6-div">
                                    <p className="para-one-of-6-div-industry">{professionalServices.sixthSectionSide1Heading}</p>
            
                                    <p className="para-two-of-6-div-industry">{professionalServices.sixthSectionSide1Para}</p>
            
                                </div>
            
                                <div className="sec-child-of-industry-6-div">
                                    <p className="para-one-of-6-div-industry">{professionalServices.sixthSectionSide2Heading}</p>
            
                                    <p className="para-two-of-6-div-industry">{professionalServices.sixthSectionSide2Para}</p>
            
                                </div>
            
                            </div>
            
                        </div>

            {/* div-7 */}


            <div className="last-div-wrapper-of-industry">
            
                            <h1 className="become-partners-list-1-sec-last-div-heading">
                            {professionalServices.seventhSectionHeading}
                            </h1>
            
                            <div className="childs-of-7-div-industry">
            
                                <div className="first-child-of-7-div-industry">
                                    <div className="fist-child-7-div-section-1-industry"></div>
            
                                    <div className="fist-child-7-div-section-2-industry">
                                        <p className="para-1-of-seven-div-industry">{professionalServices.seventhSectionSide1Para}</p>
            
                                        <button className="contact-btn-2"  onClick={toggleWidget} >{professionalServices.seventhSectionSide1Button}</button>
                                    </div>
                                </div>
            
                                <div className="first-child-of-7-div-industry">
                                    <div className="fist-child-7-div-section-1-industry"></div>
            
                                    <div className="fist-child-7-div-section-2-industry">
                                        <p className="para-1-of-seven-div-industry">{professionalServices.seventhSectionSide2Para}</p>
            
                                        <button className="contact-btn-2"  onClick={toggleWidget} >{professionalServices.seventhSectionSide1Button}</button>
                                    </div>
                                </div>
            
                            </div>
            
                        </div>

        </>


    )
}
