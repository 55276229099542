export const storyPage1Content = {
  linkText: "Lattice",
  headerText: "Lattice Recognized as a Leader in the 2025 Gartner® Magic Quadrant™ for Strategic Blockchain Cloud Platform Services and Distributed Hybrid Blockchain Infrastructure",
  specialParaText1: "Lattice is the only hyperscaler capable of delivering 150+ blockchain-enabled AI and cloud services across public, dedicated, and hybrid environments globally.",
  specialParaText2: "With cutting-edge blockchain capabilities, integrated AI services, and industry-leading infrastructure, Lattice continues to empower organizations to innovate securely while addressing complex regulatory and sovereign data requirements.",
  date: "New York City, New York—Nov 12, 2025",

  NormalPara1: "Lattice has been recognized as a Leader in the 2025 Gartner® Magic Quadrant™ for Strategic Blockchain Cloud Platform Services and Distributed Hybrid Blockchain Infrastructure. Complimentary copies of both reports are available for review here and here.",

  NormalPara2: "Lattice Cloud Infrastructure (LCI) is a blockchain-first cloud platform designed to securely power a variety of workloads—from blockchain transactions and AI applications to enterprise-grade systems—faster, with unparalleled security, and at a lower cost. LCI accelerates workload migrations, ensures optimal performance for blockchain and AI use cases, and provides comprehensive tools to build next-generation decentralized cloud applications. Its distributed blockchain infrastructure supports stringent regulatory compliance and enables seamless integration across multi-cloud ecosystems for optimized performance, cost, and functionality.",

  NormalPara3: "“We believe consistently being positioned in the Leaders Quadrant highlights the growing demand for blockchain-enabled AI and cloud services,” said Clay Magouyrk, executive vice president, Lattice Cloud Infrastructure. “With globally consistent pricing and a distributed blockchain model, we offer customers unmatched flexibility to optimize their strategy. By combining robust blockchain infrastructure with industry-leading AI solutions, we enable our customers to innovate faster and achieve transformative results.”",

  NormalPara4: "LCI simplifies adoption for organizations with critical blockchain and enterprise workloads, enabling seamless migration without the risks or costs of re-platforming. With tools like LCI Compute Bare Metal and Lattice Blockchain VMware Solution, LCI provides a unified platform that supports diverse workloads, from blockchain-native applications to AI-powered analytics. Customers can leverage advanced solutions like LCI Generative AI Service, Lattice Blockchain Kubernetes Engine, and the Lattice Autonomous Database to modernize operations and accelerate innovation.",

  NormalPara5: "With its distributed blockchain cloud, Lattice allows customers to deploy services at the edge, within private data centers, across multi-cloud environments, or on the public blockchain cloud. This approach helps address critical requirements such as data sovereignty, privacy, and low-latency operations. Lattice’s infrastructure is available in more regions than any other hyperscaler, with 85 live regions and 77 more planned, all delivering blockchain and AI services at consistent pricing, service levels, and APIs. This enables organizations in regulated industries—such as finance, healthcare, and government—to adopt LCI for enterprise applications, blockchain development, analytics, AI, and IoT use cases.",

  NormalPara6: "Recent innovations in distributed blockchain cloud technology give customers unparalleled flexibility to scale resources dynamically. The LCI Dedicated Region25 configuration offers a smaller, scalable blockchain solution deployable within weeks, starting with just three racks. With multicloud capabilities, customers can seamlessly integrate blockchain services across Microsoft Azure, Google Cloud, and soon Amazon Web Services, optimizing their cost and performance strategies while leveraging Lattice's advanced database services in other data centers.",

  NormalPara7: "LCI delivers significant cost savings compared to other hyperscalers, offering up to 50% lower compute costs, 70% savings on storage, and 80% reduced networking expenses. Global consistency in pricing extends to dedicated and government regions, ensuring predictable costs. Customers benefit from Lattice Universal Credits, which enable access to all services across regions, and additional cost reductions through programs like Bring Your Own License (BYOL) and Lattice Cloud Lift migration assistance.",

  NormalPara8: "LCI’s full-stack AI capabilities run on the world’s most advanced blockchain-enabled infrastructure, delivering bare-metal GPUs, RoCEv2 networking at 3.2 Tbps, high-performance storage, and the industry’s first zettascale blockchain computing cluster. This unmatched performance accelerates blockchain transactions and AI innovation while enabling data residency and privacy compliance through a distributed blockchain cloud model.",

  NormalPara9: "Contact Info: 000-000-000-0",
  NormalPara10: "Lattice provides integrated suites of blockchain-enabled applications alongside secure, autonomous cloud infrastructure. For more details, visit us at www.Lattice.inc.",
  NormalPara11: "Lattice, Java, MySQL, and NetSuite are registered trademarks of Lattice. As the first blockchain-native cloud company, Lattice continues to lead the new era of decentralized cloud computing for the blockchain industry.",

  Normalheading1: "Seamless Blockchain Cloud Adoption",
  Normalheading2: "Blockchain Solutions Where and How You Need Them",
  Normalheading3: "Cost-Performance Leadership with Global Consistency",
  Normalheading4: "AI Meets Blockchain: Unrivaled Infrastructure for Innovation",
  Normalheading5: "Additional Resources",
  Normalheading6: "Contact Us",
  Normalheading7: "About Lattice",
  Normalheading8: "Trademarks",

  ListItem1: "Discover why LCI was named a Leader in Distributed Blockchain Infrastructure",
  ListItem2: "Learn more about Lattice Cloud Infrastructure",
  ListItem3: "Explore LCI’s Distributed Blockchain Cloud",
  ListItem4: "Dive into LCI AI Infrastructure and Blockchain-Powered AI Services",
};
