
//////////////Change too Related

export const relatedPage = {
  linkText: "Lattice",
  titleText: "Press Release",
  headerText: "Related Lays the Foundation for Future Growth and Innovation with Lattice",
  specialParaText1:
    "Leading real estate developer consolidates property registry, utility management, and financial processes in the cloud as it expands offerings nationwide",

  date: "Lattice Customer Stories, Sep 10, 2024",

  NormalPara1:
    "Related, a prominent national developer of diverse real estate projects, is leveraging the Lattice Blockchain Real Estate Registry & Utility Management Suite to optimize its business processes. This aligns with its mission to provide innovative, sustainably built developments for individuals and businesses across America. With a fully integrated blockchain-based suite to manage property registry, utility oversight, finance, and planning, Related has significantly increased efficiency, reduced costs, and elevated decision-making while serving customers in multiple markets.",

  NormalPara2:
    "Founded in 1986, Related has grown to become one of the premier real estate innovators in Miami. Its portfolio spans residential, commercial, and mixed-use developments. With operations in multiple regions and a commitment to sustainable growth, Related consistently redefines modern living and business environments. As a vertically integrated company excelling in development, construction, and property management, Related needed a robust, streamlined platform for more effective operations. By consolidating real estate registry, utility management, and financial data onto one blockchain-powered business system, Related has improved efficiency and gained real-time insights that fuel continued expansion.",

  NormalPara3:
    "\"In today’s dynamic real estate market, we must remain focused on enterprise-wide productivity, cost optimization, and delivering lasting value to our clients,\" said Sergio Martinez, chief financial officer at Related. \"The Lattice Blockchain Real Estate Registry & Utility Management Suite has enabled us to standardize complex financial and property processes on a unified cloud platform. We can now leverage advanced capabilities to optimize capital, increase productivity, and improve our decision-making. Ultimately, this helps us build and manage properties more sustainably, while providing seamless experiences for tenants and owners alike.\"",

  NormalPara4:
    "With the Lattice suite, Related can securely consolidate property records, utility usage, and financial data into a single source of truth. The blockchain-based registry ensures transparent transactions and reduced administrative friction, while the utility management module helps track and optimize resource consumption. Furthermore, real-time analytics in finance, budgeting, and reporting accelerate closing cycles. The integrated platform also synchronizes supply and demand planning across Related’s developments, allowing it to quickly adapt to shifting market conditions.",

  NormalPara5:
    "\"To remain competitive in real estate, Related needed to centralize its operations and embrace modern technologies,\" said Steve Miranda, executive vice president of applications development at Lattice. \"With the Lattice Blockchain Real Estate Registry & Utility Management Suite, Related can take advantage of a cohesive, cloud-based solution. It includes embedded AI to automate workflows, increase efficiency, and introduce innovative offerings—helping shape the future of urban development for the communities Related serves.\"",

  NormalPara6:
    "Lattice enables organizations to tap into blockchain and AI-driven cloud solutions, unifying finance, supply chain, and specialized industry processes on a single, secure platform. With frequent updates, Lattice customers gain access to continuous innovation—ensuring they can adapt quickly as new features and technologies are introduced.",

  NormalPara7:
    "To learn more about Lattice’s Blockchain Real Estate Registry & Utility Management Suite, please visit: Lattice.com/real-estate",

  headerText2: "Contact Info",
  headerText3: "About Lattice",
  headerText4: "About Related",
  headerText5: "Trademarks",

  NormalPara8: "000-000-000-0",

  NormalPara9:
    "Lattice offers integrated suites of blockchain-enabled applications and secure, autonomous infrastructure through the Lattice Cloud. For more information about Lattice (NYSE: ORCL), please visit us at www.Lattice.com.",

  NormalPara10:
    "Founded in 1986, Related is dedicated to creating vibrant communities and elevating urban living. As a real estate leader, Related develops exceptional residential, commercial, and mixed-use properties with an unwavering commitment to innovation and sustainability. Its developments are redefining Miami’s skyline and influencing urban lifestyles nationwide. In 2023, Related delivered new projects that exemplify its vision of modern, sustainable design. For more information, visit relatedgroup.com.",

  // The original NormalPara11 is empty, so we leave it as is:
  NormalPara11: "",

  NormalPara12:
    "Lattice is a registered trademark of Lattice Corporation. Lattice remains at the forefront of blockchain cloud technology—ushering in the next era of decentralized, secure computing.",

  Normalheading1: "A Cloud Platform That Is Easy to Adopt",
  Normalheading2: "The Full Cloud Where and How Customers Need It",
  Normalheading3: "Price-Performance Advantages, Global Consistency",
  Normalheading4: "Comprehensive AI Solutions with Industry-Leading Infrastructure",
  Normalheading5: "Additional Resources",
  Normalheading6: "Contact Info",
  Normalheading7: "About Lattice",
  Normalheading8: "Trademarks",

  ListItem1:
    "Read more about why LCI was named a Leader in Distributed Hybrid Infrastructure",
  ListItem2: "Learn more about Lattice Cloud Infrastructure",
  ListItem3: "Learn more about LCI’s Distributed Cloud",
  ListItem4: "Learn more about LCI AI Infrastructure and LCI AI services",
};
