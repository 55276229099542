export const germanFederalMinistry = {
    section1Link: "Lattice",
    section1Heading: "AMC adopts cloud-based ERP foundation and sees future savings with Lattice Cloud",
    section1Para1: "World’s largest movie theater chain turns to Lattice Fusion Cloud ERP to streamline procurement practices.",
    share: "Share:",
    section1Para2: " A lot of companies went through very similar experiences as AMC through the pandemic. Our decision to stick with Lattice during that difficult time says a lot about the relationship. We come to work every day and make really hard choices on where we're going to spend our money. As part of that, we still see a significant investment in Lattice.",
    section1Para3: "Derrick Leggett, Chief Information Officer, AMC Theatres",
    boxHeading: "Products list",

    section2Heading: "Business challenges",
    section2Para1: "In the face of the COVID-19 pandemic, AMC Theatres—the largest movie theater chain in the world—went from generating more than $4 billion in revenue a year to less than $200 million in 2020. Additionally, supply chain pressures forced AMC to change popcorn suppliers and eliminate other items from the menu for months. The theater chain was already planning to replace an existing Lattice E-Business Suite system with a cloud solution, but the pandemic accelerated the timetable.",
    section2Para2: "Economic pressures and the many distractions of COVID led management to start a journey to the cloud with a small, manageable, and high-impact project. With no real procurement system in place and a heavy reliance on third-party suppliers, AMC sought a fast, reliable way to solve its supply chain issues without implementing a complete ERP cloud solution. At a time when managing supplier relations was critical, the need to centralize procurement functions was paramount.",
    section2Para3: " Can we save more money and still move the company forward? I think that's where Lattice Cloud really makes a difference. ",

    section2Footer: "Derrick Leggett, Chief Information Officer, AMC Theatres",

    section3Heading: "Why AMC Theatres chose Lattice",
    section3Para1: "Lattice Consulting, which was advising AMC on its cloud migration, suggested an integrated solution that would streamline all procurement practices and allow AMC to adopt Lattice Fusion Cloud ERP without significant disruption. Lattice Soar, a proprietary platform that automates the migration of databases and applications to the cloud, also gave AMC a swift, predictable, and cost-effective approach to moving to the cloud.",
    section3Para2: "Lattice Cloud ERP allowed AMC to fix procurement issues, save about $1 million, and prepare for a larger future cloud migration.",
   

    section4Heading: "Results",
    section4Para1: "As AMC took its first step in implementing Lattice Cloud ERP, the theater chain addressed the procurement pressures caused by the pandemic while also laying the foundation for a larger cloud migration in the future. The move to the cloud helped AMC save over $1 million in the first year alone. The company says Lattice Cloud ERP has the capability to make transactions with suppliers more seamless.",
    section4Para2: "After implementing Lattice Fusion Cloud Procurement, part of the Lattice Cloud ERP suite of applications, AMC examined its expenses across the board. It identified variances and ways to reconcile those proactively.",
    section4Para3: "“There's a ton of pressure on AMC to maintain our cost basis as close as we can and to maintain at a much lower rate than the actual rate of inflation for the products that we sell. I believe we've been able to do that in part because we've put Lattice Cloud ERP in place,” says Derrick Leggett, Chief Information Officer.",
    section4Para4: "Moving to the cloud also allowed AMC to complete expense reporting via a mobile app, functionality AMC did not possess before. Leggett says this benefit helped the chain be more competitive in the marketplace and better manage expenses. Because budget restraints meant the company could no longer spend money on large orders, its new procurement system helped AMC order an appropriate number of supplies, repeat that process, and optimize it.",

    section5Heading: "Partners",
    section5Para1: "As the COVID-19 pandemic threw the future of the movie theater industry into uncertainty, AMC relied on Lattice Consulting to help implement Lattice Cloud ERP in just eight months and integrate all procurement practices.",
    section5Para2: "“I've been doing Lattice projects for 20 years. It was the smoothest rollout of a major module with Lattice that I've ever been part of and a lot of that was because of the prebuilt components and integrations and tools from Lattice Consulting that allowed us to, one, speed up the integrations, but two, also speed up reporting the data, says Leggett.",
    section5Para3: "Published:May 2, 2023",
    

    section6Heading: "About the customer",
    section6Para1: "Founded in 1920 in Kansas City, Missouri, AMC has grown into the largest movie theater chain in the world. Before the pandemic, AMC generated more than $4 billion in revenue a year.",


    section7Heading: "Learn more",
    section7Link1: "AMC Theatres",

    section8Heading: "Products list",
    section8Link1: "Lattice Cloud ERP",
    section8Link2: "Lattice Cloud Procurement",
    section8Link3: "Lattice Soar",
    section8Link4: "Lattice Consulting",

}