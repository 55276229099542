import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import NavBar from './NavBar'; 
import HomePage from './HomePage'; 
import About from './About'
import SignInPage from "./SignInPage";
import ContactPage from './ContactPage'; 
import CreateAcc from "./Create-account";
import TermandPrivacy from "./privacyPage";
import CustomerDetails from './CustomerDetails';
import StoryPage1 from './Slide-ReadmorePages/Storypage1';
import StoryPage2 from './Slide-ReadmorePages/StoryPage2';
import StoryPage3 from './Slide-ReadmorePages/Storypage3';
import StoryPage4 from './Slide-ReadmorePages/Storypage4';
import StoryPage5 from './Slide-ReadmorePages/Storypage5';
import StoryPage6 from './Slide-ReadmorePages/Storypage6';
import StoryPage7 from './Slide-ReadmorePages/Storypage7';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import LeadershipPage from './AboutPage-External-Pages/Leadership'
import EmployPage from './AboutPage-External-Pages/employPage'; 
import SmiPage from './customers/smiPage';
import FourSeason from './customers/fourseason.js';
import CustomerStory from './customers/customer-story.js';
import Deleveroo from './customers/deleveroo.js';
import ICL from './customers/Imperial-College-London.js';
import FourSeasonCustomer from './customers/The-Four-Season-customer.js';
import SMICustomer from './customers/SMI-customer-page.js'
import AT from './customers/Albania-Tech.js'
import GFM from './customers/German-Federal-Ministry.js'
import DAI from './customers/D-Ai-Page.js'
import DeveloperRS from './nav-bar-developer-pages/developer-resource-center.js'
import Events from './nav-bar-developer-pages/developer-event.js'
import NavbarEvents from './nav-bar-company-pages/event-page.js';
import NavbarCareers from './nav-bar-company-pages/career-page.js';
import InvestorRelation from './nav-bar-company-pages/investor-relation-ship.js'
import BlogCompany from './nav-bar-company-pages/blog-page.js'
import NewsCompany from './nav-bar-company-pages/company-news.js'

import BecomeCustomer from './nav-bar-Customer-pages/become-a-customer.js'
import CustomerReview from './nav-bar-Customer-pages/customer-review.js'

import BecomeParterns from './nav-bar-Parteners-Pages/become-partners.js'

import LatticeInfraTemplate from './nav-bar-product-pages/Lattice-cloud-infrasturcture-compute-template-page.js'
import FungiblePrivateTokenGenerator from './product-tools-pages/fungible-private-token-generator.js';
import NonFungiblePrivateTokenGenerator from './product-tools-pages/non-fungible-private-token-generator.js';
import PrivateTokenAdministration from './product-tools-pages/private-token-administration.js';
import NonfungibleTokenAdministration from './product-tools-pages/non-fungible-private-token-administration.js';

import LatticeOverview from './nav-bar-product-pages/LCI-overview-page.js';
import ProductInfraAIML from './nav-bar-product-pages/AI-ML-page.js';
import ProductInfraNetworking from './nav-bar-product-pages/NetworkingPage.js';
import ProductInfraStorage from './nav-bar-product-pages/StoragePage.js';
import ProductInfraReporting from './nav-bar-product-pages/Reportingpage.js';
import ProductInfraBlockchainConfig from './nav-bar-product-pages/Private-Blockchain-Configuration.js';

import ProductSection2Page1 from './nav-bar-product-section-2/Product-Section-2-page-1.js';
import ProductSection2Page2 from './nav-bar-product-section-2/Product-Section-2-page-2.js';
import ProductSection2Page3 from './nav-bar-product-section-2/Product-Section-2-page-3.js';
import ProductSection2Page4 from './nav-bar-product-section-2/Product-Section-2-page-4.js';
import ProductSection2Page5 from './nav-bar-product-section-2/Product-Section-2-page-5.js';
import ProductSection2Page6 from './nav-bar-product-section-2/Product-Section-2-page-6.js';
import ProductSection2Page7 from './nav-bar-product-section-2/Product-Section-2-page-7.js';
import ProductSection2Page8 from './nav-bar-product-section-2/Product-Section-2-page-8.js';
import ProductSection2Page9 from './nav-bar-product-section-2/Product-Section-2-page-9.js';
import ProductSection2Page10 from './nav-bar-product-section-2/Product-Section-2-page-10.js';
import WalletServices from './product-tools-pages/wallet-services.js';
import UserIdentity from './product-tools-pages/user-identity.js';
import WebCast from './ComingSoonPage/WebCast.jsx';

import HealthCare from './nav-bar-industry-section-pages/HealthCare.jsx';
import Education from './nav-bar-industry-section-pages/education.jsx';
import FinancialServices from './nav-bar-industry-section-pages/financial-services.jsx';
import MediaEntertainment from './nav-bar-industry-section-pages/media-entertainment.jsx';
import ProfessionalServices from './nav-bar-industry-section-pages/professional-services.jsx';


import { ChatWidgetProvider } from './ChatWidgetContext.jsx';
import ChatBot from './Chat-Bot.jsx';


import LCIAboutPage from './LCINavBarPages/LCIAboutPage.jsx';


// import LocomotiveScroll from 'locomotive-scroll';





function App() {

  // useEffect(() => {
  //   const scroll = new LocomotiveScroll({
  //     el: document.querySelector('[data-scroll-container]'),
  //     smooth: window.innerWidth > 768, // Enable smooth scrolling only on devices wider than 768px
  //     inertia: 0.05, // Lower values for rougher scrolling
  //     smoothMobile: false, // Disable smooth scrolling for mobile devices
  //   });

  //   return () => scroll.destroy();
  // }, []);

  
  return (
    <ChatWidgetProvider>
    <Router>
      <NavBar/> {/* This renders the navigation bar */}
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} /> {/* Main/Home page */}
          <Route path="/contact" element={<ContactPage />} /> {/* Contact page */}
          <Route path="/about" element={<About />} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/create-account" element={<CreateAcc />} />
          <Route path="/privacy" element={<TermandPrivacy />} />
          <Route path="/customer-details" element={<CustomerDetails />} />
          <Route path="/news/announcement/lattice-nominated-in-the-2025-gartner-magic-quadrant-reports-for-strategic-cloud-blockchain-platform-services-and-distributed-hybrid-blockchain-infrastructure-2024-11-12" element={<StoryPage1 />} />
          <Route path="/news/announcement/lattice-unveils-next-generation-ehr-2024-07-18" element={<StoryPage2 />} />
          <Route path="/news/announcement/lattice-database-at-azure-adds-regions-and-services-to-meet-global-demand-2024-11-11" element={<StoryPage3 />} />
          <Route path="/news/announcement/lattice-labs-runs-large-scale-ai-workloads-on-lattice-cloud-infrastructure" element={<StoryPage4 />} />
          <Route path="/news/announcement/german-federal-ministry-of-health-expanding-lattice-health-ehr-to-30-of-its-hospitals-2024-09-12" element={<StoryPage5 />} />
          <Route path="/human-capital-management/ai-agents-open-enrollment" element={<StoryPage6 />} />
          <Route path="/news/announcement/lattice-health-clinical-ai-agent" element={<StoryPage7 />} />
          <Route path="/leadership" element={<LeadershipPage/>} />
          <Route path="/employdetails/:id" element={<EmployPage/>} />
          <Route path="/customers/d-ai/" element={<DAI />} />
          <Route path="/related" element={<SmiPage />} />
          <Route path="/fourseason" element={<FourSeason />} />
          <Route path="/customer-deliveroo" element={<Deleveroo/>} />
          <Route path="/customer-imperialcollege" element={<ICL/>} />
          <Route path="/customer-fourseason" element={<FourSeasonCustomer/>} />
          <Route path="/customer-germanfederalministry" element={<GFM/>} />
          <Route path="/customer-related" element={<SMICustomer/>} />
          <Route path="/customer-albaniatech" element={<AT/>} />
          <Route path="/customer-story" element={<CustomerStory/>} />


          <Route path="/developer-resource-center" element={<DeveloperRS/>} />
          <Route path="/developer-events" element={<Events/>} />

          <Route path="/investor-relationship" element={<InvestorRelation/>} />
          <Route path="/events" element={<NavbarEvents/>} />
          <Route path="/careers" element={<NavbarCareers/>} />
          <Route path="/company-blogs" element={<BlogCompany/>} />
          <Route path="/company-news" element={<NewsCompany/>} />

          <Route path="/become-customer" element={<BecomeCustomer/>} />
          <Route path="/customer-review" element={<CustomerReview/>} />

          <Route path="/become-partners" element={<BecomeParterns/>} />


          <Route path="/lattice-infrastructure-compute" element={<LatticeInfraTemplate/>} />
          <Route path="/fungible-private-token-generator" element={<FungiblePrivateTokenGenerator/>} />
          <Route path="/non-fungible-private-token-generator" element={<NonFungiblePrivateTokenGenerator/>} />
          <Route path="/private-token-administration" element={<PrivateTokenAdministration/>} />
          <Route path="/non-fungible-private-token-administration" element={<NonfungibleTokenAdministration/>} />
          <Route path="/wallet-services" element={<WalletServices/>} />
          <Route path="/user-identity" element={<UserIdentity/>} />

          <Route path="/lci-overview" element={<LatticeOverview/>} />
          <Route path="/lattice-ai-ml" element={<ProductInfraAIML/>} />
          <Route path="/lattice-Networking" element={<ProductInfraNetworking/>} />
          <Route path="/lattice-storage" element={<ProductInfraStorage/>} />
          <Route path="/lattice-reporting" element={<ProductInfraReporting/>} />
          <Route path="/lattice-blockchain-configuration" element={<ProductInfraBlockchainConfig/>} />

          <Route path="/health-suite" element={<ProductSection2Page1/>} />
          <Route path="/human-identity-profile-suite" element={<ProductSection2Page2/>} />
          <Route path="/real-estate-registry-utility-management-suite" element={<ProductSection2Page3/>} />
          <Route path="/campaign-elections-suite" element={<ProductSection2Page4/>} />
          <Route path="/supply-chain-manufacturing" element={<ProductSection2Page5/>} />
          <Route path="/hr-suite" element={<ProductSection2Page6/>} />
          <Route path="/wealth-management-suite" element={<ProductSection2Page7/>} />
          <Route path="/charitable-institutions-suite" element={<ProductSection2Page8/>} />
          <Route path="/gamified-retail-sector-suite" element={<ProductSection2Page9/>} />
          <Route path="/file-management-suite" element={<ProductSection2Page10/>} />
          <Route path="/investor-relationship/web-cast" element={<WebCast/>} />


          <Route path="/health" element={< HealthCare/>} />
          <Route path="/education" element={< Education/>} />
          <Route path="/financial-services" element={< FinancialServices/>} />
          <Route path="/media-entertainment" element={< MediaEntertainment/>} />
          <Route path="/professional-services" element={< ProfessionalServices/>} />

          <Route path="/cloud/why-lci" element={< LCIAboutPage/>} />


        </Routes>
      </div>
      <ChatBot />
    </Router>
    </ChatWidgetProvider>
  );
}

export default App;
