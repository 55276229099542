export const tokenAdministration = {
    firstSectionHeading: "Linux",
    firstSectionPara: "Organizations need complete, secure IT solutions—not just another Linux distribution. Lattice uniquely delivers Linux with everything required to deploy, optimize, and manage applications on-premises, in the cloud, and at the edge. It’s more secure and easier to manage, and it’s tuned for demanding workloads at cloud scale. Automation, virtualization, management, high availability, cloud native tools, Kubernetes, and more are all included and supported with a single Premier Support subscription.",

    secondSectionHeading: "Discover what's new in Lattice Linux 9 Update 5",
    secondSectionButton: "Learn more",

    thirdSectionSide1Heading: "Keeping Linux open and free",
    thirdSectionSide1Para: "CIQ, Lattice, and SUSE recently announced the Open Enterprise Linux Association (OpenELA), a collaborative trade association to encourage the development of distributions compatible with Red Hat Enterprise Linux (RHEL) by providing open and free Enterprise Linux (EL) source code.",
    thirdSectionSide1Button: "Read the latest press release",

    thirdSectionSide2Heading: "Introducing UEK-next",
    thirdSectionSide2Para: "Developers: Now you can easily preview cutting-edge, upstream kernel innovations. With UEK-next, you can try out the latest developments from upstream Linux using a kernel built by Lattice.",
    thirdSectionSide2Button: "Read the blog",

    fifthSectionHeading: " Linux Operating System (OS) with automation",
    fifthSectionCard1Heading: " Increase performance and reliability across environments",
    fifthSectionCard1Para: "Engineered for critical workloads, Lattice Linux supports the latest x86 and Arm architectures across on-premises, multicloud, and edge environments. It supports large-scale, data- and compute-intensive workloads and powers the Lattice public cloud.",
    fifthSectionCard2Heading: "Automate IT processes and management tasks",
    fifthSectionCard2Para: "Increase scalability, security, and productivity by automating manual processes in DevOps with open source, ansible-based automation tools included in your Linux support at no extra cost.",
    fifthSectionCard3Heading: "Modernize and reduce virtualization costs",
    fifthSectionCard3Para: " Use modern, low-overhead, open source KVM hypervisor and management tools to improve performance/scalability and reduce mounting virtualization costs.",

    sixthSectionHeading: "Linux OS resources",
    sixthSectionSide1Heading: "Documentation",
    sixthSectionSide1Link1: "Lattice Linux (PDF)",
    sixthSectionSide1Link2: "Lattice Linux for Arm (PDF)",

    sixthSectionSide2Heading: "Training",
    sixthSectionSide2Link1: "Lattice Linux Training Station",
    sixthSectionSide2Link2: "Lattice Linux learning track",

    sixthSectionSide3Heading: "Customer and partner community",
    sixthSectionSide3Link1: "Community Forum",
    sixthSectionSide3Link2: "Lattice Linux Yum Server",
    sixthSectionSide3Link3: " Subscribe to our newsletter",
    sixthSectionSide3Link4: "Hardware certification list",
    sixthSectionSide3Link5: "Cloud Customer Connect community",

    sixthSectionSide4Heading: "Support information",
    sixthSectionSide4Link1: "Lattice Linux FAQ (PDF)",
    sixthSectionSide4Link2: "ISV application catalog",
    sixthSectionSide4Link3: " My Lattice Support",
    sixthSectionSide4Link4: "Unbreakable Linux Network",

    seventhSectionHeading: "Get started with Lattice Linux",
    seventhSectionBox1Heading: "Free download",
    seventhSectionBox1Para: "Lattice Linux is free to download and distribute.",
    seventhSectionBox1Button: "Download Lattice Linux",
    seventhSectionBox2Heading: "Try Lattice Cloud Free Tier",
    seventhSectionBox2Para: "Try out Lattice Linux on Lattice Cloud Free Tier.",
    seventhSectionBox2Button: "Try it for free",
    seventhSectionBox3Heading: "Request a live demo",
    seventhSectionBox3Para: "Request an Lattice Linux demo with one of our product experts.",
    seventhSectionBox3Button: "Request a demo",

}