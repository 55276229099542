export const storyPage6Content = {
    linkText: "Lattice",
    titleText: "Press Release",
    headerText: "Simplify Open Enrollment with Lattice’s AI-Powered Benefits Analyst Agent",
    specialParaText1: "Imagine having a dedicated AI-powered HR assistant to guide you through benefits questions, coverage changes, and enrollment deadlines seamlessly.",

    date: "Nathaniel Brosnan | November 7, 2024",

    NormalPara1: "November 16, 2024 – Open enrollment season is here, and employees across the United States are navigating new healthcare options and pricing for the upcoming year. This time, Lattice’s cutting-edge AI technology is stepping in to make the process less stressful—for both employees and HR teams.",
    NormalPara2: "Meet Jake, an employee at a financial services firm preparing to welcome his first child. Unsure of how his insurance coverage should adapt, Joe now has access to Lattice’s Benefits Analyst AI Agent through his company’s enrollment platform. This revolutionary tool enables Joe to ask personalized, real-time questions, including:",
    NormalPara3: "“We’re expecting a baby next year—what benefit changes should I consider?”",
    NormalPara4: "“What maternity-related benefits are covered under my wife’s plan (prenatal care, hospital delivery, etc.)?”",
    NormalPara5: "“How do we add our newborn to the health plan, and when does the coverage begin?”",
    NormalPara6: "The AI agent pulls from company benefits documentation and other relevant data—like existing benefit selections and eligibility—providing Joe with accurate, tailored answers. Joe leaves the interaction confident in his decisions.",
    NormalPara7: "This innovation doesn’t just benefit employees like Joe; HR teams also reap the rewards. Open enrollment is an opportunity for companies to demonstrate their commitment to employee well-being, which is increasingly important. According to the 2024 MetLife Employee Benefit Trends Study, half of employees believe understanding their benefits better would increase loyalty to their employer.",
    NormalPara8: "Yet addressing individual employee needs at scale has been a perennial challenge for HR teams. With the AI agent acting as a personalized advisor, employees gain clarity about health, dental, and vision benefits based on their unique needs. Lattice’s Benefits Analyst AI Agent is designed to save time, cut costs, and improve the enrollment experience for everyone involved.",
    NormalPara9: "By reducing the time HR teams spend on repetitive questions, the AI agent frees up resources for strategic initiatives, such as refining benefits packages or enhancing engagement programs.",
    NormalPara10: "The Benefits Analyst AI Agent is just one of more than 50 AI agents Lattice has integrated into its Fusion applications. These AI tools deliver practical, easy-to-access support where and when employees need it most, revolutionizing workflows.",
    NormalPara11: "Since the rise of generative AI two years ago, Lattice has embedded AI capabilities into workflows such as SMART goal creation, performance reviews, and job requisitions. With its latest AI agents, Lattice takes these advancements even further. Fully integrated into Lattice Cloud HCM, these agents offer personalized, real-time assistance across tasks like hiring workflows and workforce scheduling.",
    NormalPara12: "Like the Benefits Analyst, these AI-powered agents engage users in conversational, context-aware interactions. By retaining context from prior questions, the agents can provide seamless, efficient support. For example, if Joe mentions moving to Florida, the AI agent updates its recommendations without requiring Joe to re-enter information.",
    NormalPara13: "This personalization and efficiency transform how employees and HR teams interact with benefits systems.",
    NormalPara14: "The Benefits Analyst AI Agent is available now. Current Lattice Fusion Cloud HCM customers can contact their Lattice representative to integrate this transformative tool into their benefits enrollment process.",
    NormalPara15: "Learn how AI agents on the Lattice HR Suite drive employee productivity and organizational growth.",
    NormalPara16: "Discover how generative AI and AI agents are revolutionizing HR success and employee experiences.",

    headerText2: "Why AI Agents Matter for HR",
    headerText3: "Explore AI-Powered Solutions on the Lattice HR Suite",
    headerText4: "AI Agents: Transforming Open Enrollment",
    headerText5: "Take the Next Step",
    headerText6: "Discover More Ways AI Agents Enhance HR",
    headerText7: "Join the GenAI for Lattice HR Suite Demo Webinar",



    


    Normalheading1:"A Cloud Platform That is Easy to Adopt",
    Normalheading2:"The Full Cloud Where and How Customers Need It",
    Normalheading3:"Price-Performance Advantages, Global Consistency",
    Normalheading4:"Comprehensive AI Solutions with Industry-Leading Infrastructure",
    Normalheading5:"Additional Resources",
    Normalheading6:"Contact Info",
    Normalheading7:"About Lattice",
    Normalheading8:"Trademarks",
    

    ListItem1:"Read more about why LCI was named a Leader in Distributed Hybrid Infrastructure",
    ListItem2:"Learn more about Lattice Cloud Infrastructure",
    ListItem3:"Learn more about LCI’s Distributed Cloud",
    ListItem4:"Learn more about LCI AI Services powered by D-AI",
 
  };