export const blogPage = {
    firstSectionHeader: "Lattice Blockchain Blogs",
    firstSectionPara: "Your go-to hub for the latest blockchain news, AI breakthroughs, product releases, and industry insights from Lattice's fully integrated cloud platform.",
    
    firstArticleHeading: "Women developing blockchain 2023",
    // firstArticlePara: "Women in Blockchain is creating a leading diversity-driven blockchain education and networking ecosystem. This aims to amplify underrepresented voices within blockchain in order to give the skills and confidence to anyone — regardless of age, gender, nationality or background — to blaze their own personal or professional trail within the Metaverse.",

    firstArticleDuration: "Read more",

    secondArticleHeading: "How blockchain will defeat cybersecurity",
    // secondArticlePara: "The process of building a blockchain is powered by artificial intelligence (AI) to detect and prevent malicious data tampering. Also, AI specifically designed to protect systems and databases can be implemented in a more distributed model as blockchain applications that do not require trusted nodes to remain untouched.",
    secondArticleDuration: "Read more",

    thirdArticleHeading: "Cybersecurity solutions for the blockchain industry",
    // thirdArticlePara: "Bad actors take advantage of the decentralized nature of the internet to both maintain anonymity and overcome resistance to their attacks. A common DDoS method works by first infecting multiple nodes over a variety of domains to form a semi-coordinated network called a “botnet.”",
    thirdArticleDuration: "Read more",

    secondSectionHeading: "The Lattice Backpack has finally arrived",
    secondSectionPara: "Lattice Backpack is now here and it is the first of its kind. An innovative Web3 cold wallet that empowers users to securely carry and utilize their digital assets across the web, providing a convenient and reliable solution for managing and transacting with tokens and any digital art assets across the metaverse and beyond Web3.",
    // secondSectionDuration: "3 minute read",

    searchBlogs: "Search Lattice Blogs",
    recieveBlogUpdates: "Receive the latest blog updates",
    subcribeToLatticeButton: "Subscribe to Lattice Connect for updates",

    thirdSectionHeading: "Lattice Blockchain Cloud Infrastructure Blog",
    thirdSectionArticle1Heading: "The Lattice Backpack has finally arrived",
    // thirdSectionArticle1Duration: "4-minute read",
    readMore: "Read More",

    thirdSectionArticle2Heading: "Women developing blockchain 2023",
    thirdSectionArticle2Duration: "4-minute read",

    thirdSectionArticle3Heading: "How blockchain will defeat cybersecurity",
    thirdSectionArticle3Duration: "6-minute read",

    thirdSectionArticle4Heading: "Cybersecurity solutions for the blockchain industry",
    thirdSectionArticle4Duration: "4-minute read",

    thirdSectionArticle5Heading: "Lattice Labs Sponsors AS Monaco Basketball",
    thirdSectionArticle5Duration: "4-minute read",

    thirdSectionArticle6Heading: "Blockchain Education Study 2022",
    thirdSectionArticle6Duration: "6-minute read",

    thirdSectionArticle7Heading: "LCI Search with OpenSearch v2.15: Introducing New AI and ML Capabilities",
    thirdSectionArticle7Duration: "4-minute read",

    thirdSectionArticle8Heading: "Arcon PAM Integrates with Lattice Blockchain Access Governance",
    thirdSectionArticle8Duration: "5-minute read",

    seeAll: "See All",

    fourthSectionHeading: "The Fusion Insider Blog",
    fourthSectionArticle1Heading: "AI & Blockchain Takeover—2024's Most Popular Fusion Apps Stories",
    fourthSectionArticle1Duration: "3-minute read",

    fourthSectionArticle2Heading: "See How AI and Blockchain Agents Will Work in Your ERP and EPM",
    fourthSectionArticle2Duration: "4-minute read",

    fourthSectionArticle3Heading: "Top Customers Share Fusion + Blockchain Apps Best Practices for 2025",
    fourthSectionArticle3Duration: "3-minute read",

    fourthSectionArticle4Heading: "4 ERP Best Practices to Adopt in 2025",
    fourthSectionArticle4Duration: "3-minute read",

    fourthSectionArticle5Heading: "AI + Blockchain = Better Recruiting in 2025",
    fourthSectionArticle5Duration: "3-minute read",

    fourthSectionArticle6Heading: "Quarterly Updates Made Easy",
    fourthSectionArticle6Duration: "3-minute read",

    fourthSectionArticle7Heading: "Introducing AI Agents in Fusion Apps",
    fourthSectionArticle7Duration: "3-minute read",

    fourthSectionArticle8Heading: "24D Roadmaps: GenAI and Blockchain Across ERP, HCM, SCM, and CX",
    fourthSectionArticle8Duration: "2-minute read",
};
