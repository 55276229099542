export const reporting = {
    firstSectionHeading: "Reporting and Insights",
    firstSectionPara: "Lattice Cloud Infrastructure (LCI) offers comprehensive reporting tools to analyze performance, monitor cloud operations, and manage costs effectively. Gain visibility into your cloud ecosystem with analytics, monitoring, and billing insights tailored to your needs.",
    firstSectionButton1: "Explore Reporting Tools",
    firstSectionButton2: "Contact Sales for Reporting Solutions",
    firstSectionSide2TitleImg: "Discover how LCI empowers data-driven decision-making with robust reporting.",

    secondSectionHeading: "Integrated Reporting for Every Cloud Operation",
    secondSectionPara: "From real-time analytics to proactive monitoring and cost control, LCI Reporting delivers insights to optimize your infrastructure. Leverage actionable data to enhance performance, security, and cost-efficiency.",
    secondSectionButton: "Explore LCI Reporting",

    thirdSectionHeading: "Why Choose LCI Reporting?",
    thirdSectionSide1Heading: "Advanced Analytics",
    thirdSectionSide1Para: "Harness deep insights with visualized data to optimize workloads, track application performance, and improve user experiences.",
    thirdSectionSide2Heading: "Proactive Monitoring",
    thirdSectionSide2Para: "Monitor your cloud environment in real-time with customizable alerts and health checks to ensure consistent performance.",
    thirdSectionSide3Heading: "Cost and Billing Insights",
    thirdSectionSide3Para: "Control expenses with detailed billing reports and cost management tools to avoid surprises and plan budgets effectively.",

    fourthSectionHeading: "LCI Reporting Services",
    fourthSectionButton1: "Analytics Dashboard",
    fourthSectionButton2: "Monitoring Tools",
    fourthSectionButton3: "Billing & Cost Management",

    fourthSectionPage1Heading: "Analytics Dashboard",
    fourthSectionPage1Description: "Visualize your data with intuitive dashboards that provide actionable insights across all cloud services.",
    fourthSectionPage1Link: "Learn more about Analytics Dashboard",
    fourthSectionPage1cardheading1: "Customizable Visualizations",
    fourthSectionPage1fastTrackList1: "Design dashboards to track key metrics and KPIs.",
    fourthSectionPage1cardheading2: "Predictive Analytics",
    fourthSectionPage1fastTrackList2: "Identify trends and forecast future demands with AI-powered insights.",
    fourthSectionPage1cardheading3: "Data Integration",
    fourthSectionPage1fastTrackList3: "Seamlessly pull data from multiple LCI services for unified reporting.",

    fourthSectionPage2Heading: "Monitoring Tools",
    fourthSectionPage2Description: "Proactively track system performance, detect anomalies, and receive alerts to address issues before they impact operations.",
    fourthSectionPage2Link: "Learn more about Monitoring Tools",
    fourthSectionPage2cardheading1: "Real-Time Alerts",
    fourthSectionPage2fastTrackList1: "Set custom thresholds and receive instant notifications.",
    fourthSectionPage2cardheading2: "Health Monitoring",
    fourthSectionPage2fastTrackList2: "Track the health of your applications, databases, and infrastructure.",
    fourthSectionPage2cardheading3: "Actionable Insights",
    fourthSectionPage2fastTrackList3: "Get recommendations to resolve performance issues.",

    fourthSectionPage3Heading: "Billing & Cost Management",
    fourthSectionPage3Description: "Optimize your cloud spend with transparent billing, cost-saving recommendations, and detailed usage reports.",
    fourthSectionPage3Link: "Learn more about Billing & Cost Management",
    fourthSectionPage3cardheading1: "Cost Transparency",
    fourthSectionPage3fastTrackList1: "Track expenses in real-time with granular billing details.",
    fourthSectionPage3cardheading2: "Budget Alerts",
    fourthSectionPage3fastTrackList2: "Set spending limits and receive notifications to stay within budget.",
    fourthSectionPage3cardheading3: "Savings Recommendations",
    fourthSectionPage3fastTrackList3: "Identify opportunities to reduce costs and improve efficiency.",

    fifthSectionHeading: "Maximize Your Cloud Insights",
    fifthSectionPara1: "LCI Reporting enables smarter decisions with transparent analytics, real-time monitoring, and precise cost management. Explore how these tools can help optimize your cloud infrastructure.",
    fifthSectionPara2: "Start using LCI Reporting today and see the difference.",
    fifthSectionButton1: "Get Started",
    fifthSectionButton2: "Contact Support",

    sixthSectionHeading: "LCI Reporting Success Stories",
    sixthSectionLink: "Explore all customer success stories",


    ninthSectionHeading: "Get Started with LCI Reporting",
    ninthSectionPage1Side1Heading: "Access Free Reporting Features with Lattice Cloud Free Tier",
    ninthSectionPage1Side1Para: "Try essential reporting features such as Analytics Dashboards, Monitoring Tools, and Billing Insights with the Lattice Cloud Free Tier. Get $300 in credits for advanced services.",
    ninthSectionPage1Side1button: "Try Reporting Tools for Free",
    ninthSectionPage1Side2Heading: "What’s Included in the Free Tier?",
    ninthSectionPage1Side2List1: "Basic Analytics Dashboard",
    ninthSectionPage1Side2List2: "Real-Time Monitoring Alerts",
    ninthSectionPage1Side2List3: "Transparent Billing Reports",
    ninthSectionPage1Side2List4: "Savings Recommendations with $300 Credits",

    ninthSectionPage4Side1Heading: "Contact Reporting Experts",
    ninthSectionPage4Side1Para: "Explore how LCI Reporting can enhance your cloud operations. Our experts are ready to guide you.",
    ninthSectionPage4Side1button: "Get in Touch",
    ninthSectionPage4Side2Heading: "Frequently Asked Questions",
    ninthSectionPage4Side2List1: "How do I set up Analytics Dashboards?",
    ninthSectionPage4Side2List2: "What monitoring features are available?",
    ninthSectionPage4Side2List3: "How can I manage my cloud costs effectively?",

    ninthSectionButton1: "Free Trial",
    // ninthSectionButton2: "Explore Features",
    ninthSectionButton3: "Contact Experts",

    // seventhSectionHeading: "Customer success stories",
    // seventhSectionLink: "Explore all LCI customer stories",

    // eightSectionHeading: "Customer reviews",
    // eightSectionCard1Title: "Gartner Peer Insights is a platform for rating and reviews of enterprise technology solutions by and for end-user professionals.",
    // eightSectionCard1Link1: "Read Lattice product reviews",
    // eightSectionCard2Title: "G2 is the world’s largest tech marketplace where businesses can discover, review, and manage the solutions they need to reach their potential.",
    // eightSectionCard2Link1: "Read Lattice product reviews",
    // eightSectionCard3Title: "TrustRadius is a trusted review site for business technology. Buyers can make better decisions informed by these unbiased and insightful reviews.",
    // eightSectionCard3Link1: "Read Lattice product reviews",
    

}