import React, { useState, useEffect } from "react";
import './webcast.css';

export default function WebCast() {

  const [timeLeft, setTimeLeft] = useState({
    days: 14,
    hours: 23,
    minutes: 56,
    seconds: 7,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      updateTimer();
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [timeLeft]);

  const updateTimer = () => {
    const { days, hours, minutes, seconds } = timeLeft;

    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      clearInterval();
      return;
    }

    if (seconds > 0) {
      setTimeLeft((prevTime) => ({ ...prevTime, seconds: prevTime.seconds - 1 }));
    } else if (minutes > 0) {
      setTimeLeft((prevTime) => ({
        ...prevTime,
        minutes: prevTime.minutes - 1,
        seconds: 59,
      }));
    } else if (hours > 0) {
      setTimeLeft((prevTime) => ({
        ...prevTime,
        hours: prevTime.hours - 1,
        minutes: 59,
        seconds: 59,
      }));
    } else if (days > 0) {
      setTimeLeft((prevTime) => ({
        ...prevTime,
        days: prevTime.days - 1,
        hours: 23,
        minutes: 59,
        seconds: 59,
      }));
    }
  };


  return (
    <>
      <div className="coming-soon-container">
        <div className="coming-soon-content">
          <h1>Coming Soon</h1>
          <p>We are working hard to bring something amazing! Stay tuned.</p>
          <div className="countdown-timer">
            <div className="timer-box">
              <span className="time">{timeLeft.days}</span>
              <span className="label">Days</span>
            </div>
            <div className="timer-box">
              <span className="time">{timeLeft.hours}</span>
              <span className="label">Hours</span>
            </div>
            <div className="timer-box">
              <span className="time">{timeLeft.minutes}</span>
              <span className="label">Minutes</span>
            </div>
            <div className="timer-box">
              <span className="time">{timeLeft.seconds}</span>
              <span className="label">Seconds</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
