export const wealthManagement = {
    firstSectionHeading: "Lattice Blockchain Asset Management Suite",
    firstSectionPara: "Empower wealth and asset managers with advanced blockchain technology to enhance transparency, security, and operational efficiency. The Lattice Blockchain Asset Management Suite provides a unified platform for managing key financial and asset-related processes, including tokenized asset tracking, multi-currency accounting, compliance management, and dynamic reporting. Unlock actionable insights and achieve real-time visibility into your portfolio while mitigating risks with secure, automated workflows.",
    firstSectionButton1: "Request a Demo of Lattice Asset Management",
    firstSectionButton2: "Chat with Lattice Sales",
    firstSectionSide2TitleImg: "",

    thirdSectionHeading: "Get Started with Lattice Blockchain Suite",
    thirdSectionSide1Heading: "Request a Demo",
    thirdSectionSide1Para: "Take a guided walkthrough with our experts.",
    thirdSectionSide2Heading: "Explore Product Features",
    thirdSectionSide2Para: "Discover Lattice Blockchain Asset Management at your own pace.",
    thirdSectionSide3Heading: "Contact Asset Management Sales",
    thirdSectionSide3Para: "Connect with our team to learn how Lattice can transform your operations.",

    fourthSectionHeading: "Lattice Blockchain Asset Management Features",
    fourthSectionButton1: "Platform Overview",
    fourthSectionButton2: "Client Planning",
    fourthSectionButton3: "Asset Valuation & Management",
    fourthSectionButton4: "Portfolio Consolidation",
    fourthSectionButton5: "Asset Reconciliation",
    fourthSectionButton6: "Tax Reporting",
    fourthSectionButton7: "Narrative Reporting",
    fourthSectionButton8: "Custom Workflows",
    fourthSectionButton9: "Client Data Management",

    fourthSectionPage1Heading: "Streamlined Financial Operations",
    fourthSectionPage1Description: "Optimize financial workflows with blockchain-enabled transparency and accuracy. Handle tokenized accounting processes seamlessly while improving reporting and compliance.",
    fourthSectionPage1Link: "Discover Blockchain for Asset Management",
    fourthSectionPage1cardheading1: "Accelerate Financial Operations with Automation",
    fourthSectionPage1fastTrackList1: "Automate over 80% of asset management workflows, including tokenized tax accounting and compliance tracking. Eliminate manual errors, reduce processing times, and enhance reporting precision.",
    fourthSectionPage1cardheading2: "Multi-Currency, Multi-Lingual Support",
    fourthSectionPage1fastTrackList2: "Manage global portfolios effortlessly with built-in support for diverse currencies, languages, and regulatory standards.",
    fourthSectionPage1cardheading3: "Leverage AI for Risk Mitigation",
    fourthSectionPage1fastTrackList3: "Deploy AI-driven analytics to minimize exceptions, learn from historical data, and identify risks proactively.",
    fourthSectionPage1cardheading4: "Integrated Budgeting and Planning",
    fourthSectionPage1fastTrackList4: "Enable real-time budgeting and expenditure tracking through tokenized workflows, ensuring financial control and transparency.",

    learnMoreButton: "",
    fourthSectionPage2Heading: "Advanced Accounting Hub",
    fourthSectionPage2Description: "Simplify complex accounting tasks with an enterprise-grade blockchain platform. Gain real-time insights and harmonize accounting data across systems.",
    fourthSectionPage2Link: "Learn More About Accounting Solutions",
    fourthSectionPage2cardheading1: "Enterprise-Grade Accounting",
    fourthSectionPage2fastTrackList1: "Centralize accounting processes for global portfolios and streamline compliance with blockchain technology.",
    fourthSectionPage2cardheading2: "Adapt to Changing Standards",
    fourthSectionPage2fastTrackList2: "Easily update accounting rules as regulations evolve, ensuring seamless compliance.",
    fourthSectionPage2cardheading3: "Unified Data Integration",
    fourthSectionPage2fastTrackList3: "Consolidate data from disparate systems for accurate, real-time reporting.",
    fourthSectionPage2cardheading4: "Comprehensive Financial Insights",
    fourthSectionPage2fastTrackList4: "Leverage in-depth analytics to support strategic decision-making.",

    // Additional sections (similar adjustments for tone and emphasis)

    fifthSectionHeading: "Unmatched Features for Wealth and Asset Managers",
    fifthSectionSide1Heading: "Comprehensive Analytics",
    fifthSectionSide1Para: "Analyze portfolio performance with real-time insights.",
    fifthSectionSide2Heading: "Diverse Asset Classes",
    fifthSectionSide2Para: "Efficiently manage multiple asset types with ease.",
    fifthSectionSide3Heading: "Proactive Risk Management",
    fifthSectionSide3Para: "Leverage advanced tools to mitigate risks effectively.",

    sixthSectionHeading: "Complete Solution for Growth and Innovation",
    sixthSectionCard1Heading: "Move Finance from Reactive to Strategic",
    sixthSectionCard1Para2: "Leverage automation to eliminate manual processes, enabling finance teams to focus on strategic initiatives with real-time data and AI-driven insights.",
    sixthSectionCard2Heading: "Optimize Cash Liquidity",
    sixthSectionCard2Para2: "Enhance liquidity forecasting and management using unified blockchain data and predictive algorithms.",
    sixthSectionCard3Heading: "Scale with Confidence",
    sixthSectionCard3Para2: "Confidently expand operations globally with a platform designed for scalability and compliance.",
    sixthSectionCard4Heading: "Gain Actionable Insights",
    sixthSectionCard4Para2: "Simplify decision-making with dynamic dashboards and financial visualizations.",

    seventhSectionHeading: "Customer Success Stories",
    seventhSectionLink: "Explore Lattice Success Stories",

    eightSectionHeading: "Resources for Wealth Managers",
    eightSectionCard1Title: "Blockchain Implementation Guide",
    eightSectionCard1Link1: "Explore Implementation Strategies",
    eightSectionCard1Link2: "Learn Blockchain Basics",
    eightSectionCard2Title: "Community and Collaboration",
    eightSectionCard2Link1: "Join the Lattice Community",
    eightSectionCard2Link2: "Collaborate with Experts",
    eightSectionCard3Title: "Develop Your Lattice Skills",
    eightSectionCard3Link1: "Explore Free Learning Resources",
    eightSectionCard3Link2: "Start Training Today",
};
