export const networking = {
    firstSectionHeading: "Networking",
    firstSectionPara: "Lattice Cloud Infrastructure (LCI) offers advanced networking solutions to ensure secure, scalable, and high-performance connectivity for your cloud applications. From Virtual Private Clouds to DDoS protection, LCI Networking enables seamless integration and robust security across all workloads.",
    firstSectionButton1: "Try LCI networking for free",
    firstSectionButton2: "Learn more about LCI Networking",
    firstSectionSide2TitleImg: "Explore how LCI Networking accelerates application delivery and enhances security.",

    thirdSectionHeading: "Why choose LCI Networking?",
    thirdSectionSide1Heading: "Secure and customizable connectivity",
    thirdSectionSide1Para: "Create secure, isolated environments with Virtual Private Cloud (VPC) and apply granular policies with Cloud Firewalls.",
    thirdSectionSide2Heading: "Optimized traffic management",
    thirdSectionSide2Para: "Enhance performance and reliability with intelligent Load Balancers and Content Delivery Networks (CDNs).",
    thirdSectionSide3Heading: "Robust threat protection",
    thirdSectionSide3Para: "Protect your applications with built-in DDoS Protection and Web Application Firewalls (WAF) to defend against modern threats.",

    fourthSectionHeading: "LCI Networking Services",
    fourthSectionButton1: "Virtual Private Cloud (VPC)",
    fourthSectionButton2: "Cloud Firewalls",
    fourthSectionButton3: "Load Balancers",
    fourthSectionButton4: "DNS and Domain Hosting",
    fourthSectionButton5: "DDoS Protection and WAF",
    fourthSectionButton6: "Content Delivery Network (CDN)",

    // secondSectionHeading: "Learn why Lattice Cloud Infrastructure is a leader suitable for all your workloads.",
    // secondSectionButton: "Get the Gartner report",

    fourthSectionPage1Heading: "Virtual Private Cloud (VPC)",
    fourthSectionPage1Description: "Create secure, isolated cloud environments tailored to your organizational needs. Define custom subnets, route traffic, and enable private connectivity.",
    fourthSectionPage1Link: "Learn more about VPC",
    fourthSectionPage1cardheading1: "Customizable VPCs",
    fourthSectionPage1fastTrackList1: "Isolate workloads with private networks and subnets.",
    fourthSectionPage1cardheading2: "Secure connections",
    fourthSectionPage1fastTrackList2: "Enable private connections using gateways and VPNs.",
    fourthSectionPage1cardheading3: "Seamless scalability",
    fourthSectionPage1fastTrackList3: "Scale your VPC to meet changing application demands.",
    fourthSectionPage1cardheading4: "Policy control",
    fourthSectionPage1fastTrackList4: "Define granular access rules with Cloud Firewalls.",


    fourthSectionPage2Heading: "Cloud Firewalls",
    fourthSectionPage2Description: "Define and enforce security policies to protect your applications from unauthorized access and threats.",
    fourthSectionPage2Link: "Learn more about Cloud Firewalls",
    fourthSectionPage2cardheading1: "Advanced security",
    fourthSectionPage2fastTrackList1: "Protect workloads with stateful firewall policies.",
    fourthSectionPage2cardheading2: "Custom rules",
    fourthSectionPage2fastTrackList2: "Define rules for specific traffic types and sources.",
    fourthSectionPage2cardheading3: "Real-time monitoring",
    fourthSectionPage2fastTrackList3: "Receive alerts and insights into firewall activity.",

    fourthSectionPage3Heading: "Load Balancers",
    fourthSectionPage3Description: "Distribute traffic intelligently across multiple servers or regions to ensure application reliability and performance.",
    fourthSectionPage3Link: "Learn more about Load Balancers",
    fourthSectionPage3cardheading1: "Global load balancing",
    fourthSectionPage3fastTrackList1: "Distribute traffic globally for high availability.",
    fourthSectionPage3cardheading2: "Application-aware routing",
    fourthSectionPage3fastTrackList2: "Direct traffic based on application health and policies.",
    fourthSectionPage3cardheading3: "Autoscaling support",
    fourthSectionPage3fastTrackList3: "Scale automatically to handle traffic spikes.",

    fourthSectionPage4Heading: "DNS and Domain Hosting",
    fourthSectionPage4Description: "Manage domains and DNS records with low-latency resolution and seamless integration with LCI services.",
    fourthSectionPage4Link: "Learn more about DNS and Domain Hosting",
    fourthSectionPage4cardheading1: "Simplified domain management",
    fourthSectionPage4fastTrackList1: "Easily manage DNS records and domain settings.",
    fourthSectionPage4cardheading2: "Low-latency DNS resolution",
    fourthSectionPage4fastTrackList2: "Provide fast and reliable access to your applications.",
    fourthSectionPage4cardheading3: "Integration with networking tools",
    fourthSectionPage4fastTrackList3: "Seamlessly integrate with firewalls and load balancers.",

    fourthSectionPage5Heading: "DDoS Protection and Web Application Firewall (WAF)",
    fourthSectionPage5Description: "Secure your applications from distributed denial-of-service (DDoS) attacks and OWASP Top 10 threats.",
    fourthSectionPage5Link: "Learn more about DDoS and WAF",
    fourthSectionPage5cardheading1: "Automated threat mitigation",
    fourthSectionPage5fastTrackList1: "Detect and block DDoS attacks automatically.",
    fourthSectionPage5cardheading2: "Protect against common vulnerabilities",
    fourthSectionPage5fastTrackList2: "Defend against SQL injections, XSS, and more.",
    fourthSectionPage5cardheading3: "Seamless integration",
    fourthSectionPage5fastTrackList3: "Integrate with your CDN and load balancers.",

    fourthSectionPage6Heading: "Content Delivery Network (CDN)",
    fourthSectionPage6Description: "Accelerate content delivery with LCI’s global CDN, reducing latency and improving user experiences.",
    fourthSectionPage6Link: "Learn more about CDN",
    fourthSectionPage6cardheading1: "Global caching",
    fourthSectionPage6fastTrackList1: "Store content closer to users for faster delivery.",
    fourthSectionPage6cardheading2: "Reduced latency",
    fourthSectionPage6fastTrackList2: "Minimize delays for better application performance.",
    fourthSectionPage6cardheading3: "Scalable delivery",
    fourthSectionPage6fastTrackList3: "Handle large-scale traffic with ease.",

    sixthSectionHeading: "LCI Networking Success Stories",

    // seventhSectionHeading: "LCI Networking customer success stories",
    // seventhSectionLink: "Explore all LCI customer stories",

    // eightSectionHeading: "What’s new?",
    // eightSectionCard1Title: "Secure your workloads with the LCI Network Firewall",
    // eightSectionCard1Link1: "Learn more about network security",
    // eightSectionCard2Title: "TConnect LCI government and commercial regions using Megaport Cloud Router",
    // eightSectionCard2Link1: "Learn more about interregion connections",
    // eightSectionCard3Title: "Incorporate cyber-resilience capabilities into your LCI tenancy",
    // eightSectionCard3Link1: "Learn more about cyber-resilience in LCI",

    // ninthSectionHeading: "Get started with LCI",
    // ninthSectionPage1Side1Heading: "Try 20+ Always Free cloud services, with a 30-day trial for even more",
    // ninthSectionPage1Side1Para: "Lattice offers a Free Tier with no time limits on more than 20 services such as Compute, Storage, and Autonomous Database, as well as US$300 in free credits to try additional cloud services. Get the details and sign up for your free account today.",
    // ninthSectionPage1Side1button: "Try LCI for free",
    // ninthSectionPage1Side2Heading: "What’s included with Lattice Cloud Free Tier?",
    // ninthSectionPage1Side2List1: "AMD and Arm Compute VMs",
    // ninthSectionPage1Side2List2: "200 GB total block storage",
    // ninthSectionPage1Side2List3: "10 GB object storage",
    // ninthSectionPage1Side2List4: "2 Autonomous Databases, 20 GB each",
    // ninthSectionPage1Side2List5: "10 TB outbound data transfer per month",
    // ninthSectionPage1Side2List6: "10+ more Always Free services",
    // ninthSectionPage1Side2List7: "US$300 in free credits for 30 days for even more",

    // ninthSectionPage2Side1Heading: "Reap the benefits of earning an Lattice certification",
    // ninthSectionPage2Side1Para: "Preparing for an Lattice certification exam will assess and challenge your ability to think and perform. Earning a highly recognized and valued Lattice certification will improve your job prospects and stability, accelerating your career growth.",
    // ninthSectionPage2Side1button: "Explore benefits",

    // ninthSectionPage2Side2Heading1: "Program benefits",
    // ninthSectionPage2Side2List1: "Expand your knowledge base and skills",
    // ninthSectionPage2Side2List2: "Improve your potential earning power",
    // ninthSectionPage2Side2List3: "Learn to perform complex, hands-on activities",
    // ninthSectionPage2Side2List4: "Explore LCI features, functions, and tasks",

    // ninthSectionPage3Side1Heading: "See how much you can save with LCI",
    // ninthSectionPage3Side1Para: "Lattice Cloud pricing is simple, with consistent low pricing worldwide, supporting a wide range of use cases. To estimate your low rate, check out the cost estimator and configure the services to suit your needs.",
    // ninthSectionPage3Side1button: "Try Cost Estimator",
    // ninthSectionPage3Side2Heading: "Experience the difference",
    // ninthSectionPage3Side2List1: "1/4 the outbound bandwidth costs",
    // ninthSectionPage3Side2List2: "3X the compute price-performance",
    // ninthSectionPage3Side2List3: "Same low price in every region",
    // ninthSectionPage3Side2List4: "Low pricing without long term commitments",

    // ninthSectionPage4Side1Heading: "Contact sales",
    // ninthSectionPage4Side1Para: "Interested in learning more about Lattice Cloud Infrastructure? Let one of our experts help.",
    // ninthSectionPage4Side1button: "Get in touch",
    // ninthSectionPage4Side2Heading: "They can answer questions such as",
    // ninthSectionPage4Side2List1: "How do I get started with Lattice Cloud?",
    // ninthSectionPage4Side2List2: "What kinds of workloads can I run on LCI?",
    // ninthSectionPage4Side2List3: "What types of services does LCI offer?",

    // ninthSectionButton1: "Free trial",
    // ninthSectionButton2: "Certification",
    // ninthSectionButton3: "Estimate your costs",
    // ninthSectionButton4: "Contact sales",
    ninthSectionHeading: "Get Started with LCI Networking",
    ninthSectionPage1Side1Heading: "Access Free Networking Services with Lattice Cloud Free Tier",
    ninthSectionPage1Side1Para: "Try essential networking features, including VPC, DNS, and Load Balancers, for free with the Lattice Cloud Free Tier. Get $300 in credits to explore advanced features.",
    ninthSectionPage1Side1button: "Try LCI Networking for Free",
    ninthSectionPage1Side2Heading: "What’s Included in the Free Tier?",
    ninthSectionPage1Side2List1: "Free VPC setup",
    ninthSectionPage1Side2List2: "Basic DNS management",
    ninthSectionPage1Side2List3: "100 GB of outbound CDN traffic",
    ninthSectionPage1Side2List4: "DDoS protection for key applications",
    ninthSectionPage1Side2List5: "Access to advanced networking with $300 credits",

    ninthSectionPage4Side1Heading: "Contact Networking Experts",
    ninthSectionPage4Side1Para: "Explore how LCI Networking can optimize your operations. Our experts are ready to guide you.",
    ninthSectionPage4Side1button: "Get in Touch",
    ninthSectionPage4Side2Heading: "Frequently Asked Questions",
    ninthSectionPage4Side2List1: "How do I set up VPC and Load Balancers?",
    ninthSectionPage4Side2List2: "What’s included in DDoS protection?",
    ninthSectionPage4Side2List3: "How can I integrate CDN with my applications?",

    ninthSectionButton1: "Free Trial",
    ninthSectionButton2: "Estimate Costs",
    ninthSectionButton3: "Contact Experts",

}