import React, { useState, useRef } from 'react';
import img1 from '../image/cloud/yt-video-thumbnail-tRnXMKJWqqc.jpg'
import "./Healthcare.css"
import img from '../image/rc30v0x1-providers.avif'
import img2 from '../image/rc30v0x1-payers.avif'
import img3 from '../image/rc30v0x1-publichealth.avif'
import img4 from '../image/rc30v0x1-lifesciences.avif'

import svg1 from '../image/deliveroo-logo3.png'
import svg2 from '../image/AlbaniaTech-logo.svg'
import svg4 from '../image/german-federal-ministry-logo.svg'
import svg5 from '../image/Four_Seasons_logo3.png'
import svg6 from '../image/The_Related_Companies-Logo.svg'
import svg7 from '../image/imperial-college-london-logo3.svg'

import arrow from '../image/arrow-of-industry-page.svg'
import { useChatWidget } from "../ChatWidgetContext";
import { healthCare } from '../Content/English-Content/industry/healthcare';



export default function HealthCare() {

      const { toggleWidget } = useChatWidget();


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };




    // State to track the selected resource
    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: healthCare.fourthSectionPage1Heading,
            description: healthCare.fourthSectionPage1Para,
            // link: "Learn how Lattice helps provider organizations",
            imgSrc: img,
        },
        documentation: {
            heading: healthCare.fourthSectionPage2Heading,
            description: healthCare.fourthSectionPage2Para,
            // link: "Learn about Lattice and private healthcare",
            imgSrc: img2,
        },
        events: {
            heading: healthCare.fourthSectionPage3Heading,
            description: healthCare.fourthSectionPage3Para,
            // link: "Learn how Lattice helps public health organizations",
            imgSrc: img3,
        },
        technologies: {
            heading: healthCare.fourthSectionPage4Heading,
            description: healthCare.fourthSectionPage4Para,
            // link: "Learn how Lattice helps life sciences organizations",
            imgSrc: img4,
        },

    };

    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];


    const [activeSection, setActiveSection] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (section, index) => {
        if (activeSection === section && activeIndex === index) {
            setActiveSection(null);
            setActiveIndex(null);
        } else {
            setActiveSection(section);
            setActiveIndex(index);
        }
    };

    const renderContent = () => {
        if (activeSection === "first") {
            switch (activeIndex) {
                case 0:
                    return (

                        <div className="hidden-para-div-one-8-div-industry">

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard1Heading1}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard1Para1}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard1Heading2}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard1Para2}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard1Heading3}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard1Para3}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard1Heading4}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard1Para4}</p>
                            </div>

                        </div>


                    );
                case 1:
                    return (

                        <div className="hidden-para-div-one-8-div-industry">

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard2Heading1}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard2Para1}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard2Heading2}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard2Para2}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard2Heading3}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard2Para3}</p>
                            </div>


                        </div>


                    );
                case 2:
                    return (

                        <div className="hidden-para-div-one-8-div-industry">

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard3Heading1}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard3Para1}</p>
                            </div>

                        </div>


                    );
                default:
                    return null;
            }

        } else if (activeSection === "second") {
            switch (activeIndex) {
                case 0:
                    return (

                        <div className="hidden-para-div-one-8-div-industry">

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard4Heading1}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard4Para1}</p>
                            </div>
                        </div>

                    );
                case 1:
                    return (

                        <div className="hidden-para-div-one-8-div-industry">

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard5Heading1}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard5Para1}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard5Heading2}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard5Para2}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard5Heading3}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard5Para3}</p>
                            </div>
                        </div>


                    );
                case 2:
                    return (

                        <div className="hidden-para-div-one-8-div-industry">

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard6Heading1}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard6Para1}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard6Heading2}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard6Para2}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard6Heading3}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard6Para3}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard6Heading4}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard6Para4}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard6Heading5}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{healthCare.thirdSectionCard6Para5}</p>
                            </div>

                        </div>


                    );
                default:
                    return null;
            }
        }
        return null;
    };



    return (


        <>



            <div className="product-infra-compute-first-div">



                <h1 className="industry-health-care-first-div-heading">

                    {healthCare.firstSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                    <p className="industry-health-care--first-div-paragraph">{healthCare.firstSectionPara}</p>

                    <a href="/create-account" style={{ color: "inherit", textDecoration: "none" }}><button className="product-infra-compute-first-div-btn-1">{healthCare.firstSectionButton}</button></a>


                </h1>

                <div className="product-infra-compute-first-div-img-and-para">

                    <img src={img1} className="product-infra-compute-img-of-first-div"></img>

                    <p className="product-infra-compute-title-of-img">{healthCare.firstSectionImageDescription}</p>

                </div>


            </div>

            {/* 2-div */}

            <div className="last-flat-img-industry"></div>



            {/* 3-div */}


            <div className="product-infra-compute-third-div">

                <h1 className="product-infra-compute-third-div-heading">{healthCare.secondSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>

                <div className="product-infra-compute-third-div-cards-wrapper">

                    <div className="product-infra-compute-third-div-card-1">

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {healthCare.secondSectionSide1Heading}
                            </h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{healthCare.secondSectionSide1Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {healthCare.secondSectionSide2Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{healthCare.secondSectionSide2Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">
                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {healthCare.secondSectionSide3Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{healthCare.secondSectionSide3Para}</p>

                        </div>

                    </div>

                </div>

            </div>



            {/* 8-div-of-industry */}

            <div className="dynamic-list-of-industry">
                <h1 className="product-infra-compute-third-div-heading">{healthCare.thirdSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>

                <div className="fisrt-child-of-8-div-industry">
                    {[healthCare.thirdSectionButton1, healthCare.thirdSectionButton2, healthCare.thirdSectionButton3].map((item, index) => (
                        <div
                            key={index}
                            className={`list-item-1-of-8-div-industry ${activeSection === "first" && activeIndex === index ? "active" : ""
                                }`}
                            onClick={() => handleItemClick("first", index)}
                        >
                            <h6 className="list-item-one-heading-8-div-industry">{item}</h6>
                            <img
                                src={arrow}
                                alt="arrow"
                                className={`list-item-one-img-8-div-industry ${activeSection === "first" && activeIndex === index ? "rotate" : ""
                                    }`}
                            />
                        </div>
                    ))}
                </div>

                {activeSection === "first" && activeIndex !== null && (
                    <div className="hidden-div-of-child-section-of-8-div-industry">{renderContent()}</div>
                )}



                <div className="fisrt-child-of-8-div">
                    <div className="fisrt-child-of-8-div-industry">
                        {[healthCare.thirdSectionButton4, healthCare.thirdSectionButton5, healthCare.thirdSectionButton6].map((item, index) => (
                            <div
                                key={index}
                                className={`list-item-1-of-8-div-industry ${activeSection === "second" && activeIndex === index ? "active" : ""
                                    }`}
                                onClick={() => handleItemClick("second", index)}
                            >
                                <h6 className="list-item-one-heading-8-div-industry">{item}</h6>
                                <img
                                    src={arrow}
                                    alt="arrow"
                                    className={`list-item-one-img-8-div-industry ${activeSection === "second" && activeIndex === index ? "rotate" : ""
                                        }`}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {activeSection === "second" && activeIndex !== null && (
                    <div className="hidden-div-of-child-section-of-8-div-industry">{renderContent()}</div>
                )}

            </div>








            {/* 4-div */}



            <div className="content-of-resources-industry-page" >
                <h1 className="solution-heading">{healthCare.fourthSectionHeading}</h1>

                <div className="content-wrapper-of-resource">
                    <div className="content-1-of-resource">
                        <ul>
                            <li>
                                <button
                                    className={`btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('downloads')}
                                >
                                    {healthCare.fourthSectionButton1}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('documentation')}
                                >
                                    {healthCare.fourthSectionButton2}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`btn-resource ${selectedResource === 'events' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('events')}
                                >
                                    {healthCare.fourthSectionButton3}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('technologies')}
                                >
                                    {healthCare.fourthSectionButton4}
                                </button>
                            </li>
                        </ul>
                    </div>

                    {/* Content-2 */}
                    <div className="content-2-of-resource-of-industry-section">
                        <div className="img-of-resource-and-para">
                            <div className="img-of-resource-industry-page">
                                <img className="img-of-resource-industry-page" src={currentResource.imgSrc} alt={currentResource.heading} />
                            </div>
                            <div className="para-of-res-content-industry-page">
                                <h1 className="resource-heading-of-card">{currentResource.heading}</h1>
                                <p className="para-of-resource">{currentResource.description}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



            {/* div-5 */}



            <div className="product-infra-div-seven">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="become-partners-list-1-sec-last-div-heading">
                    {healthCare.fifthSectionHeading}
                    </h1>

                    <a href='/customer-story' className="product-infra-div-six-link-of-txt-wrapper">{healthCare.fifthSectionlink}</a>

                </div>

                <div className="product-infra-div-seven-img-wrapper">

                    <img src={svg1} className="product-infra-div-seven-img-1"></img>

                    <img src={svg2} className="product-infra-div-seven-img-1"></img>

                    <img src={svg4} className="product-infra-div-seven-img-1"></img>

                    <img src={svg5} className="product-infra-div-seven-img-1"></img>

                    <img src={svg6} className="product-infra-div-seven-img-1"></img>

                    <img src={svg7} className="product-infra-div-seven-img-1"></img>


                </div>

            </div>


            {/* div-6 */}

            <div className="industry-div-6-all-wrapper">

                <h1 className="become-partners-list-1-sec-last-div-heading">
                {healthCare.sixthSectionHeading}
                </h1>

                <div className="childs-of-industry-div-6">

                    <div className="first-child-of-industry-6-div">
                        <p className="para-one-of-6-div-industry">{healthCare.sixthSectionSide1Heading}</p>

                        <p className="para-two-of-6-div-industry">{healthCare.sixthSectionSide1Para}</p>

                    </div>

                    <div className="sec-child-of-industry-6-div">
                        <p className="para-one-of-6-div-industry">{healthCare.sixthSectionSide2Heading}</p>

                        <p className="para-two-of-6-div-industry">{healthCare.sixthSectionSide2Para}</p>

                    </div>

                </div>

            </div>


            {/* div-7 */}


            <div className="last-div-wrapper-of-industry">

                <h1 className="become-partners-list-1-sec-last-div-heading">
                {healthCare.seventhSectionHeading}
                </h1>

                <div className="childs-of-7-div-industry">

                    <div className="first-child-of-7-div-industry">
                        <div className="fist-child-7-div-section-1-industry"></div>

                        <div className="fist-child-7-div-section-2-industry">
                            <p className="para-1-of-seven-div-industry">{healthCare.seventhSectionSide1Para}</p>

                            <button className="contact-btn-2" onClick={toggleWidget}  >{healthCare.seventhSectionSide1Button}</button>
                        </div>
                    </div>

                    <div className="first-child-of-7-div-industry">
                        <div className="fist-child-7-div-section-1-industry"></div>

                        <div className="fist-child-7-div-section-2-industry">
                            <p className="para-1-of-seven-div-industry">{healthCare.seventhSectionSide2Para}</p>

                            <button className="contact-btn-2" onClick={toggleWidget} >{healthCare.seventhSectionSide1Button}</button>
                        </div>
                    </div>

                </div>

            </div>





        </>


    )
}
