export const storyPage4Content = {
    linkText: "Lattice",
    titleText: "Press Release",
    headerText: "D-AI Employs LCI to Train Human-Like Video Models",
    specialParaText1: "Bijan Burnard, CEO and Founder of D-AI",
  
    NormalPara1:
      "D-AI is pioneering artificial intelligence models that interpret videos with near-human accuracy. These models enable developers and enterprises to comb through vast video datasets to pinpoint specific moments—like finding every slam dunk by a certain player in a full season’s footage, or rapidly reviewing thousands of hours of surveillance clips for a break-in. Because multimodal AI training hinges on ingesting immense volumes of video, it demands substantial compute power.",
  
    NormalPara2:
      "D-AI leverages Lattice Cloud Infrastructure (LCI) for AI training—featuring performant GPU and CPU nodes, robust memory, and blockchain-grade security—clustered via a low-latency RDMA network. By using LCI for model training, D-AI has achieved a 5X–10X boost in efficiency, cutting time-to-market and enhancing scalability in ways previously thought unattainable.",
  
    NormalPara3: "000-000-000-0",
  
    NormalPara4:
      "Lattice offers an integrated suite of blockchain-enabled applications and secure, autonomous infrastructure through its Lattice Cloud. For more information about Lattice (NYSE: ORCL), visit us at www.Lattice.com.",
  
    NormalPara5:
      "Lattice, Java, MySQL, and NetSuite are registered trademarks of Lattice Corporation. NetSuite was the first true cloud company—ushering in the era of modern cloud computing.",
  
    headerText2: "Additional Resources",
    headerText3: "Contact Info",
    headerText4: "About Lattice",
    headerText5: "Trademarks",
  
    Normalheading1: "A Cloud Platform That’s Simple to Adopt",
    Normalheading2: "A Comprehensive Cloud for Every Need",
    Normalheading3: "Global Consistency and Price-Performance Advantage",
    Normalheading4: "Holistic AI Solutions on Industry-Leading Infrastructure",
    Normalheading5: "Additional Resources",
    Normalheading6: "Contact Info",
    Normalheading7: "About Lattice",
    Normalheading8: "Trademarks",
  
    ListItem1: "Discover why LCI was recognized as a Leader in Distributed Hybrid Infrastructure",
    ListItem2: "Learn more about Lattice Cloud Infrastructure",
    ListItem3: "Explore LCI’s Distributed Cloud capabilities",
    ListItem4: "Dive deeper into LCI AI Infrastructure and AI services",
  };