import React, { createContext, useState, useContext } from 'react';

const ChatWidgetContext = createContext();

export function ChatWidgetProvider({ children }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleWidget = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <ChatWidgetContext.Provider value={{ isExpanded, toggleWidget }}>
            {children}
        </ChatWidgetContext.Provider>
    );
}

export function useChatWidget() {
    return useContext(ChatWidgetContext);
}
