import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { footerSections, cookieText } from '../Content/eng_content';
import './products-section-tools-template-page.css';
import img1 from '../image/tools/rh03v3fa-linux-v2.avif'
// link: 'All Download',
import
// link: 'All Download',
Latticelogo from '../image/lattice-logo-img.svg';
import { tokenAdministration } from '../Content/English-Content/TokenAdministration';



function PrivateTokenAdministration() {

    const latestNewsRef = useRef(null);
    const aiRef = useRef(null);
    const languagesRef = useRef(null);
    const solutionsRef = useRef(null);
    const communityRef = useRef(null);
    const resourcesRef = useRef(null);

    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const [activeSection, setActiveSection] = useState("Cloud");

    // Handle click to update active section
    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: 'Enterprise security',
            // description:
            //     'All software downloads are free, and most come with a Developer License that allows you to use full versions of the products at no charge while developing and prototyping your applications, or for strictly self-educational purposes.',
            // link: 'All Download',

            fastTrackList: ['MySQL Enterprise TDE enables data-at-rest encryption by encrypting the physical files of the database.', 'MySQL Enterprise Encryption provides encryption, key generation, digital signatures, and other cryptographic features to help organizations protect confidential data and comply with regulatory requirements.', 'MySQL Enterprise Masking and De-identification helps organizations protect sensitive data from unauthorized uses by hiding and replacing real values with substitutes.', 'MySQL Enterprise Firewall guards against cybersecurity threats by providing real-time protection against database-specific attacks, such as a SQL injection.', 'MySQL Enterprise Authentication provides ready-to-use external authentication modules to easily integrate MySQL with current security infrastructures.', 'MySQL Enterprise Audit lets you add policy-based auditing compliance quickly and seamlessly to new and current applications.'],
            cardheading: ['MySQL Enterprise Transparent Data Encryption (TDE)', 'MySQL Enterprise Encryption', 'MySQL Enterprise Masking and De-identification', 'MySQL Enterprise Firewall', 'MySQL Enterprise Authentication', 'MySQL Enterprise Audit'],
        },
        documentation: {
            heading: 'Enterprise scalability',
            // description:
            //     'Explore getting started guides, documentation, tutorials, reference architectures, and other content for Lattice products and services.',
            // link: 'Explore the content',
            cardheading: ['MySQL Thread Pool'],
            fastTrackList: ['MySQL enterprise scalability lets you meet the sustained performance and scalability requirements of ever-increasing user, query, and data loads. MySQL Thread Pool provides an efficient thread-handling model designed to reduce overhead in managing client connections and statement execution threads.'],
            headingof3div: 'Kick-start your app development with SDKs and the command-line interface.',
        },
        events: {
            heading: "Enterprise Backup",
            // description:
            //     'Explore Lattice Developer event replays and recent webinars, hosted by technical experts.',
            fastTrackList: ['MySQL Enterprise Backup performs hot nonblocking backups of MySQL databases. Full backups can be performed on all InnoDB data while MySQL is online, without interrupting queries or updates.', 'MySQL Enterprise Backup supports compressed backup, typically reducing backup size from 70% to more than 90% to reduce storage costs.', 'MySQL Enterprise Backup restores data from a full backup with full backward compatibility. Consistent point-in-time recovery (PITR) enables restoration to a specific point in time.', 'MySQL Enterprise Backup uses built-in 256-bit Advanced Encryption Standard (AES) encryption to secure all sensitive backup data.'],
            // link: 'Explore the content',
            headingof3div: 'Access developer resources and learn about future events designed just for you. Hear from experts in their field and gain practical hands-on experience.',
            cardheading: ['Backup', 'Compression', 'Restore', 'Encryption'],
        },
        technologies: {
            heading: ' High availability',
            // description:
            //     'Join events, webinars, and conferences to stay up-to-date with the latest trends and developments.',
            // link: 'All Events',
            cardheading: ['MySQL InnoDB Cluster', 'MySQL InnoDB ClusterSet'],
            fastTrackList: ['MySQL InnoDB Cluster delivers a complete high-availability solution for MySQL. Each server in a cluster replicates data to all members of the cluster while providing fault tolerance, automated failover, and elasticity.', 'MySQL InnoDB ClusterSet provides disaster tolerance for InnoDB Cluster deployments by linking a primary cluster with one or more replicas of itself in different data centers.'],
            headingof3div: 'Technologies',
        },
        languages: {
            heading: ' Monitoring',
            // description:
            //     'Join events, webinars, and conferences to stay up-to-date with the latest trends and developments.',
            // link: 'All Events',
            cardheading: ['Visually manage MySQL servers', 'Backup dashboard', 'Replication dashboard', 'Real-time performance monitoring'],
            fastTrackList: ['MySQL Enterprise Monitor provides real-time visibility into the performance and availability of all your MySQL databases. Start monitoring MySQL within 10 minutes with zero configuration and no agents. A rich set of real-time and historical graphs let you drill down into server-statistic details.', 'The backup dashboard provides visibility into crucial backup activities, letting you easily ensure that your most valuable assets are protected against data loss.', 'MySQL Enterprise Monitor automatically discovers your MySQL replication topologies and gives you visibility into the performance, availability, and health of each MySQL instance.', 'MySQL Enterprise Monitor continuously monitors MySQL queries and performance-related server metrics. MySQL Query Analyzer lets DBAs accurately pinpoint SQL code that’s the root cause of a slowdown.'],
            headingof3div: 'languages:',
        },
        learn: {
            heading: 'AI Resource Hub',
            description:
                'Discover, learn and build. Access a range of AI resources to help you get ahead on your AI journey.',
            link: 'Learn more',
            cardheading: ['Flexible VMs', 'Dense I/O VMs', 'Optimized VMs', 'Dedicated VM hosts'],
            fastTrackList: ['VMs are customizable from 1–94 cores for AMD EPYC processors, 1–32 cores for Intel, and 1–80 cores for Ampere processors, with 1–1,094 GB RAM and network bandwidth that scales with the number of cores.', 'Dense I/O VMs, with 8–24 cores and 128–512 GB RAM, offer 6.8–27.2 TB high performance, locally attached NVMe storage for big data and analytics workloads.', 'Optimized VM shapes provide 1–18 high-frequency processor cores and support cluster networking with 4–40 Gb/sec of network bandwidth.', 'Dedicated VM host shapes offer bare metal instances with 36–128 cores and 512–2,048 GB memory per host with software to host VMs.'],
            headingof3div: 'Learn',
        },

    };

    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];

     // cookies
            
            
                const [showCookieModal, setShowCookieModal] = useState(false);
                const [isLoading, setIsLoading] = useState(true);
            
                const handleCookieClick = () => {
                    setShowCookieModal(true);
                    setIsLoading(true);
            
            
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 500);
                };
            
                const handleAcceptAll = () => {
                    console.log("Cookies Accepted");
                    setShowCookieModal(false);
                };
            
                const handleDenyAll = () => {
                    console.log("Cookies Denied");
                    setShowCookieModal(false);
                };

    return (
        <>





            <div className="product-tools-tempalte-human-resource-first-div">
            
                            <div className="product-tools-tempalte-human-resource-first-div-child-1">
                                <h1 className="product-tools-tempalte-human-resource-first-div-heading">
            
                                    {tokenAdministration.firstSectionHeading}
            
                                    <span
                                        style={{
                                            display: "block",
                                            width: "40px",
                                            height: "4px",
                                            background: "#f0cc71",
                                            marginBottom: "3vh",
                                            marginTop: "3vh",
                                        }}
                                    ></span>
            
            
                                    <p className="product-tools-tempalte-human-resource-first-div-paragraph">
                                        {tokenAdministration.firstSectionPara}
                                    </p>
            
                                </h1>
            
                            </div>
            
            
            
                            {/* <div className="product-tools-tempalte-human-resource-first-div-child-2">
            
                                <img src={img1} className="product-tools-tempalte-human-resource-first-div-child-2-img">
            
                                </img>
                            </div> */}
                        </div>
            
                        <div className="product-tools-tempalte-human-resource-second-div-para-btn">
            
                            <h1 className="product-tools-tempalte-human-resource-second-div-heading">{tokenAdministration.secondSectionHeading}</h1>
            
                            <button className="product-tools-tempalte-human-resource-second-div-btn">{tokenAdministration.secondSectionButton}</button>
            
                        </div>
            
                        <div className="product-tools-tempalte-human-resource-third-div">
            
                            <div className="product-tools-tempalte-human-resource-third-div-child-1">
                                <h1 className="product-tools-tempalte-human-resource-third-div-child-1-heading">{tokenAdministration.thirdSectionSide1Heading}</h1>
                                <p className="product-tools-tempalte-human-resource-third-div-child-1-paragraph">
                                {tokenAdministration.thirdSectionSide1Para}
                                </p>
                                <button className="product-tools-tempalte-human-resource-third-div-child-1-btn">{tokenAdministration.thirdSectionSide1Button}</button>
            
                            </div>
            
                            <div className="vertical-line"></div>
            
                            <div className="product-tools-tempalte-human-resource-third-div-child-2">
                                <h1 className="product-tools-tempalte-human-resource-third-div-child-2-heading">{tokenAdministration.thirdSectionSide2Heading}</h1>
                                <p className="product-tools-tempalte-human-resource-third-div-child-2-paragraph">
                                    {tokenAdministration.thirdSectionSide2Para}
                                </p>
                                <button className="product-tools-tempalte-human-resource-third-div-child-2-btn">{tokenAdministration.thirdSectionSide2Button}</button>
            
                            </div>
                        </div>

            {/* <div className="product-tools-tempalte-human-resource-fourth-div">
                <h1 className="product-tools-tempalte-human-resource-fourth-div-heading">

                    Booking.com relies on MySQL to power one of the world’s largest travel marketplaces




                    <p className="product-tools-tempalte-human-resource-fourth-div-paragraph">
                        Booking.com uses MySQL to deliver personalized content, billing, and payments for millions of monthly active users.
                    </p>

                    <button className="product-tools-tempalte-human-resource-fourth-div-btn">Watch the video (22:15) </button>

                </h1>
            </div> */}

            {/* <div className="product-human-resource-div-four-content-of-resources">
                <h1 className="product-human-resource-div-four-solution-heading">

                    MySQL Enterprise features

                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>
            

            <div className="product-human-resource-div-four-content-wrapper-of-resource">
                <div className="product-human-resource-div-four-content-1-of-resource">
                    <ul>
                        <li>
                            <button
                                className={`product-human-resource-div-four-btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                    }`}
                                onClick={() => handleResourceClick('downloads')}
                            >
                                Enterprise security
                            </button>
                        </li>
                        <li>
                            <button
                                className={`product-human-resource-div-four-btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                    }`}
                                onClick={() => handleResourceClick('documentation')}
                            >
                                Enterprise scalability
                            </button>
                        </li>
                        <li>
                            <button
                                className={`product-human-resource-div-four-btn-resource ${selectedResource === 'events' ? 'active' : ''
                                    }`}
                                onClick={() => handleResourceClick('events')}
                            >
                                Enterprise Backup
                            </button>
                        </li>
                        <li>
                            <button
                                className={`product-human-resource-div-four-btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                    }`}
                                onClick={() => handleResourceClick('technologies')}
                            >
                                High availability
                            </button>
                        </li>
                        <li>
                            <button
                                className={`product-human-resource-div-four-btn-resource ${selectedResource === 'languages' ? 'active' : ''
                                    }`}
                                onClick={() => handleResourceClick('languages')}
                            >
                                Monitoring
                            </button>
                        </li>
                        
                    </ul>
                </div>

                
                <div className="product-human-resource-div-four-combined-content">
                   
                    <div className="product-human-resource-div-four-top-content">
                        <h1 className="product-human-resource-div-four-resource-heading">{currentResource.heading}</h1>
                        <p className="product-human-resource-div-four-resource-description">{currentResource.description}</p>
                        
                    </div>


                    
                    <div className="product-human-resource-div-four-grid-content">
                        {currentResource.fastTrackList.map((item, index) => (
                            <div className="product-human-resource-div-four-grid-item" key={index}>
                                <h2 className="product-human-resource-div-four-grid-heading">{currentResource.cardheading[index]}</h2>
                                <p className="product-human-resource-div-four-grid-para">{item}</p>
                                
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            </div> */}

            {/* Content-3 */}
            {/* <div className="content-3-of-resource">
                    <p className="res-3-div-para">{currentResource.headingof3div}</p>
                      <ul>
                        {currentResource.fastTrackList.map((item, index) => (
                          <li className="list-of-3-div" key={index}> <a className="link-of-com">
            
                            <span className="arrow-of-div-3">
                            <svg width="20" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 4l4 4-4 4" stroke="#006B8F" strokeWidth="1.5" fill="none"/>
                            </svg>
                            </span>
                            
                             {item} </a></li>
                        ))}
                      </ul>
                    </div> */}
            {/* </div>
                </div> */}

           <div className="product-tools-tempalte-human-resource-sixth-div">
                           <h1 className="product-tools-tempalte-human-resource-sixth-div-heading">
                               {tokenAdministration.fifthSectionHeading}
                           </h1>
                           <h3 className="product-tools-tempalte-human-resource-sixth-div-sub-heading">
                           {tokenAdministration.fifthSectionCard1Heading}
                           </h3>
                           <p className="product-tools-tempalte-human-resource-sixth-div-para">
                           {tokenAdministration.fifthSectionCard1Para}
                           </p>
           
                           <h3 className="product-tools-tempalte-human-resource-sixth-div-sub-heading">
                           {tokenAdministration.fifthSectionCard2Heading}
                           </h3>
                           <p className="product-tools-tempalte-human-resource-sixth-div-para">
                           {tokenAdministration.fifthSectionCard2Para}
                           </p>
           
                           <h3 className="product-tools-tempalte-human-resource-sixth-div-sub-heading">
                           {tokenAdministration.fifthSectionCard3Heading}
                           </h3>
                           <p className="product-tools-tempalte-human-resource-sixth-div-para">
                           {tokenAdministration.fifthSectionCard3Para}
                           </p>
                       </div>

             <div className="product-tools-tempalte-human-resource-seventh-div">
                            <h1 className="product-tools-tempalte-human-resource-seventh-div-heading">
            
                            {tokenAdministration.sixthSectionHeading}
                            </h1>
                        </div>
            
                        <div className="product-tools-tempalte-human-resource-eight-div">
            
                            <div className="product-tools-tempalte-human-resource-eight-div-child-1">
                                <h1 className="product-tools-tempalte-human-resource-third-div-child-1-heading">{tokenAdministration.sixthSectionSide1Heading}</h1>
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-1-paragraph">
                                {tokenAdministration.sixthSectionSide1Link1}
                                </a>
                                <br />
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-1-paragraph">
                                {tokenAdministration.sixthSectionSide1Link2}
                                </a>
            
                            </div>
            
                            <div className="eight-div-child-1-vertical-line"></div>
            
                            <div className="product-tools-tempalte-human-resource-eight-div-child-2">
                                <h1 className="product-tools-tempalte-human-resource-eight-div-child-2-heading">{tokenAdministration.sixthSectionSide2Heading}</h1>
            
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-2-paragraph">
                                {tokenAdministration.sixthSectionSide2Link1}
                                </a>
                                <br />
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-2-paragraph">
                                {tokenAdministration.sixthSectionSide2Link2}
                                </a>
            
                            </div>
            
                            <div className="eight-div-child-1-vertical-line"></div>
            
                            <div className="product-tools-tempalte-human-resource-eight-div-child-3">
                                <h1 className="product-tools-tempalte-human-resource-eight-div-child-3-heading">{tokenAdministration.sixthSectionSide3Heading}
                                </h1>
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph">
                                {tokenAdministration.sixthSectionSide3Link1}
                                </a>
                                <br />
                                <a
                                    href="#"
                                    className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph"
                                    style={{width: "15rem" }}>
                                    {tokenAdministration.sixthSectionSide3Link2}
                                </a>
                                <br />
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph" style={{width: "20rem", marginTop: "0" }}>
                                {tokenAdministration.sixthSectionSide3Link3}
                                </a>
                                <br />
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph">
                                {tokenAdministration.sixthSectionSide3Link4}
                                </a>
                                <br />
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph">
                                {tokenAdministration.sixthSectionSide3Link5}
                                </a>
                            </div>
            
                            <div className="eight-div-child-1-vertical-line"></div>
            
                            <div className="product-tools-tempalte-human-resource-eight-div-child-4">
                                <h1 className="product-tools-tempalte-human-resource-eight-div-child-4-heading">{tokenAdministration.sixthSectionSide4Heading}</h1>
            
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                                {tokenAdministration.sixthSectionSide4Link1}
                                </a>
                                <br />
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                                {tokenAdministration.sixthSectionSide4Link2}
                                </a>
                                <br />
            
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                                {tokenAdministration.sixthSectionSide4Link3}
                                </a>
                                <br />
            
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                                {tokenAdministration.sixthSectionSide4Link4}
                                </a>
                                <br />
            
                                <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                                {tokenAdministration.sixthSectionSide4Link5}
                                </a>
            
            
                            </div>
                        </div>

            <div class="mysql-section">
                            <h2 class="mysql-heading">{tokenAdministration.seventhSectionHeading}</h2>
                            <div class="mysql-cards">
                                <div class="mysql-card">
                                    <div class="card-top">
                                        <i class="icon-download"></i>
                                        <h3>{tokenAdministration.seventhSectionBox1Heading}</h3>
                                    </div>
                                    <div class="card-bottom">
                                        <p>{tokenAdministration.seventhSectionBox1Para}</p>
                                        <button>{tokenAdministration.seventhSectionBox1Button}</button>
                                    </div>
                                </div>
                                <div class="mysql-card">
                                    <div class="card-top">
                                        <i class="icon-subscription"></i>
                                        <h3>{tokenAdministration.seventhSectionBox2Heading}</h3>
                                    </div>
                                    <div class="card-bottom">
                                        <p>{tokenAdministration.seventhSectionBox2Para}</p>
                                        <button>{tokenAdministration.seventhSectionBox2Button}</button>
                                    </div>
                                </div>
                                <div class="mysql-card">
                                    <div class="card-top">
                                        <i class="icon-contact"></i>
                                        <h3>{tokenAdministration.seventhSectionBox3Heading}</h3>
                                    </div>
                                    <div class="card-bottom">
                                        <p>{tokenAdministration.seventhSectionBox3Para}</p>
                                        <button>{tokenAdministration.seventhSectionBox3Button}</button>
                                    </div>
                                </div>
                            </div>
                        </div>






            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                 <div className="footer-bottom">
                              
                                                  <span className="footer-separator"> © 2024 Lattice</span> |
                              
                                                  <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
                              
                                                  <span className="footer-separator"
                                                      style={{ cursor: "pointer", color: "inherit" }}
                                                      onClick={handleCookieClick}> Cookie Preferences  </span>
                              
                                                  <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
                              
                                                  <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                                              </div>
                              
                                          </div>
                              
                              
                                          {showCookieModal && (
                                              <div
                                                  className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                                                      }`}
                                              >
                                                  <div className="cookie-modal">
                              
                                                      {isLoading ? (
                                                          <div className="loading-spinner">{cookieText.loading}</div>
                                                      ) : (
                                                          <div className="cookie-content">
                              
                                                              <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                                                  &times;
                                                              </button>
                                                              <div className="heading-of-cookies-with-logo">
                              
                                                                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>
                              
                                                                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>
                              
                                                              </div>
                              
                                                              <div className="div-2-heading-below-text-and-list">
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>
                              
                                                                  <ul>
                                                                  {cookieText.listItems.map((item, index) => (
                                                                      <li key={index} className="div-2-cookies-list-text">
                                                                          <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                                                      </li>
                                                                      ))}
                                                                  </ul>
                              
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>
                              
                                                              </div>
                              
                                                              <div className="button-group">
                                                                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                                                                     {cookieText.btn1}
                                                                  </button>
                                                                  <button className="btn deny-btn" onClick={handleDenyAll}>
                                                                  {cookieText.btn2}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          )}

        </>
    );

}
export default PrivateTokenAdministration; 