export const navbarCareersPage = {
    firstSectionLinkText: "Lattice Careers",
    firstSectionHeading: "Shape the Future of Blockchain Cloud",
    firstSectionPara:
      "Join a pioneering team that's redefining how businesses operate worldwide. At Lattice, you'll empower organizations, drive meaningful change, and help build secure, scalable blockchain solutions.",
    firstSectionButton: "Search Jobs",
  
    secondSectionHeading: "Transform the World with Breakthrough Innovation",
    secondSectionPara:
      "Contribute to next-generation technologies that simplify complex processes and foster global trust. Collaborate with diverse, forward-thinking minds and help define the future of blockchain-powered cloud solutions.",
    secondSectionButton: "Search for jobs",
  
    thirdSectionHeading: "Grow Both Personally and Professionally",
    thirdSectionArticle1Heading: "Launch Your Career",
    thirdSectionArticle1Para:
      "Whether you're a student or recent graduate, our programs help you develop in-demand skills and embark on a career where you'll truly make a difference.",
    learnMoreButton: "Learn More",
  
    thirdSectionArticle2Heading: "Advance Your Path",
    thirdSectionArticle2Para:
      "Benefit from mentorship, hands-on training, and innovative projects. At Lattice, you'll have endless opportunities to expand your expertise and fuel your ambition.",
    
    thirdSectionArticle3Heading: "Champion Inclusion",
    thirdSectionArticle3Para:
      "Our culture celebrates diverse perspectives. Together, we amplify every voice to create a more inclusive environment and drive innovation forward.",
    
    thirdSectionArticle4Heading: "Thrive and Make an Impact",
    thirdSectionArticle4Para:
      "From comprehensive benefits to flexible work arrangements, we enable you to bring your whole self to work, empowering you to create a global impact through blockchain innovation.",
  
    fourthSectionHeading: "Featured Opportunities",
    fourthSectionArticle1Heading: "Lattice Cloud Infrastructure",
    fourthSectionArticle1Para:
      "Join our fearless team of engineers to build the next generation of blockchain-enabled cloud solutions.",
    applyNowButton: "Apply now",
  
    fourthSectionArticle2Heading: "Lattice NetSuite",
    fourthSectionArticle2Para:
      "Help transform the way the world does business by delivering cutting-edge blockchain cloud solutions.",
    
    fourthSectionArticle3Heading: "Applications Consulting",
    fourthSectionArticle3Para:
      "Collaborate with top-tier organizations, shape digital transformation strategies, and share your expertise on our high-performing consulting team.",
    
    fourthSectionArticle4Heading: "Security Clearance Jobs",
    fourthSectionArticle4Para:
      "Play a pivotal role in securing our customers' data and infrastructure. Safeguard the future of technology with a team dedicated to trust and resilience.",
  
    fifthSectionHeading: "A Place You'll Love to Work",
    fifthSectionPara:
      "We foster a people-first culture that embraces innovation, diversity, and creativity. At Lattice, we listen closely and act on feedback, ensuring you have the support and resources you need to thrive.",
  
    fifthSectionArticle1Title: "The Best Employers for Diversity",
    fifthSectionArticle1Organization: "Forbes",
    fifthSectionArticle1Year: "2024",
  
    fifthSectionArticle2Title: "The Best Employers for Univeristy Graduates",
    fifthSectionArticle2Organization: "Forbes",
    fifthSectionArticle2Year: "2023",
  
    fifthSectionArticle3Title: "5 Star Employer",
    fifthSectionArticle3Organization: "VETS Indexes",
    fifthSectionArticle3Year: "2024",
  
    fifthSectionArticle4Title: "Best Places to Work for Race Equality",
    fifthSectionArticle4Organization: "Human Rights Campaign Foundation",
    fifthSectionArticle4Year: "2023 - 2024",
  
    fifthSectionArticle5Title: "World's Most Attractive Employers",
    fifthSectionArticle5Organization: "Universum",
    fifthSectionArticle5Year: "2023",
  
    fifthSectionArticle6Title: "Disability Equality Index",
    fifthSectionArticle6Organization: "Best Place to Work for Disability Inclusion",
    fifthSectionArticle6Year: "2024",
  
    ////////
    sixthSectionHeading: "Career Blogs",
    sixthSectionStory1Quote:
      "Shifting from a job to a career mindset fuels long-term growth and impact.",
    sixthSectionStory1Heading: "Brian's Journey in Lattice Leadership",
    sixthSectionStory1Para:
      "Senior Latina leader Katty Coulson shares how community, representation, and a growth mindset guide her success in a blockchain-driven world.",
    sixthSectionStory1Button: "Read Katty's story",
    sixthSectionStory2Quote:
      "Protecting data and privacy is a responsibility we take seriously—it's about securing people's futures.",
    sixthSectionStory2Heading: "How Security Research Shapes Tomorrow",
    sixthSectionStory2Para:
      "Huseyn’s passion for puzzle-solving and knowledge sharing fuels our cybersecurity efforts. Discover how he's building a safer, blockchain-enabled future.",
    sixthSectionStory2Button: "Read Huseyn's story",
    sixthSectionStory3Quote:
      "The power of community within engineering drives collective learning and game-changing breakthroughs.",
    sixthSectionStory3Heading:
      "Building Networks: How LCI's Engineering Community Accelerates Careers",
    sixthSectionStory3Para:
      "Colin Torretta joined Lattice Cloud Infrastructure when it was just an ambitious startup within. Learn how a thriving engineering community steered his career toward new heights.",
    sixthSectionStory3Button: "Read Colin's story",
  /////



    seventhSectionBox1Heading: "Join Our Team",
    seventhSectionBox1Para:
      "Discover your next role and take the first step toward a future built on blockchain innovation.",
    seventhSectionBox1Button: "Search Job",
  
    seventhSectionBox2Heading: "Lattice Talent Network",
    seventhSectionBox2Para: "Receive the latest career insights and job opportunities.",
    seventhSectionBox2Button: "Join Our Network",
  
    seventhSectionConnect: "Connect",
    seventhSectionFacebook: "Facebook",
    seventhSectionTwitter: "Twitter",
    seventhSectionInstagram: "Instagram",
    seventhSectionLinkedin: "LinkedIn",
    seventhSectionYoutube: "YouTube",
  };
  