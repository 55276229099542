import React, { useState, useRef, useEffect } from 'react';
import './Lattice-cloud-infrasturcture-compute-template-page.css'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import img1 from '../image/cloud/rc24-oci-networking-benefits.avif'
import img2 from '../image/The_Related_Companies-Logo.svg'
import img3 from '../image/cloud/customerlogo-adept-clr.png'
import svg1 from '../image/imperial-college-london-logo3.svg'
// import svg2 from '../image/cloud/customerlogo-8x8-blk.svg'
// import svg4 from '../image/cloud/customerlogo-zoom-blk.svg'
import svg5 from '../image/german-federal-ministry-logo.svg'
// import svg6 from '../image/cloud/customerlogo-goto-blk.svg'
import svg7 from '../image/AlbaniaTech-logo.svg'
import svg8 from '../image/deliveroo-logo3.png'
import svg9 from '../image/Four_Seasons_logo3.png'
import svg12 from '../image/svg/view_week_24dp_E8EAED.svg'
import svg13 from '../image/svg/speed_24dp_E8EAED.svg'
import svg14 from '../image/svg/lock_open_24dp_E8EAED.svg'


import img5 from '../image/cloud/rc102-secure-your-workloads.svg'
import img6 from '../image/cloud/rc102-connect-oci-government-and-commercial-regions.svg'
import img7 from '../image/cloud/rc102-incorporate-cyber-resilience-capabilities.svg'
import Latticelogo from '../image/lattice-logo-img.svg';
import { networking } from '../Content/English-Content/ProductInfraNetworking';



function ProductInfraNetworking() {


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };





    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: networking.fourthSectionPage1Heading,
            description:
                networking.fourthSectionPage1Description,
            link: networking.fourthSectionPage1Link,

            fastTrackList: [networking.fourthSectionPage1fastTrackList1, networking.fourthSectionPage1fastTrackList2, networking.fourthSectionPage1fastTrackList3, networking.fourthSectionPage1fastTrackList4],
            cardheading: [networking.fourthSectionPage1cardheading1, networking.fourthSectionPage1cardheading2, networking.fourthSectionPage1cardheading3, networking.fourthSectionPage1cardheading4],
        },
        documentation: {
            heading: networking.fourthSectionPage2Heading,
            description:
                networking.fourthSectionPage2Description,
            link: networking.fourthSectionPage2Link,
            cardheading: [networking.fourthSectionPage2cardheading1, networking.fourthSectionPage2cardheading2, networking.fourthSectionPage2cardheading3],
            fastTrackList: [networking.fourthSectionPage2fastTrackList1, networking.fourthSectionPage2fastTrackList2, networking.fourthSectionPage2fastTrackList3],
            headingof3div: 'Kick-start your app development with SDKs and the command-line interface.',
        },
        events: {
            heading: networking.fourthSectionPage3Heading,
            description:
                networking.fourthSectionPage3Description,
            link: networking.fourthSectionPage3Link,
            cardheading: [networking.fourthSectionPage3cardheading1, networking.fourthSectionPage3cardheading2],
            fastTrackList: [networking.fourthSectionPage3fastTrackList1, networking.fourthSectionPage3fastTrackList2],
            headingof3div: 'Access developer resources and learn about future events designed just for you. Hear from experts in their field and gain practical hands-on experience.',
        },
        technologies: {
            heading: networking.fourthSectionPage4Heading,
            description:
                networking.fourthSectionPage4Description,
            link: networking.fourthSectionPage4Link,

            fastTrackList: [networking.fourthSectionPage4fastTrackList1, networking.fourthSectionPage4fastTrackList2, networking.fourthSectionPage4fastTrackList3],
            cardheading: [networking.fourthSectionPage4cardheading1, networking.fourthSectionPage4cardheading2, networking.fourthSectionPage4cardheading3],
            headingof3div: 'Technologies',
        },
        languages: {
            heading: networking.fourthSectionPage5Heading,
            description:
                networking.fourthSectionPage5Description,
            link: networking.fourthSectionPage5Link,

            fastTrackList: [networking.fourthSectionPage5fastTrackList1, networking.fourthSectionPage5fastTrackList2, networking.fourthSectionPage5fastTrackList3],
            cardheading: [networking.fourthSectionPage5cardheading1, networking.fourthSectionPage5cardheading2, networking.fourthSectionPage5cardheading3],
            headingof3div: 'languages:',
        },
        learn: {
            heading: networking.fourthSectionPage6Heading,
            description:
                networking.fourthSectionPage6Description,
            link: networking.fourthSectionPage6Link,

            fastTrackList: [networking.fourthSectionPage6fastTrackList1, networking.fourthSectionPage6fastTrackList2, networking.fourthSectionPage6fastTrackList3],
            cardheading: [networking.fourthSectionPage6cardheading1, networking.fourthSectionPage6cardheading2, networking.fourthSectionPage6cardheading3],
            headingof3div: 'Learn',
        },

    };




    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];




    const [currentSlide, setCurrentSlide] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

    const cards = [
        {
            id: 1,
            title: networking.eightSectionCard1Title,
            link: networking.eightSectionCard1Link1,
            // link2: "Analysis report",
            imgs: img5
        },
        {
            id: 2,
            title: networking.eightSectionCard2Title,
            link: networking.eightSectionCard2Link1,
            // link2: "Announcement",
            imgs: img6
        },
        {
            id: 3,
            title: networking.eightSectionCard3Title,
            link: networking.eightSectionCard3Link1,
            // link2: "Blog",
            imgs: img7
        },
    ];

    const handleNext = () => {
        if (currentSlide < cards.length - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth <= 600;
            setIsSmallScreen(isSmall);

            // Reset slide index if switching back to large screen
            if (!isSmall) {
                setCurrentSlide(0);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const [activeSection, setActiveSection] = useState("Free trial");

    // Handle click to update active section
    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };






    return (
        <>

            <div className="product-infra-compute-first-div">



                <h1 className="product-infra-compute-first-div-heading">

                    {networking.firstSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                    <p className="product-infra-compute-first-div-paragraph">{networking.firstSectionPara}</p>

                    <button className="product-infra-compute-first-div-btn-1"><a href="/create-account" style={{ color:"inherit" , textDecoration:"none"}}>{networking.firstSectionButton1}</a></button>

                    {/* <button className="product-infra-compute-first-div-btn-2">{networking.firstSectionButton2}</button> */}

                </h1>

                <div className="product-infra-compute-first-div-img-and-para">

                    <img src={img1} className="product-infra-compute-img-of-first-div"></img>

                    <p className="product-infra-compute-title-of-img">{networking.firstSectionSide2TitleImg}</p>

                </div>


            </div>



            <div className="product-infra-compute-sec-div">

                <h1 className="product-infra-compute-sec-div-heading">Comprehensive Networking Services for Every Application

                    <p className="product-infra-compute-sec-div-para">
                        LCI Networking delivers a suite of tools designed to optimize performance and security. Whether you need secure connectivity, load balancing, or global content delivery, our services provide the foundation for your cloud operations.
                    </p>

                </h1>

                {/* <button className="product-infra-compute-sec-div-btn">Explore LCI Networking Services</button> */}

            </div>



            <div className="product-infra-compute-third-div">

                <h1 className="product-infra-compute-third-div-heading">

                    {networking.thirdSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="product-infra-compute-third-div-cards-wrapper">

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg12} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {networking.thirdSectionSide1Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{networking.thirdSectionSide1Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg13} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {networking.thirdSectionSide2Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{networking.thirdSectionSide2Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg14} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {networking.thirdSectionSide3Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{networking.thirdSectionSide3Para}</p>

                        </div>

                    </div>



                </div>


            </div>

            {/* <div className="product-networking-compute-sec-div">

                <h1 className="product-networking-compute-sec-div-heading">{networking.secondSectionHeading}


                </h1>

                <button className="product-networking-compute-sec-div-btn">{networking.secondSectionButton} </button>

            </div> */}



            <div className="product-infrs-div-four-content-of-resources">
                <h1 className="product-infrs-div-four-solution-heading">

                    {networking.fourthSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="product-infrs-div-four-content-wrapper-of-resource">
                    <div className="product-infrs-div-four-content-1-of-resource">
                        <ul>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('downloads')}
                                >
                                    {networking.fourthSectionButton1}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('documentation')}
                                >
                                    {networking.fourthSectionButton2}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'events' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('events')}
                                >
                                    {networking.fourthSectionButton3}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('technologies')}
                                >
                                    {networking.fourthSectionButton4}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'languages' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('languages')}
                                >
                                    {networking.fourthSectionButton5}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'learn' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('learn')}
                                >
                                    {networking.fourthSectionButton6}
                                </button>
                            </li>
                        </ul>
                    </div>


                    <div className="product-infrs-div-four-combined-content">

                        <div className="product-infrs-div-four-top-content">
                            <h1 className="product-infrs-div-four-resource-heading">{currentResource.heading}</h1>
                            <p className="product-infrs-div-four-resource-description">{currentResource.description}</p>
                            {/* <button className="product-infrs-div-four-resource-btn">{currentResource.link}</button> */}
                        </div>



                        <div className="product-infrs-div-four-grid-content">
                            {currentResource.fastTrackList.map((item, index) => (
                                <div className="product-infrs-div-four-grid-item" key={index}>
                                    <h2 className="product-infrs-div-four-grid-heading">{currentResource.cardheading[index]}</h2>
                                    <p className="product-infrs-div-four-grid-para">{item}</p>
                                    {/* <a href="#" className="product-infrs-div-four-grid-link">Learn More</a> */}
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>



            <div className="product-infra-div-five">

                <h1 className="product-infrs-div-four-solution-heading">

                    Maximize Value with LCI Networking
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <p className="product-infra-div-five-small-para">Save costs and improve performance with LCI’s networking services. Consistent global pricing ensures that you get the best value for your cloud infrastructure.</p>

                <p className="product-infra-div-five-small-para">Estimate your costs and see how LCI Networking stacks up against competitors.</p>

                {/* <div className="product-infra-div-five-cards-wrapper">

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            57%</h1>

                        <p className="product-infra-div-five-all-card-para">less than AWS m6a.xlarge</p>

                    </div>

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            57%</h1>

                        <p className="product-infra-div-five-all-card-para">less than AWS m6a.xlarge</p>

                    </div>

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            51%</h1>

                        <p className="product-infra-div-five-all-card-para">less than Google Cloud n2d-standard-4</p>

                    </div>

                </div> */}

                <div className="product-infra-div-five-btns-wrapper">

                    {/* <button className="product-infra-div-five-btn-1">Try Cost Estimator</button>

                    <button className="product-infra-div-five-btn-2">Compare pricing</button> */}

                </div>

                {/* <p className="product-infra-div-five-very-small-para">* Compared with the monthly cost of a two OCPU (four vCPU) AMD (E4) with 16 GB in US eastern regions. On-demand prices are as of June 5, 2024.</p> */}


            </div>



            <div className="product-infra-div-six">

                {/* <div className="product-infra-div-six-text-wrapper">

                    <h1 className="product-infra-div-six-heading-oftxt-wrapper">{networking.sixthSectionHeading}</h1>

                    <a className="product-infra-div-six-link-of-txt-wrapper">Explore all success stories</a>

                </div> */}

                {/* <div className="product-infra-div-six-cards-wrapper">

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                                   </p>

                        <a className="product-infra-div-six-card-1-all-links">
                            {networking.sixthSectionCard1Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{networking.sixthSectionCard1Para2}</p>

                    </div>

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                                   </p>

                        <a className="product-infra-div-six-card-1-all-links">
                            {networking.sixthSectionCard2Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{networking.sixthSectionCard2Para2}</p>

                    </div>


                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                                   </p>

                        <a className="product-infra-div-six-card-1-all-links">
                            {networking.sixthSectionCard3Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{networking.sixthSectionCard3Para2}</p>

                    </div>

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                                   </p>

                        <a className="product-infra-div-six-card-1-all-links">
                            {networking.sixthSectionCard4Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{networking.sixthSectionCard4Para2}</p>

                    </div>

                </div> */}

            </div>


            <div className="product-infra-div-seven">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="product-infrs-div-four-solution-heading">

                    {networking.sixthSectionHeading}
                        <span
                            style={{
                                display: "block",
                                width: "40px",
                                height: "4px",
                                background: "#f0cc71",
                                marginBottom: "3vh",
                                marginTop: "3vh",
                            }}
                        ></span>

                    </h1>

                    <a href='/customer-story' className="product-infra-div-six-link-of-txt-wrapper">Explore all success stories</a>

                </div>

                <div className="product-infra-div-seven-img-wrapper">

                    <img src={svg1} className="product-infra-div-seven-img-1"></img>

                    {/* <img src={svg2} className="product-infra-div-seven-img-1"></img> */}

                    <img src={img2} className="product-infra-div-seven-img-1"></img>

                    {/* <img src={svg4} className="product-infra-div-seven-img-1"></img> */}

                    <img src={svg5} className="product-infra-div-seven-img-1"></img>

                    {/* <img src={svg6} className="product-infra-div-seven-img-1"></img> */}

                    <img src={svg7} className="product-infra-div-seven-img-1"></img>

                    <img src={svg8} className="product-infra-div-seven-img-1"></img>

                    <img src={svg9} className="product-infra-div-seven-img-1"></img>

                </div>

            </div>


            {/* <div className="product-infra-div-eight">
                <h1 className="product-infra-div-eight-heading">{networking.eightSectionHeading}</h1>
                <div className="product-infra-div-eight-cards-wrapper">
                    {cards.map((card, index) => (
                        <div
                            key={card.id}
                            className="product-infra-div-eight-card-1"
                            style={{
                                transform: isSmallScreen
                                    ? `translateX(-${currentSlide * 100}%)`
                                    : "none",
                                transition: "transform 0.5s ease-in-out",
                            }}
                        >
                            <div className="product-infra-div-eight-card-1-content-div">
                                <a className="product-infra-div-eight-card-1-content-div-all-links">
                                    {card.link2}
                                </a>
                                <h1 className="product-infra-div-eight-card-1-content-div-all-heading">
                                    {card.title}
                                </h1>
                                <a className="product-infra-div-eight-card-1-content-div-all-links">
                                    {card.link}
                                </a>
                            </div>
                            <div className="product-infra-div-eight-card-1-img-div">
                                <img className="product-infra-div-eight-card-1-img-div" src={card.imgs}></img>
                            </div>
                        </div>
                    ))}
                </div>

                {isSmallScreen && (
                    <div className="carousel-controls">
                        <button onClick={handlePrev} disabled={currentSlide === 0}>
                            &lt;
                        </button>
                        <span>
                            {currentSlide + 1} / {cards.length}
                        </span>
                        <button onClick={handleNext} disabled={currentSlide === cards.length - 1}>
                            &gt;
                        </button>
                    </div>
                )}
            </div> */}


            <div className="product-infra-last-div">

                <div className="product-infra-last-div-wrapper">

                    <h1 className="product-infra-last-div-heading">{networking.ninthSectionHeading}</h1>

                    <div className="become-partners-dynamic-island" style={{ marginTop: "5vh" }}>


                        <button
                            onClick={() => handleSectionClick("Free trial")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Free trial" ? "active-section" : ""}`}
                        >
                            {networking.ninthSectionButton1}
                        </button>

                        {/* <button
                            onClick={() => handleSectionClick("Hands-on labs")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Hands-on labs" ? "active-section" : ""}`}
                        >
                            {networking.ninthSectionButton2}
                        </button> */}

                        {/* <button
                            onClick={() => handleSectionClick("Estimate your costs")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Estimate your costs" ? "active-section" : ""}`}
                        >
                            {networking.ninthSectionButton3}
                        </button>

                        <button
                            onClick={() => handleSectionClick("Contact sales")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Contact sales" ? "active-section" : ""}`}
                        >

                            {networking.ninthSectionButton4}
                        </button> */}


                    </div>


                    <div className="become-partners-dynamic-content-area">


                        <div className="become-partners-sec-div-cards">
                            {activeSection === "Free trial" && (
                                <>

                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{networking.ninthSectionPage1Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{networking.ninthSectionPage1Side1Para}</p>

                                            <a href="/create-account" style={{ color:"inherit" , textDecoration:"none"}}> <button className="product-infra-last-div-1-list-card-1-all-main-btn">{networking.ninthSectionPage1Side1button}</button> </a>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{networking.ninthSectionPage1Side2Heading}</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage1Side2List1}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage1Side2List2}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage1Side2List3}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> {networking.ninthSectionPage1Side2List4}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage1Side2List5}</li>
                                                {/* <li className="product-infra-last-div-list-1-card-1-list-all"> {networking.ninthSectionPage1Side2List6}</li> */}

                                            </ul>

                                        </div>



                                    </div>

                                </>
                            )}

                            {/* {activeSection === "Hands-on labs" && (
                                <>

                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-2-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{networking.ninthSectionPage2Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{networking.ninthSectionPage2Side1Para}</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn">{networking.ninthSectionPage2Side1button}</button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{networking.ninthSectionPage2Side2Heading1}</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage2Side2List1}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage2Side2List2}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage2Side2List3}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> {networking.ninthSectionPage2Side2List4}</li> */}
                                                {/* <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li> */}
                                            {/* </ul>

                                        </div>

                                    </div>

                                </>
                            )}


                            {activeSection === "Estimate your costs" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{networking.ninthSectionPage3Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{networking.ninthSectionPage3Side1Para}</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn">{networking.ninthSectionPage3Side1button}</button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{networking.ninthSectionPage3Side2Heading}</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage3Side2List1}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage3Side2List2}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage3Side2List3}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> {networking.ninthSectionPage3Side2List4}</li> */}
                                                {/* <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                                           <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                                           <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li> */}
                                            {/* </ul>

                                        </div>



                                    </div>






                                </>
                            )}

                            {activeSection === "Contact sales" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{networking.ninthSectionPage4Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{networking.ninthSectionPage4Side1Para}</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn">{networking.ninthSectionPage4Side1button}</button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{networking.ninthSectionPage4Side2Heading}</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage4Side2List1}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage4Side2List2}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{networking.ninthSectionPage4Side2List3}</li> */}
                                                {/* <li className="product-infra-last-div-list-1-card-1-list-all"> 2 Autonomous Databases, 20 GB each</li>
                                                                            <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                                            <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                                            <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li> */}
                                            {/* </ul>

                                        </div>



                                    </div>




                                </>
                            )} */}


                        </div>

                    </div>


                </div>

            </div>













            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    );
};

export default ProductInfraNetworking;
