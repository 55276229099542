import React, { useState } from "react";
import "./event-page.css";
import "./../customers/customer-story.css";
import { footerSections, cookieText } from "../Content/eng_content";
import { Link } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import ShareIcon from "../image/share_icon.png";
import PaginationRight from "../image/pqagination-right.png";
import PaginationLeft from "../image/pqagination-left.png";
import Search from "../image/search.png";
import RightSide from "../image/angle-right.png";
import DownSide from "../image/angle-down.png";
import Latticelogo from '../image/lattice-logo-img.svg';
import { companyEvents } from "../Content/English-Content/NavbarEvents";

function EventPage() {
  const events = [
    {
      type: companyEvents.event1Type,
      title: companyEvents.event1title,
      description:
      companyEvents.event1Description,
      date: companyEvents.event1date,
      location: companyEvents.event1location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event1Type,
      title: companyEvents.event1title,
      description:
      companyEvents.event1Description,
      date: companyEvents.event1date,
      location: companyEvents.event1location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event1Type,
      title: companyEvents.event1title,
      description:
      companyEvents.event1Description,
      date: companyEvents.event1date,
      location: companyEvents.event1location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event1Type,
      title: companyEvents.event1title,
      description:
      companyEvents.event1Description,
      date: companyEvents.event1date,
      location: companyEvents.event1location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event1Type,
      title: companyEvents.event1title,
      description:
      companyEvents.event1Description,
      date: companyEvents.event1date,
      location: companyEvents.event1location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event1Type,
      title: companyEvents.event1title,
      description:
      companyEvents.event1Description,
      date: companyEvents.event1date,
      location: companyEvents.event1location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event1Type,
      title: companyEvents.event1title,
      description:
      companyEvents.event1Description,
      date: companyEvents.event1date,
      location: companyEvents.event1location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event1Type,
      title: companyEvents.event1title,
      description:
      companyEvents.event1Description,
      date: companyEvents.event1date,
      location: companyEvents.event1location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event1Type,
      title: companyEvents.event1title,
      description:
      companyEvents.event1Description,
      date: companyEvents.event1date,
      location: companyEvents.event1location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event1Type,
      title: companyEvents.event1title,
      description:
      companyEvents.event1Description,
      date: companyEvents.event1date,
      location: companyEvents.event1location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
    {
      type: companyEvents.event2Type,
      title: companyEvents.event2title,
      description:
      companyEvents.event2Description,
      date: companyEvents.event2date,
      location: companyEvents.event2location,
    },
  ];

  const browseByFormat = [
    companyEvents.browseByFormatCheckbox1,
    companyEvents.browseByFormatCheckbox2,
    companyEvents.browseByFormatCheckbox3,
  ];
  const typeFilter = [
    companyEvents.typeFilterCheckbox1,
    companyEvents.typeFilterCheckbox2,
    companyEvents.typeFilterCheckbox3,
    companyEvents.typeFilterCheckbox4,
    companyEvents.typeFilterCheckbox5,
    companyEvents.typeFilterCheckbox6,
  ];
  const dateFilter = [
    companyEvents.dateFilterCheckbox1,
    companyEvents.dateFilterCheckbox2,
    companyEvents.dateFilterCheckbox3,
    companyEvents.dateFilterCheckbox4,
    companyEvents.dateFilterCheckbox5,
    companyEvents.dateFilterCheckbox6,
  ];
  const locationFilter = [
    companyEvents.locationFilterCheckbox1,
    companyEvents.locationFilterCheckbox2,
    companyEvents.locationFilterCheckbox3,
    companyEvents.locationFilterCheckbox4,
  ];
  const industry = [ companyEvents.industryCheckbox1,
    companyEvents.industryCheckbox2,];
  const product = [
    companyEvents.productCheckbox1,
    companyEvents.productCheckbox2,
    companyEvents.productCheckbox3,
  ];
  const language = [
    companyEvents.languageCheckbox1,
    companyEvents.languageCheckbox2,
    companyEvents.languageCheckbox3,
    companyEvents.languageCheckbox4,
    companyEvents.languageCheckbox5,
  ];

  const [openSections, setOpenSections] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage, setEventsPerPage] = useState(5);
  const totalPages = Math.ceil(events.length / eventsPerPage);
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);

  const handleCheckboxChange = (value) => {
    setSelectedValues((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const removeItem = (value) => {
    setSelectedValues((prev) => prev.filter((item) => item !== value));
  };
  const clearAll = () => {
    setSelectedValues([]);
  };

  const handleAccordionToggle = (section) => {
    setActiveAccordion(activeAccordion === section ? null : section);
  };

  const handleFilterTextClick = () => {
    const accordion = document.querySelector(
      ".customer-stories-filter-accordion"
    );
    accordion.style.display =
      accordion.style.display === "block" ? "none" : "block";
  };

  const handleDoneClick = () => {
    const accordion = document.querySelector(
      ".customer-stories-filter-accordion"
    );
    accordion.style.display = "none";
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggleDropdown = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const rangeSize = 5;
  const currentRangeStart =
    Math.floor((currentPage - 1) / rangeSize) * rangeSize + 1;
  const currentRangeEnd = Math.min(
    currentRangeStart + rangeSize - 1,
    totalPages
  );

  const handleNextRange = () => {
    if (currentRangeEnd < totalPages) {
      handlePageChange(currentRangeEnd + 1);
    }
  };

  const handlePrevRange = () => {
    if (currentRangeStart > 1) {
      handlePageChange(currentRangeStart - 1);
    }
  };

  const handleItemsPerPageChange = (numItems) => {
    setEventsPerPage(numItems);
    setCurrentPage(1);
  };

   // cookies
  
  
      const [showCookieModal, setShowCookieModal] = useState(false);
      const [isLoading, setIsLoading] = useState(true);
  
      const handleCookieClick = () => {
          setShowCookieModal(true);
          setIsLoading(true);
  
  
          setTimeout(() => {
              setIsLoading(false);
          }, 500);
      };
  
      const handleAcceptAll = () => {
          console.log("Cookies Accepted");
          setShowCookieModal(false);
      };
  
      const handleDenyAll = () => {
          console.log("Cookies Denied");
          setShowCookieModal(false);
      };


  return (
    <>
      <section className="nav-events-bg">
        <div className="search-oracle-events">
          <h2>{companyEvents.section1Heading}</h2>
          <p>{companyEvents.section1Para}</p>
        </div>

        <div className="customer-filter-flex">
          <div className="customer-stories-search-accordion">
            <div className="customer-stories-search-bar">
              <div className="customer-stories-search-wrapper">
                <span className="customer-stories-search-icon">
                  <img src={Search}></img>
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  className="customer-stories-search-input"
                />
              </div>
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "browseByFormat"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("browseByFormat")}
              >
                {companyEvents.section2Button1}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "browseByFormat" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "browseByFormat" && (
                <div className="customer-stories-accordion-content">
                  {browseByFormat.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        className="customer-stories-accordion-checkbox"
                        onChange={() => handleCheckboxChange(item)}
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "typeFilter"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("typeFilter")}
              >
                {companyEvents.section2Button2}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "typeFilter" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "typeFilter" && (
                <div className="customer-stories-accordion-content">
                  {typeFilter.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "dateFilter"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("dateFilter")}
              >
                {companyEvents.section2Button3}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "dateFilter" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "dateFilter" && (
                <div className="customer-stories-accordion-content">
                  {dateFilter.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "locationFilter"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("locationFilter")}
              >
                {companyEvents.section2Button4}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "locationFilter" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "locationFilter" && (
                <div className="customer-stories-accordion-content">
                  {locationFilter.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "industry"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("industry")}
              >
                {companyEvents.section2Button5}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "locationFilter" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "industry" && (
                <div className="customer-stories-accordion-content">
                  {industry.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "product"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("product")}
              >
                {companyEvents.section2Button6}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "locationFilter" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "product" && (
                <div className="customer-stories-accordion-content">
                  {product.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>
            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "language"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("language")}
              >
                {companyEvents.section2Button7}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "locationFilter" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "language" && (
                <div className="customer-stories-accordion-content">
                  {language.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="customer-stories-filter-accordion">
            <div className="customer-stories-filter-header">
              <h4>{companyEvents.filter}</h4>
              <button
                className="customer-stories-filter-done"
                onClick={handleDoneClick}
              >
                {companyEvents.done}
              </button>
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "browseByFormat"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("browseByFormat")}
              >
                {companyEvents.section2Button1}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "browseByFormat" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "browseByFormat" && (
                <div className="">
                  {browseByFormat.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "typeFilter"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("typeFilter")}
              >
                {companyEvents.section2Button2}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "typeFilter" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "typeFilter" && (
                <div className="">
                  {typeFilter.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "dateFilter"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("dateFilter")}
              >
                {companyEvents.datefilter}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "dateFilter" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "dateFilter" && (
                <div className="">
                  {dateFilter.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "locationFilter"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("locationFilter")}
              >
                {companyEvents.section2Button4}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "locationFilter" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "locationFilter" && (
                <div className="">
                  {locationFilter.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "industry"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("industry")}
              >
                {companyEvents.section2Button5}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "industry" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "industry" && (
                <div className="">
                  {industry.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "product"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("product")}
              >
                {companyEvents.section2Button6}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "industry" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "product" && (
                <div className="">
                  {product.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>
            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${
                  activeAccordion === "language"
                    ? "customer-stories-accordion-header-active"
                    : ""
                }`}
                onClick={() => handleAccordionToggle("language")}
              >
                {companyEvents.section2Button7}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "industry" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "language" && (
                <div className="">
                  {language.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="customer-filter">
            <div className="customer-story-filter">
              <div className="filter-text" onClick={handleFilterTextClick}>
                <strong>{companyEvents.filteredBy}</strong>:
                {selectedValues.length == 0 ? (
                  <span className="no-filter-applied">{companyEvents.noFiltersApplied}</span>
                ) : (
                  ""
                )}
              </div>

              {selectedValues.length > 0 ? (
                <p className="clear-all" onClick={() => clearAll()}>
                  {companyEvents.clearAll}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="nav-events-filter-items">
              <ul>
                {selectedValues.map((value) => (
                  <li key={value}>
                    <span>{value}</span>
                    <span
                      onClick={() => {
                        removeItem(value);
                      }}
                      className="remove-filter-item"
                    >
                      X
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="container mt-3 nav-events-box">
              <div className="d-flex align-items-center gap-2 nav-event-show">
                <span>{companyEvents.show}</span>
                <div className="dropdown">
                  <button
                    className="btn btn-light dropdown-toggle nav-events-dropdown d-flex justify-content-between align-items-center"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>{eventsPerPage} {companyEvents.items}</span>
                    <span className="ms-2"></span>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleItemsPerPageChange(5)}
                      >
                        {companyEvents.items5}
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleItemsPerPageChange(7)}
                      >
                        {companyEvents.items7}
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleItemsPerPageChange(10)}
                      >
                        {companyEvents.items10}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="results-text mt-2">
                {indexOfFirstEvent + 1} -{" "}
                {Math.min(indexOfLastEvent, events.length)} of {events.length}{" "}
                {companyEvents.results}
              </div>

              <div className="container my-4 nav-events-wrapper">
                {currentEvents.map((event, index) => (
                  <div key={index} className="event-item">
                    <h6 className="text-uppercase text-secondary nav-event-type">
                      {event.type}
                    </h6>
                    <h5 className="text-primary nav-event-title">
                      {event.title}
                    </h5>
                    <p>{event.description}</p>
                    <div className="text-muted mb-1 nav-event-date">
                      {event.date}
                      {event.location && (
                        <>
                          <span className="nav-event-separator">|</span>
                          {event.location}
                        </>
                      )}
                      <span className="nav-event-separator">|</span>
                      <button className="btn btn-dark me-2 nav-event-register">
                        {companyEvents.register}
                      </button>
                      <span className="nav-event-separator">|</span>
                      <span className="nav-event-share">
                        <img src={ShareIcon} alt="Share icon"></img>
                      </span>
                    </div>
                  </div>
                ))}

                <div className="d-flex justify-content-center">
                  <Pagination className="nav-events-pagination">
                    {currentRangeStart > 1 && (
                      <div
                        onClick={handlePrevRange}
                        className="nav-events-arrow"
                      >
                        <img src={PaginationLeft} alt="pagination left" />
                      </div>
                    )}

                    {[...Array(currentRangeEnd - currentRangeStart + 1)].map(
                      (_, index) => {
                        const pageNum = currentRangeStart + index;
                        return (
                          <Pagination.Item
                            key={pageNum}
                            active={pageNum === currentPage}
                            onClick={() => handlePageChange(pageNum)}
                          >
                            {pageNum}
                          </Pagination.Item>
                        );
                      }
                    )}

                    {currentRangeEnd < totalPages && (
                      <div
                        onClick={handleNextRange}
                        className="nav-events-arrow"
                      >
                        <img src={PaginationRight} alt="pagination right" />
                      </div>
                    )}
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="footer">
        <div className="f_list">
          {footerSections.map((section) => (
            <div key={section.id} className="list-section">
              <h3
                className="footer-heading"
                onClick={() => toggleDropdown(section.id)}
              >
                {section.heading}
                <span
                  className={`arrow ${
                    openSections[section.id] ? "rotate" : ""
                  }`}
                >
                  &#8595;
                </span>
              </h3>
              <div
                className={`resource-list ${
                  openSections[section.id] ? "show" : ""
                }`}
              >
                <ul>
                  {section.links.map((link, index) => (
                    <li key={index}>
                      {link.to.startsWith("/") ? (
                        <Link to={link.to}>{link.text}</Link>
                      ) : (
                        <a href={link.to}>{link.text}</a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="footer-bottom">
                              
                                                  <span className="footer-separator"> © 2024 Lattice</span> |
                              
                                                  <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
                              
                                                  <span className="footer-separator"
                                                      style={{ cursor: "pointer", color: "inherit" }}
                                                      onClick={handleCookieClick}> Cookie Preferences  </span>
                              
                                                  <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
                              
                                                  <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                                              </div>
                              
                                          </div>
                              
                              
                                          {showCookieModal && (
                                              <div
                                                  className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                                                      }`}
                                              >
                                                  <div className="cookie-modal">
                              
                                                      {isLoading ? (
                                                          <div className="loading-spinner">{cookieText.loading}</div>
                                                      ) : (
                                                          <div className="cookie-content">
                              
                                                              <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                                                  &times;
                                                              </button>
                                                              <div className="heading-of-cookies-with-logo">
                              
                                                                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>
                              
                                                                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>
                              
                                                              </div>
                              
                                                              <div className="div-2-heading-below-text-and-list">
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>
                              
                                                                  <ul>
                                                                  {cookieText.listItems.map((item, index) => (
                                                                      <li key={index} className="div-2-cookies-list-text">
                                                                          <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                                                      </li>
                                                                      ))}
                                                                  </ul>
                              
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>
                              
                                                              </div>
                              
                                                              <div className="button-group">
                                                                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                                                                     {cookieText.btn1}
                                                                  </button>
                                                                  <button className="btn deny-btn" onClick={handleDenyAll}>
                                                                  {cookieText.btn2}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          )}
    </>
  );
}

export default EventPage;
