import React, { useState, useRef } from 'react';
import './developer.css'
import { developerSlides, footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import img from '../image/developer-img/last-1-img.avif'
import img2 from '../image/developer-img/last-2-img.avif'
import img3 from '../image/developer-img/last-3-img.webp'
import img4 from '../image/developer-img/technology transfer.jpg'
import img5 from '../image/developer-img/programming-languages-shutterstock-1680857539.webp'
import img6 from '../image/developer-img/last-last.avif'
import img7 from '../image/deliveroo-logo3.png'
import img8 from '../image/The_Related_Companies-Logo.svg'
import img9 from '../image/imperial-college-london-logo3.svg'
import img10 from '../image/german-federal-ministry-logo.svg'
import img11 from '../image/Four_Seasons_logo3.png'
import img12 from '../image/AlbaniaTech-logo.svg'
// import img13 from '../image/developer-img/devo-php-logo.avif'
// import img14 from '../image/developer-img/devo-javascript-logo.png'


import card1 from '../image/developer-img/card-1.webp'
import card2 from '../image/developer-img/card2.webp'
import card3 from '../image/developer-img/card-3.webp'

import secLlast from '../image/developer-img/sec-last-div.avif'
import Latticelogo from '../image/lattice-logo-img.svg';
import { developerResourceCenter } from '../Content/English-Content/developer-resource-center';

function DevelopersResourceCenter() {
    const latestNewsRef = useRef(null);
    const aiRef = useRef(null);
    const languagesRef = useRef(null);
    const solutionsRef = useRef(null);
    const communityRef = useRef(null);
    const resourcesRef = useRef(null);

    // State for active navigation item
    const [activeIndex, setActiveIndex] = useState(null);

    // Handle navigation clicks
    const handleNavigation = (ref, index) => {
        // Smooth scroll to the referenced content section
        ref.current.scrollIntoView({ behavior: 'smooth' });

        // Set active index to highlight the clicked item
        setActiveIndex(index);
    };

    // Style for active navigation item
    const getNavItemClass = (index) => {
        return activeIndex === index ? 'active-nav-item' : '';
    };


    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(false);

    const nextSlide = () => {
        setFade(false); // Reset animation
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % developerSlides.length); // Move to the next slide
            setFade(true); // Trigger fade-in for new content
        }, 100); // Ensure a slight delay for animation reset
    };

    const prevSlide = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + developerSlides.length) % developerSlides.length);
            setFade(true);
        }, 100);
    };

    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };




    // State to track the selected resource
    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: developerResourceCenter.seventhSectionPage1Heading,
            description:
                developerResourceCenter.seventhSectionPage1Description,
            link: developerResourceCenter.seventhSectionPage1Link,
            imgSrc: img,
            fastTrackList: [developerResourceCenter.seventhSectionPage1FastrackList1, developerResourceCenter.seventhSectionPage1FastrackList2, developerResourceCenter.seventhSectionPage1FastrackList3],
            headingof3div: developerResourceCenter.seventhSectionPage1DivHeading,
        },
        documentation: {
            heading: developerResourceCenter.seventhSectionPage2Heading,
            description:
                developerResourceCenter.seventhSectionPage2Description,
            link: developerResourceCenter.seventhSectionPage2Link,
            imgSrc: img2,
            fastTrackList: [developerResourceCenter.seventhSectionPage2FastrackList1, developerResourceCenter.seventhSectionPage2FastrackList2, developerResourceCenter.seventhSectionPage2FastrackList3, developerResourceCenter.seventhSectionPage2FastrackList4, developerResourceCenter.seventhSectionPage2FastrackList5, developerResourceCenter.seventhSectionPage2FastrackList6, developerResourceCenter.seventhSectionPage2FastrackList7, developerResourceCenter.seventhSectionPage2FastrackList8],
            headingof3div: developerResourceCenter.seventhSectionPage2DivHeading,
        },
        events: {
            heading: developerResourceCenter.seventhSectionPage3Heading,
            description:
                developerResourceCenter.seventhSectionPage3Description,
            link: developerResourceCenter.seventhSectionPage3Link,
            imgSrc: img3,
            fastTrackList: [developerResourceCenter.seventhSectionPage3FastrackList1, developerResourceCenter.seventhSectionPage3FastrackList2],
            headingof3div: developerResourceCenter.seventhSectionPage3DivHeading,
        },
        technologies: {
            heading: developerResourceCenter.seventhSectionPage4Heading,
            description:
                developerResourceCenter.seventhSectionPage4Description,
            link: developerResourceCenter.seventhSectionPage4Link,
            imgSrc: img4,
            fastTrackList: [developerResourceCenter.seventhSectionPage4FastrackList1],
            headingof3div: developerResourceCenter.seventhSectionPage4DivHeading,
        },
        languages: {
            heading: developerResourceCenter.seventhSectionPage5Heading,
            description:
                developerResourceCenter.seventhSectionPage5Description,
            link: developerResourceCenter.seventhSectionPage5Link,
            imgSrc: img5,
            fastTrackList: [developerResourceCenter.seventhSectionPage5FastrackList1, developerResourceCenter.seventhSectionPage5FastrackList2, developerResourceCenter.seventhSectionPage5FastrackList3,],
            headingof3div: developerResourceCenter.seventhSectionPage5DivHeading,
        },
        learn: {
            heading: developerResourceCenter.seventhSectionPage6Heading,
            description:
                developerResourceCenter.seventhSectionPage6Description,
            link: developerResourceCenter.seventhSectionPage6Link,
            imgSrc: img6,
            fastTrackList: [developerResourceCenter.seventhSectionPage6FastrackList1, developerResourceCenter.seventhSectionPage6FastrackList2, developerResourceCenter.seventhSectionPage6FastrackList3,],
            headingof3div: developerResourceCenter.seventhSectionPage6DivHeading,
        },

    };

    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };




    return (
        <>

            <div className="main-div-heading">

                <h1 className="main-heading-of-developer">

                    {developerResourceCenter.firstSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span> <p className="side-heading-para">{developerResourceCenter.firstSectionPara}</p>
                </h1>

            </div>

            <div className="nav-bar-of-developer">
                <ul>
                    <p className="first-nav-heading">{developerResourceCenter.navBarHeading}</p>
                    <li className={getNavItemClass(0)}>
                        <button className="btn-of-developer" onClick={() => handleNavigation(latestNewsRef, 0)}>
                            {developerResourceCenter.navBarButton1}
                        </button>
                    </li>
                    <li className={getNavItemClass(1)}>
                        <button className="btn-of-developer" onClick={() => handleNavigation(aiRef, 1)}>
                            {developerResourceCenter.navBarButton2}
                        </button>
                    </li>
                    <li className={getNavItemClass(2)}>
                        <button className="btn-of-developer" onClick={() => handleNavigation(languagesRef, 2)}>
                            {developerResourceCenter.navBarButton3}
                        </button>
                    </li>
                    <li className={getNavItemClass(3)}>
                        <button className="btn-of-developer" onClick={() => handleNavigation(solutionsRef, 3)}>
                            {developerResourceCenter.navBarButton4}
                        </button>
                    </li>
                    <li className={getNavItemClass(4)}>
                        <button className="btn-of-developer" onClick={() => handleNavigation(communityRef, 4)}>
                            {developerResourceCenter.navBarButton5}
                        </button>
                    </li>
                    <li className={getNavItemClass(5)}>
                        <button className="btn-of-developer" onClick={() => handleNavigation(resourcesRef, 5)}>
                            {developerResourceCenter.navBarButton6}
                        </button>
                    </li>
                </ul>
            </div>


            <div className="dynamic-content-of-developer">
                <div className="content-of-latest-news" ref={latestNewsRef}>

                    <div className="scroller-img-bg-of-developer">
                        <div className="slider-developer">
                            {/* Image slides */}
                            <div className="slides">
                                {developerSlides.map((slide, index) => (
                                    <div
                                        key={index}
                                        className={`slide ${index === currentIndex ? 'active' : ''}`}
                                    >
                                        <div className="row">
                                            <div className="column img-container">
                                                <img src={slide.image} alt={slide.title} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Info content with fade-in animation */}
                            <div className={`column content ${fade ? 'fade-in' : ''}`}>
                                <h1>{developerSlides[currentIndex].title}</h1>
                                <span
                                    style={{
                                        display: 'block',
                                        width: '10%',
                                        height: '4px',
                                        backgroundColor: 'rgba(255, 140, 0, 1)',
                                        marginBottom: '2vh',
                                    }}
                                ></span>
                                <p>{developerSlides[currentIndex].description}</p>
                                <a href={developerSlides[currentIndex].link} className="btns">
                                    {developerSlides[currentIndex].buttonText}
                                </a>
                            </div>

                            {/* Navigation buttons */}
                            <div className="navigation">
                                <button className="prev" onClick={prevSlide}>
                                    <svg width="85" height="100" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 4l-4 4 4 4" stroke="#3A3631" strokeWidth="1" fill="none" />
                                    </svg>
                                </button>
                                <button className="next" onClick={nextSlide}>
                                    <svg width="85" height="100" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 4l4 4-4 4" stroke="#3A3631" strokeWidth="1" fill="none" />
                                    </svg>
                                </button>
                            </div>

                            {/* Bullet points for slide navigation */}
                            <div className="bullets">
                                {developerSlides.map((_, index) => (
                                    <span
                                        key={index}
                                        className={`bullet ${index === currentIndex ? 'active' : ''}`}
                                        onClick={() => setCurrentIndex(index)}
                                    ></span>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>


                <div className="content-of-ai" ref={aiRef}>

                    <div className="heading-of-ai">
                        <h1 className="ai-heading">{developerResourceCenter.thirdSectionHeading}</h1>
                        <p className="links-of-all-ai"> <a href='#'> {developerResourceCenter.thirdSectionLink}</a></p>
                    </div>

                    <div className="card-of-ai">

                        <div className="ai-card-1">

                            <div className="img-of-ai-card"><img src={card1} className="img-of-ai-card" ></img></div>

                            <h1 className="card-heading">{developerResourceCenter.thirdSectionCard1Heading}</h1>
                            <ul>
                                <li>{developerResourceCenter.thirdSectionCard1List1}</li>
                                <li>{developerResourceCenter.thirdSectionCard1List2}</li>
                                <li>{developerResourceCenter.thirdSectionCard1List3}</li>
                            </ul>

                            <a className="link-of-card">{developerResourceCenter.thirdSectionCard1Button}</a>

                        </div>

                        <div className="ai-card-2">

                            <div className="img-of-ai-card"><img src={card2} className="img-of-ai-card" ></img></div>

                            <h1 className="card-heading">{developerResourceCenter.thirdSectionCard2Heading}</h1>
                            <ul>
                                <li>{developerResourceCenter.thirdSectionCard2List1}</li>
                                <li>{developerResourceCenter.thirdSectionCard2List2}</li>
                                <li>{developerResourceCenter.thirdSectionCard2List3}</li>
                            </ul>

                            <a className="link-of-card">{developerResourceCenter.thirdSectionCard2Button}</a>

                        </div>

                        <div className="ai-card-3">

                            <div className="img-of-ai-card"><img src={card3} className="img-of-ai-card" ></img></div>

                            <h1 className="card-heading">{developerResourceCenter.thirdSectionCard3Heading}</h1>
                            <ul>
                                <li>{developerResourceCenter.thirdSectionCard3List1}</li>
                                <li>{developerResourceCenter.thirdSectionCard3List1}</li>
                                <li>{developerResourceCenter.thirdSectionCard3List1}</li>
                            </ul>

                            <a className="link-of-card">{developerResourceCenter.thirdSectionCard3Button}</a>


                        </div>



                    </div>

                </div>



                <div className="content-of-languages" ref={languagesRef}>
                    <h1 className="heading-of-lang">{developerResourceCenter.fourthSectionHeading}</h1>
                    <div className="img-of-lang">
                        <img src={img7} className="img-of-lang1"></img>
                        <img src={img8} className="img-of-lang1"></img>
                        <img src={img9} className="img-of-lang1"></img>
                        <img src={img10} className="img-of-lang1"></img>
                        <img src={img11} className="img-of-lang1"></img>
                        <img src={img12} className="img-of-lang1"></img>
                        {/* <img src={img13} className="img-of-lang1"></img>
                        <img src={img14} className="img-of-lang1"></img> */}

                    </div>

                </div>

                <div className="content-of-solutions" ref={solutionsRef}>
                    <h1 className="solution-heading">{developerResourceCenter.fifthSectionHeading}</h1>

                    <div className="solution-card">

                        <div className="solution-card-1">

                            <h1 className="solution-heading">{developerResourceCenter.fifthSectionCard1Heading}</h1>

                            <p className="solution-para">{developerResourceCenter.fifthSectionCard1Para}</p>

                            <button className="solution-btn">{developerResourceCenter.fifthSectionButton}</button>

                        </div>

                        <div className="solution-card-2">

                            <h1 className="solution-heading">{developerResourceCenter.fifthSectionCard2Heading}</h1>

                            <p className="solution-para">{developerResourceCenter.fifthSectionCard2Para}</p>

                            <button className="solution-btn">{developerResourceCenter.fifthSectionButton}</button>

                        </div>

                        <div className="solution-card-3">

                            <h1 className="solution-heading">{developerResourceCenter.fifthSectionCard3Heading}</h1>

                            <p className="solution-para">{developerResourceCenter.fifthSectionCard3Para}</p>

                            <button className="solution-btn">{developerResourceCenter.fifthSectionButton}</button>
                        </div>

                        <div className="solution-card-4">

                            <h1 className="solution-heading">{developerResourceCenter.fifthSectionCard4Heading}</h1>

                            <p className="solution-para">{developerResourceCenter.fifthSectionCard4Para}</p>

                            <button className="solution-btn">{developerResourceCenter.fifthSectionButton}</button>

                        </div>

                        <div className="solution-card-5">

                            <h1 className="solution-heading">{developerResourceCenter.fifthSectionCard5Heading}</h1>

                            <p className="solution-para">{developerResourceCenter.fifthSectionCard5Para}</p>

                            <button className="solution-btn">{developerResourceCenter.fifthSectionButton}</button>

                        </div>

                        <div className="solution-card-6">

                            <h1 className="solution-heading">{developerResourceCenter.fifthSectionCard6Heading}</h1>

                            <p className="solution-para">{developerResourceCenter.fifthSectionCard6Para}</p>

                            <button className="solution-btn">{developerResourceCenter.fifthSectionButton}</button>
                        </div>

                    </div>

                </div>


                <div className="content-of-community" ref={communityRef}>

                    <h1 className="solution-heading">{developerResourceCenter.sixthSectionHeading}</h1>

                    <div className="com-and-event-img-para">
                        <div className="img-of-com-and-event">
                            <img src={secLlast} className="img-of-com-and-event" ></img>
                        </div>

                        <div className="para-of-com-and-event">

                            <h1 className="solution-heading">{developerResourceCenter.sixthSectionSubHeading}</h1>

                            <p className="para-of-com">{developerResourceCenter.sixthSectionPara}</p>

                            <button className="com-and-event-btn">{developerResourceCenter.sixthSectionButton}</button>

                        </div>
                    </div>
                    <div className="card-of-com-and-event">

                        <div className="com-card-1">
                            <h1 className="com-heading-of-card">
                                {developerResourceCenter.sixthSectionCard1Heading}
                            </h1>

                            <p className="com-para-of-card">
                                {developerResourceCenter.sixthSectionCard1Para}
                            </p>

                            <a className="link-of-com">
                                {developerResourceCenter.sixthSectionCard1Button}
                            </a>

                        </div>

                        <div className="com-card-1">
                            <h1 className="com-heading-of-card">
                                {developerResourceCenter.sixthSectionCard2Heading}
                            </h1>

                            <p className="com-para-of-card">
                                {developerResourceCenter.sixthSectionCard2Para}
                            </p>

                            <a className="link-of-com">
                                {developerResourceCenter.sixthSectionCard2Button}
                            </a>

                        </div>

                        <div className="com-card-1">
                            <h1 className="com-heading-of-card">
                                {developerResourceCenter.sixthSectionCard3Heading}
                            </h1>

                            <p className="com-para-of-card">
                                {developerResourceCenter.sixthSectionCard3Para}
                            </p>

                            <a className="link-of-com">
                                {developerResourceCenter.sixthSectionCard3Button}
                            </a>

                        </div>

                    </div>


                </div>


                <div className="content-of-resources" ref={resourcesRef}>
                    <h1 className="solution-heading">{developerResourceCenter.seventhSectionHeading}</h1>

                    <div className="content-wrapper-of-resource">
                        <div className="content-1-of-resource">
                            <ul>
                                <li>
                                    <button
                                        className={`btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                            }`}
                                        onClick={() => handleResourceClick('downloads')}
                                    >
                                        {developerResourceCenter.seventhSectionButton1}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={`btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                            }`}
                                        onClick={() => handleResourceClick('documentation')}
                                    >
                                        {developerResourceCenter.seventhSectionButton2}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={`btn-resource ${selectedResource === 'events' ? 'active' : ''
                                            }`}
                                        onClick={() => handleResourceClick('events')}
                                    >
                                        {developerResourceCenter.seventhSectionButton3}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={`btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                            }`}
                                        onClick={() => handleResourceClick('technologies')}
                                    >
                                        {developerResourceCenter.seventhSectionButton4}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={`btn-resource ${selectedResource === 'languages' ? 'active' : ''
                                            }`}
                                        onClick={() => handleResourceClick('languages')}
                                    >
                                        {developerResourceCenter.seventhSectionButton5}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={`btn-resource ${selectedResource === 'learn' ? 'active' : ''
                                            }`}
                                        onClick={() => handleResourceClick('learn')}
                                    >
                                        {developerResourceCenter.seventhSectionButton6}
                                    </button>
                                </li>
                            </ul>
                        </div>

                        {/* Content-2 */}
                        <div className="content-2-of-resource">
                            <div className="img-of-resource-and-para">
                                <div className="img-of-resource">
                                    <img className="img-of-resource" src={currentResource.imgSrc} alt={currentResource.heading} />
                                </div>
                                <div className="para-of-res-content">
                                    <h1 className="resource-heading-of-card">{currentResource.heading}</h1>
                                    <p className="para-of-resource">{currentResource.description}</p>
                                    <a className="link-of-com">{currentResource.link}</a>
                                </div>
                            </div>
                        </div>

                        {/* Content-3 */}
                        <div className="content-3-of-resource">
                            <p className="res-3-div-para">{currentResource.headingof3div}</p>
                            <ul>
                                {currentResource.fastTrackList.map((item, index) => (
                                    <li className="list-of-3-div" key={index}> <a className="link-of-com">

                                        <span className="arrow-of-div-3">
                                            <svg width="20" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 4l4 4-4 4" stroke="#006B8F" strokeWidth="1.5" fill="none" />
                                            </svg>
                                        </span>

                                        {item} </a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>


            </div>


            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    );
};

export default DevelopersResourceCenter;
