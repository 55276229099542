export const customerStory = {
    section1Heading: "Lattice Customer Successes",
    section1Para: "Innovation, ease of use, better results—those are just some of the reasons organizations in all industries and of all sizes rely on Lattice to help them succeed. Hear from our customers to learn what makes Lattice the right choice for them, and why we're the right partner for your business.",
    section1Button: "Search customers",

    section2Card1Heading: "430K+",
    section2Card1Para: "customers",

    section2Card2Heading: "30K+",
    section2Card2Para: "customer reviews",

    section2Card3Heading: "300K+",
    section2Card3Para: "cloud community members",

    section2Card4Heading: "85K+",
    section2Card4Para: "customer reference",

    section3Heading: "Learn about our latest customer successes.",
    section3Button: "View our customer highlights",

    section4Heading: "Take a closer look at our customers",
    section4Button1: "Region",
    section4Button2: "Industry",
    section4Button3: "Products",
    section4Button4: "Use Case",
    section4Button5: "Asset Type",

    filter: "Filter",
    done: "Done",
    nofiltersApplied: "No filters applied",
    clearAll: "Clear All",

    industry: "Industry:",
    location: "Location:",
    readMore: "Read more" ,

    viewMore: "View More",

    cardData1Title: "The Four Season helps astronomers see farther with AI",
    cardData1Industry: "Private",
    cardData1Location: "United Kingdom",

    cardData2Title: "Related Resorts impresses guests with help from Lattice Fusion Cloud ERP",
    cardData2Industry: "Retail",
    cardData2Location: "united state ",

    cardData3Title: "German Federal Ministry of Health adopts cloud-based ERP foundation ",
    cardData3Industry: "Justice, Public Order...",
    cardData3Location: "United Kingdom",

}