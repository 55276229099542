import React, { useState, useRef } from 'react';
import './event.css'
import { footerSections, cookieText} from '../Content/eng_content';
import { Link } from 'react-router-dom';
import Latticelogo from '../image/lattice-logo-img.svg';
import { developerEvents } from '../Content/English-Content/developer-events';


function DeveloperEvent() {

    
     const [openSections, setOpenSections] = useState({});
    
        const toggleDropdown = (section) => {
            setOpenSections((prevState) => ({
                ...prevState,
                [section]: !prevState[section],
            }));
        };

        // cookies
                    
                    
                        const [showCookieModal, setShowCookieModal] = useState(false);
                        const [isLoading, setIsLoading] = useState(true);
                    
                        const handleCookieClick = () => {
                            setShowCookieModal(true);
                            setIsLoading(true);
                    
                    
                            setTimeout(() => {
                                setIsLoading(false);
                            }, 500);
                        };
                    
                        const handleAcceptAll = () => {
                            console.log("Cookies Accepted");
                            setShowCookieModal(false);
                        };
                    
                        const handleDenyAll = () => {
                            console.log("Cookies Denied");
                            setShowCookieModal(false);
                        };





    return (
        <>



        <div className="upper-div-event">

            <div className="upper-div-content">

                <Link className="Link-of-event" to="/developer-resource-center">
                            <span>
                                {developerEvents.firstSectionLink}
                                <span className="arrow-eventpage">
                                <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 4l4 4-4 4" stroke="#FFF" strokeWidth="1.5" fill="none"/>
                                </svg>
                                </span>
                            </span>
                            </Link>

            <h1 className="main-heading-of-event">

            {developerEvents.firstSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span> <p className="side-heading-event-para">{developerEvents.firstSectionPara1}<br></br><br></br>
                    {developerEvents.firstSectionPara2}<br></br><br></br>
                    {developerEvents.firstSectionPara3} <a className="link-of-card">{developerEvents.firstSectionPara4}</a> 
                    </p>

                    </h1>

            </div>

        </div>



        <div className="sec-div-of-event-outer">

            <h1 className="sec-heading">
            {developerEvents.secondSectionHeading}
                        </h1>

       

            

            <div className="sec-div-inner-div">

            <h1 className="sec-div-inner-div-heading">{developerEvents.secondSectionInnerDivHeading}</h1>

            <p className="sec-div-inner-div-bold-para">{developerEvents.secondSectionInnerDivBoldPara1}</p>

            <span className="sec-div-inner-div-bold-para">{developerEvents.secondSectionInnerDivBoldPara2} </span>  <span className="sec-div-inner-div-normal-para">- {developerEvents.secondSectionInnerDivNormalPara1}</span><br></br>

            <span className="sec-div-inner-div-bold-para">{developerEvents.secondSectionInnerDivBoldPara3} </span>  <span className="sec-div-inner-div-normal-para">- {developerEvents.secondSectionInnerDivNormalPara1}</span><br></br>

            <span className="sec-div-inner-div-bold-para">{developerEvents.secondSectionInnerDivBoldPara4} </span>  <span className="sec-div-inner-div-normal-para"> {developerEvents.secondSectionInnerDivNormalPara2}</span><br></br>

            <span className="sec-div-inner-div-bold-para">{developerEvents.secondSectionInnerDivBoldPara5} </span>  <span className="sec-div-inner-div-normal-para">{developerEvents.secondSectionInnerDivNormalPara3}</span><br></br>


            </div>

    

        </div>

        <div className="third-div-of-event">

            <h1 className="third-div-event-heading">{developerEvents.thirdSectionHeading}
            </h1>

            <div className="social-media-icon">
                {/* facebook */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#006B8F" width="30px" height="30px">
                    <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 5.017 3.676 9.159 8.438 9.876v-6.987H7.897v-2.89h2.54V9.797c0-2.514 1.493-3.896 3.778-3.896 1.095 0 2.238.195 2.238.195v2.459h-1.26c-1.242 0-1.63.772-1.63 1.561v1.875h2.773l-.443 2.89h-2.33v6.987C18.324 21.159 22 17.017 22 12z" />
                </svg>

                {/* linkedin */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#006B8F" width="24px" height="30px">
                    <path d="M22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.226.792 24 1.771 24h20.451C23.208 24 24 23.226 24 22.271V1.729C24 .774 23.208 0 22.225 0zM7.082 20.452H3.582V9h3.5v11.452zM5.332 7.508a2.026 2.026 0 1 1 0-4.053 2.026 2.026 0 0 1 0 4.053zm14.666 12.944h-3.5v-5.545c0-1.322-.026-3.022-1.843-3.022-1.845 0-2.128 1.44-2.128 2.928v5.639h-3.5V9h3.362v1.563h.05c.468-.885 1.613-1.816 3.32-1.816 3.548 0 4.2 2.337 4.2 5.377v6.328z" />
                </svg>

                {/* youtube */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#006B8F" width="24px" height="30px">
                    <path d="M23.498 6.186a2.825 2.825 0 0 0-1.986-1.988C19.842 3.664 12 3.664 12 3.664s-7.842 0-9.512.534A2.825 2.825 0 0 0 .502 6.186C0 7.856 0 12 0 12s0 4.144.502 5.814a2.825 2.825 0 0 0 1.986 1.988c1.67.534 9.512.534 9.512.534s7.842 0 9.512-.534a2.825 2.825 0 0 0 1.986-1.988C24 16.144 24 12 24 12s0-4.144-.502-5.814zM9.745 15.568V8.432l6.276 3.568-6.276 3.568z" />
                </svg>

                {/* X (Twitter) */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#006B8F" width="24px" height="30px">
                    <path d="M23.447 4.924c-.835.37-1.732.62-2.675.732a4.712 4.712 0 0 0 2.084-2.6c-.893.53-1.878.91-2.93 1.118a4.699 4.699 0 0 0-8.002 4.28A13.345 13.345 0 0 1 1.64 3.162a4.695 4.695 0 0 0 1.452 6.28A4.662 4.662 0 0 1 .96 8.79v.06a4.694 4.694 0 0 0 3.764 4.604 4.733 4.733 0 0 1-2.117.08 4.696 4.696 0 0 0 4.385 3.26A9.416 9.416 0 0 1 0 19.54a13.27 13.27 0 0 0 7.22 2.116c8.665 0 13.408-7.182 13.408-13.408 0-.204-.005-.408-.014-.61A9.623 9.623 0 0 0 24 4.557a9.309 9.309 0 0 1-2.553.697z" />
                </svg>

                {/* blog */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#006B8F" width="24px" height="30+px">
                    <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm1 18H9v-2h4v2zm2-4H9V6h6v8z" />
                </svg>

            </div>


        </div>

           




             <div className="footer">
                                <div className="f_list">
                                    {footerSections.map((section) => (
                                        <div key={section.id} className="list-section">
                                            <h3
                                                className="footer-heading"
                                                onClick={() => toggleDropdown(section.id)}
                                            >
                                                {section.heading}
                                                <span
                                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                                >
                                                    &#8595;
                                                </span>
                                            </h3>
                                            <div
                                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                                    }`}
                                            >
                                                <ul>
                                                    {section.links.map((link, index) => (
                                                        <li key={index}>
                                                            {link.to.startsWith('/') ? (
                                                                <Link to={link.to}>{link.text}</Link>
                                                            ) : (
                                                                <a href={link.to}>{link.text}</a>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="footer-bottom">
                              
                                                  <span className="footer-separator"> © 2024 Lattice</span> |
                              
                                                  <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
                              
                                                  <span className="footer-separator"
                                                      style={{ cursor: "pointer", color: "inherit" }}
                                                      onClick={handleCookieClick}> Cookie Preferences  </span>
                              
                                                  <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
                              
                                                  <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                                              </div>
                              
                                          </div>
                              
                              
                                          {showCookieModal && (
                                              <div
                                                  className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                                                      }`}
                                              >
                                                  <div className="cookie-modal">
                              
                                                      {isLoading ? (
                                                          <div className="loading-spinner">{cookieText.loading}</div>
                                                      ) : (
                                                          <div className="cookie-content">
                              
                                                              <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                                                  &times;
                                                              </button>
                                                              <div className="heading-of-cookies-with-logo">
                              
                                                                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>
                              
                                                                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>
                              
                                                              </div>
                              
                                                              <div className="div-2-heading-below-text-and-list">
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>
                              
                                                                  <ul>
                                                                  {cookieText.listItems.map((item, index) => (
                                                                      <li key={index} className="div-2-cookies-list-text">
                                                                          <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                                                      </li>
                                                                      ))}
                                                                  </ul>
                              
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>
                              
                                                              </div>
                              
                                                              <div className="button-group">
                                                                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                                                                     {cookieText.btn1}
                                                                  </button>
                                                                  <button className="btn deny-btn" onClick={handleDenyAll}>
                                                                  {cookieText.btn2}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          )}

        </>
    );
};

export default DeveloperEvent;
