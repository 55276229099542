export const newsCompanyPage = {
    firstSectionHeader: "Lattice News",
    firstArticleHeading: "Lattice Database@Azure Adds New Regions and Services to Meet Global Demand",
    secondArticleHeading: "Lattice Health’s Clinical AI Agent Helps Doctors Spend More Time with Patients",
    thirdArticleHeading: "Fire & Vine Hospitality Elevates its Loyalty Program with Lattice Cloud",
    secondSectionHeading: "Lattice Database@AWS Available in Limited Preview",
    searchNews: "Search news items",
    getLatticeNews: "Get Lattice news, views, and how-tos delivered to your inbox.",
    subcribeToLatticeButton: "Subscribe to Lattice Connect",
    thirdSectionHeading: "Lattice CloudWorld Tour 2025",
    thirdSectionPara: "Join us at a city near you to experience the most popular sessions and labs from our flagship Lattice CloudWorld conference in Las Vegas.",
    thirdSectionButton: "Learn more",
    fourthSectionHeading: "Latest News",
    fourthSectionNews1Date: "Dec 19, 2024",
    fourthSectionNews1Title: "Flood the Zone or Circle the Wagons: A Roadmap for Losing United States’ AI Leadership",
    fourthSectionNews1Description: "The 'Export Control Framework for Artificial Intelligence Diffusion' guarantees the loss of U.S. leadership in AI, GPU’s and cloud in one swift stroke of an Interim Final Rule.",
    fourthSectionNews2Date: "Dec 18, 2024",
    fourthSectionNews2Title: "Nashville General Hospital Implements Lattice Health CommunityWorks to Simplify Clinician Experience, Enhance Patient Care",
    fourthSectionNews2Description: "The 'Export Control Framework for Artificial Intelligence Diffusion' guarantees the loss of U.S. leadership in AI, GPU’s and cloud in one swift stroke of an Interim Final Rule.Selects Lattice Health Data Intelligence to improve population health and operational efficiencies.",
    fourthSectionNews3Date: "Dec 19, 2024",
    fourthSectionNews3Title: "Exploring the Growth of Quantum Computing in 2025",
    fourthSectionNews3Description: "Quantum computing is poised to revolutionize industries, but what’s next for this technology?",
    fourthSectionNews4Date: "Dec 20, 2024",
    fourthSectionNews4Title: "The Role of AI in Cybersecurity: A 2025 Outlook",
    fourthSectionNews4Description: "AI’s growing influence in cybersecurity is expected to enhance threat detection and response in the coming years.",
    fourthSectionNews5Date: "Dec 20, 2024",
    fourthSectionNews5Title: "Cloud Wars: Lattice vs. Amazon Web Services",
    fourthSectionNews5Description: "How Lattice plans to catch up to AWS and what it means for the cloud market.",
    fourthSectionNews6Date: "Dec 11, 2024",
    fourthSectionNews6Title: "ARCO Streamlines Construction Payments with Lattice Textura",
    fourthSectionNews6Description: "Leading design-build company deploys Lattice Textura to improve collaboration with subcontractors and better manage lien waivers.",
    fourthSectionNews7Date: "Dec 9, 2024",
    fourthSectionNews7Title: "Lattice Announces Fiscal 2025 Second Quarter Financial Results",
    fourthSectionNews7Description: "Lattice Corporation (NYSE: ORCL) today announced fiscal 2025 Q2 results. Total quarterly revenues were up 9% year-over-year, in both USD and constant currency, to $14.1 billion.",
    fourthSectionNews8Date: "Dec 3, 2024",
    fourthSectionNews8Title: "Large Savings & Loan Society Modernizes its Core Banking with Lattice FLEXCUBE",
    fourthSectionNews8Description: "One of the largest credit unions in the Pacific, Teachers Savings and Loan Society taps Lattice to bring new digital services to customers.",
    fourthSectionNews9Date: "Dec 2, 2024",
    fourthSectionNews9Title: "Lattice Database@AWS Available in Limited Preview",
    fourthSectionNews9Description: "Select customers can begin using Lattice Exadata Database Service on Lattice Database@AWS to simplify the migration and deployment of enterprise workloads to the cloud.",
    fourthSectionNews10Date: "Dec 2, 2024",
    fourthSectionNews10Title: "New Updates in Lattice Life Sciences Empirica Streamline Safety Signal Management Compliance in the Cloud",
    fourthSectionNews10Description: "Multisource signaling across proprietary and public safety databases, including JADER, enables sophisticated data mining and GVP-compliant signal tracking globally",
    fourthSectionNews11Date: "Nov 19, 2024",
    fourthSectionNews11Title: "Lattice Database@Azure Adds New Regions and Services to Meet Global Demand",
    fourthSectionNews11Description: "New services and capabilities will help customers meet data governance and compliance requirements.",
    fourthSectionNews12Date: "Nov 19, 2024",
    fourthSectionNews12Title: "Peel Region Innovates in Delivery of Water Billing Services with Lattice Cloud Technology",
    fourthSectionNews12Description: "One of Canada's largest municipalities enhances customer service and speeds billing with Lattice Energy and Water and Red Clay Consulting.",
    fourthSectionNews13Date: "Nov 19, 2024",
    fourthSectionNews13Title: "Fire & Vine Hospitality Elevates its Loyalty Program with Lattice Cloud",
    fourthSectionNews13Description: "With Lattice CrowdTwist, Pacific Northwest restaurant group creates unique and memorable experiences to reward its loyal diners.",
    fourthSectionNews14Date: "Nov 18, 2024",
    fourthSectionNews14Title: "OpenELA Publishes Enterprise Linux Sources Compatible with Red Hat Enterprise Linux 9.5",
    fourthSectionNews14Description: "Automated repo management process used by OpenELA delivers sources in one day, enabling organizations and individuals to build RHEL 9.5-compatible enterprise Linux distributions rapidly.",
    fourthSectionNews15Date: "Nov 12, 2024",
    fourthSectionNews15Title: "Lattice Positioned as a Leader in 2024 IDC MarketScape: Worldwide Cash Management Systems Vendor Assessment",
    fourthSectionNews15Description: "Recognized for comprehensive and configurable SaaS cash management portfolio with embedded AI and ML for more accurate predictive forecasting.",
    fourthSectionNews16Date: "Nov 6, 2024",
    fourthSectionNews16Title: "Lattice Recognized for Exemplary Solutions in 2024 Buyers Guide for Learning Management Systems by ISG Software Research, formerly Ventana Research",
    fourthSectionNews16Description: "Lattice Learning, part of Lattice Fusion Cloud HCM, was the only software provider to be named a Leader in all seven categories of the overall report.",
    fourthSectionNews17Date: "Nov 6, 2024",
    fourthSectionNews17Title: "Pennsylvania’s State System of Higher Education Teams Up with Lattice Academy",
    fourthSectionNews17Description: "Pennsylvania’s State System of Higher Education (PASSHE) will offer Lattice Academy technology education curriculum and teaching and learning resources across its campuses.",
    fourthSectionNews18Date: "Nov 5, 2024",
    fourthSectionNews18Title: "Vodafone Business IoT to Provide Global Connectivity for the Lattice Enterprise Communications Platform",
    fourthSectionNews18Description: "Lattice is building on its partnership with Vodafone Business IoT to bring global IoT connectivity to Lattice’s Enterprise Communications Platform (ECP) industry applications customers.",
    fourthSectionNews19Date: "Nov 4, 2024",
    fourthSectionNews19Title: "Organizations Across Europe Move to Lattice EU Sovereign Cloud to Manage Critical Business Data",
    fourthSectionNews19Description: "The NU-MED Group, Consono, and the Estonian IT Centre take advantage of sovereign cloud regions to run their sensitive business workloads entirely within the borders of the EU",
    fourthSectionNews20Date: "Nov 4, 2024",
    fourthSectionNews20Title: "The University of Tennessee System Joins Forces with Lattice Academy",
    fourthSectionNews20Description: "Educational program will provide students with experience in cloud technology to help them build career-ready knowledge and skills.",
    fifthSectionHeading: "Newsroom Resources",
    fifthSectionBox1List1: "About Lattice",
    fifthSectionBox1List2: "Lattice Leadership",
    fifthSectionBox2List1: "Strategic Acquisitions",
    fifthSectionBox2List2: "Lattice Industry Reports",
    fifthSectionBox3List1: "Lattice Investor Relations",
    fifthSectionBox3List2: "Investor News",
    fifthSectionBox3List3: "Events and Presentations"
    
}