export const realEstatePage = {
    firstSectionHeading: "Revolutionize Real Estate with Blockchain",
    firstSectionPara: "The Lattice Blockchain Real Estate Registry & Utility Management Suite is transforming how real estate and utilities are managed. By leveraging blockchain, AI, and IoT, this suite addresses inefficiencies, enhances sustainability, and unlocks the potential for smart cities.",
    firstSectionButton1: "Explore the Suite",
    firstSectionButton2: "Learn About Digital Twin Tokens (DTT®)",
    firstSectionSide2TitleImg: "See how blockchain redefines real estate management (2:06)",

    secondSectionHeading: "Introducing Digital Twin Tokens (DTT®)",
    secondSectionPara: "Digital Twin Tokens (DTT®) provide a blockchain-powered framework to digitally represent built or bare land real estate. By integrating IoT and property data, DTT® enables real-time insights, optimization, and management across all property aspects.",
    secondSectionButton: "Discover the Benefits",

    thirdSectionHeading: "Why Use the Real Estate & Utility Suite?",
    thirdSectionSide1Heading: "AI-Driven Optimization",
    thirdSectionSide1Para: "Enhance operational efficiency with AI-powered tools that provide predictive insights and real-time decision-making.",
    thirdSectionSide2Heading: "Comprehensive Property Records",
    thirdSectionSide2Para: "Securely store a digital history of construction materials, invoices, and property components on the blockchain for transparency.",
    thirdSectionSide3Heading: "IoT-Integrated Connectivity",
    thirdSectionSide3Para: "Seamlessly connect property systems and devices to the blockchain for intelligent and automated operations.",

    fourthSectionHeading: "Suite Capabilities",
    fourthSectionButton1: "Property Management",
    fourthSectionButton2: "Utility Billing",
    fourthSectionButton3: "Digital History Tracking",
    fourthSectionButton4: "AI-Powered Recommendations",
    fourthSectionButton5: "Smart City Integration",

    fourthSectionPage1Heading: "Property Management",
    fourthSectionPage1Description: "Streamline property operations by integrating critical systems, components, and data into a unified blockchain framework.",
    fourthSectionPage1Link: "Learn more about Property Management",
    fourthSectionPage1cardheading1: "Data Consolidation",
    fourthSectionPage1fastTrackList1: "Access all property-related data in a centralized, blockchain-secured platform.",
    fourthSectionPage1cardheading2: "AI Insights",
    fourthSectionPage1fastTrackList2: "Receive predictive analytics to optimize maintenance, resource allocation, and performance.",
    fourthSectionPage1cardheading3: "Compliance Automation",
    fourthSectionPage1fastTrackList3: "Ensure regulatory compliance with transparent records stored on the blockchain.",
    fourthSectionPage1cardheading4: "Digital Twin Management",
    fourthSectionPage1fastTrackList4: "Leverage DTT® to simulate property operations and test scenarios before implementation.",
    learnMoreButton: "Learn More",

    fourthSectionPage2Heading: "Utility Management",
    fourthSectionPage2Description: "Digitize utility billing and resource usage tracking to promote sustainability and efficiency.",
    fourthSectionPage2Link: "Learn more about Utility Management",
    fourthSectionPage2cardheading1: "Automated Billing",
    fourthSectionPage2fastTrackList1: "Generate and send bills securely through blockchain with detailed usage breakdowns.",
    fourthSectionPage2cardheading2: "Usage Tracking",
    fourthSectionPage2fastTrackList2: "Monitor utility consumption patterns with blockchain-secured data.",
    fourthSectionPage2cardheading3: "AI Recommendations",
    fourthSectionPage2fastTrackList3: "Receive actionable insights to reduce waste and optimize energy consumption.",

    fourthSectionPage3Heading: "Digital History Tracking",
    fourthSectionPage3Description: "Maintain a tamper-proof digital history of materials, components, and systems for every property.",
    fourthSectionPage3Link: "Learn more about Digital History Tracking",
    fourthSectionPage3cardheading1: "Construction Records",
    fourthSectionPage3fastTrackList1: "Store invoices, material specifications, and component data for future reference.",
    fourthSectionPage3cardheading2: "Lifecycle Management",
    fourthSectionPage3fastTrackList2: "Track the lifecycle of all property elements to enhance maintenance and replacement planning.",

    fourthSectionPage4Heading: "AI-Powered Recommendations",
    fourthSectionPage4Description: "Optimize property and utility management with actionable AI-driven insights.",
    fourthSectionPage4Link: "Learn more about AI Recommendations",
    fourthSectionPage4cardheading1: "Predictive Maintenance",
    fourthSectionPage4fastTrackList1: "Identify potential issues before they occur with predictive analytics.",
    fourthSectionPage4cardheading2: "Resource Optimization",
    fourthSectionPage4fastTrackList2: "Optimize resource allocation based on historical and real-time data.",

    fourthSectionPage5Heading: "Smart City Integration",
    fourthSectionPage5Description: "Contribute to smart city ecosystems by connecting properties and utilities through decentralized networks.",
    fourthSectionPage5Link: "Learn more about Smart City Integration",
    fourthSectionPage5cardheading1: "DePIN Framework",
    fourthSectionPage5fastTrackList1: "Leverage Decentralized Physical Infrastructure Networks (DePIN) to integrate properties with urban systems.",
    fourthSectionPage5cardheading2: "Sustainability at Scale",
    fourthSectionPage5fastTrackList2: "Support sustainable development with data-driven solutions for urban challenges.",
    fourthSectionPage5cardheading3: "Smart Urban Planning",
    fourthSectionPage5fastTrackList3: "Utilize real-time data for better decision-making in city management.",

    fifthSectionHeading: "Why Choose Lattice for Real Estate?",
    fifthSectionPara1: "Lattice’s blockchain-based suite empowers property owners, developers, and utility companies with unmatched transparency, efficiency, and sustainability.",
    fifthSectionPara2: "By integrating AI, IoT, and blockchain, Lattice transforms how real estate and utilities are managed.",
    fifthSectionCard1Heading: "Unmatched Transparency",
    fifthSectionCard1Para: "Blockchain-backed records ensure accountability and trust among stakeholders.",
    fifthSectionCard2Heading: "Operational Efficiency",
    fifthSectionCard2Para: "AI-driven tools optimize resource allocation and streamline operations.",
    fifthSectionCard3Heading: "Sustainability",
    fifthSectionCard3Para: "Reduce waste and promote eco-friendly practices with advanced analytics and IoT.",
    fifthSectionButton1: "Discover More",
    fifthSectionButton2: "Request a Demo",

    sixthSectionHeading: "Get Started Today",
    sixthSectionLink: "Learn more about the Real Estate Suite",
    sixthSectionCard1Heading: "Request a Demo",
    sixthSectionCard1Para: "See how Lattice can transform your property management and utility operations.",
    sixthSectionCard2Heading: "Explore DTT® Technology",
    sixthSectionCard2Para: "Learn how Digital Twin Tokens revolutionize real estate management.",
};
