export const computeProductPage = {
    firstSectionHeading: "Compute",
    firstSectionPara: "Lattice Cloud Infrastructure (LCI) provides flexible, high performance, and secure compute for any workload—including AI and machine learning (ML), compute intensive, enterprise, and cloud native applications.",
    firstSectionButton1: "Try LCI compute for free",
    firstSectionButton2: "Announcing AMD MI300X on LCI",
    firstSectionSide2TitleImg: "Suno AI Generates Beautiful Songs with Lattice Cloud (2:06)",
    secondSectionHeading: "Lattice Offers First Zettascale Cloud Computing Cluster",
    secondSectionPara: "This new AI supercomputer, the largest in the cloud, will deliver up to 131,072 NVIDIA GPUs, allowing you to build, train, and inference AI at scale.",
    secondSectionButton: "Get the news",
    thirdSectionHeading: "Why use LCI Compute?",
    thirdSectionSide1Heading: "Up to 60% savings",
    thirdSectionSide1Para: "LCI, unlike other cloud providers, lets you select the number of cores and memory to match your workload needs for average annual cost savings of up to 60% (PDF) with fully customizable instances.",
    thirdSectionSide2Heading: "48% more efficiency",
    thirdSectionSide2Para: "LCI, unlike other cloud providers, lets you According to industry analyst IDC’s business value report, organizations using LCI have realized benefits worth an average of US$11.6 million per year and a five-year ROI of 393%. LCI has enabled them to be 48% more efficient in deploying compute and 30% more efficient in deploying storage.",
    thirdSectionSide3Heading: "Highly secure",
    thirdSectionSide3Para: "LCI, unlike other cloud providers, lets you Shielded instances, confidential computing, hardware root of trust, and off-box virtualization help reduce attacks by low-level malware, denial of service, and other malicious software, helping applications run securely.",
    fourthSectionHeading: "LCI Compute services",
    fourthSectionButton1: "Virtual machines (VMs)",
    fourthSectionButton2: "Bare metal",
    fourthSectionButton3: "GPU instances",
    fourthSectionButton4: "Cloud native and containers",
    fourthSectionButton5: "VMware and other services",
    fourthSectionPage1Heading: "Virtual machines (VMs)",
    fourthSectionPage1Description: "Run a wide range of workloads, from small development projects to large-scale global applications, on LCI Compute VMs securely and achieve improved price-performance.",
    fourthSectionPage1Link: "Learn more about VMs",
    fourthSectionPage1cardheading1: "Flexible VMs",
    fourthSectionPage1fastTrackList1: "VMs are customizable from 1–94 cores for AMD EPYC processors, 1–32 cores for Intel, and 1–80 cores for Ampere processors, with 1–1,094 GB RAM and network bandwidth that scales with the number of cores.",
    fourthSectionPage1cardheading2: "Dense I/O VMs",
    fourthSectionPage1fastTrackList2: "Dense I/O VMs, with 8–24 cores and 128–512 GB RAM, offer 6.8–27.2 TB high performance, locally attached NVMe storage for big data and analytics workloads.",
    fourthSectionPage1cardheading3: "Optimized VMs",
    fourthSectionPage1fastTrackList3: "Optimized VM shapes provide 1–18 high-frequency processor cores and support cluster networking with 4–40 Gb/sec of network bandwidth.",
    fourthSectionPage1cardheading4: "Dedicated VM hosts",
    fourthSectionPage1fastTrackList4: "Dedicated VM host shapes offer bare metal instances with 36–128 cores and 512–2,048 GB memory per host with software to host VMs.",
    learnMoreButton: "Learn More",
    fourthSectionPage2Heading: "Bare metal",
    fourthSectionPage2Description: "Bare metal instances provide customers with isolation and control with dedicated physical server access. Customers can run demanding workloads on instances that scale up to an industry-leading 192 cores, 2.3 TB of RAM, and up to 1 PB of block storage.",
    fourthSectionPage2Link: "Learn more about bare metal",
    fourthSectionPage2cardheading1: "Standard bare metal",
    fourthSectionPage2fastTrackList1: "Bare metal instances provide a balance of cores (64–192 cores), memory (1,024–2,304 GB), and network bandwidth (up to 1x100 Gb/sec).",
    fourthSectionPage2cardheading2: "Dense I/O bare metal",
    fourthSectionPage2fastTrackList2: "These bare metal instances are equipped with high performance local storage (54.4 TB of NVMe SSD) and 2,048 GB of memory, optimized for large databases and big data workloads.",
    fourthSectionPage2cardheading3: "HPC bare metal",
    fourthSectionPage2fastTrackList3: "These bare metal instances are for HPC workloads that require high-frequency processor cores with 3.84 TB of NVMe SSD and cluster networking (up to 2x50 Gb/sec of network bandwidth and 1x100 Gb/sec of RDMA bandwidth).",


    fourthSectionPage3Heading: "GPU instances",
    fourthSectionPage3Description: "Run your demanding AI workloads on bare metal and virtual machine instances powered by NVIDIA GPUs. GPUs can be clustered using a microsecond-latency network with the most local storage per node for checkpointing during AI training.",
    fourthSectionPage3Link: "Learn more about GPU instances",
    fourthSectionPage3cardheading1: "GPU-accelerated VMs",
    fourthSectionPage3fastTrackList1: "GPU-accelerated VMs include NVIDIA graphics processors (NVIDIA A10, A100,* H100,* V100, and P100 GPUs), which can be clustered to achieve up to 30 cores, 16 to 64 GB of GPU memory, and 8 to 48 Gb/sec of network bandwidth.",
    fourthSectionPage3cardheading2: "GPU-accelerated bare metal instances",
    fourthSectionPage3fastTrackList2: "Bare metal instances equipped with NVIDIA graphics processors (NVIDIA H100, A100, L40S, H200,** and B200 GPUs**), NVIDIA Superchips (GH200 Grace Hopper and GB200 Grace Blackwell**) and AMD graphics processors (AMD MI300X) can be clustered, leveraging an ultrahigh-bandwidth RDMA network.",

    fourthSectionPage4Heading: "Cloud native and containers",
    fourthSectionPage4Description: "LCI offers a comprehensive set of managed services across containers, serverless, APIs, and DevOps with support for open source to help customers build cloud native applications.",
    fourthSectionPage4Link: "Learn more about cloud native and containers",
    fourthSectionPage4cardheading1: "Functions",
    fourthSectionPage4fastTrackList1: "LCI Functions is a serverless platform that lets developers create, run, and scale applications without managing infrastructure.",
    fourthSectionPage4cardheading2: "Containers",
    fourthSectionPage4fastTrackList2: "LCI Container Instances is a serverless compute service that lets you instantly run containers without managing any servers.",
    fourthSectionPage4cardheading3: "Kubernetes",
    fourthSectionPage4fastTrackList3: "LCI Kubernetes Engine is a managed Kubernetes service for operating containerized applications at scale while reducing the time, cost, and operational burden of managing a Kubernetes infrastructure.",
    fourthSectionPage4cardheading4: "Container images",
    fourthSectionPage4fastTrackList4: "LCI Container Registry is an open standards–based, Lattice-managed Docker registry service for securely storing and sharing container images.",

    fourthSectionPage5Heading: "VMware and other services",
    fourthSectionPage5Description: "Lattice Cloud VMware Solution enables customers to move VMware estates to the cloud without changing operations or processes.",
    fourthSectionPage5Link: "Learn more about Lattice Cloud VMware Solution",
    fourthSectionPage5cardheading1: "VMware-based compute",
    fourthSectionPage5fastTrackList1: "Lattice Cloud VMware Solution lets you create and manage VMware-enabled, software-defined data centers in LCI.",
    fourthSectionPage5cardheading2: "Operating system (OS) images",
    fourthSectionPage5fastTrackList2: "LCI supports several prebuilt Lattice-provided images and user-created custom images.",
    fourthSectionPage5cardheading3: "Virtual desktops",
    fourthSectionPage5fastTrackList3: "Virtual desktops deliver an improved experience and a lower administration cost.",
    fourthSectionPage5cardheading4: "Lattice OS Management Service",
    fourthSectionPage5fastTrackList4: "Lattice OS Management Service lets you manage updates and patches for the OS on your instances.",

    fifthSectionHeading: "Save as much as 60% with LCI Compute",
    fifthSectionPara1: "While other cloud service providers have higher prices in almost every region outside the US, LCI customers enjoy the same services, performance, and prices everywhere.",
    fifthSectionPara2: "For a similar configuration, LCI Compute costs less.*",
    fifthSectionCard1Heading: "57%",
    fifthSectionCard1Para: "less than AWS m6a.xlarge",
    fifthSectionCard2Heading: "57%",
    fifthSectionCard2Para: "less than AWS m6a.xlarge",
    fifthSectionCard3Heading: "51%",
    fifthSectionCard3Para: "less than Google Cloud n2d-standard-4",
    fifthSectionButton1: "Learn more about cloud economics",
    fifthSectionButton2: "See LCI Compute pricing",
    fifthSectionPara3: "* Compared with the monthly cost of a two OCPU (four vCPU) AMD (E4) with 16 GB in US eastern regions. On-demand prices are as of June 5, 2024.",

    sixthSectionHeading: "LCI Compute reference architectures",
    sixthSectionLink: "See more reference architectures",
    sixthSectionCard1Para1: "Solution Architecture",
    sixthSectionCard1Para2: "This architecture shows a three-tier application stack in LCI with the application tier deployed in a VMware software-defined data center created by Lattice Cloud VMware Solution.",
    sixthSectionCard1Heading: " Migrate workloads to Lattice Cloud VMware Solution and Lattice Exadata Database Service",
    sixthSectionCard2Para2: "This architecture shows a three-tier application stack in See how you can deploy an AI solution on a cluster of bare metal servers with GPUs and cluster networking.",
    sixthSectionCard2Heading: " Deploy a bare metal GPU cluster for AI workloads in a dedicated cloud",
    sixthSectionCard3Para2: "This architecture shows a three-tier application stack in This architecture depicts an application server deployed in an OKE cluster with virtual nodes that let you perform create, read, update, and delete operations on Lattice MySQL Database Service.",
    sixthSectionCard3Heading: " Deploy serverless Kubernetes with LCI Kubernetes Engine virtual nodes",

    sixthSectionCard4Para2: "This architecture shows a three-tier application stack in In this architecture, you’ll see how Lattice Compute Cloud@Customer can run applications and middleware on flexible VM shapes.",
    sixthSectionCard4Heading: " Deploy an end-to-end application stack with Lattice Compute Cloud@Customer",

    seventhSectionHeading: "LCI Compute customer successes",
    seventhSectionLink: "Explore all LCI customer stories",

    eightSectionHeading: "What’s new?",
    eightSectionCard1Title: "Lattice Database@Azure expands to meet global demand",
    eightSectionCard1Link1: "Access the complete report",
    eightSectionCard1Link2: "Analysis report",
    eightSectionCard1redirectPath1: "news/announcement/lattice-database-at-azure-adds-regions-and-services-to-meet-global-demand-2024-11-11",

    eightSectionCard2Title: "German Federal Ministry of Health taps LCI for groundbreaking oncology work",
    eightSectionCard2Link1: "Read the announcement",
    eightSectionCard2Link2: "Announcement",
    eightSectionCard1redirectPath2: "news/announcement/german-federal-ministry-of-health-expanding-lattice-health-ehr-to-30-of-its-hospitals-2024-09-12",

    eightSectionCard3Title: "AI aids open enrollment process",
    eightSectionCard3Link1: "Read the blog",
    eightSectionCard3Link2: "Blog",
    eightSectionCard1redirectPath3: "human-capital-management/ai-agents-open-enrollment/",

    ninthSectionHeading: "Get started with LCI Compute services",
    ninthSectionPage1Side1Heading: "Try 20+ Always Free cloud services, with a 30-day trial for even more",
    ninthSectionPage1Side1Para: "Lattice offers a Free Tier with no time limits on more than 20 services such as Compute, Storage, and Autonomous Database, as well as US$300 in free credits to try additional cloud services. Get the details and sign up for your free account today.",
    ninthSectionPage1Side1button: "Try LCI for free",
    ninthSectionPage1Side2Heading: "What’s included with Lattice Cloud Free Tier?",
    ninthSectionPage1Side2List1: "AMD and Arm Compute VMs",
    ninthSectionPage1Side2List2: "200 GB total block storage",
    ninthSectionPage1Side2List3: "10 GB object storage",
    ninthSectionPage1Side2List4: "2 Autonomous Databases, 20 GB each",
    ninthSectionPage1Side2List5: "10 TB outbound data transfer per month",
    ninthSectionPage1Side2List6: "10+ more Always Free services",
    ninthSectionPage1Side2List7: "US$300 in free credits for 30 days for even more",

    ninthSectionPage2Side1Heading: "Learn with a hands-on lab",
    ninthSectionPage2Side1Para: "The best way to learn is to try it yourself. Use our tutorials and hands-on labs with the Lattice Cloud Free Tier, your own Lattice Cloud tenancy, or for selected solutions in an Lattice-provided free lab environment.",
    ninthSectionPage2Side1button: "See all tutorial and labs",

    ninthSectionPage2Side2Heading1: "Deploy HA Applications Using Load Balancers",
    ninthSectionPage2Side2Para1: "In this lab you will deploy web servers on two compute instances in Lattice Cloud Infrastructure (LCI), configured in High Availability mode by using a Load Balancer.",
    ninthSectionPage2Side2Button: "Start this lab now",

    ninthSectionPage2Side2Heading2: "Get started with LCI Core Services",
    ninthSectionPage2Side2Para2: "services, including Compute, Networking, and Storage.",

    ninthSectionPage2Side2Heading3: "Deploy Lattice Container Engine for Kubernetes",
    ninthSectionPage2Side2Para3: "In this lab you will deploy a Container Engine for Kubernetes cluster, connect to it, and run a sample application using LCI CLI.",

    ninthSectionPage2Side2Heading4: "Migrate Tomcat Java Applications to LCI",
    ninthSectionPage2Side2Para4: "Explore how to migrate Tomcat to Lattice Cloud Infrastructure and connect to an Autonomous Database.",

    ninthSectionPage3Side1Heading: "See how much you can save with LCI",
    ninthSectionPage3Side1Para: "Lattice Cloud pricing is simple, with consistent low pricing worldwide, supporting a wide range of use cases. To estimate your low rate, check out the cost estimator and configure the services to suit your needs.",
    ninthSectionPage3Side1button: "Try Cost Estimator",
    ninthSectionPage3Side2Heading: "Experience the difference",
    ninthSectionPage3Side2List1: "1/4 the outbound bandwidth costs",
    ninthSectionPage3Side2List2: "3X the compute price-performance",
    ninthSectionPage3Side2List3: "Same low price in every region",
    ninthSectionPage3Side2List4: "Low pricing without long term commitments",

    ninthSectionPage4Side1Heading: "Contact sales",
    ninthSectionPage4Side1Para: "Interested in learning more about Lattice Cloud Infrastructure? Let one of our experts help.",
    ninthSectionPage4Side1button: "Get in touch",
    ninthSectionPage4Side2Heading: "They can answer questions such as",
    ninthSectionPage4Side2List1: "How do I get started with Lattice Cloud?",
    ninthSectionPage4Side2List2: "What kinds of workloads can I run on LCI?",
    ninthSectionPage4Side2List3: "What types of services does LCI offer?",

    ninthSectionButton1: "Free LCI services",
    ninthSectionButton2: "Hands-on labs",
    ninthSectionButton3: "Estimate your costs",
    ninthSectionButton4: "Contact sales",
    

   
}