import React, { useState } from "react";
import "./customer-story.css";
import { Link } from "react-router-dom";
import { footerSections, cookieText } from "../Content/eng_content";
import Navbar from "./NewNavbar";
import Image1 from "../image/customer-story/deliveroo-logo.svg";
import Image2 from "../image/customer-story/imperial-college-london-logo.svg";
import Image3 from "../image/customer-story/Four-season-customer-story.svg";
import Image4 from "../image/SMI-middle-img.svg";
import Image5 from "../image/german-federal-ministry-logo.svg";
import Image6 from "../image/customer-story/Albania-tech.svg";
import Search from "../image/search.png";
import RightSide from "../image/angle-right.png";
import DownSide from "../image/angle-down.png";
import Latticelogo from '../image/lattice-logo-img.svg';
import { customerStory } from "../Content/English-Content/CustomerStory";

const CustomerStory = () => {
  const [openSections, setOpenSections] = useState({});
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [visibleCount, setVisibleCount] = useState(6);
  const [selectedValues, setSelectedValues] = useState([]);

  const region = [
    "Asia Pacific",
    "Europe, Middle East, and Africa",
    "Japan",
    "Latin America",
    "US and Canada",
  ];
  const industry = [
    "Agriculture",
    "Automative",
    "Construction and Engineering",
  ];
  const product = ["Oracle Cloud Infrastructure", "Oracle Cloud Applications"];
  const useCase = ["Build Cloud Native Apps", "Build with GenAi Services"];
  const assetType = ["Blog", "podcast"];

  const cardsData = [
    {
      id: 1,
      logo: Image1,
      title:
        "Deliveroo relies on Lattice Cloud to deliver on promises to its customers",
      industry: "private",
      location: "United Kingdom",
      Link:"/customer-deleveroo",
      readMore: "Read more"
    },
    {
      id: 2,
      logo: Image2,
      title:
        "Imperial College London enhances its end-to-end client strategy with Lattice Cloud",
      industry: "Retail",
      location: "Londan",
      Link:"/customer-imperialcollege",
      readMore: "Read more"
    },
    {
      id: 3,
      logo: Image3,
      title:
        customerStory.cardData1Title,
      industry: customerStory.cardData1Industry,
      location: customerStory.cardData1Location,
      Link:"/customer-fourseason",
      readMore: "Read more"
    },
    {
      id: 4,
      logo: Image4,
      title:
        customerStory.cardData2Title,
      industry: customerStory.cardData2Industry,
      location: customerStory.cardData2Location,
      Link:"/customer-related",
      readMore: "Read more"
    },
    {
      id: 5,
      logo: Image5,
      title:
        customerStory.cardData3Title,
      industry: customerStory.cardData3Industry,
      location: customerStory.cardData3Location,
      Link:"/customer-germanfederalministry",
      readMore: "Read more"
    },
    {
      id: 6,
      logo: Image6,
      title: "Albania Tech pioneers regional digital transformation with Lattice Cloud solutions",
      industry: customerStory.cardData3Industry,
      location: customerStory.cardData3Location,
      Link:"/customer-albaniatech",
      readMore: "Read more"
    },
  //   {
  //     id: 7,
  //     logo: Image1,
  //     title:
  //       customerStory.cardData3Title,
  //     industry: customerStory.cardData3Industry,
  //     location: customerStory.cardData3Location,
  //   },
  //   {
  //     id: 8,
  //     logo: Image2,
  //     title:
  //       customerStory.cardData3Title,
  //     industry: customerStory.cardData3Industry,
  //     location: customerStory.cardData3Location,
  //   },
  //   {
  //     id: 9,
  //     logo: Image3,
  //     title:
  //       customerStory.cardData3Title,
  //     industry: customerStory.cardData3Industry,
  //     location: customerStory.cardData3Location,
  //   },
  //   {
  //     id: 10,
  //     logo: Image1,
  //     title:
  //       customerStory.cardData3Title,
  //     industry: customerStory.cardData3Industry,
  //     location: customerStory.cardData3Location,
  //   },
  //   {
  //     id: 11,
  //     logo: Image2,
  //     title:
  //       customerStory.cardData3Title,
  //     industry: customerStory.cardData3Industry,
  //     location: customerStory.cardData3Location,
  //   },
  //   {
  //     id: 12,
  //     logo: Image3,
  //     title:
  //       customerStory.cardData3Title,
  //     industry: customerStory.cardData3Industry,
  //     location: customerStory.cardData3Location,
  //   },
  //   {
  //     id: 13,
  //     logo: Image1,
  //     title:
  //       customerStory.cardData3Title,
  //     industry: customerStory.cardData3Industry,
  //     location: customerStory.cardData3Location,
  //   },
  //   {
  //     id: 14,
  //     logo: Image2,
  //     title:
  //       customerStory.cardData3Title,
  //     industry: customerStory.cardData3Industry,
  //     location: customerStory.cardData3Location,
  //   },
  //   {
  //     id: 15,
  //     logo: Image3,
  //     title:
  //       customerStory.cardData3Title,
  //     industry: customerStory.cardData3Industry,
  //     location: customerStory.cardData3Location,
  //   },
  ];

  const handleCheckboxChange = (value) => {
    setSelectedValues((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const removeItem = (value) => {
    setSelectedValues((prev) => prev.filter((item) => item !== value));
  };
  const clearAll = () => {
    setSelectedValues([]);
  };

  const handleViewMore = () => {
    setVisibleCount((prevCount) => Math.min(prevCount + 6, cardsData.length));
  };

  const hasMoreCards = visibleCount < cardsData.length;

  const handleAccordionToggle = (section) => {
    setActiveAccordion(activeAccordion === section ? null : section);
  };

  const toggleDropdown = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleFilterTextClick = () => {
    const accordion = document.querySelector(
      ".customer-stories-filter-accordion"
    );
    accordion.style.display =
      accordion.style.display === "block" ? "none" : "block";
  };

  const handleDoneClick = () => {
    const accordion = document.querySelector(
      ".customer-stories-filter-accordion"
    );
    accordion.style.display = "none";
  };

  // cookies


  const [showCookieModal, setShowCookieModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleCookieClick = () => {
    setShowCookieModal(true);
    setIsLoading(true);


    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const handleAcceptAll = () => {
    console.log("Cookies Accepted");
    setShowCookieModal(false);
  };

  const handleDenyAll = () => {
    console.log("Cookies Denied");
    setShowCookieModal(false);
  };

  return (
    <>
      {/* <Navbar /> */}
      <section className="customer-story-banner-wrapper">
        <div className="customer-story-banner"></div>
        <div className="customer-story-content">
          <h1 className="customer-story-heading">{customerStory.section1Heading}</h1>
          <p className="customer-story-para">
            {customerStory.section1Para}
          </p>
          <button className="customer-story-btn">{customerStory.section1Button}</button>
        </div>
      </section>

      <section className="customer-story-info">
        <div className="customer-flex">
          <div>
            <div className="customer-box">
              <h5>{customerStory.section2Card1Heading}</h5>
              <p>{customerStory.section2Card1Para}</p>
            </div>
          </div>
          <div>
            <div className="customer-box">
              <h5>{customerStory.section2Card2Heading}</h5>
              <p>{customerStory.section2Card2Para}</p>
            </div>
          </div>
          <div>
            <div className="customer-box">
              <h5>{customerStory.section2Card3Heading}</h5>
              <p>{customerStory.section2Card3Para}</p>
            </div>
          </div>
          <div>
            <div className="customer-box">
              <h5>{customerStory.section2Card4Heading}</h5>
              <p>{customerStory.section2Card4Para}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="customer-story-learn">
        <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
          <div>
            <p>{customerStory.section3Heading}</p>
          </div>
          <div>
            <button>{customerStory.section3Button}</button>
          </div>
        </div>
      </section>

      <section>
        <div className="customer-closer-look">
          <h2>{customerStory.section4Heading}</h2>
        </div>

        <div className="customer-filter-flex">
          <div className="customer-stories-search-accordion">
            <div className="customer-stories-search-bar">
              <div className="customer-stories-search-wrapper">
                <span className="customer-stories-search-icon">
                  <img src={Search}></img>
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  className="customer-stories-search-input"
                />
              </div>
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${activeAccordion === "region"
                    ? "customer-stories-accordion-header-active"
                    : ""
                  }`}
                onClick={() => handleAccordionToggle("region")}
              >
                {customerStory.section4Button1}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "region" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "region" && (
                <div className="customer-stories-accordion-content">
                  {region.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        className="customer-stories-accordion-checkbox"
                        onChange={() => handleCheckboxChange(item)}
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${activeAccordion === "industry"
                    ? "customer-stories-accordion-header-active"
                    : ""
                  }`}
                onClick={() => handleAccordionToggle("industry")}
              >
                {customerStory.section4Button2}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "industry" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "industry" && (
                <div className="customer-stories-accordion-content">
                  {industry.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${activeAccordion === "products"
                    ? "customer-stories-accordion-header-active"
                    : ""
                  }`}
                onClick={() => handleAccordionToggle("products")}
              >
                {customerStory.section4Button3}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "products" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "products" && (
                <div className="customer-stories-accordion-content">
                  {product.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${activeAccordion === "useCase"
                    ? "customer-stories-accordion-header-active"
                    : ""
                  }`}
                onClick={() => handleAccordionToggle("useCase")}
              >
                {customerStory.section4Button4}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "useCase" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "useCase" && (
                <div className="customer-stories-accordion-content">
                  {useCase.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section customer-stories-new-accordian">
              <button
                className={`customer-stories-accordion-header ${activeAccordion === "assetType"
                    ? "customer-stories-accordion-header-active"
                    : ""
                  }`}
                onClick={() => handleAccordionToggle("assetType")}
              >
                {customerStory.section4Button5}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "useCase" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "assetType" && (
                <div className="customer-stories-accordion-content">
                  {assetType.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="customer-stories-filter-accordion">
            <div className="customer-stories-filter-header">
              <h4>{customerStory.filter}</h4>
              <button
                className="customer-stories-filter-done"
                onClick={handleDoneClick}
              >
                {customerStory.done}
              </button>
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${activeAccordion === "region"
                    ? "customer-stories-accordion-header-active"
                    : ""
                  }`}
                onClick={() => handleAccordionToggle("region")}
              >
                {customerStory.section4Button1}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "region" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "region" && (
                <div className="">
                  {region.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${activeAccordion === "industry"
                    ? "customer-stories-accordion-header-active"
                    : ""
                  }`}
                onClick={() => handleAccordionToggle("industry")}
              >
                {customerStory.section4Button2}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "industry" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "industry" && (
                <div className="">
                  {industry.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${activeAccordion === "products"
                    ? "customer-stories-accordion-header-active"
                    : ""
                  }`}
                onClick={() => handleAccordionToggle("products")}
              >
                {customerStory.section4Button3}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "products" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "products" && (
                <div className="">
                  {product.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${activeAccordion === "useCase"
                    ? "customer-stories-accordion-header-active"
                    : ""
                  }`}
                onClick={() => handleAccordionToggle("useCase")}
              >
                {customerStory.section4Button4}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "useCase" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "useCase" && (
                <div className="">
                  {useCase.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="customer-stories-accordion-section">
              <button
                className={`customer-stories-accordion-header ${activeAccordion === "assetType"
                    ? "customer-stories-accordion-header-active"
                    : ""
                  }`}
                onClick={() => handleAccordionToggle("assetType")}
              >
                {customerStory.section4Button5}
                <span className="customer-stories-accordion-toggle-icon">
                  {activeAccordion === "assetType" ? (
                    <img src={DownSide} alt="Down Side" />
                  ) : (
                    <img src={RightSide} alt="Right Side" />
                  )}
                </span>
              </button>
              {activeAccordion === "assetType" && (
                <div className="">
                  {assetType.map((item, index) => (
                    <label
                      key={index}
                      className="customer-stories-accordion-checkbox-label"
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                        className="customer-stories-accordion-checkbox"
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="customer-filter">
            <div className="customer-story-filter">
              <div className="filter-text" onClick={handleFilterTextClick}>
                <strong>Filtered by</strong>:
                {selectedValues.length == 0 ? (
                  <span className="no-filter-applied">{customerStory.nofiltersApplied}</span>
                ) : (
                  ""
                )}
              </div>

              {selectedValues.length > 0 ? (
                <p className="clear-all" onClick={() => clearAll()}>
                  {customerStory.clearAll}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="filter-items">
              <ul>
                {selectedValues.map((value) => (
                  <li key={value}>
                    {value}{" "}
                    <span
                      onClick={() => {
                        removeItem(value);
                      }}
                    >
                      X
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="customer-story-card-list">
              {cardsData.slice(0, visibleCount).map((card) => (
                <div className="customer-story-card" key={card.id}>
                  <img
                    src={card.logo}
                    alt="Logo"
                    className="customer-story-card-logo"
                  />
                  <h3 className="customer-story-card-title">{card.title}</h3>
                  <p className="customer-story-card-info">
                    <strong>{customerStory.industry}</strong> <strong>{card.industry}</strong>
                  </p>
                  <p className="customer-story-card-info customer-info-2">
                    <strong>{customerStory.location}</strong> <strong>{card.location}</strong>
                  </p>
                  <button className="customer-story-card-button">
                  <a href={card.Link} style={{color: "inherit" , textDecoration: "none"}} > {card.readMore} </a> 
                  </button>
                </div>
              ))}
            </div>

            {hasMoreCards && (
              <div className="customer-view-more">
                <p onClick={handleViewMore}>
                  <strong>{customerStory.viewMore}</strong>
                </p>
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="footer">
        <div className="f_list">
          {footerSections.map((section) => (
            <div key={section.id} className="list-section">
              <h3
                className="footer-heading"
                onClick={() => toggleDropdown(section.id)}
              >
                {section.heading}
                <span
                  className={`arrow ${openSections[section.id] ? "rotate" : ""
                    }`}
                >
                  &#8595;
                </span>
              </h3>
              <div
                className={`resource-list ${openSections[section.id] ? "show" : ""
                  }`}
              >
                <ul>
                  {section.links.map((link, index) => (
                    <li key={index}>
                      {link.to.startsWith("/") ? (
                        <Link to={link.to}>{link.text}</Link>
                      ) : (
                        <a href={link.to}>{link.text}</a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="footer-bottom">

          <span className="footer-separator"> © 2024 Lattice</span> |

          <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

          <span className="footer-separator"
            style={{ cursor: "pointer", color: "inherit" }}
            onClick={handleCookieClick}> Cookie Preferences  </span>

          <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

          <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
        </div>

      </div>


      {showCookieModal && (
        <div
          className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
            }`}
        >
          <div className="cookie-modal">

            {isLoading ? (
              <div className="loading-spinner">{cookieText.loading}</div>
            ) : (
              <div className="cookie-content">

                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                  &times;
                </button>
                <div className="heading-of-cookies-with-logo">

                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                </div>

                <div className="div-2-heading-below-text-and-list">
                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                  <ul>
                    {cookieText.listItems.map((item, index) => (
                      <li key={index} className="div-2-cookies-list-text">
                        <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                      </li>
                    ))}
                  </ul>

                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                </div>

                <div className="button-group">
                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                    {cookieText.btn1}
                  </button>
                  <button className="btn deny-btn" onClick={handleDenyAll}>
                    {cookieText.btn2}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerStory;
