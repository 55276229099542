import emp1 from '../image/leadership/Bijan.jpeg';
import emp5 from '../image/leadership/Alina Burnard.png';
import emp3 from '../image/leadership/Cyrille Leroy.png';
// import emp3 from '../image/leadership/Adam Cohen.png';
// import emp4 from '../image/leadership/Ankish Gupta.jpg';
// import emp5 from '../image/leadership/Ben Hawker.png';
import emp6 from '../image/leadership/Brad McGann.png';
import emp7 from '../image/leadership/Hatim-daudi.jpeg';
import emp8 from '../image/leadership/Cyrille Leroy.png';
import emp9 from '../image/leadership/Jonathan Mileshik.jpeg';
import emp10 from '../image/leadership/James Taiclet.png';
// import emp11 from '../image/leadership/Joseph F. Dunford.png';
import emp12 from '../image/leadership/Josh Pelkin.png';
import { executiveLeadership } from './English-Content/ExecutiveLeadership';
// src/Content/employees.js
export const employees = [
    {
        id: 1,
        name: executiveLeadership.card1Name,
        role: executiveLeadership.card1Role,
        description: executiveLeadership.card1Description1,
        description2: executiveLeadership.card1Description2,
        img: emp1,
        read: executiveLeadership.card1Button
    },
    {
        id: 2,
        name: executiveLeadership.card2Name,
        role: executiveLeadership.card2Role,
        description: executiveLeadership.card2Description1,
        description2: executiveLeadership.card2Description2,
        img: emp6,
        read: executiveLeadership.card2Button
    },
    {
        id: 3,
        name: executiveLeadership.card3Name,
        role: executiveLeadership.card3Role,
        description: executiveLeadership.card3Description1,
        description2: executiveLeadership.card3Description2,
        img: emp3,
        read: executiveLeadership.card3Button
    },
    // {
    //     id: 4,
    //     name: executiveLeadership.card4Name,
    //     role: executiveLeadership.card4Role,
    //     description: executiveLeadership.card4Description1,
    //     description2: executiveLeadership.card4Description2,
    //     description3: executiveLeadership.card4Description3,
    //     description4: executiveLeadership.card4Description4,
    //     img: emp4,
    //     read: executiveLeadership.card4Button
    // },
    {
        id: 9,
        name: executiveLeadership.card9Name,
        role: executiveLeadership.card9Role,
        description: executiveLeadership.card9Description1,
        description2: executiveLeadership.card9Description2,
        // description3: executiveLeadership.card9Description3,
        // description4: executiveLeadership.card9Description4,
        img: emp9,
        read: executiveLeadership.card9Button
    },

    {
        id: 10,
        name: executiveLeadership.card10Name,
        role: executiveLeadership.card10Role,
        description: executiveLeadership.card10Description1,
        description2: executiveLeadership.card10Description2,
        // description3: executiveLeadership.card10Description3,
        // description4: executiveLeadership.card10Description4,
        img: emp10,
        read: executiveLeadership.card10Button
    },
    {
        id: 12,
        name: executiveLeadership.card12Name,
        role: executiveLeadership.card12Role,
        description: executiveLeadership.card12Description1,
        description2: executiveLeadership.card12Description2,
        // description3: executiveLeadership.card12Description3,
        // description4: executiveLeadership.card12Description4,
        img: emp12,
        read: executiveLeadership.card12Button
    },
    {
        id: 7,
        name: executiveLeadership.card7Name,
        role: executiveLeadership.card7Role,
        description: executiveLeadership.card7Description1,
        description2: executiveLeadership.card7Description2,
        // description3: executiveLeadership.card7Description3,
        img: emp7,
        read: executiveLeadership.card7Button
    },
    {
        id: 11,
        name: executiveLeadership.card11Name,
        role: executiveLeadership.card11Role,
        description: executiveLeadership.card11Description1,
        description2: executiveLeadership.card11Description2,
        // description3: executiveLeadership.card11Description3,
        // description4: executiveLeadership.card11Description4,
        img: "",
        read: executiveLeadership.card11Button
    },
    // {
    //     id: 6,
    //     name: executiveLeadership.card6Name,
    //     role: executiveLeadership.card6Role,
    //     description: executiveLeadership.card6Description1,
    //     description2: executiveLeadership.card6Description2,
    //     description3: executiveLeadership.card6Description3,
    //     img: emp6,
    //     read: executiveLeadership.card6Button
    // },
    // {
    //     id: 8,
    //     name: executiveLeadership.card8Name,
    //     role: executiveLeadership.card8Role,
    //     description: executiveLeadership.card8Description1,
    //     description2: executiveLeadership.card8Description2,
    //     description3: executiveLeadership.card8Description3,
    //     description4: executiveLeadership.card8Description4,
    //     img: emp8,
    //     read: executiveLeadership.card8Button
    // },
    {
        id: 5,
        name: executiveLeadership.card5Name,
        role: executiveLeadership.card5Role,
        description: executiveLeadership.card5Description1,
        description2: executiveLeadership.card5Description2,
        description3: executiveLeadership.card5Description3,
        img: emp5,
        read: executiveLeadership.card5Button
    },

    // Add more employees as needed
];
