import React, { useState, useRef } from 'react';
import './blog-page.css'
import './company-news.css'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import svg1 from "../image/svg/rss_feed_24dp_E8EAED.svg"

import img1 from '../image/developer-img/rh07-oracle-aws.png'
import img2 from '../image/developer-img/rh07-database-azure.png'
import img3 from '../image/developer-img/rh07-clinical-ai-agent.avif'
import img4 from '../image/developer-img/rh07-fire-vine.avif'
import svg2 from "../image/svg/search_24dp_E8EAED.svg"
import Latticelogo from '../image/lattice-logo-img.svg';
import { newsCompanyPage } from '../Content/English-Content/NewsCompany';




function NewsPage() {


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const newsData = [
        {
          date: newsCompanyPage.fourthSectionNews1Date,
          title: newsCompanyPage.fourthSectionNews1Title,
          description: newsCompanyPage.fourthSectionNews1Description
        },
        {
          date: newsCompanyPage.fourthSectionNews2Date,
          title: newsCompanyPage.fourthSectionNews2Title,
          description: newsCompanyPage.fourthSectionNews2Description
        },
        {
          date: newsCompanyPage.fourthSectionNews3Date,
          title: newsCompanyPage.fourthSectionNews3Title,
          description: newsCompanyPage.fourthSectionNews3Description
        },
        {
          date: newsCompanyPage.fourthSectionNews4Date,
          title: newsCompanyPage.fourthSectionNews4Title,
          description: newsCompanyPage.fourthSectionNews4Description
        },
        {
          date: newsCompanyPage.fourthSectionNews5Date,
          title: newsCompanyPage.fourthSectionNews5Title,
          description: newsCompanyPage.fourthSectionNews5Description
        },
        {
            date: newsCompanyPage.fourthSectionNews6Date,
            title: newsCompanyPage.fourthSectionNews6Title,
            description: newsCompanyPage.fourthSectionNews6Description
          },

          {
            date: newsCompanyPage.fourthSectionNews7Date,
            title: newsCompanyPage.fourthSectionNews7Title,
            description: newsCompanyPage.fourthSectionNews7Description
          },

          {
            date: newsCompanyPage.fourthSectionNews8Date,
            title: newsCompanyPage.fourthSectionNews8Title,
            description: newsCompanyPage.fourthSectionNews8Description
          },

          {
            date: newsCompanyPage.fourthSectionNews9Date,
            title: newsCompanyPage.fourthSectionNews9Title,
            description: newsCompanyPage.fourthSectionNews9Description
          },

          {
            date: newsCompanyPage.fourthSectionNews10Date,
            title: newsCompanyPage.fourthSectionNews10Title,
            description: newsCompanyPage.fourthSectionNews10Description
          },

          {
            date: newsCompanyPage.fourthSectionNews11Date,
            title: newsCompanyPage.fourthSectionNews11Title,
            description: newsCompanyPage.fourthSectionNews11Description
          },


          {
            date: newsCompanyPage.fourthSectionNews12Date,
            title: newsCompanyPage.fourthSectionNews12Title,
            description: newsCompanyPage.fourthSectionNews12Description
          },

          {
            date: newsCompanyPage.fourthSectionNews13Date,
            title: newsCompanyPage.fourthSectionNews13Title,
            description: newsCompanyPage.fourthSectionNews13Description
          },

          {
            date: newsCompanyPage.fourthSectionNews14Date,
            title: newsCompanyPage.fourthSectionNews14Title,
            description: newsCompanyPage.fourthSectionNews14Description
          },

          {
            date: newsCompanyPage.fourthSectionNews15Date,
            title: newsCompanyPage.fourthSectionNews15Title,
            description: newsCompanyPage.fourthSectionNews15Description
          },

          {
            date: newsCompanyPage.fourthSectionNews16Date,
            title: newsCompanyPage.fourthSectionNews16Title,
            description: newsCompanyPage.fourthSectionNews16Description
          },

          {
            date: newsCompanyPage.fourthSectionNews17Date,
            title: newsCompanyPage.fourthSectionNews17Title,
            description: newsCompanyPage.fourthSectionNews17Description
          },

          {
            date: newsCompanyPage.fourthSectionNews18Date,
            title: newsCompanyPage.fourthSectionNews18Title,
            description: newsCompanyPage.fourthSectionNews18Description
          },

          {
            date: newsCompanyPage.fourthSectionNews19Date,
            title: newsCompanyPage.fourthSectionNews19Title,
            description: newsCompanyPage.fourthSectionNews19Description
          },

          {
            date: newsCompanyPage.fourthSectionNews20Date,
            title: newsCompanyPage.fourthSectionNews20Title,
            description: newsCompanyPage.fourthSectionNews20Description
          },
        // Add more news items as needed
      ];


      const [visibleItems, setVisibleItems] = useState(5); // Start by showing 5 items

      const loadMoreItems = () => {
        setVisibleItems((prev) => prev + 5); // Load 5 more items each time
      };

       // cookies
      
      
          const [showCookieModal, setShowCookieModal] = useState(false);
          const [isLoading, setIsLoading] = useState(true);
      
          const handleCookieClick = () => {
              setShowCookieModal(true);
              setIsLoading(true);
      
      
              setTimeout(() => {
                  setIsLoading(false);
              }, 500);
          };
      
          const handleAcceptAll = () => {
              console.log("Cookies Accepted");
              setShowCookieModal(false);
          };
      
          const handleDenyAll = () => {
              console.log("Cookies Denied");
              setShowCookieModal(false);
          };
    





    return (
        <>


            <div className="oracle-blog-div">

                <div className="oracle-blog-div-heading">

                <h1 className="oracle-news-div-heading-h1">{newsCompanyPage.firstSectionHeader}</h1>

                </div>

            </div>


            <div className="news-page-news-container">

                <div className="news-page-upper-area-img">

                    <div className="news-page-upper-area-img-one">

                        <img src={img1} className="news-page-img-of-one-div-of-uai"></img>

                        <a href='#' className="news-page-heading-of-img">{newsCompanyPage.secondSectionHeading}</a><br></br>

                    </div>

                    <div className="news-page-upper-area-img-sec">

                    <div className="news-page-upper-area-img-sec-one-img">

                            <div className="news-page-img-one-uaisoi" >
                                <img src={img2} className="news-page-img-one-uaisoi"></img>
                            </div>

                            <div className="news-page-img-content">

                                <a href='#' className="news-page-one-link-heading">{newsCompanyPage.firstArticleHeading}</a><br></br>

                            </div>

                            </div>

                        <div className="news-page-upper-area-img-sec-one-img">

                            <div className="news-page-img-one-uaisoi" >
                                <img src={img3} className="news-page-img-one-uaisoi"></img>
                            </div>

                            <div className="news-page-img-content">

                                <a href='#' className="news-page-one-link-heading">{newsCompanyPage.secondArticleHeading}</a><br></br>

                            </div>

                        </div>

                        <div className="news-page-upper-area-img-sec-one-img">

                            <div className="news-page-img-one-uaisoi" >
                                <img src={img4} className="news-page-img-one-uaisoi"></img>
                            </div>

                            <div className="news-page-img-content">

                                <a href='#' className="news-page-one-link-heading">{newsCompanyPage.thirdArticleHeading}</a><br></br>

                            </div>

                        </div>

                    </div>

                </div>

            </div>


            <div className="news-page-search-wrapper">

                <div className="news-page-search-area">

                    <div className="news-page-sign-up-div">

                        <h2 className="news-page-search-box-heading">{newsCompanyPage.searchNews}</h2>

                        <img src={svg2} className="news-page-search-icon"></img>
                        <input
                            type="search"
                            placeholder="Enter search..."
                            className="news-page-search-input"
                            required
                        />

                    </div>

                    <div className="news-page-border-between"></div>

                    <div className="news-page-heading-and-side-btn">

                        <h2 className="news-page-search-box-heading">{newsCompanyPage.getLatticeNews}</h2>

                        <a href="/create-account" style={{ color:"inherit" , textDecoration:"none"}}>  <button className="news-page-search-btn">{newsCompanyPage.subcribeToLatticeButton}</button> </a>

                    </div>

                </div>

            </div>

            <div className="news-section-of-news-page">
      <div className="all-news-of-section">
        <h1 className="news-heading-of-all-section">{newsCompanyPage.fourthSectionHeading}</h1>

        {newsData.slice(0, visibleItems).map((item, index) => (
          <div key={index} className="content-under-heading-of-news-page">
            <span className="news-page-small-div">{item.date}</span>

            <div className="news-page-heading-and-para">
              <a className="main-heading-of-news">
                {item.title}
              </a>
              <p className="description-of-heading-of-news">
                {item.description}
              </p>
            </div>
          </div>
        ))}

        {visibleItems < newsData.length && (
          <button className="see-more-btn-news-page" onClick={loadMoreItems}>
            See More +
          </button>
        )}
      </div>

      <div className="news-floating-div">
        <h1 className="news-floating-div-heading">{newsCompanyPage.thirdSectionHeading}</h1>
        <p className="news-floating-div-para">
          {newsCompanyPage.thirdSectionPara}
        </p>
        <a href="/lattice-infrastructure-compute" style={{ color: "inherit", textDecoration: "none" }}> <button className="news-floating-div-btn">{newsCompanyPage.thirdSectionButton}</button> </a>
      </div>
    </div>


    <div className="news-page-last-card-container">

        <h1 className="news-page-last-card-heading">
        {newsCompanyPage.fifthSectionHeading}
        </h1>

        <div className="news-page-card-wrapper">

            <div className="news-page-card-1">

                <div className="img-news-page-card">

                <h1 className="text-span-of-news-page-last-div">  About Lattice</h1>

                </div>

                <div className="news-page-content-of-card">

                   <a href='/about' className="news-page-card-links">{newsCompanyPage.fifthSectionBox1List1}</a> <br></br>

                   <a href='/leadership' className="news-page-card-links">{newsCompanyPage.fifthSectionBox1List2}</a>

                </div>

            </div>

            <div className="news-page-card-1">

            <div className="img-news-page-card">

            <h1 className="text-span-of-news-page-last-div"> For Analysts</h1>

            </div>

            <div className="news-page-content-of-card">

            <a href='#' className="news-page-card-links">{newsCompanyPage.fifthSectionBox2List1}</a> <br></br>

            <a href='/lattice-reporting' className="news-page-card-links">{newsCompanyPage.fifthSectionBox2List2}</a>


            </div>
                
            </div>

            <div className="news-page-card-1">

            <div className="img-news-page-card">

            <h1 className="text-span-of-news-page-last-div"> For Investors </h1>

            </div>

            <div className="news-page-content-of-card">

            <a href='/investor-relationship' className="news-page-card-links">{newsCompanyPage.fifthSectionBox3List1}</a> <br></br>

            <a href='/company-news' className="news-page-card-links">{newsCompanyPage.fifthSectionBox3List2}</a> <br></br>

            <a href='/events' className="news-page-card-links">{newsCompanyPage.fifthSectionBox3List3}</a> 

            </div>
                
            </div>

        </div>

    </div>








            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                 <div className="footer-bottom">
                              
                                                  <span className="footer-separator"> © 2024 Lattice</span> |
                              
                                                  <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
                              
                                                  <span className="footer-separator"
                                                      style={{ cursor: "pointer", color: "inherit" }}
                                                      onClick={handleCookieClick}> Cookie Preferences  </span>
                              
                                                  <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
                              
                                                  <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                                              </div>
                              
                                          </div>
                              
                              
                                          {showCookieModal && (
                                              <div
                                                  className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                                                      }`}
                                              >
                                                  <div className="cookie-modal">
                              
                                                      {isLoading ? (
                                                          <div className="loading-spinner">{cookieText.loading}</div>
                                                      ) : (
                                                          <div className="cookie-content">
                              
                                                              <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                                                  &times;
                                                              </button>
                                                              <div className="heading-of-cookies-with-logo">
                              
                                                                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>
                              
                                                                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>
                              
                                                              </div>
                              
                                                              <div className="div-2-heading-below-text-and-list">
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>
                              
                                                                  <ul>
                                                                  {cookieText.listItems.map((item, index) => (
                                                                      <li key={index} className="div-2-cookies-list-text">
                                                                          <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                                                      </li>
                                                                      ))}
                                                                  </ul>
                              
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>
                              
                                                              </div>
                              
                                                              <div className="button-group">
                                                                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                                                                     {cookieText.btn1}
                                                                  </button>
                                                                  <button className="btn deny-btn" onClick={handleDenyAll}>
                                                                  {cookieText.btn2}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          )}

        </>
    );
};

export default NewsPage;
