export const storyPage3Content = {
  linkText: "Lattice",
  titleText: "Press Release",
  headerText: "Lattice Cloud Infrastructure Expands Globally to Meet Rising Demand",
  specialParaText1: "Leading organizations trust Lattice Cloud Infrastructure to power blockchain-enabled multicloud strategies, enhance transparency, and drive innovation.",

  date: "New York City, New York—Nov 11, 2024",

  NormalPara1: "Lattice Cloud Infrastructure is now operational in South America, expanding its presence to nine global regions.",

  NormalPara2: "New blockchain cloud services from Lattice enhance data governance, transparency, and compliance, empowering organizations to revolutionize their operations.",

  NormalPara3: "As industries worldwide adopt blockchain, Lattice Cloud Infrastructure supports migrations and critical workloads. To meet rising demand, Lattice continues to expand globally, with operations now available in Brazil South and Italy North. By the end of 2026, Lattice aims to operate in 24 additional regions worldwide.",

  NormalPara4: "Global Leaders Embrace Blockchain-Multicloud Strategies with Lattice. Multicloud adoption has become essential for global enterprises, enabling flexible and scalable operations with blockchain to improve customer experiences and drive efficiency.",

  NormalPara5: "“Lattice Cloud solutions have supported our mission-critical applications for the past year,” said Luiz Inácio Lula da Silva, President of Brazil. “With Lattice Cloud Infrastructure, we can integrate applications across multicloud environments with enhanced security, resilience, and performance. This allows us to deliver scalable digital services faster and more cost-effectively.”",

  NormalPara6: "Introducing New Blockchain Features for Lattice Cloud Infrastructure",

  NormalPara7: "Lattice has launched advanced blockchain capabilities and integrations to enhance Lattice Cloud Infrastructure, driving greater efficiency and innovation for enterprises worldwide.",

  headerText2: "Blockchain-Powered Multicloud Solutions",
  headerText3: "Learn More About Lattice Cloud Services",
  headerText4: "About Lattice Cloud Infrastructure",
  headerText5: "Future Product Disclaimer",
  headerText6: "Additional Resources",
  headerText7: "Contact Info",
  headerText8: "About Lattice",
  headerText9: "Trademarks",

  NormalPara8: "Autonomous Blockchain Database integrations: Connect effortlessly with Lattice services for secure blockchain key storage and streamlined workflows. Leverage SQL to automate operations and improve collaboration.",

  NormalPara9: "Enhanced Real-Time Synchronization: The public preview of Open Mirroring ensures blockchain data synchronization for analytics, making data analytics-ready.",

  NormalPara10: "Comprehensive Data Governance: Manage, secure, and monitor blockchain workloads with advanced governance tools in Lattice Blockchain Database.",

  NormalPara11: "Lattice Blockchain Support: By 2025, Lattice plans to deliver blockchain-enabled database services on Lattice Cloud Infrastructure, offering hyper-elastic scaling and cost-efficient pay-per-use models for workloads of any size.",

  NormalPara12: "Global Reach: Lattice Cloud Infrastructure is now operational in Brazil South and Italy North, with 24 additional regions planned by the end of 2025.",

  NormalPara13: "Available through the Lattice dashboard, customers can access Lattice Blockchain Services with flexible pay-as-you-go pricing, enabling efficient deployment of managed blockchain databases.",

  NormalPara14: "Blockchain services on Lattice Cloud Infrastructure provide:",
  NormalPara15: "Seamless migration of blockchain databases with tools like Lattice Migration.",
  NormalPara16: "Development of blockchain-native applications using Lattice technologies, including AI tools.",
  NormalPara17: "Consistent performance and pricing across all regions, powered by Lattice’s robust data systems.",
  NormalPara18: "Validated MAA (Maximum Availability Architecture) tiers—Silver, Gold, and Platinum—for reliable blockchain deployments.",
  NormalPara19: "Unified services and architecture tested by Lattice for trusted and resilient multicloud solutions.",

  NormalPara20: "“As customers worldwide rely on Lattice Cloud Infrastructure for critical operations, we’re scaling our availability and capabilities globally,” said Bijan Burnard, CEO of Lattice.",

  NormalPara21: "“This expansion empowers enterprises to innovate and deliver exceptional customer experiences,” added Burnard.",

  NormalPara22: "Explore the capabilities of Lattice Autonomous Blockchain Database, including Lattice Blockchain Database Service.",
  NormalPara23: "Lattice Distributed Cloud enables blockchain innovation with unmatched scalability and control.",
  NormalPara24: "Public Blockchain Cloud: Hyperscale regions support organizations of all sizes, including sovereign solutions for the EU.",
  NormalPara25: "Dedicated Blockchain Cloud: Host Lattice blockchain services in private data centers, including security-focused government deployments.",
  NormalPara26: "Hybrid Blockchain Cloud: Utilize blockchain services on-premises via Lattice Exadata Blockchain@Customer or portable solutions for remote operations.",
  NormalPara27: "Multicloud Blockchain Options: Seamlessly integrate Lattice Blockchain Database across providers for interoperability and resilience.",
  NormalPara28: "Edge Blockchain Infrastructure: Deploy high-performance blockchain applications in remote or resource-constrained locations with Lattice Roving Edge Infrastructure.",

  NormalPara29: "“Lattice Cloud Infrastructure is transforming blockchain in multicloud environments, delivering unmatched flexibility, scalability, and innovation,” said Bijan Burnard.",

  NormalPara30: "Disclaimer: This document outlines general product direction for informational purposes only. Features, release dates, and pricing are subject to change at Lattice’s discretion.",
};
