import React, { useState } from 'react';
import "./customer.css"; 
import { Link } from 'react-router-dom';
import { footerSections, cookieText } from '../Content/eng_content';
import customer0 from '../image/middle-img-1.svg'
import Latticelogo from '../image/lattice-logo-img.svg';
import { dai } from '../Content/English-Content/DAI';

const DAI = () => {



    
    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

     // cookies
    
    
        const [showCookieModal, setShowCookieModal] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
    
        const handleCookieClick = () => {
            setShowCookieModal(true);
            setIsLoading(true);
    
    
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        };
    
        const handleAcceptAll = () => {
            console.log("Cookies Accepted");
            setShowCookieModal(false);
        };
    
        const handleDenyAll = () => {
            console.log("Cookies Denied");
            setShowCookieModal(false);
        };





    return (
        <>



        <div className="customer-img1-div-of-dai-page">



            <div className="img-and-content-div">

                <img src={customer0} className="top-image-of-dai-page">

                </img>

                <div className="flotting-content-div-of-dai-page">

                <Link className="Link-of-customer" to="/">
                <span>
                    {dai.section1Link} 
                    <span className="arrow-customerPage">
                    <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 4l4 4-4 4" stroke="#000000" strokeWidth="1.5" fill="none"/>
                    </svg>
                    </span>
                </span>
                </Link>

                <h1 className="Customerheader-text">
                {dai.section1Heading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "2vh",
                        }}
                    ></span>
                </h1>

                <p className="customer-normal-text mt-2">
                {dai.section1Para1}
                </p>


                <div className="customer-para-special-of-dai-page">
                    <p className='share'>{dai.share}</p>
                    <div class="customer-icon-div-of-dai-page">

                    
                    {/* <!-- Facebook Icon --> */}
                    <a
                        aria-label="Share on Facebook"
                        title="Share on Facebook"
                        class="sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="facebook"
                        >
                        <path
                            d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.407.593 24 1.324 24H12.82v-9.294H9.692V11.53h3.128V8.785c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.098 2.794.142v3.24l-1.918.001c-1.503 0-1.795.714-1.795 1.762v2.31h3.587l-.467 3.176h-3.12V24h6.116C23.407 24 24 23.407 24 22.676V1.324C24 .593 23.407 0 22.676 0z"
                        />
                        </svg>
                       
                    </a>


                    {/* <!-- Twitter Icon --> */}
                    <a
                        aria-label="Share on Twitter"
                        title="Share on Twitter"
                        class="sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M22.46 6c-.77.35-1.6.58-2.46.69a4.27 4.27 0 001.88-2.36c-.83.49-1.75.83-2.72 1.02a4.27 4.27 0 00-7.3 3.9 12.1 12.1 0 01-8.77-4.45 4.27 4.27 0 001.32 5.7A4.23 4.23 0 012 9.71v.05a4.27 4.27 0 003.42 4.18c-.4.11-.82.17-1.25.17a4.25 4.25 0 01-.8-.07 4.27 4.27 0 003.99 2.96 8.56 8.56 0 01-5.3 1.83c-.34 0-.68-.02-1.01-.06a12.06 12.06 0 006.55 1.92c7.88 0 12.2-6.53 12.2-12.2 0-.18 0-.36-.01-.54A8.8 8.8 0 0022.46 6z"
                        />
                        </svg>
                       
                    </a>

                    {/* <!-- LinkedIn Icon --> */}
                    <a
                        aria-label="Share on LinkedIn"
                        title="Share on LinkedIn"
                        class="sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M19 0H5C2.239 0 0 2.239 0 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5V5c0-2.761-2.239-5-5-5zM8.339 20.5H5.337V9h3.002v11.5zm-1.502-13.002a1.501 1.501 0 110-3.002 1.501 1.501 0 010 3.002zM20.5 20.5h-3v-5.621c0-1.341-.025-3.065-1.867-3.065-1.867 0-2.153 1.459-2.153 2.964V20.5h-3.002V9h2.883v1.562h.041c.402-.762 1.384-1.562 2.85-1.562 3.048 0 3.613 2.008 3.613 4.618V20.5z"
                        />
                        </svg>
                       
                    </a>

                    {/* <!-- Email Icon --> */}
                    <a
                        aria-label="Share via Email"
                        title="Share via Email"
                        class="sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M19.5 4h-15A2.5 2.5 0 002 6.5v11A2.5 2.5 0 004.5 20h15a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0019.5 4zm.5 2.5V6l-7.25 5.25a.75.75 0 01-.75 0L5 6v.5h15zM4.5 18a.5.5 0 01-.5-.5v-9.5L12 14.5l8-6.5v9.5a.5.5 0 01-.5.5h-15z"
                        />
                        </svg>
                      
                    </a>
                    </div>
                    <blockquote>
                    <p className="share-bottom-para-of-dai-page">“
                                                                   
                    {dai.section1Para2}
                                                                   
                    ”</p>
                    <footer className='text-in-line'>
                    {dai.section1Para3}
                    </footer>
                    </blockquote>
                    
                </div>

                <div className="flooting-div-2-of-dai-page">
                    <p className="flooting-div-2-para">
                    {dai.boxHeading}
                    </p>
                </div>


                </div> 
                    
            </div>



        </div>

        <div className="below-info-div-of-d-ai-page">

        <p className="normal-para">
        {dai.section2Para1}<br></br> <br></br> 

        {/* By migrating some of the company’s most critical workloads to LCI, Uber will be able to reduce costs while delivering new consumer and earner experiences. In addition, the company’s innovations with Lattice to transform last-mile logistics in retail will help bring increased profitability. <br></br> <br></br> 

        Watch the video to hear Uber CEO Dara Khosrowshahi and Lattice CEO Safra Catz discuss how Uber has evolved from a ride-share company to a go-anywhere, get-anything company, and how Lattice Cloud is supporting the transformation.
        <br></br> <br></br> <br></br>  */}

        {dai.section2Para2}
        </p>


        <div className="side-by-side-div-info">

            <div className="sbs">

            <p className="heading-of-sbs">
            {dai.section7Heading}
            </p>
            <a href='#' className='tag-of-customer'>
            {dai.section7Link1}
            </a>

            </div>

            <div className="sbs">
                
                <p className="heading-of-sbs">
                {dai.section8Heading}
                </p>
                <a href='#' className='tag-of-customer'>
                <ul>
                <a href="/lci-overview" style={{ color:"inherit" , textDecoration:"none"}}> <li> {dai.section8Link1}</li> </a>
                <li> {dai.section8Link2}</li>
                <li> {dai.section8Link3}</li>
                </ul>
                </a>
    
                </div>

        </div>

        </div>

            





            <div className="footer">
                    <div className="f_list">
                        {footerSections.map((section) => (
                            <div key={section.id} className="list-section">
                                <h3
                                    className="footer-heading"
                                    onClick={() => toggleDropdown(section.id)}
                                >
                                    {section.heading}
                                    <span
                                        className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                    >
                                        &#8595;
                                    </span>
                                </h3>
                                <div
                                    className={`resource-list ${openSections[section.id] ? 'show' : ''
                                        }`}
                                >
                                    <ul>
                                        {section.links.map((link, index) => (
                                            <li key={index}>
                                                {link.to.startsWith('/') ? (
                                                    <Link to={link.to}>{link.text}</Link>
                                                ) : (
                                                    <a href={link.to}>{link.text}</a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                   <div className="footer-bottom">
                              
                                                  <span className="footer-separator"> © 2024 Lattice</span> |
                              
                                                  <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
                              
                                                  <span className="footer-separator"
                                                      style={{ cursor: "pointer", color: "inherit" }}
                                                      onClick={handleCookieClick}> Cookie Preferences  </span>
                              
                                                  <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
                              
                                                  <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                                              </div>
                              
                                          </div>
                              
                              
                                          {showCookieModal && (
                                              <div
                                                  className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                                                      }`}
                                              >
                                                  <div className="cookie-modal">
                              
                                                      {isLoading ? (
                                                          <div className="loading-spinner">{cookieText.loading}</div>
                                                      ) : (
                                                          <div className="cookie-content">
                              
                                                              <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                                                  &times;
                                                              </button>
                                                              <div className="heading-of-cookies-with-logo">
                              
                                                                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>
                              
                                                                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>
                              
                                                              </div>
                              
                                                              <div className="div-2-heading-below-text-and-list">
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>
                              
                                                                  <ul>
                                                                  {cookieText.listItems.map((item, index) => (
                                                                      <li key={index} className="div-2-cookies-list-text">
                                                                          <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                                                      </li>
                                                                      ))}
                                                                  </ul>
                              
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>
                              
                                                              </div>
                              
                                                              <div className="button-group">
                                                                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                                                                     {cookieText.btn1}
                                                                  </button>
                                                                  <button className="btn deny-btn" onClick={handleDenyAll}>
                                                                  {cookieText.btn2}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          )}


        </>
    );
};

export default DAI ;
