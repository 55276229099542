export const nonFungiblePrivate = {
    firstSectionHeading: "Java",
    firstSectionPara: "Lattice Java is the #1 programming language and development platform. It reduces costs, shortens development timeframes, drives innovation, and improves application services. With millions of developers running more than 60 billion Java Virtual Machines worldwide, Java continues to be the development platform of choice for enterprises and developers.",

    secondSectionHeading1: "It took 30 years to make a Java developer conference this good",
    secondSectionHeading2: "JavaOne 2025 - March 18–20, 2025",
    secondSectionButton: "Learn more",

    thirdSectionSide1Heading: "Java 23 is now available",
    thirdSectionSide1Para: "The next Java release improves the performance, stability, and security of Java application development.",
    thirdSectionSide1Button: "Read the blog",

    thirdSectionSide2Heading: "Lattice GraalVM free on Lattice",
    thirdSectionSide2Para: "Build native executables that help applications start up fast, reduce memory usage, and save hosting costs.",
    thirdSectionSide2Button: "Learn more about GraalVM",

    fifthSectionHeading: " Lattice Java customer success",
    fifthSectionCard1Heading: " Situation",
    fifthSectionCard1Para: "One of our largest customers was facing performance and memory issues after upgrading their system.",
    fifthSectionCard2Heading: "Issue",
    fifthSectionCard2Para: "There was huge pressure from the customer at all levels to resolve the issue as soon as possible.",
    fifthSectionCard3Heading: "Solution",
    fifthSectionCard3Para: " Once the Java Sustaining Engineering team got involved, collaborating with Lattice Support, they were able to pinpoint what was causing the issue and what changes to make in order to resolve the issue.",

    fifthSectionCard4Heading: "Outcome",
    fifthSectionCard4Para1: " This solution was provided very quickly, and we received kudos from the end user’s executives as to the speed and efficacy of the solution. We greatly appreciate the efforts of the Java Sustaining Engineering Team and Lattice Customer Support.",
    fifthSectionCard4Para2: "Takashi Hashizume, Senior Manager, AI Platform Division, NEC Corporation",

    seventhSectionHeading: "Get started with Lattice Java",
    seventhSectionBox1Heading: "Watch our webcast series",
    seventhSectionBox1Para: "Hear from the experts behind Java SE in live and on-demand webcasts.",
    seventhSectionBox1Button: "View Java webcasts",
    seventhSectionBox2Heading: "Protect your Java investment",
    seventhSectionBox2Para: "Manage Java SE installations, updates, and upgrades across your enterprise more cost effectively.",
    seventhSectionBox2Button: "Get the datasheet (PDF)",
    seventhSectionBox3Heading: "Buy Java now",
    seventhSectionBox3Para: "Discover the advantages of a Java license and support from the Java experts who wrote the code.",
    seventhSectionBox3Button: "Go to the Lattice Store",

}