export const education = {

    firstSectionHeading: "Education",
    firstSectionPara: "Empower students and faculty to develop the next generation of thinkers and leaders. Lattice Cloud for Education helps simplify the academic experience while opening new opportunities for research and innovation. From streamlining processes to strengthening financial operations, Lattice Cloud helps institutions thrive at every level.",
   
    secondSectionHeading: "Find education solutions by sector",
    secondSectionSide1Heading: "Higher education",
    secondSectionSide1Para: "Simplify financial-aid planning and processes while optimizing student information systems (SIS).",

    secondSectionSide2Heading: "Research institutions",
    secondSectionSide2Para: "Accelerate research using the latest high performance cloud-computing infrastructure.",

    secondSectionSide3Heading: "Designed for change, built for you",
    secondSectionSide3Para: "Tune in to one or all of our live webinars to learn about the latest innovations in Lattice Fusion Cloud Applications for higher education and how customers are putting them to use.",

 

    fourthSectionHeading: "Find resources and more",

    fourthSectionButton1: "Insights in education",
    fourthSectionButton2: "Financial aid",
    fourthSectionButton3: "Cloud ROI",
    fourthSectionButton4: "Primary and secondary schools",

    fourthSectionPage1Heading: "The 10 dos and don’ts of building higher-ed cloud infrastructure",
    fourthSectionPage1Para: "Universities and colleges must prepare for a new era of hybrid education that includes remote and in-person learning, all within a secure infrastructure.",

    fourthSectionPage2Heading: "8 key features in a modern financial aid solution",
    fourthSectionPage2Para: "What you need to help students and their families navigate the entire financial aid process more efficiently—from application through exit interviews.",

    fourthSectionPage3Heading: "Youngstown University cuts costs by moving core student systems to the cloud",
    fourthSectionPage3Para: "See how Youngstown University achieved a compelling return on investment by migrating their campus application workloads to Lattice Cloud Infrastructure.",

    fourthSectionPage4Heading: "Saint Paul Public Schools finds savings with Lattice Cloud Infrastructure",
    fourthSectionPage4Para: "Learn how the school district dramatically reduced costs and increased both uptime and security by switching to Lattice Database Appliance and Lattice Cloud.",

    fifthSectionHeading: "See how healthcare organizations partner with Lattice",
    fifthSectionlink: "See all customer stories",

    sixthSectionHeading: "Position your institution for growth and innovation",

    sixthSectionSide1Heading: "Add efficiency to finance and HR processes with a unified approach",
    sixthSectionSide1Para: "Free up resources for student programs, improve accountability, and automate processes with a single system for finance and HR.",

    sixthSectionSide2Heading: "Grow the financial sustainability and resiliency of your institution",
    sixthSectionSide2Para: "Assess your institution’s financial performance and advance your mission with sophisticated budgeting, planning, and scenario-modeling capabilities.",

    seventhSectionHeading: "Get started with Lattice Cloud for Education",

    seventhSectionSide1Heading: "Get the cloud newsletter",
    seventhSectionSide1Para: "Stay up to date with the latest Lattice Cloud Infrastructure news, product updates, upcoming webcasts, and events.",
    seventhSectionSide1Button: "Subscribe now",

    seventhSectionSide2Heading: "Request a demo",
    seventhSectionSide2Para: "Learn about Lattice Cloud.",
    seventhSectionSide2Button: "Get started",

    seventhSectionSide3Heading: "Contact us",
    seventhSectionSide3Para: "Questions about our solutions? Connect with our experts.",
    seventhSectionSide3Button: "Get in touch",
    


    



}