import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { footerSections, cookieText } from '../Content/eng_content';
import './products-section-tools-template-page.css';
import img1 from '../image/tools/rh03-oracle-java-r1.png'
import
// description:
//     'All software downloads are free, and most come with a Developer License that allows you to use full versions of the products at no charge while developing and prototyping your applications, or for strictly self-educational purposes.',
Latticelogo // description:

    //     'All software downloads are free, and most come with a Developer License that allows you to use full versions of the products at no charge while developing and prototyping your applications, or for strictly self-educational purposes.',
    from '../image/lattice-logo-img.svg';
import { nonFungiblePrivate } from '../Content/English-Content/NonFungiblePrivate';



function NonFungiblePrivateTokenGenerator() {

    const latestNewsRef = useRef(null);
    const aiRef = useRef(null);
    const languagesRef = useRef(null);
    const solutionsRef = useRef(null);
    const communityRef = useRef(null);
    const resourcesRef = useRef(null);

    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const [activeSection, setActiveSection] = useState("Cloud");

    // Handle click to update active section
    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: 'The world’s most popular modern development platform',
            // description:
            //     'All software downloads are free, and most come with a Developer License that allows you to use full versions of the products at no charge while developing and prototyping your applications, or for strictly self-educational purposes.',
            link: 'Download Java now',

            fastTrackList: ['The world’s most popular modern development platform, Java SE is the programming language of choice for enterprise applications. Java SE reduces costs, shortens development time, drives innovation, and improves application services. Protect your Java investment with Lattice Java SE Universal Subscription, which now includes Lattice GraalVM and Java Management Service.'],
            cardheading: [''],
        },
        documentation: {
            heading: 'Java SE Subscription includes Lattice GraalVM—for free',
            // description:
            //     'Explore getting started guides, documentation, tutorials, reference architectures, and other content for Lattice products and services.',
            // link: 'Explore the content',
            cardheading: [''],
            fastTrackList: ['Take advantage of the high-performance JDK with advanced optimizations that improve Java application performance and microservices deployment—on-premises and in the cloud. Together, the two technologies add value for cloud native deployments with native image and multilanguage support.'],
            headingof3div: '',
        },
        events: {
            heading: "Enterprise Backup",
            // description:
            //     'Explore Lattice Developer event replays and recent webinars, hosted by technical experts.',
            fastTrackList: ['MySQL Enterprise Backup performs hot nonblocking backups of MySQL databases. Full backups can be performed on all InnoDB data while MySQL is online, without interrupting queries or updates.', 'MySQL Enterprise Backup supports compressed backup, typically reducing backup size from 70% to more than 90% to reduce storage costs.', 'MySQL Enterprise Backup restores data from a full backup with full backward compatibility. Consistent point-in-time recovery (PITR) enables restoration to a specific point in time.', 'MySQL Enterprise Backup uses built-in 256-bit Advanced Encryption Standard (AES) encryption to secure all sensitive backup data.'],
            // link: 'Explore the content',
            headingof3div: 'Access developer resources and learn about future events designed just for you. Hear from experts in their field and gain practical hands-on experience.',
            cardheading: ['Backup', 'Compression', 'Restore', 'Encryption'],
        },
        technologies: {
            heading: ' High availability',
            // description:
            //     'Join events, webinars, and conferences to stay up-to-date with the latest trends and developments.',
            // link: 'All Events',
            cardheading: ['MySQL InnoDB Cluster', 'MySQL InnoDB ClusterSet'],
            fastTrackList: ['MySQL InnoDB Cluster delivers a complete high-availability solution for MySQL. Each server in a cluster replicates data to all members of the cluster while providing fault tolerance, automated failover, and elasticity.', 'MySQL InnoDB ClusterSet provides disaster tolerance for InnoDB Cluster deployments by linking a primary cluster with one or more replicas of itself in different data centers.'],
            headingof3div: 'Technologies',
        },
        languages: {
            heading: ' Monitoring',
            // description:
            //     'Join events, webinars, and conferences to stay up-to-date with the latest trends and developments.',
            // link: 'All Events',
            cardheading: ['Visually manage MySQL servers', 'Backup dashboard', 'Replication dashboard', 'Real-time performance monitoring'],
            fastTrackList: ['MySQL Enterprise Monitor provides real-time visibility into the performance and availability of all your MySQL databases. Start monitoring MySQL within 10 minutes with zero configuration and no agents. A rich set of real-time and historical graphs let you drill down into server-statistic details.', 'The backup dashboard provides visibility into crucial backup activities, letting you easily ensure that your most valuable assets are protected against data loss.', 'MySQL Enterprise Monitor automatically discovers your MySQL replication topologies and gives you visibility into the performance, availability, and health of each MySQL instance.', 'MySQL Enterprise Monitor continuously monitors MySQL queries and performance-related server metrics. MySQL Query Analyzer lets DBAs accurately pinpoint SQL code that’s the root cause of a slowdown.'],
            headingof3div: 'languages:',
        },
        learn: {
            heading: 'AI Resource Hub',
            description:
                'Discover, learn and build. Access a range of AI resources to help you get ahead on your AI journey.',
            link: 'Learn more',
            cardheading: ['Flexible VMs', 'Dense I/O VMs', 'Optimized VMs', 'Dedicated VM hosts'],
            fastTrackList: ['VMs are customizable from 1–94 cores for AMD EPYC processors, 1–32 cores for Intel, and 1–80 cores for Ampere processors, with 1–1,094 GB RAM and network bandwidth that scales with the number of cores.', 'Dense I/O VMs, with 8–24 cores and 128–512 GB RAM, offer 6.8–27.2 TB high performance, locally attached NVMe storage for big data and analytics workloads.', 'Optimized VM shapes provide 1–18 high-frequency processor cores and support cluster networking with 4–40 Gb/sec of network bandwidth.', 'Dedicated VM host shapes offer bare metal instances with 36–128 cores and 512–2,048 GB memory per host with software to host VMs.'],
            headingof3div: 'Learn',
        },

    };

    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };

    return (
        <>





            <div className="product-tools-tempalte-human-resource-first-div">

                <div className="product-tools-tempalte-human-resource-first-div-child-1">
                    <h1 className="product-tools-tempalte-human-resource-first-div-heading">


                        {nonFungiblePrivate.firstSectionHeading}

                        <span
                            style={{
                                display: "block",
                                width: "40px",
                                height: "4px",
                                background: "#f0cc71",
                                marginBottom: "3vh",
                                marginTop: "3vh",
                            }}
                        ></span>


                        <p className="product-tools-tempalte-human-resource-first-div-paragraph">
                            {nonFungiblePrivate.firstSectionPara}
                        </p>

                    </h1>

                </div>



                <div className="product-tools-tempalte-human-resource-first-div-child-2">

                    <img src={img1} className="product-tools-tempalte-human-resource-first-div-child-2-img">

                    </img>
                </div>
            </div>

            <div className="product-tools-tempalte-human-resource-second-div-para-btn">

                <h1 className="product-tools-tempalte-human-resource-second-div-heading">{nonFungiblePrivate.secondSectionHeading1} <br></br>
                    {nonFungiblePrivate.secondSectionHeading2}</h1>

                <button className="product-tools-tempalte-human-resource-second-div-btn">{nonFungiblePrivate.secondSectionButton}</button>

            </div>

            <div className="product-tools-tempalte-human-resource-third-div">

                <div className="product-tools-tempalte-human-resource-third-div-child-1">
                    <h1 className="product-tools-tempalte-human-resource-third-div-child-1-heading">{nonFungiblePrivate.thirdSectionSide1Heading}</h1>
                    <p className="product-tools-tempalte-human-resource-third-div-child-1-paragraph">
                        {nonFungiblePrivate.thirdSectionSide1Para}
                    </p>
                    <button className="product-tools-tempalte-human-resource-third-div-child-1-btn">{nonFungiblePrivate.thirdSectionSide1Button}</button>

                </div>

                <div className="vertical-line"></div>

                <div className="product-tools-tempalte-human-resource-third-div-child-2">
                    <h1 className="product-tools-tempalte-human-resource-third-div-child-2-heading">{nonFungiblePrivate.thirdSectionSide2Heading}</h1>
                    <p className="product-tools-tempalte-human-resource-third-div-child-2-paragraph">
                        {nonFungiblePrivate.thirdSectionSide2Para}
                    </p>
                    <button className="product-tools-tempalte-human-resource-third-div-child-2-btn">{nonFungiblePrivate.thirdSectionSide2Button}</button>

                </div>
            </div>

            {/* <div className="product-tools-tempalte-human-resource-fourth-div">
                <h1 className="product-tools-tempalte-human-resource-fourth-div-heading">

                    Booking.com relies on MySQL to power one of the world’s largest travel marketplaces




                    <p className="product-tools-tempalte-human-resource-fourth-div-paragraph">
                        Booking.com uses MySQL to deliver personalized content, billing, and payments for millions of monthly active users.
                    </p>

                    <button className="product-tools-tempalte-human-resource-fourth-div-btn">Watch the video (22:15) </button>

                </h1>
            </div> */}

            {/* <div className="product-human-resource-div-four-content-of-resources">
                <h1 className="product-human-resource-div-four-solution-heading">

                Java products and technologies

                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>
            

            <div className="product-human-resource-div-four-content-wrapper-of-resource">
                <div className="product-human-resource-div-four-content-1-of-resource">
                    <ul>
                        <li>
                            <button
                                className={`product-human-resource-div-four-btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                    }`}
                                onClick={() => handleResourceClick('downloads')}
                            >
                                Java SE
                            </button>
                        </li>
                        <li>
                            <button
                                className={`product-human-resource-div-four-btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                    }`}
                                onClick={() => handleResourceClick('documentation')}
                            >
                                Oracle GraalVM
                            </button>
                        </li>
                        <li>
                            <button
                                className={`product-human-resource-div-four-btn-resource ${selectedResource === 'events' ? 'active' : ''
                                    }`}
                                onClick={() => handleResourceClick('events')}
                            >
                                Java on OCI
                            </button>
                        </li>
                        <li>
                            <button
                                className={`product-human-resource-div-four-btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                    }`}
                                onClick={() => handleResourceClick('technologies')}
                            >
                                Oracle WebLogic Server
                            </button>
                        </li>
                        <li>
                            <button
                                className={`product-human-resource-div-four-btn-resource ${selectedResource === 'languages' ? 'active' : ''
                                    }`}
                                onClick={() => handleResourceClick('languages')}
                            >
                                Java Card
                            </button>
                        </li>
                        
                    </ul>
                </div>

                
                <div className="product-human-resource-div-four-combined-content">
                    
                    <div className="product-human-resource-div-four-top-content">
                        <h1 className="product-human-resource-div-four-resource-heading">{currentResource.heading}</h1>
                        <p className="product-human-resource-div-four-resource-description">{currentResource.description}</p>
                        
                    </div>


                   
                    <div className="product-human-resource-div-four-grid-content">
                        {currentResource.fastTrackList.map((item, index) => (
                            <div className="product-human-resource-div-four-grid-item" key={index}>
                                <h2 className="product-human-resource-div-four-grid-heading">{currentResource.cardheading[index]}</h2>
                                <p className="product-human-resource-div-four-grid-para">{item}</p>
                                
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            </div> */}

            {/* Content-3 */}
            {/* <div className="content-3-of-resource">
                    <p className="res-3-div-para">{currentResource.headingof3div}</p>
                      <ul>
                        {currentResource.fastTrackList.map((item, index) => (
                          <li className="list-of-3-div" key={index}> <a className="link-of-com">
            
                            <span className="arrow-of-div-3">
                            <svg width="20" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 4l4 4-4 4" stroke="#006B8F" strokeWidth="1.5" fill="none"/>
                            </svg>
                            </span>
                            
                             {item} </a></li>
                        ))}
                      </ul>
                    </div> */}
            {/* </div>
                </div> */}

            <div className="product-tools-tempalte-human-resource-sixth-div">
                <h1 className="product-tools-tempalte-human-resource-sixth-div-heading">
                    {nonFungiblePrivate.fifthSectionHeading}
                </h1>
                <h3 className="product-tools-tempalte-human-resource-sixth-div-sub-heading">
                    {nonFungiblePrivate.fifthSectionCard1Heading}
                </h3>
                <p className="product-tools-tempalte-human-resource-sixth-div-para">
                    {nonFungiblePrivate.fifthSectionCard1Para}
                </p>

                <h3 className="product-tools-tempalte-human-resource-sixth-div-sub-heading">
                    {nonFungiblePrivate.fifthSectionCard2Heading}
                </h3>
                <p className="product-tools-tempalte-human-resource-sixth-div-para">
                    {nonFungiblePrivate.fifthSectionCard2Para}
                </p>

                <h3 className="product-tools-tempalte-human-resource-sixth-div-sub-heading">
                    {nonFungiblePrivate.fifthSectionCard3Heading}
                </h3>
                <p className="product-tools-tempalte-human-resource-sixth-div-para">
                    {nonFungiblePrivate.fifthSectionCard3Para}
                </p>

                <h3 className="product-tools-tempalte-human-resource-sixth-div-sub-heading">
                    {nonFungiblePrivate.fifthSectionCard4Heading}
                </h3>
                <p className="product-tools-tempalte-human-resource-sixth-div-para">
                    {nonFungiblePrivate.fifthSectionCard4Para1} <br></br><br></br>
                    {nonFungiblePrivate.fifthSectionCard4Para2}
                </p>
            </div>

            {/* <div className="product-tools-tempalte-human-resource-seventh-div">
                <h1 className="product-tools-tempalte-human-resource-seventh-div-heading">

                    MySQL Enterprise Edition resources
                </h1>
            </div>

            <div className="product-tools-tempalte-human-resource-eight-div">

                <div className="product-tools-tempalte-human-resource-eight-div-child-1">
                    <h1 className="product-tools-tempalte-human-resource-third-div-child-1-heading">Documentation</h1>
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-1-paragraph">
                        MySQL Reference Manual
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-1-paragraph">
                        MySQL release notes
                    </a>

                </div>

                <div className="eight-div-child-1-vertical-line"></div>

                <div className="product-tools-tempalte-human-resource-eight-div-child-2">
                    <h1 className="product-tools-tempalte-human-resource-eight-div-child-2-heading">Training</h1>

                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-2-paragraph">
                        MySQL courses and learning paths
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-2-paragraph">
                        Free MySQL Explorer training
                    </a>

                </div>

                <div className="eight-div-child-1-vertical-line"></div>

                <div className="product-tools-tempalte-human-resource-eight-div-child-3">
                    <h1 className="product-tools-tempalte-human-resource-eight-div-child-3-heading">On-demand webinars
                    </h1>
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph">
                        MySQL for Beginners
                    </a>
                    <br />
                    <a
                        href="#"
                        className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph"
                        style={{width: "15rem" }}>
                        MySQL Security from Data Protection to Regulation Compliance
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph" style={{width: "20rem", marginTop: "0" }}>
                        High Availability and Disaster Recovery with MySQL InnoDB ClusterSet
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph">
                        MySQL Enterprise in Financial Services
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph">
                        MySQL as an Embedded Database for ISVs
                    </a>
                </div>

                <div className="eight-div-child-1-vertical-line"></div>

                <div className="product-tools-tempalte-human-resource-eight-div-child-4">
                    <h1 className="product-tools-tempalte-human-resource-eight-div-child-4-heading">Connect</h1>

                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                        Lattice MySQL Blog
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                        Twitter
                    </a>
                    <br />

                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                        Facebook
                    </a>
                    <br />

                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                        YouTube
                    </a>
                    <br />

                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                        LinkedIn
                    </a>


                </div>
            </div> */}

            <div class="mysql-section">
                <h2 class="mysql-heading">{nonFungiblePrivate.seventhSectionHeading}</h2>
                <div class="mysql-cards">
                    <div class="mysql-card">
                        <div class="card-top">
                            <i class="icon-download"></i>
                            <h3>{nonFungiblePrivate.seventhSectionBox1Heading}</h3>
                        </div>
                        <div class="card-bottom">
                            <p>{nonFungiblePrivate.seventhSectionBox1Para}</p>
                            <button>{nonFungiblePrivate.seventhSectionBox1Button}</button>
                        </div>
                    </div>
                    <div class="mysql-card">
                        <div class="card-top">
                            <i class="icon-subscription"></i>
                            <h3>{nonFungiblePrivate.seventhSectionBox2Heading}</h3>
                        </div>
                        <div class="card-bottom">
                            <p>{nonFungiblePrivate.seventhSectionBox2Para}</p>
                            <button>{nonFungiblePrivate.seventhSectionBox2Button}</button>
                        </div>
                    </div>
                    <div class="mysql-card">
                        <div class="card-top">
                            <i class="icon-contact"></i>
                            <h3>{nonFungiblePrivate.seventhSectionBox3Heading}</h3>
                        </div>
                        <div class="card-bottom">
                            <p>{nonFungiblePrivate.seventhSectionBox3Para}</p>
                            <button>{nonFungiblePrivate.seventhSectionBox3Button}</button>
                        </div>
                    </div>
                </div>
            </div>






            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    );

}
export default NonFungiblePrivateTokenGenerator; 