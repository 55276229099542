import React, { useState } from 'react';
import './contact.css';
import { Link } from 'react-router-dom';

import logo1 from '../src/image/con1.avif'
import logo2 from '../src/image/con2.avif'
import logo3 from '../src/image/con3.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import { footerSections, cookieText } from './Content/eng_content';
import { contactContent } from './Content/contact-content';
import Latticelogo from "../src/image/lattice-logo-img.svg";
import { useChatWidget } from "./ChatWidgetContext";



function ContactPage() {

     const { toggleWidget } = useChatWidget(); 


  const [openSections, setOpenSections] = useState({});
  const [activeItem, setActiveItem] = useState('training');

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const toggleDropdown = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };


  // State to track expanded sections
  const [expanded, setExpanded] = useState([false, false, false, false]);

  const toggleExpand = (index) => {
    setExpanded(prevExpanded => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index]; // Toggle the clicked section
      return newExpanded;
    });
  };

   // cookies
  
  
      const [showCookieModal, setShowCookieModal] = useState(false);
      const [isLoading, setIsLoading] = useState(true);
  
      const handleCookieClick = () => {
          setShowCookieModal(true);
          setIsLoading(true);
  
  
          setTimeout(() => {
              setIsLoading(false);
          }, 500);
      };
  
      const handleAcceptAll = () => {
          console.log("Cookies Accepted");
          setShowCookieModal(false);
      };
  
      const handleDenyAll = () => {
          console.log("Cookies Denied");
          setShowCookieModal(false);
      };


  return (
    <div className='all'>


      <div className="contact-page">
      
        <div className="contact-intro-img">
          <div className="intro-heading">
            <h1>{contactContent.intro.heading}</h1>
            {contactContent.intro.buttons.map((buttonText, index) => (
              <button key={index} className={`contact-btn-${index + 1}`} onClick={toggleWidget}>
                {buttonText}
              </button>
            ))}
          </div>
          <div className="intro-img"></div>
        </div>

      
        {/* <div className="con">
          {contactContent.connectOptions.map((option, index) => (
            <div className={`con${index + 1}`} key={index}>
              <img src={option.logo} alt={option.altText} className={`con${index + 1}-image`} />
              <p>
                <b>{option.title}</b>
                {option.description && <><br /><span className="message-info">{option.description}</span></>}
                <br />
                <span className="link">
                  <a href={option.linkHref}>{option.linkText}</a>
                </span>
              </p>
            </div>
          ))}
        </div> */}



          <h1 className='heading-of-4-divs'>How can we help ?</h1>

        <div className="contact-container">
      {/* First Contact Item */}
      <div className={`contact-item ${expanded[0] ? 'expanded' : ''}`} onClick={() => toggleExpand(0)}>
      <svg
              xmlns="http://www.w3.org/2000/svg"
              className="contact-image"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              stroke-width="2"
              width="48"
              height="40"
            >
              <circle cx="12" cy="8" r="4" />
              <path d="M4 20c0-4 8-4 8-4s8 0 8 4v2H4v-2z" />
            </svg>


        <h3 className='contact-heading-of-div'>I'd like to speak with the sales team  </h3>
        <p className='contact-paragrapg-of-div'>Learn about our product and pricing </p>
        <div className="arrow-container">
          <svg
            className={`arrow ${expanded[0] ? 'up' : 'down'}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              stroke="white"
              strokeWidth="2"
              d="M12 19l-6-6 6-6"
            />
          </svg>
        </div>
      </div>
      {/* Expanded content for first div */}
      {expanded[0] && (
        <div className="expanded-content">
          <div className="row">
            <div className="expand-item">
            <img src={logo2} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">Chat with Lattice</h3>
        
            <a href="#" className="contactpage-link" onClick={toggleWidget}>Open a live chat</a>
            </div>

            <div className="expand-item">
            <img src={logo3} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">Call Lattice Sales</h3>
            <p className="contactpage-paragraph">
            We’re available Monday to Friday from 5 a.m. to 5 p.m. PT.
            </p>
            <a href="#" className="contactpage-link">See the phone numbers</a>
            </div>

            <div className="expand-item">
            <img src={logo1} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">Send a message to Lattice Sales</h3>
            <p className="contactpage-paragraph">
            Send us a message here and we’ll get back to you. For a faster response, please provide your contact information.
            </p>
            <a href="#" className="contactpage-link" onClick={toggleWidget}>Send us a message</a>
            </div>
          </div>
        </div>
      )}

      {/* Second Contact Item */}
      <div className={`contact-item ${expanded[1] ? 'expanded' : ''}`} onClick={() => toggleExpand(1)}>
      <svg
            xmlns="http://www.w3.org/2000/svg"
            className="contact-image"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            stroke-width="1"
            width="48"
            height="48"
          >
            <path d="M12 1c4.97 0 9 4.03 9 9v4a3 3 0 0 1-3 3h-1v-2h1a1 1 0 0 0 1-1v-4c0-3.87-3.13-7-7-7S5 6.13 5 10v4a1 1 0 0 0 1 1h1v2H6a3 3 0 0 1-3-3v-4c0-4.97 4.03-9 9-9z" />
          
            <circle cx="12" cy="17" r="3" />
            <path
              d="M12 14v-1M15.5 15.5l.7-.7M14 19h1M8.5 15.5l-.7-.7M9 19H8M10.5 13.5l-.7.7"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

        <h3 className='contact-heading-of-div'>
        I'd like to speak with the Lattice team</h3>
        <p className='contact-paragrapg-of-div'>Connect with Lattice team for tranning support.</p>
        <div className="arrow-container">
          <svg
            className={`arrow ${expanded[1] ? 'up' : 'down'}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              stroke="white"
              strokeWidth="2"
              d="M12 19l-6-6 6-6"
            />
          </svg>
        </div>
      </div>
      {/* Expanded content for second div */}
      {expanded[1] && (
        <div className="expanded-content">
         <div className="row">
            <div className="expand-item">
            <img src={logo2} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">I have question about certification, billing, account access</h3>
            
            <p className="contactpage-paragraph">
            Customes with Lattice tranning and certification questions, including billing, account access, or genral inquirie, should visit us.
            </p>

            <a href="#" className="contactpage-link">Tranning and Certification FAQ's</a>
            </div>

            <div className="expand-item">
            <img src={logo3} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">I need to submit a support request</h3>
            <p className="contactpage-paragraph">visit the Education and Certification Support site to open an Oracle University support request. Please note that an Oracle single sign-on account is required for access.
            </p>
            </div>

            <div className="expand-item">
            <img src={logo1} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">I want to speak with Oracle University Sales</h3>
            <p className="contactpage-paragraph">
            <a href="#" className="contactpage-link" onClick={toggleWidget} >Send us a message here</a>  and we’ll get back to you. For a faster response, please provide your contact information and include details on how we can help.
            </p>
    
            </div>
          </div>
        </div>
      )}

      {/* Third Contact Item */}
      <div className={`contact-item ${expanded[2] ? 'expanded' : ''}`} onClick={() => toggleExpand(2)}>
      <svg
          xmlns="http://www.w3.org/2000/svg"
          className="contact-image"
          viewBox="0 0 24 24"
          fill="none"
          stroke="white"
          stroke-width="1"
          width="48"
          height="48"
        >

          <path
            d="M5 2h14a1 1 0 0 1 1 1v18l-3-2-3 2-3-2-3 2-3-2v-18a1 1 0 0 1 1-1z"
            stroke-linejoin="round"
          />

          <line x1="8" y1="6" x2="16" y2="6" />
          <line x1="8" y1="10" x2="16" y2="10" />
          <line x1="8" y1="14" x2="12" y2="14" />

          <path d="M13.5 17.5c0-1.5-3-1.5-3-3s3-1.5 3-3" />
          <line x1="11.5" y1="12" x2="11.5" y2="18" />
        </svg>

        <h3 className='contact-heading-of-div'>
       I'm an Lattice customer who needs support </h3>
        <p className='contact-paragrapg-of-div'>Get technical, billing, and account questions answered.</p>
        <div className="arrow-container">
          <svg
            className={`arrow ${expanded[2] ? 'up' : 'down'}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              stroke="white"
              strokeWidth="2"
              d="M12 19l-6-6 6-6"
            />
          </svg>
        </div>
      </div>
      {/* Expanded content for third div */}
      {expanded[2] && (
        <div className="expanded-content">
          <div className="row">
            <div className="expand-item">
            <img src={logo2} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">I need technical support</h3>

            <p className="contactpage-paragraph">find  the <a href="#" className="contactpage-link" onClick={toggleWidget} >customer supports</a>you need</p>
            
           
            </div>
           
            <div className="expand-item">
            <img src={logo3} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">I have a billing question</h3>
            <a href="#" className="contactpage-link">Explore blockchain billing resource</a>
            </div>

            <div className="expand-item">
            <img src={logo1} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">I need help with my account</h3>
            <p className="contactpage-paragraph">
            Get help logging in to your <a href="/create-account" className="contactpage-link">Lattice account.</a>
            </p>
            
            </div>
          </div>
        </div>
      )}

      {/* Fourth Contact Item */}
      <div className={`contact-item ${expanded[3] ? 'expanded' : ''}`} onClick={() => toggleExpand(3)}>
      <svg
          xmlns="http://www.w3.org/2000/svg"
          className="contact-image"
          viewBox="0 0 24 24"
          fill="none"
          stroke="white"
          stroke-width="1"
          width="48"
          height="48"
        >
          <circle cx="12" cy="12" r="10" />

          <circle cx="12" cy="12" r="4" />
          
          <line x1="12" y1="2" x2="12" y2="6" />
          <line x1="12" y1="18" x2="12" y2="22" />
          <line x1="2" y1="12" x2="6" y2="12" />
          <line x1="18" y1="12" x2="22" y2="12" />
          
          <circle cx="12" cy="10" r="2" />
          <path d="M10 14c1-1 3-1 4 0" />
        </svg>

        <h3 className='contact-heading-of-div'>
        I have a general question</h3>
        <p className='contact-paragrapg-of-div'>need help starting your blockchain trial? intrested in working for Lattice? Just ask us.</p>
        <div className="arrow-container">
          <svg
            className={`arrow ${expanded[3] ? 'up' : 'down'}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              stroke="white"
              strokeWidth="2"
              d="M12 19l-6-6 6-6"
            />
          </svg>
        </div>
      </div>
      {/* Expanded content for fourth div */}
      {expanded[3] && (
        <div className="expanded-content">
         <div className="row">
            <div className="expand-item">
            <img src={logo2} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">How do I get started with my cloud trial?</h3>
        
            <p><a href="/create-account" className="contactpage-link">Set up your account</a> and more.</p>
            </div>

            <div className="expand-item">
            <img src={logo3} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">I’m interested in working for Lattice</h3>
            <p className="contactpage-paragraph">
           Discover what <a href="/careers" className="contactpage-link">careers options</a>  are available.
            </p>
  
            </div>

            <div className="expand-item">
            <img src={logo1} alt="Logo 1" className="contactpage-logo" />
            <h3 className="contactpage-heading">I have other options </h3>
            <p className="contactpage-paragraph">
            <a href="#" className="contactpage-link">Get contact information</a> for general inquirie and more.
            </p>
           
            </div>
          </div>
        </div>
      )}
    </div>



        {/* Assistance Section
        <div className="contact-dpage">
          <div className="contact-A">
            <p className="contact-hpt">{contactContent.assistance.heading}</p>
          </div>
          <div className="contact-list-container">
            <ul className="contact-list">
              {contactContent.assistance.categories.map((category) => (
                <li
                  key={category.key}
                  className={`contact-list-item ${activeItem === category.key ? 'active' : ''}`}
                  onClick={() => handleItemClick(category.key)}
                >
                  {category.label}
                </li>
              ))}
            </ul>
          </div>
          <div className="contact-flex-container">
            <div className="contact-first-div">
              {contactContent.assistance.categories.map((category) => (
                <div
                  key={category.key}
                  className={`contact-content-div ${activeItem === category.key ? 'active' : ''}`}
                >
                  {/* Check if the content contains HTML */}
                  {/* {typeof category.content === 'string' && category.content.includes('<div') ? (
                    <div dangerouslySetInnerHTML={{ __html: category.content }} />
                  ) : (
                    <p>{category.content}</p>
                  )}
                </div>
              ))}
            </div>

          </div>
        </div>

        {/* Team Section */}
        <div className="team">
          <div className="teamA">
            <p className="title">{contactContent.team.partners.title}</p>
            {contactContent.team.partners.content.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
          <div className="teamB">
            <p className="title">{contactContent.team.careers.title}</p>
            {contactContent.team.careers.content.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        </div>
      </div> 

      <div className="footer">
        <div className="f_list">
          {footerSections.map((section) => (
            <div key={section.id} className="list-section">
              <h3
                className="footer-heading"
                onClick={() => toggleDropdown(section.id)}
              >
                {section.heading}
                <span
                  className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                >
                  &#8595;
                </span>
              </h3>
              <div
                className={`resource-list ${openSections[section.id] ? 'show' : ''
                  }`}
              >
                <ul>
                  {section.links.map((link, index) => (
                    <li key={index}>
                      {link.to.startsWith('/') ? (
                        <Link to={link.to}>{link.text}</Link>
                      ) : (
                        <a href={link.to}>{link.text}</a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div> 

        <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                    {cookieText.listItems.map((item, index) => (
                                        <li key={index} className="div-2-cookies-list-text">
                                            <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                        </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                       {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                    {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}


    </div>
  );
}

export default ContactPage;
