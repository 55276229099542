import React, { useState } from "react";
import { Link } from 'react-router-dom';
import {footerSections } from './Content/eng_content'; 
import{descriptions} from './Content/eng_Privacy_Content'
import "./Privacy.css";

const Privacy = () => {


    const [openSections, setOpenSections] = useState({});
    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const [selectedItem, setSelectedItem] = useState("Privacy Policies");

    const handleClick = (item) => {
        setSelectedItem(item);
    };

    return (
        <div className="privacy-page">
            {/* Image Section */}
            <div className="privacy-img1"></div>

            {/* Header Section */}
            <div className="header-div">
            <Link className="Link-of-privacy" to="/">
            <span>
                Lattice 
                <span className="arrow-privacypage">
                <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 4l4 4-4 4" stroke="#161513" strokeWidth="1.5" fill="none"/>
                </svg>
                </span>
            </span>
            </Link>


            <h1 className="header-text">
                {selectedItem === "Privacy Policies"
                    ? "Privacy @ Lattice"
                    : `${selectedItem}`}
                <span
                    style={{
                        display: "block",
                        width: "40px",
                        height: "4px",
                        background: "#f0cc71",
                        marginBottom: "2vh",
                    }}
                ></span>
            </h1>


            </div>

            {/* Background Decoration */}
            <div className="long-short-bg"></div>

            {/* Navigation List */}
            <div className="dynamic-div">
                {Object.keys(descriptions).map((item) => (
                    <div
                        key={item}
                        className={`privacy-list ${selectedItem === item ? "active" : ""}`}
                        onClick={() => handleClick(item)}
                    >
                        {item}
                    </div>
                ))}
            </div>

            {/* Description Section */}
            <div className="dynamic-description-div">
                <div
                    dangerouslySetInnerHTML={{ __html: descriptions[selectedItem] }}
                ></div>
            </div>
      
            <div className="footer">
            <div className="f_list">
                {footerSections.map((section) => (
                    <div key={section.id} className="list-section">
                        <h3
                            className="footer-heading"
                            onClick={() => toggleDropdown(section.id)}
                        >
                            {section.heading}
                            <span
                                className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                            >
                                &#8595;
                            </span>
                        </h3>
                        <div
                            className={`resource-list ${
                                openSections[section.id] ? 'show' : ''
                            }`}
                        >
                            <ul>
                                {section.links.map((link, index) => (
                                    <li key={index}>
                                        {link.to.startsWith('/') ? (
                                            <Link to={link.to}>{link.text}</Link>
                                        ) : (
                                            <a href={link.to}>{link.text}</a>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
           <div className="footer-bottom">
           
                               <span className="footer-separator"> © 2024 Lattice</span> |
           
                               <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
           
                               <span className="footer-separator"> Cookie Preferences  </span>
           
                               <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
           
                               <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                           </div>
        </div>




        </div>
        


    );
};

export default Privacy;
