import React, { useState, useRef } from 'react';
import img1 from '../image/cloud/yt-video-thumbnail-tRnXMKJWqqc.jpg'
import "./Healthcare.css"
import img from '../image/rc30v0x1-providers.avif'
import img2 from '../image/rc30v0x1-payers.avif'
import img3 from '../image/rc30v0x1-publichealth.avif'
import img4 from '../image/rc30v0x1-lifesciences.avif'

import svg1 from '../image/deliveroo-logo3.png'
import svg2 from '../image/AlbaniaTech-logo.svg'
import svg4 from '../image/german-federal-ministry-logo.svg'
import svg5 from '../image/Four_Seasons_logo3.png'
import svg6 from '../image/The_Related_Companies-Logo.svg'
import svg7 from '../image/imperial-college-london-logo3.svg'

import arrow from '../image/arrow-of-industry-page.svg'
import { fungiblePrivate } from '../Content/English-Content/FungiblePrivate';
import { useChatWidget } from "../ChatWidgetContext";
import { financialServices } from '../Content/English-Content/industry/financial-services';



export default function FinancialServices() {

     const { toggleWidget } = useChatWidget();


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };
   


    const [activeSection, setActiveSection] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (section, index) => {
        if (activeSection === section && activeIndex === index) {
            setActiveSection(null);
            setActiveIndex(null);
        } else {
            setActiveSection(section);
            setActiveIndex(index);
        }
    };

    const renderContent = () => {
        if (activeSection === "first") {
            switch (activeIndex) {
                case 0:
                   return (
                   
                                           <div className="hidden-para-div-one-8-div-industry">
                   
                                               <div className="hidden-dic-child-of-8-div-industry">
                                                   <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard1Heading1}</h1>
                                                   <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard1Para1}</p>
                                               </div>
                   
                                               <div className="hidden-dic-child-of-8-div-industry">
                                                   <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard1Heading2}</h1>
                                                   <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard1Para2}</p>
                                               </div>
                   
                                               <div className="hidden-dic-child-of-8-div-industry">
                                                   <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard1Heading3}</h1>
                                                   <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard1Para3}</p>
                                               </div>
                   
                                               <div className="hidden-dic-child-of-8-div-industry">
                                                   <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard1Heading4}</h1>
                                                   <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard1Para4}</p>
                                               </div>
                   
                                           </div>
                   
                   
                                       );
                case 1:
                   return (
                   
                                           <div className="hidden-para-div-one-8-div-industry">
                   
                                               <div className="hidden-dic-child-of-8-div-industry">
                                                   <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard2Heading1}</h1>
                                                   <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard2Para1}</p>
                                               </div>
                   
                                               <div className="hidden-dic-child-of-8-div-industry">
                                                   <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard2Heading2}</h1>
                                                   <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard2Para2}</p>
                                               </div>
                   
                                               <div className="hidden-dic-child-of-8-div-industry">
                                                   <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard2Heading3}</h1>
                                                   <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard2Para3}</p>
                                               </div>
                   
                   
                                           </div>
                   
                   
                                       );
                case 2:
                    return (

                        <div className="hidden-para-div-one-8-div-industry">

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard3Heading1}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard3Para1}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard3Heading2}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard3Para2}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard3Heading3}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard3Para3}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard3Heading4}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard3Para4}</p>
                            </div>

                        </div>


                    );
                default:
                    return null;
            }

        } else if (activeSection === "second") {
            switch (activeIndex) {
                case 0:
                    return (

                        <div className="hidden-para-div-one-8-div-industry">

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard4Heading1}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard4Para1}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard4Heading2}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard4Para2}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard4Heading3}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard4Para3}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard4Heading4}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard4Para4}</p>
                            </div>

                        </div>


                    );
                case 1:
                    return (

                        <div className="hidden-para-div-one-8-div-industry">

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard5Heading1}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard5Para1}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard5Heading2}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard5Para2}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard5Heading3}
                                </h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard5Para3}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard5Heading4}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard5Para4}</p>
                            </div>

                        </div>


                    );
                case 2:
                    return (

                        <div className="hidden-para-div-one-8-div-industry">

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard6Heading1}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard6Para1}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard6Heading2}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard6Para2}</p>
                            </div>

                            <div className="hidden-dic-child-of-8-div-industry">
                                <h1 className="heading-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard6Heading3}</h1>
                                <p className="para-1-of-hidden-child-1-8-div-industry">{financialServices.thirdSectionCard6Para3}</p>
                            </div>

                        </div>


                    );
                default:
                    return null;
            }
        }
        return null;
    };



    return (


        <>



            <div className="product-infra-compute-first-div">



                <h1 className="industry-health-care-first-div-heading">

                {financialServices.firstSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                    <p className="industry-health-care--first-div-paragraph">{financialServices.firstSectionPara}</p>

                    <button className="product-infra-compute-first-div-btn-1"><a href="/create-account" style={{ color: "inherit", textDecoration: "none" }}>{financialServices.firstSectionButton}</a></button>

                    

                </h1>


            </div>

            {/* 2-div */}

            <div className="last-flat-img-industry"></div>



            {/* 3-div */}


            <div className="product-infra-compute-third-div">

                <h1 className="product-infra-compute-third-div-heading">{financialServices.secondSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>

                <div className="product-infra-compute-third-div-cards-wrapper">

                    <div className="product-infra-compute-third-div-card-1">

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {financialServices.secondSectionSide1Heading}
                            </h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{financialServices.secondSectionSide1Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {financialServices.secondSectionSide2Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{financialServices.secondSectionSide2Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">
                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                            {financialServices.secondSectionSide3Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{financialServices.secondSectionSide3Para}</p>

                        </div>

                    </div>

                </div>

            </div>



            {/* 8-div-of-industry */}

            <div className="dynamic-list-of-industry">
                <h1 className="product-infra-compute-third-div-heading">{financialServices.thirdSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>

                <div className="fisrt-child-of-8-div-industry">
                    {[financialServices.thirdSectionButton1, financialServices.thirdSectionButton2, financialServices.thirdSectionButton3].map((item, index) => (
                        <div
                            key={index}
                            className={`list-item-1-of-8-div-industry ${activeSection === "first" && activeIndex === index ? "active" : ""
                                }`}
                            onClick={() => handleItemClick("first", index)}
                        >
                            <h6 className="list-item-one-heading-8-div-industry">{item}</h6>
                            <img
                                src={arrow}
                                alt="arrow"
                                className={`list-item-one-img-8-div-industry ${activeSection === "first" && activeIndex === index ? "rotate" : ""
                                    }`}
                            />
                        </div>
                    ))}
                </div>

                {activeSection === "first" && activeIndex !== null && (
                    <div className="hidden-div-of-child-section-of-8-div-industry">{renderContent()}</div>
                )}



                <div className="fisrt-child-of-8-div">
                    <div className="fisrt-child-of-8-div-industry">
                        {[financialServices.thirdSectionButton4, financialServices.thirdSectionButton5, financialServices.thirdSectionButton6].map((item, index) => (
                            <div
                                key={index}
                                className={`list-item-1-of-8-div-industry ${activeSection === "second" && activeIndex === index ? "active" : ""
                                    }`}
                                onClick={() => handleItemClick("second", index)}
                            >
                                <h6 className="list-item-one-heading-8-div-industry">{item}</h6>
                                <img
                                    src={arrow}
                                    alt="arrow"
                                    className={`list-item-one-img-8-div-industry ${activeSection === "second" && activeIndex === index ? "rotate" : ""
                                        }`}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {activeSection === "second" && activeIndex !== null && (
                    <div className="hidden-div-of-child-section-of-8-div-industry">{renderContent()}</div>
                )}

            </div>


            {/* div-5 */}



            <div className="product-infra-div-seven">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="become-partners-list-1-sec-last-div-heading">
                        {financialServices.fifthSectionHeading}
                    </h1>

                    <a href='/customer-story' className="product-infra-div-six-link-of-txt-wrapper">{financialServices.fifthSectionlink}</a>

                </div>

                <div className="product-infra-div-seven-img-wrapper">

                    <img src={svg1} className="product-infra-div-seven-img-1"></img>

                    <img src={svg2} className="product-infra-div-seven-img-1"></img>

                    <img src={svg4} className="product-infra-div-seven-img-1"></img>

                    <img src={svg5} className="product-infra-div-seven-img-1"></img>

                    <img src={svg6} className="product-infra-div-seven-img-1"></img>

                    <img src={svg7} className="product-infra-div-seven-img-1"></img>


                </div>

            </div>


            {/* div-6 */}

            <div className="industry-div-6-all-wrapper">
            
                            <h1 className="become-partners-list-1-sec-last-div-heading">
                            {financialServices.sixthSectionHeading}
                            </h1>
            
                            <div className="childs-of-industry-div-6">
            
                                <div className="first-child-of-industry-6-div">
                                    <p className="para-one-of-6-div-industry">{financialServices.sixthSectionSide1Heading}</p>
            
                                    <p className="para-two-of-6-div-industry">{financialServices.sixthSectionSide1Para}</p>
            
                                </div>
            
                                <div className="sec-child-of-industry-6-div">
                                    <p className="para-one-of-6-div-industry">{financialServices.sixthSectionSide2Heading}</p>
            
                                    <p className="para-two-of-6-div-industry">{financialServices.sixthSectionSide2Para}</p>
            
                                </div>
            
                            </div>
            
                        </div>


            {/* div-7 */}

             <div class="mysql-section">
                            <h2 class="mysql-heading">{financialServices.seventhSectionHeading}</h2>
                            <div class="mysql-cards">
                                <div class="mysql-card">
                                    <div class="card-top">
                                        <i class="icon-download"></i>
                                        <h3>{financialServices.seventhSectionSide1Heading}</h3>
                                    </div>
                                    <div class="card-bottom">
                                        <p>{financialServices.seventhSectionSide1Para}</p>
                                        <a href="/create-account" style={{ color:"inherit" , textDecoration:"none"}}><button>Subscribe now</button></a>
                                    </div>
                                </div>
                                <div class="mysql-card">
                                    <div class="card-top">
                                        <i class="icon-subscription"></i>
                                        <h3>{financialServices.seventhSectionSide2Heading}</h3>
                                    </div>
                                    <div class="card-bottom">
                                        <p>{financialServices.seventhSectionSide2Para}</p>
                                        <button>Get started</button>
                                    </div>
                                </div>
                                <div class="mysql-card">
                                    <div class="card-top">
                                        <i class="icon-contact"></i>
                                        <h3>{financialServices.seventhSectionSide3Heading}</h3>
                                    </div>
                                    <div class="card-bottom">
                                        <p>{financialServices.seventhSectionSide3Para}</p>
                                        <button onClick={toggleWidget} >Get in touch</button>
                                    </div>
                                </div>
                            </div>
                        </div>


            {/* <div className="last-div-wrapper-of-industry">

                <h1 className="become-partners-list-1-sec-last-div-heading">
                    Get started with Lattice Health
                </h1>

                <div className="childs-of-7-div-industry">

                    <div className="first-child-of-7-div-industry">
                        <div className="fist-child-7-div-section-1-industry"></div>

                        <div className="fist-child-7-div-section-2-industry">
                            <p className="para-1-of-seven-div-industry">Talk to a team member about Lattice solutions for healthcare.</p>

                            <button className="contact-btn-2">Contact Lattice Health today</button>
                        </div>
                    </div>

                    <div className="first-child-of-7-div-industry">
                        <div className="fist-child-7-div-section-1-industry"></div>

                        <div className="fist-child-7-div-section-2-industry">
                            <p className="para-1-of-seven-div-industry">Customers can contact our support team 24/7 for help.</p>

                            <button className="contact-btn-2">Contact us for help</button>
                        </div>
                    </div>

                </div>

            </div> */}





        </>


    )
}
