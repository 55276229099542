export const healthCare = {

    firstSectionHeading: "Lattice Health—Reimagine the future of health",
    firstSectionPara: "Connected technologies and unified data empower individuals and enable the health ecosystem to accelerate innovation and influence health outcomes. Lattice Health is building an open healthcare platform with intelligent tools for data-driven, human-centric healthcare experiences to connect consumers, healthcare providers, payers, and public health and life sciences organizations.",
    firstSectionButton: "Connect to Healthcare",
    firstSectionImageDescription: "Watch the video: Lattice strives to improve healthcare for all (1:56)",

    secondSectionHeading: "How Lattice Health can help",
    secondSectionSide1Heading: "Improve health globally",
    secondSectionSide1Para: "With the largest global EHR market share, we are able to bring data together to enable clinicians, patients, and researchers to take meaningful action, advance health, and work to improve outcomes worldwide.",

    secondSectionSide2Heading: "Streamline operations",
    secondSectionSide2Para: "Rated the largest revenue cycle management (RCM) leader by IDC MarketScape, we provide timely, predictive, and actionable health insights to automate processes, optimize resources, and drive efficiencies.",

    secondSectionSide3Heading: "Unleash innovation",
    secondSectionSide3Para: "Accelerate innovation, benefit from flexible infrastructure and platform resources, and drive clinical intelligence through our open, extensible ecosystem of partners and technologies.",

    thirdSectionHeading: "Lattice Health products",

    thirdSectionCard1Heading1: "Clinical suite",
    thirdSectionCard1Para1: "Help improve the quality of care and the patient experience while enhancing clinical and operational efficiency with Lattice Health’s clinical suite. Our solutions help care teams across the continuum document and access critical patient data, refine workflows, and support patient safety initiatives.",

    thirdSectionCard1Heading2: "Service lines and departments",
    thirdSectionCard1Para2: "Organize care delivery around your service lines to help drive revenue, improve quality, and reduce costs. Our service lines and departments portfolio of clinical solutions can help you provide knowledge-driven care—informed by electronic patient health records and unique workflows that support service lines and departments throughout the care continuum.",

    thirdSectionCard1Heading3: "Interoperability",
    thirdSectionCard1Para3: "Facilitate the flow of patient data across provider, vendor, geographical, and technological boundaries with interoperable systems. Provide practitioners with access to a holistic view of each patient’s record—and with it, the potential to coordinate care across health systems, make more-informed care decisions, and gain operational efficiencies.",

    thirdSectionCard1Heading4: "Continuum of care",
    thirdSectionCard1Para4: "Help your patients achieve the best possible health outcomes by coordinating, managing, and delivering high-quality care across the continuum—from rehabilitation, home health, and long-term care to behavioral health. Our continuum of care solutions help clinicians find the right venue for patients to get the continued care they need while enabling the exchange of information during transitions of care.",

    thirdSectionCard2Heading1: "Clinical operations",
    thirdSectionCard2Para1: "Transform your clinical operations by empowering your clinicians with real-time health system tools and techniques that help them overcome today’s challenges and provide high-quality care. A real-time health system strategy helps your organization achieve predictable excellence in clinical, operational, and financial outcomes by using near real-time data from many sources.",

    thirdSectionCard2Heading2: "Revenue cycle",
    thirdSectionCard2Para2: "Clinically driven and patient-focused—that’s the foundation of Lattice Health’s revenue cycle management solutions. A clinically driven revenue cycle takes information captured by clinicians during care and uses it to drive financial outcomes.",

    thirdSectionCard2Heading3: "Reporting and analytics",
    thirdSectionCard2Para3: "Transform your organization’s data into usable, actionable information with Lattice’s healthcare analytics solutions. Realize the true value of your data by helping executives, clinicians, analysts, and staff spend less time searching for and preparing data and more time acting on it.",

    thirdSectionCard3Heading1: "Population health",
    thirdSectionCard3Para1: "Move beyond individual care. With our population health solutions, you can expand your organization’s care focus by magnifying efforts across entire populations and emphasizing chronic condition management and prevention. We can help you provide coordinated care beyond the hospital to promote healthier populations.",


    thirdSectionCard4Heading1: "Consumer experience",
    thirdSectionCard4Para1: "Meet rising consumer expectations and place the person at the center of their healthcare journey with Lattice Health’s consumer experience solutions, which include digital front door and virtual care capabilities.",


    thirdSectionCard5Heading1: "Security",
    thirdSectionCard5Para1: "Maintain a strong security posture for your enterprise with data security solutions that help you manage clinical identity, regulatory compliance, and security auditing and maintain business continuity, even during downtimes.",

    thirdSectionCard5Heading2: "Services",
    thirdSectionCard5Para2: "Lattice Health’s professional services provide in-depth analytics to help determine appropriate performance improvement plans, outline governance, and increase clinician satisfaction and efficiency in the EHR.",

    thirdSectionCard5Heading3: "Regulatory",
    thirdSectionCard5Para3: "Constantly changing federal and state regulations create complex challenges for many US healthcare providers. Through our regulatory compliance and regulatory consulting services, we can help your organization stay current with regulatory programs, create a regulatory steering committee, and track performance.",

    thirdSectionCard6Heading1: "Healthcare ERP—finance and operations",
    thirdSectionCard6Para1: "Gain a single view of financial data across your health delivery system. Plan more effectively, reduce the cost of care, and build a more responsive supply chain. Implement new business models, such as value-based healthcare, more easily and adapt faster when your business environment changes.",

    thirdSectionCard6Heading2: "Healthcare human capital management (HCM)",
    thirdSectionCard6Para2: "Create an employee experience that reflects your values, promotes diversity, equity, and inclusion, and helps clinical and nonclinical staff grow in their careers.",

    thirdSectionCard6Heading3: "Customer experience",
    thirdSectionCard6Para3: "Deliver personalized experiences across digital marketing channels to interact with patients when, where, and how they prefer, helping them stay engaged in their health.",

    thirdSectionCard6Heading4: "Healthcare cloud infrastructure",
    thirdSectionCard6Para4: "Lattice Cloud Infrastructure (OCI) is built to run every healthcare workload—from legacy application systems to data science–driven and modern machine learning–enabled services—to help deliver more-informed care choices and an enhanced, patient-focused healthcare experience.",

    thirdSectionCard6Heading5: "NetSuite for healthcare and life sciences",
    thirdSectionCard6Para5: "NetSuite's cloud-based business management suite, used by more than 34,000 customers, enables healthcare and life sciences organizations to improve visibility, minimize complexity, and remain agile in the face of massive change.",

    thirdSectionButton1: "Clinical applications",
    thirdSectionButton2: "Clinical and financial operations",
    thirdSectionButton3: "Population health",
    thirdSectionButton4: "Consumer experience",
    thirdSectionButton5: "Services and support",
    thirdSectionButton6: "Enterprise solutions",

    fourthSectionHeading: "People and organizations we serve",

    fourthSectionButton1: "Providers",
    fourthSectionButton2: "Private healthcare",
    fourthSectionButton3: "Public health",
    fourthSectionButton4: "Life sciences",

    fourthSectionPage1Heading: "Improve healthcare experiences",
    fourthSectionPage1Para: "Improve care decisions, support whole-person care, and promote better experiences for patients, clinicians, and staff with intuitive, intelligent provider solutions.",

    fourthSectionPage2Heading: "Simplify billing processes",
    fourthSectionPage2Para: "Streamline policy administration, personalize member experiences, help drive billing accuracy, and speed up claims processing with a complete core administration platform for payers.",

    fourthSectionPage3Heading: "Promote healthier populations",
    fourthSectionPage3Para: "Use technology to generate scalable, global insights to advance population health initiatives, promote healthy lifestyles, and address factors that can cause diseases.",

    fourthSectionPage4Heading: "Accelerate breakthrough clinical research",
    fourthSectionPage4Para: "Unify data from clinical trials, streamline and automate safety case management, and strengthen business operations with solutions designed to support life sciences innovation and research.",

    fifthSectionHeading: "See how healthcare organizations partner with Lattice",
    fifthSectionlink: "See all customer stories",

    sixthSectionHeading: "Partnering to transform health",

    sixthSectionSide1Heading: "Reinventing healthcare—from access to outcomes",
    sixthSectionSide1Para: "Accenture and Lattice are working together to transform healthcare into a modern, connected health ecosystem, helping support improved access through a holistic, human-centric experience with the goal of driving better outcomes for all.",

    sixthSectionSide2Heading: "Establishing a modern operating system for healthcare",
    sixthSectionSide2Para: "Deloitte and Lattice bring the experience and technology that will help connect the healthcare ecosystem and create a modern, human-centric health operating system, which can lead to healthier, happier, and more productive communities.",

    seventhSectionHeading: "Get started with Lattice Health",

    seventhSectionSide1Para: "Talk to a team member about Lattice solutions for healthcare.",
    seventhSectionSide1Button: "Contact Lattice Health today",

    seventhSectionSide2Para: "Customers can contact our support team 24/7 for help.",
    seventhSectionSide2Button: "Contact us for help",
    

}