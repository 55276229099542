export const customerReview = {
    firstSectionHeading: "Customer Reviews",
    firstSectionPara: "At Lattice, we believe in our advocates and the value of customer voices. We encourage you to share your experiences and what you’ve learned on your journey with us. Educate your peers through your expertise and let them hear from those who they trust the most—people like you with direct, relatable, relevant experience. To leave a review, click on one or more of the links below.",
    
    secondSectionHeading: "Leave a review for Lattice",
    secondSectionCard1Heading: "Gartner Peer Insights*",
    secondSectionCard1Para: "Make a big impact with Gartner Peer Insights reviews. These anonymous reviews complement Gartner research with firsthand experiences from organizations that have recently implemented new software and solutions. It should take about 10 minutes to fill out the form.",
    secondSectionCard1Button: "Leave a review",

    secondSectionCard2Heading: "TrustRadius*",
    secondSectionCard2Para: "Showcase your skills and help your peers with a TrustRadius review. TrustRadius allows for depth and detail, helping your review to stand out and provide the guidance that other buyers need. It should take about 10 minutes to fill out the form.",
    secondSectionCard2Button: "Leave a review",

    secondSectionCard3Heading: "G2",
    secondSectionCard3Para: "Build your digital brand. With more than four million views a month, G2 helps you share your experiences with a broad audience, quickly. It should take about 10 minutes to fill out the form.",
    secondSectionCard3Button: "Leave a review",

    thirdSectionPara: "* The GARTNER PEER INSIGHTS logo is a trademark and service mark of Gartner, Inc. and/or its affiliates and is used herein with permission. All rights reserved. Gartner Peer Insights reviews constitute the subjective opinions of individual end users based on their own experiences and do not represent the views of Gartner or its affiliates.",


}