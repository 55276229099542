export const walletServices = {
    // 1) First Section
    firstSectionHeading: "Lattice Wallet-as-a-Service Plugin",
    firstSectionPara:
      "The Lattice Wallet-as-a-Service Plugin makes it easy for developers to create, manage, and transact across private or EVM-based blockchains. This fully managed solution ensures secure, low-latency transactions with multi-sig capabilities, seamless cross-chain interoperability, and flexible pay-per-use pricing. Enjoy streamlined wallet deployment, robust on-chain governance, and compatibility with on-premises or third-party wallet solutions.",
  
    // 2) Second Section
    secondSectionHeading:
      "We’ve distilled our core strategies for delivering secure, scalable wallet services—so you can focus on innovation.",
    secondSectionButton: "Get the details",
  
    // 3) Third Section
    thirdSectionSide1Heading: "Announcing Cross-Chain Bridge Integration",
    thirdSectionSide1Para:
      "Access multiple blockchain networks with one interface. Seamlessly move assets and data between private chains and EVM-compatible environments for improved liquidity and broader utility.",
    thirdSectionSide1Button: "Read the blog",
  
    thirdSectionSide2Heading: "Get started with Lattice Wallet-as-a-Service",
    thirdSectionSide2Para:
      "Deploy wallets, manage permissions, and integrate cross-chain transactions with minimal overhead. No complicated infrastructure required.",
    thirdSectionSide2Button: "Get started now",
  
    // 4) Fourth Section
    fourthSectionHeading: "Wallet-as-a-Service features and benefits",
    fourthSectionButton1: "Simple development experience",
    fourthSectionButton2: "Cross-chain transaction support",
    fourthSectionButton3: "Fully managed plugin",
    fourthSectionButton4: "High performance at scale",
    fourthSectionButton5: "Cost-effective pricing",
    // fourthSectionButton6: "U.S. Federal Financials",
  
    // 4a) Fourth Section Page 1
    fourthSectionPage1cardheading1:
      "Multi-sig, cross-chain, or private-chain wallet strategies",
    fourthSectionPage1fastTrackList1:
      "Choose the wallet model that fits your use case—multi-sig, EVM-based, or private networks—accessed through intuitive APIs.",
    fourthSectionPage1cardheading2:
      "100% compatible with on-premises or third-party wallets",
    fourthSectionPage1fastTrackList2:
      "Run the same wallet configuration in Lattice Cloud, other clouds, or on-premises without vendor lock-in.",
    fourthSectionPage1cardheading3: "Modern development stacks",
    fourthSectionPage1fastTrackList3:
      "Leverage robust SDKs (Java, Python, Node.js, .NET, and Go) or RESTful APIs to integrate wallet operations seamlessly.",
    fourthSectionPage1cardheading4: "Integration with popular dev tools",
    fourthSectionPage1fastTrackList4:
      "Use Eclipse, IntelliJ, or VS Code plugins to streamline wallet queries, test transactions, and manage on-chain data.",
  
    learnMoreButton: "Learn More",
  
    // 4b) Fourth Section Page 2
    fourthSectionPage2cardheading1: "Cross-Chain Bridges",
    fourthSectionPage2fastTrackList1:
      "Enable multi-chain asset transfers and real-time synchronization between geographically or technologically distinct networks.",
    fourthSectionPage2cardheading2: "Scale multinational or multi-project usage",
    fourthSectionPage2fastTrackList2:
      "Offer low-latency local signing and asset movement regardless of the original chain or region.",
    fourthSectionPage2cardheading3: "Cost-effective approach",
    fourthSectionPage2fastTrackList3:
      "Save on cross-chain transaction fees with predictable pricing. Zero cost on initial migrations from existing wallets.",
    fourthSectionPage2cardheading4: "Minimal code changes",
    fourthSectionPage2fastTrackList4:
      "Existing dApps continue working uninterrupted after upgrading to cross-chain wallet features.",
  
    // 4c) Fourth Section Page 3
    fourthSectionPage3cardheading1: "Serverless",
    fourthSectionPage3fastTrackList1:
      "No need to specify servers or nodes—simply define transaction throughput and let us handle the scaling.",
    fourthSectionPage3cardheading2: "Dedicated environment",
    fourthSectionPage3fastTrackList2:
      "Ideal for enterprise-grade security, with automated backups, private chain isolation, and on-demand scaling.",
    fourthSectionPage3cardheading3: "Instant scaling",
    fourthSectionPage3fastTrackList3:
      "Accommodate millions of on-chain requests per second for high-volume dApps and tokenized platforms.",
    fourthSectionPage3cardheading4: "Auto-healing",
    fourthSectionPage3fastTrackList4:
      "Detects and routes around node or shard failures, ensuring uninterrupted wallet operations.",
    fourthSectionPage3fastTrackList5:
      "Protects transaction requests via predictive failover mechanisms for both private and EVM networks.",
  
    // 4d) Fourth Section Page 4
    fourthSectionPage4cardheading1:
      "Atomic transactions and deterministic finality",
    fourthSectionPage4fastTrackList1:
      "Enable multi-contract flows with consistent states, guaranteeing predictable results for critical on-chain operations.",
    fourthSectionPage4cardheading2: "Flexible security options",
    fourthSectionPage4fastTrackList2:
      "Tune each wallet’s security threshold—multi-sig confirmations, single-sig speed, or advanced sidechain protocols—based on your latency needs.",
  
    // 4e) Fourth Section Page 5
    fourthSectionPage5cardheading1: "Transparent, predictable pricing",
    fourthSectionPage5fastTrackList1:
      "A straightforward pricing model with no minimum commitments or hidden surcharges across regions.",
    fourthSectionPage5cardheading2: "Lower cost than AWS or other wallet providers",
    fourthSectionPage5fastTrackList2:
      "Lattice Wallet-as-a-Service can cost up to 72% less than comparable managed wallet solutions, delivering more value for your budget.",
  
    // 5) Fifth Section: Use Cases
    fifthSectionHeading: "Common Wallet-as-a-Service use cases",
    fifthSectionCard1Heading: "Personalized DeFi Experiences",
    fifthSectionCard1Para:
      "Empower each user with a dedicated wallet and unique feature sets, ensuring seamless onboarding and tailored DeFi interactions.",
    fifthSectionCard2Heading: "Digital Asset and NFT Management",
    fifthSectionCard2Para:
      "Quickly deploy marketplaces or token platforms that handle asset minting, transfers, and trades on multiple chains.",
    fifthSectionCard3Heading: "Mobile dApp Integrations",
    fifthSectionCard3Para:
      "Build mobile-friendly applications at consumer scale, enabling on-the-go transactions and wallet access.",
    fifthSectionCard4Heading: "Real-time Fraud Detection",
    fifthSectionCard4Para:
      "Identify suspicious activity instantly, blocking fraudulent transfers or wallet compromises before they finalize.",
    fifthSectionCard5Heading: "IoT and Edge Blockchain",
    fifthSectionCard5Para:
      "Connect IoT devices to private or EVM-based chains, managing high-volume micro-transactions with minimal overhead.",
  
    // 6) Seventh Section
    seventhSectionHeading: "Get started with Lattice Wallet-as-a-Service",
    seventhSectionBox1Heading: "Watch a tutorial",
    seventhSectionBox1Para:
      "A “Hello Wallet” tutorial demonstrating secure wallet creation and transaction signing with Java SDK.",
    seventhSectionBox1Button: "Experience “Hello Wallet”",
    seventhSectionBox2Heading: "On-Premises",
    seventhSectionBox2Para:
      "Integrate the Lattice Wallet Plugin into your self-hosted environment for total control over your on-chain operations.",
    seventhSectionBox2Button: "Add Wallet",
    seventhSectionBox3Heading: "Wallet community",
    seventhSectionBox3Para:
      "Join the discussions, ask questions, and learn best practices for Lattice’s Wallet-as-a-Service Plugin.",
    seventhSectionBox3Button: "Wallet forums",
  };