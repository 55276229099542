// about-content.js

export const aboutContent = {
  link: {
    text: "Lattice",
    path: "/",
    svgPath: "M8 4l4 4-4 4",
  },
  introHeading: "Empowering Blockchain Businesses Through Innovation",
  introDescription:
    "Since 2023, Lattice Cloud Infrastructure has been at the forefront of revolutionizing industries with cutting-edge blockchain technology and scalable cloud solutions. Our mission is to enable customers, partners, and communities to thrive in the era of digital transformation.",
  scale: {
    title: "Our Global Scale",
    points: [
      "US$100M in revenue in FY2024",
      "US$50M+ invested in R&D since FY2023",
      "120 employees across global offices",
      "70 customer support specialists fluent in 20+ languages",
      "240 consulting experts delivering exceptional services worldwide",
    ],
  },
  innovationHighlights: {
    title: "Innovation That Powers Possibilities",
    points: [
      "The world’s first autonomous cloud infrastructure tailored for blockchain solutions",
      "A comprehensive suite of blockchain-enabled cloud applications",
      "Largest Blockchain Healthcare implementation, serving healthcare ministries across Europe and Asia-Pacific",
      "Over 1000 developers and customers engaged in Lattice’s thriving community",
    ],
  },
  aboutSections: [
    {
      heading: <a className="about-links" href="/customer-story">Customer Success Stories</a>,
      description: "Explore how global businesses are leveraging Lattice to drive their success.",
    },
    {
      heading: <a className="about-links" href="/investor-relationship">Investor Relations</a>,
      description: "Access financial updates, earnings reports, and insights for investors.",
    },
    {
      heading: <a className="about-links" href="/leadership">Meet Our Leadership</a>,
      description: "Learn about the visionaries driving Lattice's innovation in blockchain and AI.",
    },
    {
      heading: <a className="about-links" href="/company-news">Latest News</a>,
      description: "Stay informed on our latest announcements, product launches, and industry updates.",
    },
    {
      heading: <a className="about-links" href="#">Corporate Responsibility</a>,
      description: "Learn about our sustainability initiatives, philanthropic efforts, and educational programs.",
    },
    {
      heading: <a className="about-links" href="/careers">Join Our Team</a>,
      description: "Be part of the team shaping the future of blockchain and cloud innovation.",
    },
  ],
  partnersHeading: "Transform Your Business with Lattice Blockchain Solutions",
};
