import React, { useState } from "react";
import "./career-page.css";
import { footerSections, cookieText } from "../Content/eng_content";
import { Link } from "react-router-dom";
import VideoImage from "../image/career-video-image.webp";
import PlayIcon from "../image/play-icon.svg";
import OpportunityImageOne from "../image/career-opportunity-01.avif";
import OpportunityImageTwo from "../image/career-opportunity-02.webp";
import OpportunityImageThree from "../image/career-opportunity-03.avif";
import OpportunityImageFour from "../image/career-opportunity-04.avif";
import Facebook from "../image/career_facebook.png";
import Youtube from "../image/career_youtube.png";
import Twitter from "../image/career_twitter.png";
import Instagram from "../image/career_instagram.png";
import Linkedin from "../image/career_linkedin.png";
import CareerTrophy from "../image/career-trophy.png";
import Applyicon from "../image/apply_icon.png";
import UserNetworkIcon from "../image/user_network_icon.png";
import BlogImage1 from "../image/career-blog-01.png";
import BlogImage2 from "../image/career-blog-02.png";
import BlogImage3 from "../image/career-blog-03.png";
import Latticelogo from '../image/lattice-logo-img.svg';
import { navbarCareersPage } from "../Content/English-Content/NavbarCareers";

function CareerPage() {
  const [openSections, setOpenSections] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);

  const careerData = [
    {
      title: navbarCareersPage.thirdSectionArticle1Heading,
      description:
        navbarCareersPage.thirdSectionArticle1Para,
    },
    {
      title: navbarCareersPage.thirdSectionArticle2Heading,
      description:
      navbarCareersPage.thirdSectionArticle2Para,
    },
    {
      title: navbarCareersPage.thirdSectionArticle3Heading,
      description:
      navbarCareersPage.thirdSectionArticle3Para,
    },
    {
      title: navbarCareersPage.thirdSectionArticle4Heading,
      description:
      navbarCareersPage.thirdSectionArticle4Para,
    },
  ];

  const opportunities = [
    {
      title: navbarCareersPage.fourthSectionArticle1Heading,
      description:
        navbarCareersPage.fourthSectionArticle1Para,
      buttonText: navbarCareersPage.applyNowButton,
      image: OpportunityImageOne,
    },
    {
      title: navbarCareersPage.fourthSectionArticle2Heading,
      description:
      navbarCareersPage.fourthSectionArticle2Para,
      buttonText: navbarCareersPage.applyNowButton,
      image: OpportunityImageTwo,
    },
    {
      title: navbarCareersPage.fourthSectionArticle3Heading,
      description:
      navbarCareersPage.fourthSectionArticle3Para,
      buttonText: navbarCareersPage.applyNowButton,
      image: OpportunityImageThree,
    },
    {
      title: navbarCareersPage.fourthSectionArticle4Heading,
      description:
      navbarCareersPage.fourthSectionArticle4Para,
      buttonText: navbarCareersPage.applyNowButton,
      image: OpportunityImageFour,
    },
  ];

  const awards = [
    {
      title: navbarCareersPage.fifthSectionArticle1Title,
      organization: navbarCareersPage.fifthSectionArticle1Organization,
      year: navbarCareersPage.fifthSectionArticle1Year,
    },
    {
      title: navbarCareersPage.fifthSectionArticle2Title,
      organization: navbarCareersPage.fifthSectionArticle2Organization,
      year: navbarCareersPage.fifthSectionArticle2Year,
    },
    {
      title: navbarCareersPage.fifthSectionArticle3Title,
      organization: navbarCareersPage.fifthSectionArticle3Organization,
      year: navbarCareersPage.fifthSectionArticle3Year,
    },
    {
      title: navbarCareersPage.fifthSectionArticle4Title,
      organization: navbarCareersPage.fifthSectionArticle4Organization,
      year: navbarCareersPage.fifthSectionArticle4Year,
    },
    {
      title: navbarCareersPage.fifthSectionArticle5Title,
      organization: navbarCareersPage.fifthSectionArticle5Organization,
      year: navbarCareersPage.fifthSectionArticle5Year,
    },
    {
      title: navbarCareersPage.fifthSectionArticle6Title,
      subtitle: navbarCareersPage.fifthSectionArticle6Organization,
      year: navbarCareersPage.fifthSectionArticle6Year,
    },
  ];

  const stories = [
    {
      id: 1,
      quote:
        navbarCareersPage.sixthSectionStory1Quote,
      heading: navbarCareersPage.sixthSectionStory1Heading,
      description:
        navbarCareersPage.sixthSectionStory1Para,
      image: BlogImage1,
      link: "#",
      linkText: navbarCareersPage.sixthSectionStory1Button,
    },
    {
      id: 2,
      quote:
      navbarCareersPage.sixthSectionStory2Quote,
      heading: navbarCareersPage.sixthSectionStory2Heading,
      description:
      navbarCareersPage.sixthSectionStory2Para,
      image: BlogImage2,
      link: "#",
      linkText: navbarCareersPage.sixthSectionStory2Button,
    },
    {
      id: 3,
      quote:
      navbarCareersPage.sixthSectionStory3Quote,
      heading:
      navbarCareersPage.sixthSectionStory3Heading,
      description:
      navbarCareersPage.sixthSectionStory3Para,
      image: BlogImage3,
      link: "#",
      linkText: navbarCareersPage.sixthSectionStory3Button,
    },
  ];

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const toggleDropdown = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

   // cookies
  
  
      const [showCookieModal, setShowCookieModal] = useState(false);
      const [isLoading, setIsLoading] = useState(true);
  
      const handleCookieClick = () => {
          setShowCookieModal(true);
          setIsLoading(true);
  
  
          setTimeout(() => {
              setIsLoading(false);
          }, 500);
      };
  
      const handleAcceptAll = () => {
          console.log("Cookies Accepted");
          setShowCookieModal(false);
      };
  
      const handleDenyAll = () => {
          console.log("Cookies Denied");
          setShowCookieModal(false);
      };

  return (
    <>
      <section className="nav-career-banner-wrapper">
        <div className="nav-career-banner"></div>
        <div className="nav-career-content">
          <h2 className="nav-lattice-career">{navbarCareersPage.firstSectionLinkText}</h2>
          <h1 className="nav-career-heading">{navbarCareersPage.firstSectionHeading}</h1>
          <p className="nav-career-para">
            {navbarCareersPage.firstSectionPara}
          </p>
          <button className="nav-career-btn">{navbarCareersPage.firstSectionButton}</button>
        </div>
      </section>

      <section className="nav-career-video-wrapper">
        <div className="">
          <div className="nav-career-video-container position-relative">
            {!isPlaying && (
              <>
                <img
                  src={VideoImage}
                  alt="Video Thumbnail"
                  className="img-fluid"
                />
                <div
                  className="nav-career-play-button position-absolute"
                  onClick={handlePlay}
                >
                  <img src={PlayIcon} alt="Play Button" />
                </div>
              </>
            )}
            {isPlaying && (
              <iframe
                src="https://www.youtube.com/embed/Tk1t3WKK-ZY?autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-100"
              ></iframe>
            )}
          </div>
        </div>
        <div className="nav-career-content-right">
          <h2>{navbarCareersPage.secondSectionHeading}</h2>
          <p>
            {navbarCareersPage.secondSectionPara}
          </p>
          <a
            href="#"
            className="btn btn-link text-primary nav-career-job-search"
          >
            {navbarCareersPage.secondSectionButton}
          </a>
        </div>
      </section>

      <section className="nav-career-section">
        <div className="nav-career-grow">
          <h2 className="nav-career-title">{navbarCareersPage.thirdSectionHeading}</h2>
          <div className="nav-career-cards">
            {careerData.map((item, index) => (
              <div key={index}>
                <div className="nav-career-slate">
                  <h2 className="nav-card-title">{item.title}</h2>
                  <p className="nav-card-description">{item.description}</p>
                  <a href="#" className="learn-more">
                    {navbarCareersPage.learnMoreButton}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="nav-career-opportunity-wrapper mt-5">
        <h2 className="text-start mb-4 nav-career-featured-opportunity">
          {navbarCareersPage.fourthSectionHeading}
        </h2>
        <div className="nav-career-opportunity-box">
          {opportunities.map((opportunity, index) => (
            <div className="mb-4" key={index}>
              <div className="d-flex align-items-center p-3 card-style">
                <img
                  src={opportunity.image}
                  alt={opportunity.title}
                  className="opportunity-image"
                />
                <div className="ms-3 nav-career-opportunity-gap">
                  <h5 className="mb-2 nav-career-opportunity-title">
                    {opportunity.title}
                  </h5>
                  <p className="mb-3 ">{opportunity.description}</p>
                  <a
                    href="#"
                    className="btn btn-link text-primary nav-career-opportunity-learn"
                  >
                    {opportunity.buttonText}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="nav-career-award-wrapper">
        <h2 className="nav-career-place mb-4">A place you'll love to work</h2>
        <p className="lead mb-5 text-one-rem">
          We're proud to be a company that makes diversity and inclusion a
          priority. We listen closely to what you have to say—and we think
          that's what makes us an employer of choice.
        </p>

        <div className="career-awards-box g-4">
          {awards.map((award, index) => (
            <div key={index} className="career-award">
              <div className="">
                <img
                  src={CareerTrophy}
                  alt="Trophy"
                  className="mb-3"
                  style={{ width: "32px", height: "32px" }}
                />
                <h3 className="career-award-title">{award.title}</h3>
                <div className="career-award-title">
                  {award.subtitle && <p className="mb-1">{award.subtitle}</p>}
                  <p className="mb-0">
                    {award.organization} | {award.year}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="nav-career-award-wrapper">
        <div className="story-blogs">
          <h2 className="nav-career-place mb-4">Career Blogs</h2>
          <a
            href="/company-blogs"
            className="btn btn-link text-primary nav-career-opportunity-learn"
          >
            Read more blogs
          </a>
        </div>
        <div className="story-section">
          {stories.map((story) => (
            <div className="story-card" key={story.id}>
              <div className="story-content">
                <div className="story-text">
                  <p className="story-quote">"{story.quote}"</p>
                </div>
                <div className="story-image">
                  <img src={story.image} alt={story.heading} />
                </div>
              </div>
              <div className="story-details">
                <div>
                <h3 className="story-heading">{story.heading}</h3>
                <p className="story-description">{story.description}</p>
                </div>
                <a href={story.link} className="read-story-link">
                  {story.linkText}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="nav-career-apply-wrapper">
        <div className="d-flex justify-content-start align-items-start gap-3 w-100 nav-career-apply-main">
          <div className="apply-box-container">
            <div className="golden-side">
              <img src={Applyicon} alt="Image" />
              <p className="apply-today">{navbarCareersPage.seventhSectionBox1Heading}</p>
            </div>
            <div className="white-side">
              <p className="text-line">
                {navbarCareersPage.seventhSectionBox1Para}
              </p>
              <button className="search-job-btn">{navbarCareersPage.seventhSectionBox1Button}</button>
            </div>
          </div>

          <div className="apply-box-container">
            <div className="golden-side">
              <img src={UserNetworkIcon} alt="Image" />
              <p className="apply-today">{navbarCareersPage.seventhSectionBox2Heading}</p>
            </div>
            <div className="white-side">
              <p className="text-line">
                {navbarCareersPage.seventhSectionBox2Para}
              </p>
              <button className="search-job-btn">{navbarCareersPage.seventhSectionBox2Button}</button>
            </div>
          </div>
        </div>
      </section>

      <section className="nav-career-social-links">
        <div className="nav-career-social-flex">
          <div className="career-connect">
            <p>{navbarCareersPage.seventhSectionConnect}</p>
          </div>
          <a href="">
            <div className="career-solcial-flex">
              <img
                src={Facebook}
                alt="facebook"
                className="nav-career-fb"
              ></img>
              <span>{navbarCareersPage.seventhSectionFacebook}</span>
            </div>
          </a>
          <a href="">
            <div className="career-solcial-flex">
              <img src={Twitter} alt="twitter" className="nav-career-x"></img>
              <span>{navbarCareersPage.seventhSectionTwitter}</span>
            </div>
          </a>
          <a href="">
            <div className="career-solcial-flex">
              <img
                src={Instagram}
                alt="instagram"
                className="nav-career-insta"
              ></img>
              <span>{navbarCareersPage.seventhSectionInstagram}</span>
            </div>
          </a>
          <a href="">
            <div className="career-solcial-flex">
              <img
                src={Linkedin}
                alt="linkedin"
                className="nav-career-fb"
              ></img>
              <span>{navbarCareersPage.seventhSectionLinkedin}</span>
            </div>
          </a>
          <a href="">
            <div className="career-solcial-flex">
              <img src={Youtube} alt="youtube" className="nav-career-fb"></img>
              <span>{navbarCareersPage.seventhSectionYoutube}</span>
            </div>
          </a>
        </div>
      </section>

      <div className="footer">
        <div className="f_list">
          {footerSections.map((section) => (
            <div key={section.id} className="list-section">
              <h3
                className="footer-heading"
                onClick={() => toggleDropdown(section.id)}
              >
                {section.heading}
                <span
                  className={`arrow ${
                    openSections[section.id] ? "rotate" : ""
                  }`}
                >
                  &#8595;
                </span>
              </h3>
              <div
                className={`resource-list ${
                  openSections[section.id] ? "show" : ""
                }`}
              >
                <ul>
                  {section.links.map((link, index) => (
                    <li key={index}>
                      {link.to.startsWith("/") ? (
                        <Link to={link.to}>{link.text}</Link>
                      ) : (
                        <a href={link.to}>{link.text}</a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="footer-bottom">
                              
                                                  <span className="footer-separator"> © 2024 Lattice</span> |
                              
                                                  <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
                              
                                                  <span className="footer-separator"
                                                      style={{ cursor: "pointer", color: "inherit" }}
                                                      onClick={handleCookieClick}> Cookie Preferences  </span>
                              
                                                  <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
                              
                                                  <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                                              </div>
                              
                                          </div>
                              
                              
                                          {showCookieModal && (
                                              <div
                                                  className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                                                      }`}
                                              >
                                                  <div className="cookie-modal">
                              
                                                      {isLoading ? (
                                                          <div className="loading-spinner">{cookieText.loading}</div>
                                                      ) : (
                                                          <div className="cookie-content">
                              
                                                              <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                                                  &times;
                                                              </button>
                                                              <div className="heading-of-cookies-with-logo">
                              
                                                                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>
                              
                                                                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>
                              
                                                              </div>
                              
                                                              <div className="div-2-heading-below-text-and-list">
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>
                              
                                                                  <ul>
                                                                  {cookieText.listItems.map((item, index) => (
                                                                      <li key={index} className="div-2-cookies-list-text">
                                                                          <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                                                      </li>
                                                                      ))}
                                                                  </ul>
                              
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>
                              
                                                              </div>
                              
                                                              <div className="button-group">
                                                                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                                                                     {cookieText.btn1}
                                                                  </button>
                                                                  <button className="btn deny-btn" onClick={handleDenyAll}>
                                                                  {cookieText.btn2}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          )}
    </>
  );
}

export default CareerPage;
