import React, { useState, useRef } from 'react';
import './become-partners.css'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import svg1 from '../image/svg/filter_list_24dp_E8EAED.svg'
import svg2 from '../image/The_Related_Companies-Logo.svg'
import svg3 from '../image/deliveroo-logo3.png'
import svg4 from '../image/imperial-college-london-logo3.svg'
import svg5 from '../image/Four_Seasons_logo3.png'
import svg6 from '../image/deliveroo-logo3.png'
import svg7 from '../image/Four_Seasons_logo3.png'
import svg8 from '../image/AlbaniaTech-logo.svg'
import svg9 from '../image/imperial-college-london-logo3.svg'
import svg10 from '../image/german-federal-ministry-logo.svg'
import svg11 from '../image/The_Related_Companies-Logo.svg'
import svg12 from '../image/svg/customerlogo-guardian-clr.svg'
import svg13 from '../image/svg/heathrow_logo_rgb.svg'
import svg14 from '../image/svg/johnson-controls-clr.svg'
import svg15 from '../image/svg/customerlogo-jvckenwood-clr.svg'
import svg16 from '../image/svg/customerlogo-kpn-clr.svg'
import svg17 from '../image/svg/customerlogo-marriott-clr.svg'
import svg18 from '../image/svg/customerlogo-mgm-clr.svg'
import svg19 from '../image/svg/customerlogo-vopak-2024-clr.svg'
import svg20 from '../image/svg/customerlogo-sakrua-clr.svg'
import svg21 from '../image/svg/customerlogo-skechers-clr.svg'
import svg22 from '../image/svg/customerlogo-wonderful-company-clr.svg'
import svg23 from '../image/svg/customerlogo-wendys-clr.svg'
import img1 from '../image/partner/partner-program-12405043.avif'
import img2 from '../image/partner/rc142-jp-tile26.avif'
import img3 from '../image/partner/rc142-career-img-be-true.self.avif'
import img4 from '../image/partner/rc142-1-kr.avif'
import img5 from '../image/partner/rc142-2-kr.avif'
import img6 from '../image/partner/infolob-12630557.avif'
import img7 from '../image/partner/centroid-12630588.avif'
import img8 from '../image/partner/syntax-12630617.avif'
import img9 from '../image/partner/namos-12630648.avif'
import img10 from '../image/partner/customerlogo-cohu-color.png'
import img11 from '../image/partner/customerlogo-gorilla-clr.avif'
import Latticelogo from '../image/lattice-logo-img.svg';
import { becomePartner } from '../Content/English-Content/BecomeParterns';


function BecomeParterns() {


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };



    const [activeSection, setActiveSection] = useState("Cloud");

    // Handle click to update active section
    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

     // cookies
    
    
        const [showCookieModal, setShowCookieModal] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
    
        const handleCookieClick = () => {
            setShowCookieModal(true);
            setIsLoading(true);
    
    
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        };
    
        const handleAcceptAll = () => {
            console.log("Cookies Accepted");
            setShowCookieModal(false);
        };
    
        const handleDenyAll = () => {
            console.log("Cookies Denied");
            setShowCookieModal(false);
        };



    return (
        <>

            <div className="become-partners-first-div">

                <div className="become-partners-first-div-child-1">

                    <h1 className="become-partners-first-div-heading">

                    {becomePartner.firstSectionHeading}
                        <span
                            style={{
                                display: "block",
                                width: "40px",
                                height: "4px",
                                background: "#f0cc71",
                                marginBottom: "3vh",
                                marginTop: "3vh",
                            }}
                        ></span>

                        <p className="become-partners-first-div-paragraph">{becomePartner.firstSectionPara}</p>

                    </h1>

                </div>

                {/* <div className="become-partners-first-div-child-2">

                    <img src={img1} className="become-partners-first-div-child-2-img">
                    
                    </img>

                    <p className="become-partners-first-div-paragraph">{becomePartner.firstSectionSide2TitleImg}</p>

                </div> */}

            </div>


            <div className="become-partners-sec-div-dynamic-content">
                <div className="become-partners-dynamic-wrapper">
                    <div className="become-partners-dynamic-island">
                        <button
                            onClick={() => handleSectionClick("Cloud")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Cloud" ? "active-section" : ""}`}
                        >
                            {becomePartner.secondSectionButton1}
                        </button>

                        {/* <button
                            onClick={() => handleSectionClick("Industry Healthcare")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Industry Healthcare" ? "active-section" : ""}`}
                        >
                            {becomePartner.secondSectionButton2}
                        </button> */}

                        {/* <button
                            onClick={() => handleSectionClick("License & Hardware")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "License & Hardware" ? "active-section" : ""}`}
                        >
                            {becomePartner.secondSectionButton3}
                        </button> */}
                    </div>

                    <div className="become-partners-dynamic-content-area">


                        <div className="become-partners-sec-div-cards">
                            {activeSection === "Cloud" && (
                                <>

                                    <h1 className="become-partners-first-div-heading">
                                        {becomePartner.cloudSection1Heading}
                                        <span
                                            style={{
                                                display: "block",
                                                width: "40px",
                                                height: "4px",
                                                background: "#f0cc71",
                                                marginBottom: "3vh",
                                                marginTop: "3vh",
                                            }}
                                        ></span>
                                        <p className="become-partners-first-div-paragraph">
                                            {becomePartner.cloudSection1Para}
                                        </p>
                                    </h1>

                                    <div className="become-partners-sec-div-all-card-wrapper">

                                        <div className="become-partners-sec-div-cards-card-1">

                                            <img src={img2} className="become-partners-card-1-all-img"></img>

                                            <div className="become-partners-card-1-content-wrapper">

                                                <h1 className="become-partners-card-1-all-heading">{becomePartner.cloudSection1Card1Heading}</h1>

                                                <p className="become-partners-card-1-all-para">{becomePartner.cloudSection1Card1Para}</p>

                                            </div>

                                        </div>

                                        <div className="become-partners-sec-div-cards-card-1">

                                            <img src={img3} className="become-partners-card-1-all-img"></img>

                                            <div className="become-partners-card-1-content-wrapper">

                                                <h1 className="become-partners-card-1-all-heading">{becomePartner.cloudSection1Card2Heading}</h1>

                                                <p className="become-partners-card-1-all-para">{becomePartner.cloudSection1Card2Para}</p>

                                            </div>

                                        </div>

                                        <div className="become-partners-sec-div-cards-card-1">

                                            <img src={img4} className="become-partners-card-1-all-img"></img>

                                            <div className="become-partners-card-1-content-wrapper">

                                                <h1 className="become-partners-card-1-all-heading">{becomePartner.cloudSection1Card3Heading}</h1>

                                                <p className="become-partners-card-1-all-para">{becomePartner.cloudSection1Card3Para}</p>

                                            </div>

                                        </div>

                                        <div className="become-partners-sec-div-cards-card-1">

                                            <img src={img5} className="become-partners-card-1-all-img"></img>

                                            <div className="become-partners-card-1-content-wrapper">

                                                <h1 className="become-partners-card-1-all-heading">{becomePartner.cloudSection1Card4Heading}</h1>

                                                <p className="become-partners-card-1-all-para">{becomePartner.cloudSection1Card4Para}</p>

                                            </div>

                                        </div>

                                    </div>


                                    <div className="become-parteners-dyanmic-content-2-div">

                                        <h1 className="become-partners-first-div-heading">
                                            {becomePartner.cloudSection2Heading}
                                            <span
                                                style={{
                                                    display: "block",
                                                    width: "40px",
                                                    height: "4px",
                                                    background: "#f0cc71",
                                                    marginBottom: "3vh",
                                                    marginTop: "3vh",
                                                }}
                                            ></span>
                                            <p className="become-partners-first-div-paragraph">
                                            {becomePartner.cloudSection2Para}
                                            </p>
                                        </h1>

                                        <div className="become-parteners-dyanmic-content-2-div-card-wrapper">

                                            <div className="become-parteners-dyanmic-content-2-div-card-1">

                                                <img src={svg1} className="become-partners-side-by-side-img1"></img>

                                                <div className="become-partners-side-by-side-content-img-div">

                                                    <h1 className="become-partners-side-by-side-content-heading">
                                                    {becomePartner.cloudSection2Card1Heading}
                                                    </h1>

                                                    <li className="become-partners-list-2-card-1-all-para">
                                                    {becomePartner.cloudSection2Card1List1}
                                                    </li>
                                                    <li className="become-partners-list-2-card-1-all-para">
                                                    {becomePartner.cloudSection2Card1List2}
                                                    </li>

                                                </div>

                                            </div>

                                            <div className="become-parteners-dyanmic-content-2-div-card-1">

                                                <img src={svg1} className="become-partners-side-by-side-img1"></img>

                                                <div className="become-partners-side-by-side-content-img-div">

                                                    <h1 className="become-partners-side-by-side-content-heading">
                                                    {becomePartner.cloudSection2Card2Heading}
                                                    </h1>

                                                    <li className="become-partners-list-2-card-1-all-para">
                                                    {becomePartner.cloudSection2Card2List1}
                                                    </li>
                                                    <li className="become-partners-list-2-card-1-all-para">
                                                    {becomePartner.cloudSection2Card2List2}
                                                    </li>
                                                    <li className="become-partners-list-2-card-1-all-para">
                                                    {becomePartner.cloudSection2Card2List3}
                                                    </li>

                                                </div>

                                            </div>

                                            <div className="become-parteners-dyanmic-content-2-div-card-1">

                                                <img src={svg1} className="become-partners-side-by-side-img1"></img>

                                                <div className="become-partners-side-by-side-content-img-div">

                                                    <h1 className="become-partners-side-by-side-content-heading">
                                                    {becomePartner.cloudSection2Card3Heading}
                                                    </h1>

                                                    <li className="become-partners-list-2-card-1-all-para">
                                                    {becomePartner.cloudSection2Card3List1}
                                                    </li>

                                                    <li className="become-partners-list-2-card-1-all-para">
                                                    {becomePartner.cloudSection2Card3List2}
                                                    </li>

                                                    <li className="become-partners-list-2-card-1-all-para">
                                                    {becomePartner.cloudSection2Card3List3}
                                                    </li>

                                                    <li className="become-partners-list-2-card-1-all-para">
                                                    {becomePartner.cloudSection2Card3List4}
                                                    </li>

                                                </div>

                                            </div>

                                        </div>

                                        <div className="become-partners-1-list-extended-box-2">

                                            <div className="become-partners-1-list-extended-child-1">

                                                <h1 className="become-partners-1-list-extended-child-1-all-main-heading">{becomePartner.cloudSection3Side1Heading1}</h1>

                                                <h1 className="become-partners-1-list-extended-child-1-all-para-heading">{becomePartner.cloudSection3Side1Heading2}</h1>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side1List1}
                                                </li>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side1List2}
                                                </li>

                                                <h1 className="become-partners-1-list-extended-child-1-all-para-heading">{becomePartner.cloudSection3Side1Heading3}</h1>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side1List3}
                                                </li>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side1List4}
                                                </li>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side1List5}
                                                </li>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side1List6}
                                                </li>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side1List7}
                                                </li>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side1List8}
                                                </li>

                                                <h1 className="become-partners-1-list-extended-child-1-all-para-heading">{becomePartner.cloudSection3Side1Heading4}</h1>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side1List9}
                                                </li>

                                                <h1 className="become-partners-1-list-extended-child-1-all-para-heading">{becomePartner.cloudSection3Side1Heading5}</h1>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side1List10}
                                                </li>
                                                
                                                <p>{becomePartner.cloudSection3Side1Para}</p>

                                            </div>

                                            <div className="become-partners-1-list-extended-child-1">

                                                <h1 className="become-partners-1-list-extended-child-1-all-main-heading">{becomePartner.cloudSection3Side2Heading1}</h1>

                                                <h1 className="become-partners-1-list-extended-child-1-all-para-heading">{becomePartner.cloudSection3Side2Heading2}</h1>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side2List1}
                                                </li>

                                                <h1 className="become-partners-1-list-extended-child-1-all-para-heading">{becomePartner.cloudSection3Side2Heading3}</h1>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side2List2}
                                                </li>

                                                <h1 className="become-partners-1-list-extended-child-1-all-para-heading">{becomePartner.cloudSection3Side2Heading4}</h1>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side2List3}
                                                </li>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side2List4}
                                                </li>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side2List5}
                                                </li>

                                                <h1 className="become-partners-1-list-extended-child-1-all-para-heading">{becomePartner.cloudSection3Side2Heading5}</h1>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side2List6}
                                                </li>

                                                <li className="become-partners-list-2-card-1-all-para">
                                                {becomePartner.cloudSection3Side2List7}
                                                </li>
                                                

                                            </div>

                                        </div>


                                    </div>

                                    <div className="become-partner-list-1-last-div-para-btn">

                                        <h1 className="become-partner-list-1-last-div-heading">{becomePartner.cloudSection4Heading}</h1>

                                        {/* <button className="become-partner-list-1-last-div-btn">{becomePartner.cloudSection4Button}</button> */}

                                    </div>


                                    <div className="become-partners-list-1-third-div">

                                        <h1 className="become-partners-list-1-3-div-heading">{becomePartner.cloudSection5Heading}</h1>

                                        {/* <div className="become-partners-list-1-3-div-cards-wrapper">

                                                <img src={img6} className="become-partners-list-1-3-div-card-1"></img>

                                                <img src={img7} className="become-partners-list-1-3-div-card-1"></img>

                                                <img src={img8} className="become-partners-list-1-3-div-card-1"></img>

                                                <img src={img9} className="become-partners-list-1-3-div-card-1"></img>
                                            
                                        </div> */}

                                        <div className="become-partners-list-1-3-div-cards-2-wrapper">

                                            <div className="become-partners-list-1-3-div-card-2">

                                                <img src={svg2} className="become-partners-card-2-img-logos"></img>

                                                <p className="become-partners-card-2-of-div-3-all-special-para">
                                                    <span className="large-quotes">“</span>
                                                    {becomePartner.cloudSection6Card1Quote}
                                                    <span className="large-quotes">”</span>
                                                    </p>



                                                    {/* <p className="become-partners-card-2-of-div-3-all-normal-para">
                                                        <span className="large-dash">—</span>
                                                        {becomePartner.cloudSection6Card1Para}
                                                        </p> */}


                                            </div>

                                            <div className="become-partners-list-1-3-div-card-2">

                                                <img src={svg3} className="become-partners-card-2-img-logos"></img>

                                                <p className="become-partners-card-2-of-div-3-all-special-para">
                                                    <span className="large-quotes">“</span>
                                                    {becomePartner.cloudSection6Card2Quote}
                                                    <span className="large-quotes">”</span>
                                                    </p>



                                                    {/* <p className="become-partners-card-2-of-div-3-all-normal-para">
                                                        <span className="large-dash">—</span>
                                                        {becomePartner.cloudSection6Card2Para}
                                                        </p> */}


                                            </div>


                                            <div className="become-partners-list-1-3-div-card-2">

                                            <img src={svg4} className="become-partners-card-2-img-logos"></img>

                                                <p className="become-partners-card-2-of-div-3-all-special-para">
                                                    <span className="large-quotes">“</span>
                                                    {becomePartner.cloudSection6Card3Quote}
                                                    <span className="large-quotes">”</span>
                                                    </p>



                                                    {/* <p className="become-partners-card-2-of-div-3-all-normal-para">
                                                        <span className="large-dash">—</span>
                                                        {becomePartner.cloudSection6Card3Para}
                                                        </p> */}


                                            </div>


                                            <div className="become-partners-list-1-3-div-card-2">

                                                <img src={svg5} className="become-partners-card-2-img-logos"></img>

                                                <p className="become-partners-card-2-of-div-3-all-special-para">
                                                    <span className="large-quotes">“</span>
                                                    {becomePartner.cloudSection6Card4Quote}
                                                    <span className="large-quotes">”</span>
                                                    </p>



                                                    {/* <p className="become-partners-card-2-of-div-3-all-normal-para">
                                                        <span className="large-dash">—</span>
                                                        {becomePartner.cloudSection6Card4Para}
                                                        </p> */}


                                            </div>


                                        </div>


                                    </div>


                                    <div className="become-partner-list-1-sec-last-div">

                                    <h1 className="become-partners-list-1-sec-last-div-heading">{becomePartner.cloudSection7Heading}</h1>

                                    <p className="become-partners-first-div-paragraph">
                                    {becomePartner.cloudSection7Para}
                                    </p>

                                    <div className="become-partner-list-1-sec-last-div-logo-wrapper">

                                        <img src={svg6} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg7} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        {/* <img src={img10} className="become-partner-list-1-sec-last-div-logo-1"></img> */}

                                        <img src={svg8} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg9} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg10} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg11} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        {/* <img src={img11} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg12} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg13} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg14} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg15} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg16} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg17} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg18} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg19} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg20} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg21} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg22} className="become-partner-list-1-sec-last-div-logo-1"></img>

                                        <img src={svg23} className="become-partner-list-1-sec-last-div-logo-1"></img> */}

                                    </div>

                                    </div>


                                    <div className="become-partners-end-div">

                                        <p className="become-partners-end-div-para">
                                        {becomePartner.cloudSection8Para}
                                        </p>

                                    </div>
                                </>
                            )}




                            
                        </div>
                    </div>
                </div>
            </div>










            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
               <div className="footer-bottom">
                              
                                                  <span className="footer-separator"> © 2024 Lattice</span> |
                              
                                                  <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
                              
                                                  <span className="footer-separator"
                                                      style={{ cursor: "pointer", color: "inherit" }}
                                                      onClick={handleCookieClick}> Cookie Preferences  </span>
                              
                                                  <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
                              
                                                  <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                                              </div>
                              
                                          </div>
                              
                              
                                          {showCookieModal && (
                                              <div
                                                  className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                                                      }`}
                                              >
                                                  <div className="cookie-modal">
                              
                                                      {isLoading ? (
                                                          <div className="loading-spinner">{cookieText.loading}</div>
                                                      ) : (
                                                          <div className="cookie-content">
                              
                                                              <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                                                  &times;
                                                              </button>
                                                              <div className="heading-of-cookies-with-logo">
                              
                                                                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>
                              
                                                                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>
                              
                                                              </div>
                              
                                                              <div className="div-2-heading-below-text-and-list">
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>
                              
                                                                  <ul>
                                                                  {cookieText.listItems.map((item, index) => (
                                                                      <li key={index} className="div-2-cookies-list-text">
                                                                          <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                                                      </li>
                                                                      ))}
                                                                  </ul>
                              
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>
                              
                                                              </div>
                              
                                                              <div className="button-group">
                                                                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                                                                     {cookieText.btn1}
                                                                  </button>
                                                                  <button className="btn deny-btn" onClick={handleDenyAll}>
                                                                  {cookieText.btn2}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          )}

        </>
    );
};

export default BecomeParterns;
