import React, { useState, useRef, useEffect } from 'react';
import "./lciallnavbar.css"
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import { useChatWidget } from "../ChatWidgetContext";
import Latticelogo from '../image/lattice-logo-img.svg';
import img1 from '../image/cloud/rc24-oci-networking-benefits.avif'
import simg2 from '../image/2020-Gartner-Magic-Quadrant.jpg'
import cardimg1 from '../image/deliveroo-logo3.png'
import cardimg2 from '../image/imperial-college-london-logo3.svg'
import cardimg3 from '../image/Four_Seasons_logo3.png'
import svg1 from '../image/deliveroo-logo3.png'
import svg2 from '../image/AlbaniaTech-logo.svg'
import svg4 from '../image/german-federal-ministry-logo.svg'
import svg5 from '../image/Four_Seasons_logo3.png'
import svg6 from '../image/The_Related_Companies-Logo.svg'
import svg7 from '../image/imperial-college-london-logo3.svg'
import arrow from '../image/arrow-of-industry-page.svg'



export default function LCIAboutPage() {

    const { toggleWidget } = useChatWidget();


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };

    const aboutSections = [
        {
            heading: <a className="about-links" href="#">Off-box virtualization</a>,
            description: "Complete instance isolation for higher security and performance",
        },
        {
            heading: <a className="about-links" href="#" >Custom security chips</a>,
            description: "Zero-trust approach to keep you safe from other tenants",
        },
        {
            heading: <a className="about-links" href="#" >Nonblocking networks</a>,
            description: "Cloud networks designed to match dedicated on-premises networks",
        },
        {
            heading: <a className="about-links" href="#" >L2 network virtualization</a>,
            description: "Cloud networking to natively support VMware, Oracle Database, and other clustering architectures",
        },
        {
            heading: <a className="about-links" href="#" >RDMA cluster networking</a>,
            description: "Microsecond latency clusters for the most compute-intensive workloads",
        },
        {
            heading: <a className="about-links" href="#" >Flex infrastructure</a>,
            description: "Online infrastructure scales resources up and down without application rewrites",
        },
    ];


    const aboutSections2 = [
        {
            heading: <a className="about-links" href="#">Interfaces and automation</a>,
            description: "Console, CLI, API/SDKs, Cloud Shell, and Resource Manager (Terraform)",
        },
        {
            heading: <a className="about-links" href="#" >API management</a>,
            description: " API management from API Design to deployment with API Gateway",
        },
        {
            heading: <a className="about-links" href="#" >Nonblocking networks</a>,
            description: "Cloud networks designed to match dedicated on-premises networks.",
        },
        {
            heading: <a className="about-links" href="#" >L2 network virtualization</a>,
            description: "Cloud networking to natively support VMware, Oracle Database, and other clustering architectures.",
        },
        {
            heading: <a className="about-links" href="#" >RDMA cluster networking</a>,
            description: "Microsecond latency clusters for the most compute-intensive workloads.",
        },
        {
            heading: <a className="about-links" href="#" >Flex infrastructure</a>,
            description: "Online infrastructure scales resources up and down without application rewrites.",
        },
    ];

    const aboutSections3 = [
        {
            heading: <a className="about-links" href="#"> Microsoft Azure Interconnect</a>,
            description: " Regional low-latency integration for multicloud architectures.",
        },
        {
            heading: <a className="about-links" href="#" >API management</a>,
            description: " API management from API Design to deployment with API Gateway",
        },
        {
            heading: <a className="about-links" href="#" >Nonblocking networks</a>,
            description: "Cloud networks designed to match dedicated on-premises networks.",
        },
        {
            heading: <a className="about-links" href="#" >L2 network virtualization</a>,
            description: "Cloud networking to natively support VMware, Oracle Database, and other clustering architectures.",
        },
        {
            heading: <a className="about-links" href="#" >RDMA cluster networking</a>,
            description: "Microsecond latency clusters for the most compute-intensive workloads.",
        },
        {
            heading: <a className="about-links" href="#" >Flex infrastructure</a>,
            description: "Online infrastructure scales resources up and down without application rewrites.",
        },
    ];



    const [listactiveSection, listsetActiveSection] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (section, index) => {
        if (listactiveSection === section && activeIndex === index) {
            listsetActiveSection(null);
            setActiveIndex(null);
        } else {
            listsetActiveSection(section);
            setActiveIndex(index);
        }
    };

    const renderContent = () => {
        if (listactiveSection === "first") {
            switch (activeIndex) {
                case 0:
                    return (

                        <div className="hidden-para-div-one-8-div-lci-about">

                            <h1 className="heading-1-of-10-div-lci-about">AI and Machine Learning</h1>
                            <p className="lci-about-3-div-paragraph">Easily add intelligence to your applications and workloads with prebuilt perception and decision models and out-of-the-box chatbots, or build and train your own models with our data science services.</p>
                            <a href="/lattice-ai-ml" style={{ color: "inherit", textDecoration: "none" }}> <button className="customer-review-card-1-all-btns">Explore AI and ML</button> </a>

                            <div className="ten-div-links-container-of-lci-about">
                                <div className="first-link-box-of-lci-bout">
                                    <h1 className="heading-2-of-10-div-lci-about">Generative AI</h1>
                                    <a href="#">Generative AI</a>
                                    <a href="#">Generative AI Service</a>
                                    <a href="#">Generative AI Agents</a>
                                    <a href="#">Code Assist</a>
                                    <a href="#">HeatWave GenAI</a>
                                </div>

                                <div className="first-link-box-of-lci-bout">
                                    <h1 className="heading-2-of-10-div-lci-about">AI Services</h1>
                                    <a href="#">AI Services</a>
                                    <a href="#">Generative AI Service</a>
                                    <a href="#">Generative AI Agents</a>
                                    <a href="#">Code Assist</a>
                                    <a href="#">HeatWave GenAI</a>
                                </div>

                                <div className="first-link-box-of-lci-bout">
                                    <h1 className="heading-2-of-10-div-lci-about">ML Services</h1>
                                    <a href="#">ML Services</a>
                                    <a href="#">Generative AI Service</a>
                                    <a href="#">Generative AI Agents</a>
                                    <a href="#">Code Assist</a>
                                    <a href="#">HeatWave GenAI</a>
                                </div>

                                <div className="first-link-box-of-lci-bout">
                                    <h1 className="heading-2-of-10-div-lci-about">Media Services</h1>
                                    <a href="#">ML Services</a>
                                    <a href="#">Generative AI Service</a>
                                </div>
                            </div>

                        </div>


                    );
                case 1:
                    return (

                        <div className="hidden-para-div-one-8-div-lci-about">

                            <h1 className="heading-1-of-10-div-lci-about">Compute</h1>
                            <p className="lci-about-3-div-paragraph">Secure and elastic compute capacity in the cloud that ranges from flexible virtual machines (Flex VMs) and high-performance bare metal servers to HPC and GPUs.</p>
                            <a href="/lattice-infrastructure-compute" style={{ color: "inherit", textDecoration: "none" }}> <button className="customer-review-card-1-all-btns">Explore Compute services</button> </a>

                            <div className="ten-div-links-container-of-lci-about">
                                <div className="first-link-box-of-lci-bout">
                                    <a href="#">Arm-based Compute</a>
                                    <a href="#">Bare Metal Servers</a>
                                    <a href="#">GPU-Accelerated Compute</a>
                                </div>

                                <div className="first-link-box-of-lci-bout"></div>

                                <div className="first-link-box-of-lci-bout">
                                    <a href="#">HPC Compute</a>
                                    <a href="#">Virtual Machines</a>
                                    <a href="#">VMware</a>
                                </div>
                            </div>

                        </div>


                    );

                default:
                    return null;
            }

        } else if (listactiveSection === "second") {
            switch (activeIndex) {
                case 0:
                    return (

                        <div className="hidden-para-div-one-8-div-lci-about">

                            <h1 className="heading-1-of-10-div-lci-about">Storage</h1>
                            <p className="lci-about-3-div-paragraph">Address key use cases with on-demand local, object, file, block, and archive storage.</p>
                            <a href="/lattice-storage" style={{ color: "inherit", textDecoration: "none" }}> <button className="customer-review-card-1-all-btns">Explore Storage services</button> </a>

                            <div className="ten-div-links-container-of-lci-about">
                                <div className="first-link-box-of-lci-bout">
                                    <a href="#">Archive Storage</a>
                                    <a href="#">Block Volumes</a>
                                    <a href="#">File Storage</a>
                                </div>

                                <div className="first-link-box-of-lci-bout">
                                </div>

                                <div className="first-link-box-of-lci-bout">
                                    <a href="#">Object Storage</a>
                                    <a href="#">Storage Gateway</a>
                                </div>
                            </div>

                        </div>

                    );
                case 1:
                    return (

                        <div className="hidden-para-div-one-8-div-lci-about">

                            <h1 className="heading-1-of-10-div-lci-about">Networking</h1>
                            <p className="lci-about-3-div-paragraph">
                                Connect securely to a customizable, isolated virtual cloud network (VCN) as the heart of your cloud deployment. Move data at 25% of the cost of other cloud providers.
                            </p>
                            <a href="/lattice-Networking" style={{ color: "inherit", textDecoration: "none" }}> <button className="customer-review-card-1-all-btns">Explore Networking services</button> </a>

                            <div className="ten-div-links-container-of-lci-about">
                                <div className="first-link-box-of-lci-bout">
                                    <a href="#">Customer-Premises Equipment</a>
                                    <a href="#">DNS Management</a>
                                    <a href="#">FastConnect</a>
                                    <a href="#">Load Balancers</a>
                                </div>

                                <div className="first-link-box-of-lci-bout">
                                </div>

                                <div className="first-link-box-of-lci-bout">
                                    <a href="#">Networking Gateways</a>
                                    <a href="#">Private Endpoint</a>
                                    <a href="#">Site-to-Site VPN</a>
                                    <a href="#">Virtual Cloud Networks</a>
                                </div>
                            </div>

                        </div>


                    );
                default:
                    return null;
            }
        }
        return null;
    };



    const [activeSection, setActiveSection] = useState("Free trial");

    // Handle click to update active section
    const handleSectionClick = (section) => {
        setActiveSection(section);
    };






    return (
        <>

           

            {/* div-1 */}

            <div className="product-infra-compute-first-div">
                <h1 className="product-infra-compute-first-div-heading">
                    Why Customers are Choosing OCI
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                    <p className="product-infra-compute-first-div-paragraph">Oracle Cloud is the first public cloud built from the ground up to be a better cloud for every application. By rethinking core engineering and systems design for cloud computing, we created innovations that solve problems that customers have with existing public clouds. We accelerate migrations of existing enterprise workloads, deliver better reliability and performance for all applications, and offer the complete services customers need to build innovative cloud applications. There are six key reasons customers are choosing Oracle Cloud Infrastructure (OCI) for all their cloud workloads.</p>
                    <button className="product-infra-compute-first-div-btn-1"><a href="/create-account" style={{ color: "inherit", textDecoration: "none" }}>Try Lattice Cloud Free</a></button>
                </h1>
                <div className="product-infra-compute-first-div-img-and-para">
                    <img src={img1} className="product-infra-compute-img-of-first-div"></img>
                    <p className="product-infra-compute-title-of-img">Mutual Materials moves on-premises E-Business Suite to Oracle Cloud and improves performance by 20% (2:30)</p>
                </div>
            </div>



            {/* div-2 */}

            <div className="sec-div-of-lci-about-navbar-page">
                <div className="child-2-of-2-div-lci-about">
                    <img src={simg2} alt="Gartner" />
                </div>
                <div className="child-1-of-2-div-lci-about">
                    <h1 className="heading-1-of-lci-about">2024 Gartner® Magic Quadrant™ for Strategic Cloud Platform Services</h1>
                    <p className="para-1-of-lci-about">Oracle is the only hyperscaler capable of delivering more than 150 AI and cloud services across public, dedicated, and hybrid cloud environments, anywhere in the world. Read the report to see why Oracle is named a Leader.</p>
                    <a href="/news/announcement/lattice-nominated-in-the-2025-gartner-magic-quadrant-reports-for-strategic-cloud-blockchain-platform-services-and-distributed-hybrid-blockchain-infrastructure-2024-11-12" style={{ color: "inherit", textDecoration: "none" }}><button className="product-infra-compute-first-div-btn-1">Access the Gartner report</button></a>
                </div>
            </div>


            {/* div-3 */}

            <div className="div-3-of-lci-about-page">

                <h1 className="lci-about-3-div-heading">
                    1. Far easier to migrate critical enterprise workloads
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                    <p className="lci-about-3-div-paragraph">Many enterprise applications are challenging to move to the cloud, as most hyperscale cloud providers were architected on a virtual machine model with resources shared through the compute hypervisor and oversubscribed networks. This older cloud computing architecture makes it difficult for enterprise applications to run at the expected levels of performance and availability without typically significant modification, which adds complexity and risk. Enterprise applications and other performance-sensitive on-premises applications were designed to run best on: scale-up architectures for resource scaling (not scale-out); ultra-low latency networks; persistent connections to relational databases; and clustering of resources for availability. OCI was designed with key cloud technologies to address the requirements of enterprise applications.</p>
                </h1>
            </div>


            {/* div-4 */}

            <div className="lci-about-wrapper" >
                <h1 className="lci-about-list-heading-1-all">Key Oracle Cloud Infrastructure innovations</h1>
                <div className="lci-about-grid">
                    {aboutSections.map((section, index) => (
                        <div className="lci-grid-item" key={index}>
                            <h2 className="grid-item-heading">{section.heading}</h2>
                            <div className="description">{section.description}</div>
                        </div>
                    ))}
                </div>
            </div>



            {/* div-5 */}



            <div className="customer-review-cards-container">
                <h1 className="customer-review-cards-container-heading-main">Customer success with enterprise cloud migrations</h1>
                <div className="customers-review-card-wrapper">

                    <div className="customer-review-card-1">
                        <div className="customer-review-card-1-img"><img src={cardimg1} ></img></div>

                        <div className="customer-review-content-wraper-of-card">
                            <h1 className="customer-review-card-1-all-heading">Deliveroo</h1>
                            <p className="customer-review-card-1-all-para">Deliveroo relies on Lattice Cloud to deliver on promises to its customers</p>
                            <a href="/customer-deliveroo" style={{ color: "inherit", textDecoration: "none" }}> <button className="customer-review-card-1-all-btns">Read Deleveroo Story</button> </a>
                        </div>

                    </div>


                    <div className="customer-review-card-1">
                        <div className="customer-review-card-1-img"><img src={cardimg2} ></img></div>

                        <div className="customer-review-content-wraper-of-card">

                            <h1 className="customer-review-card-1-all-heading">Imperial College London</h1>
                            <p className="customer-review-card-1-all-para">Imperial College London enhances its end-to-end client strategy with Lattice Cloud.</p>
                            <a href="/customer-imperialcollege" style={{ color: "inherit", textDecoration: "none" }}> <button className="customer-review-card-1-all-btns">Imperial College London story</button> </a>


                        </div>

                    </div>



                    <div className="customer-review-card-1">
                        <div className="customer-review-card-1-img"><img src={cardimg3} ></img></div>

                        <div className="customer-review-content-wraper-of-card">

                            <h1 className="customer-review-card-1-all-heading">Four Season</h1>
                            <p className="customer-review-card-1-all-para">The Four Season helps astronomers see farther with AI.</p>
                            <a href="/customer-fourseason" style={{ color: "inherit", textDecoration: "none" }}> <button className="customer-review-card-1-all-btns">Four Season story</button> </a>

                        </div>
                    </div>
                </div>
            </div>



            {/* div-6 */}

            <div className="div-3-of-lci-about-page">

                <h1 className="lci-about-3-div-heading">
                    2. Everything you need to build modern cloud native applications
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                    <p className="lci-about-3-div-paragraph">While OCI is designed to be better for enterprise applications, the same innovations in networking, compute, and storage make cloud native applications more performant, resilient, and scalable. OCI has the broad cloud services and partner ecosystem you need to build production cloud native apps.</p>
                </h1>
            </div>

            <div className="lci-about-wrapper">
                <h1 className="lci-about-list-heading-1-all" >OCI Platform and Development Services</h1>
                <div className="lci-about-grid">
                    {aboutSections2.map((section, index) => (
                        <div className="lci-grid-item" key={index}>
                            <h2 className="grid-item-heading">{section.heading}</h2>
                            <div className="description">{section.description}</div>
                        </div>
                    ))}
                </div>
            </div>


            {/* div-7 */}


            <div className="product-infra-div-seven">
                <div className="product-infra-div-six-text-wrapper">
                    <p className="lci-about-3-div-paragraph">OCI is designed to be a better cloud for your entire development toolchain with support for cloud services from an array of partnerships.</p>
                    <a href='/customer-story' className="product-infra-div-six-link-of-txt-wrapper">Explore all customer story</a>
                </div>

                <div className="product-infra-div-seven-img-wrapper">
                    <img src={svg1} className="product-infra-div-seven-img-1"></img>
                    <img src={svg2} className="product-infra-div-seven-img-1"></img>
                    <img src={svg4} className="product-infra-div-seven-img-1"></img>
                    <img src={svg5} className="product-infra-div-seven-img-1"></img>
                    <img src={svg6} className="product-infra-div-seven-img-1"></img>
                    <img src={svg7} className="product-infra-div-seven-img-1"></img>
                </div>
            </div>


            {/* div-8 */}

            <div className="div-3-of-lci-about-page">

                <h1 className="lci-about-3-div-heading">
                    3. Oracle Cloud provides the most support for hybrid cloud strategies
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                    <p className="lci-about-3-div-paragraph">We designed the architecture of Oracle Cloud to support a wide range of deployment options for customers. Customers can run an entire OCI region from within their data center, move entire on-premises VMware environments to public cloud regions, or deliver OCI services like Exadata and Roving Edge right to the site of the work needed. Oracle also has a unique interconnection with Microsoft Azure to provide low-latency, unified identity, multicloud services in 12 regions around the world. Whether customers have already chosen a primary cloud provider, or are earlier in their cloud adoption, they can modernize their business with the control, performance, and value of OCI's range of hybrid choices.</p>
                </h1>
            </div>

            <div className="lci-about-wrapper">
                <h1 className="lci-about-list-heading-1-all" >OCI Platform and Development Services</h1>
                <div className="lci-about-grid">
                    {aboutSections3.map((section, index) => (
                        <div className="lci-grid-item" key={index}>
                            <h2 className="grid-item-heading">{section.heading}</h2>
                            <div className="description">{section.description}</div>
                        </div>
                    ))}
                </div>
            </div>


            {/* 9-div */}

            <div className="div-3-of-lci-about-page">

                <h1 className="lci-about-3-div-heading">
                    4. OCI offers superior price-performance
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                    <p className="lci-about-3-div-paragraph">In designing Oracle Cloud, we wanted a cloud that performed better for every application, but also had better price-performance. To do this, we made our pricing globally consistent, where the cost for a compute or storage service is consistently low across all regions to simplify adoption. We made our pricing competitive without requiring customers to make significant, multiyear commitments. We designed core cloud capabilities, such as security and container management, to be included in the cost of compute, not as an add-on charge. We built better performing networks, but also priced the movement of data out of the cloud in recognition of how our customers are building multicloud networks to serve their customers.
                        <br /> <br />
                        Beyond service pricing, we're continually making it easier to work with Oracle Cloud. We've backed our services with the most comprehensive SLAs, where we financially guarantee not only availability, but also the performance of our network and storage services as well as your ability to manage services through APIs at all times. To simplify migration, we include the design, planning, and migration of your workloads into the cloud at no extra cost, through our Cloud Lift program. Finally, we can reduce your software license support costs by 25 to 33 percent as you scale your OCI usage through Oracle Support Rewards.
                        <br /> <br />
                        The benefits add up. Let’s say you're moving a custom application to OCI. You can opt for lower Bring Your Own License pricing for Oracle Database in the cloud, get no-cost engineering support to move your application, and reduce your overall costs through volume OCI discounts and by earning Support Rewards.
                    </p>
                </h1>
            </div>



            {/* div-10 */}

            <div className="ten-div-of-lci-about">
                <h1 className="ten-div-1-all-heading">Compare OCI to other cloud providers</h1>
                <p className="lci-about-3-div-paragraph">It's natural to wonder how OCI compares with other cloud vendors such as AWS, Azure, or Google Cloud. Check out our point-by-point comparisons, or sign up for our Free Tier, with dozens of free cloud services, and see for yourself. For generalists, our Cloud Adoption Framework is a good place to learn more and plan your path forward. For technologists, our Architecture Center shares best practices, tutorials, and hands-on labs for the many types of workloads you can run on OCI.
                </p>

            </div>

            <div className="ten-div-of-lci-about">

                <h1 className="lci-about-3-div-heading">
                    Complete cloud infrastructure and platform services for every workload
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                    <p className="lci-about-3-div-paragraph">OCI offers a common set of 150-plus cloud services in each cloud region. Get all the services you need—from containers and VMware to AI—to migrate, modernize, build, and scale your IT. Automate all your workloads, including both existing and new applications and data platforms.
                    </p>
                </h1>



                <div className="fisrt-child-of-8-div-industry">
                    {["AI and Machine Learning", "Compute"].map((item, index) => (
                        <div
                            key={index}
                            className={`list-item-1-of-8-div-industry ${listactiveSection === "first" && activeIndex === index ? "active" : ""
                                }`}
                            onClick={() => handleItemClick("first", index)}
                        >
                            <h6 className="list-item-one-heading-8-div-industry">{item}</h6>
                            <img
                                src={arrow}
                                alt="arrow"
                                className={`list-item-one-img-8-div-industry ${listactiveSection === "first" && activeIndex === index ? "rotate" : ""
                                    }`}
                            />
                        </div>
                    ))}
                </div>

                {listactiveSection === "first" && activeIndex !== null && (
                    <div className="hidden-div-of-child-section-of-8-div-industry">{renderContent()}</div>
                )}


                <div className="fisrt-child-of-8-div">
                    <div className="fisrt-child-of-8-div-industry">
                        {["Storage", "Networking"].map((item, index) => (
                            <div
                                key={index}
                                className={`list-item-1-of-8-div-industry ${listactiveSection === "second" && activeIndex === index ? "active" : ""
                                    }`}
                                onClick={() => handleItemClick("second", index)}
                            >
                                <h6 className="list-item-one-heading-8-div-industry">{item}</h6>
                                <img
                                    src={arrow}
                                    alt="arrow"
                                    className={`list-item-one-img-8-div-industry ${listactiveSection === "second" && activeIndex === index ? "rotate" : ""
                                        }`}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {listactiveSection === "second" && activeIndex !== null && (
                    <div className="hidden-div-of-child-section-of-8-div-industry">{renderContent()}</div>
                )}

            </div>



            {/* 11-div */}


            <div className="product-infra-last-div">

                <div className="product-infra-last-div-wrapper">

                    <h1 className="product-infra-last-div-heading">Get started with OCI</h1>
                    <div className="become-partners-dynamic-island" style={{ marginTop: "5vh" }}>

                        <button
                            onClick={() => handleSectionClick("Free trial")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Free trial" ? "active-section" : ""}`}
                        >
                            Sign up for a trial
                        </button>

                        <button
                            onClick={() => handleSectionClick("Hands-on labs")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Hands-on labs" ? "active-section" : ""}`}
                        >
                            Talk to sales
                        </button>

                        <button
                            onClick={() => handleSectionClick("Estimate your costs")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Estimate your costs" ? "active-section" : ""}`}
                        >
                            Subscribe to OCI Insider
                        </button>

                        <button
                            onClick={() => handleSectionClick("Contact sales")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Contact sales" ? "active-section" : ""}`}
                        >

                            Analyst reports on OCI
                        </button>
                    </div>


                    <div className="become-partners-dynamic-content-area">


                        <div className="become-partners-sec-div-cards">
                            {activeSection === "Free trial" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">Try Always Free cloud services and get a 30-day trial</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">Oracle offers a Free Tier with no time limits on selection of services such as Autonomous Database, Compute, and Storage, as well as US$300 in free credits to try additional cloud services. Get the details and sign up for your free account today.</p>

                                            <a href="/create-account" style={{ color: "inherit", textDecoration: "none" }}><button className="product-infra-last-div-1-list-card-1-all-main-btn"> Try LCI free</button> </a>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">What’s included with Oracle Cloud Free Tier?</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">Always Free</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">2 Autonomous Databases, 20 GB each</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">Compute VMs</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">100 GB block volume</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">10 GB object storage</li>
                                            </ul>

                                        </div>

                                    </div>

                                </>
                            )}

                            {activeSection === "Hands-on labs" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">
                                        <div className="product-infra-last-div-2-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">Contact sales</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">Interested in learning more about Oracle Cloud Infrastructure? Let one of our experts help.</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn" onClick={toggleWidget} >Get in touch</button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">They can answer questions such as:</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">What workloads run best on OCI?</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">How do I get the most out of my overall Oracle investments?</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">How does OCI compare to other providers?</li>
                                            </ul>


                                        </div>
                                    </div>

                                </>
                            )}


                            {activeSection === "Estimate your costs" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">Oracle Cloud Insider</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">Get the latest industry trends, best practices, and product updates, and be in the know about new training programs and upcoming events.</p>
                                            <a href="/create-account" style={{ color: "inherit", textDecoration: "none" }}><button className="product-infra-last-div-1-list-card-1-all-main-btn"> Subscribe today </button></a>
                                        </div>
                                    </div>

                                </>
                            )}

                            {activeSection === "Contact sales" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">Get expert perspectives</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">Learn more about your options in the cloud from industry analysts such as IDC. They provide detailed, objective perspectives on the latest strategies and solutions.</p>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">
                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">IDC Technology Spotlight: Oracle Cloud Infrastructure for heterogeneous workloads</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">Omdia report: Matching the workload characteristics to the cloud’s capabilities</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">Omdia report: Why all clouds are not the same</li>

                                            </ul>
                                        </div>
                                    </div>

                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>









            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}




        </>
    )
}
