// content.js 

import simg from '../image/LATTICE LABS LOGOS.jpg'
import simg1 from '../image/automation-featured-image-1.jpg'
import simg2 from '../image/2020-Gartner-Magic-Quadrant.jpg'
import simg3 from '../image/Health-care-slider-bg-ig.jpg'
import simg4 from '../image/slider-helth-care-img.jpg'
import simg5 from '../image/Lattice-labs-slider-bg.jpg'
import simg6 from '../image/customer-story/business-person-futuristic-business-environment.jpg'

import simg8 from '../image/developer-img/slide1.webp'
import simg9 from '../image/developer-img/slide2.webp'

import brand1 from '../image/deliveroo-logo.png'
import brand2 from '../image/ICL-logo.png'
import brand3 from '../image/Four_Seasons_logo_brand3.png'
import brand4 from '../image/SMI_logo_brand4.png'
import brand5 from '../image/BMG-logo.png'
import brand6 from '../image/Albenia_tech_logo6.png'

 //  content for slide area of home-page 

 export const slides = [
    {   //1
        title: "Lattice is named a nominee for 2025 Gartner®️ Magic Quadrant™️ Leader for Strategic Blockchain Cloud Platform Services and Distributed Hybrid Blockchain Infrastructure",
        description: "Lattice is the only hyperscaler capable of delivering 150+ AI and other cloud services across public, dedicated, and hybrid cloud environments.",
        image: simg2,
        link: "/news/announcement/lattice-nominated-in-the-2025-gartner-magic-quadrant-reports-for-strategic-cloud-blockchain-platform-services-and-distributed-hybrid-blockchain-infrastructure-2024-11-12", 
        buttonText: "Learn More"
    },
    {   //2
        title: "Lattice unveils next-gen EHR",
        description: "New cloud-based blockchain health record, with embedded AI and military-grade security, will automate processes and simplify information exchange among providers, patients, and payers when launched in 2025.",
        image: simg1,
        link: "/news/announcement/lattice-unveils-next-generation-ehr-2024-07-18",
        buttonText: "Read More"
    },
    {   //3
        title: "Lattice Database@Azure expands to meet global demand",
        description: "Multicloud Blockchain service is available for the first time in South America and now in nine Microsoft Azure regions overall, with an additional 24 regions planned by the end of 2025.",
        image: simg,
        link: "/news/announcement/lattice-database-at-azure-adds-regions-and-services-to-meet-global-demand-2024-11-11",
        buttonText: "Discover More"
    },
    {   //4
        title: "Lattice Labs runs large-scale AI workloads on Lattice Cloud Infrastructure",
        description: "LCI to deliver faster, more cost-effective inferencing, fine-tuning, and batch processing for Lattice customers.",
        image: simg5, 
        link: "/news/announcement/lattice-labs-runs-large-scale-ai-workloads-on-lattice-cloud-infrastructure",
        buttonText: "Read More about Lattice Labs" //lattice lab logos 
    },
    {   //5
        title: "German Federal Ministry of Health taps LCI for groundbreaking oncology work",
        description: "German Federal Ministry of Health turns to Lattice Cloud Blockchain Infrastructure to improve precision of cancer research, diagnoses.",
        image: simg3, 
        link: "/news/announcement/german-federal-ministry-of-health-expanding-lattice-health-ehr-to-30-of-its-hospitals-2024-09-12",
        buttonText: "Read the story" // bblue helth care logo with some helth care img 
    },
    {   //6
        title: "AI aids open enrollment process",
        description: "Learn how an AI agent in Lattice Fusion Cloud HCM can make a difference in benefits enrollment, for both employees and HR teams.",
        image: simg6,
        link: "/human-capital-management/ai-agents-open-enrollment/",
        buttonText: "Read the story"
    },
    {   //7
        title: "Lattice Health Clinical AI Agent helps doctors spend more time with patients",
        description: "Cloud-based AI tool simplifies access to patients' medical histories, automates note-taking and coding, proposes clinical follow-ups, and more.",
        image: simg4, 
        link: "/news/announcement/lattice-health-clinical-ai-agent",
        buttonText: "Read the press release"
    }

];



// Home page content -- Blockchain solutions data --card area-3 div content

export const blockchainSolutions = [
    {
       
       
        title: "D-AI boosts model efficiency and performance with LCI AI Infrastructure",
        description:
        `<div>

           <p class="mt-5">LCI service helps drive tech provider's high-powered AI model training and inference business.</p>

            </div>`,
        link: "customers/d-ai/",
        linkText: "Read D-AI’s story"
    },
    {
       
        title: "US homebuilder lays foundation for growth with Lattice Fusion Cloud Applications",
        description:
            `<p class="pt-2">Related integrates finance, planning, and supply chain processes as it expands its offerings nationwide.</p>`,
        link: "/related",
        linkText: "Read more about Related"
    },
    {
       
        title: "Four Seasons to standardize on Lattice Cloud Property Management",
        description:
            ` <p class="mt-5">
            Leading hotel chain extends platform to more than 100 hotels and all-inclusive properties.
            </p>`,
        link: "fourseason",
        linkText: "Read more about Four Seasons"
    }
];

//  section with 2-images section with explore btns lttice cloud and infrastructure area 

export const customerSection = {
    title: "Learn why our customers trust Lattice",
    link: "/customer-story",
    linkText: "Explore customer story",
};

export const customersList = [
    { id: "training", image: brand1 },
    { id: "technical", image: brand2 },
    { id: "billing", image: brand3 },
    { id: "account", image: brand4 },
    { id: "cloud", image: brand5 },
    { id: "innovation", image: brand6 },
];

export const customerDetails = {
    training: {
        heading: "Deliveroo relies on Lattice Cloud to deliver on promises to its customers",
        buttonText:"Read Deliveroo Story",
    },
    technical: {
        heading: "Imperial College London enhances its end-to-end client strategy with Lattice Cloud",
        buttonText:"Read ICL Story",
    },
    billing: {
        heading: "The Four Season helps astronomers see farther with AI",
        buttonText:"Read FourSeasons Story",
    },
    account: {
        heading: "Related Resorts impresses guests with help from Lattice Fusion Cloud ERP",
        buttonText:"Read Related Story",
    },
    cloud: {
        heading: "German Federal Ministry of Health adopts cloud-based ERP foundation and sees future savings with Lattice Cloud",
        buttonText:"Read GFMH Story",
    },
    innovation: {
      heading: "Albania Tech pioneers regional digital transformation with Lattice Cloud solutions",
      buttonText:"Read Albania Tech Story",
  }

};

 export const getCustomer = (activeItem) => {
  const routes = {
      training: "/customer-deliveroo",
      technical: "/customer-imperialcollege",
      billing: "/customer-fourseason",
      account: "/customer-related",
      cloud: "/customer-germanfederalministry",
      innovation: "/customer-albaniatech",
  };
  return routes[activeItem] || "/"; // Default to home if no match
};


// footer-Content list content bottom list area 

export const footerSections = [
    {
        id: 'resources',
        heading: 'Lattice Labs',
        links: [
            { text: 'Who We Are', to: '/about' },
            { text: 'Careers', to: '/careers' },
            { text: 'Contact Us', to: '/contact' },
            { text: 'Partners', to: '/become-partners' },
            // { text: 'FAQ', to: '#' },
            // { text: 'Students', to: '#' },
        ],
    },
    {
        id: 'learn',
        heading: 'Learn',
        links: [
            { text: 'What is cloud computing?', to: '/lattice-infrastructure-compute' },
            { text: 'Analyst Reports', to: '/lattice-reporting' },
            { text: 'Best cloud-based SCM', to: '/supply-chain-manufacturing' },
            { text: 'Cloud Economics', to: '/wealth-management-suite' },
            // { text: 'What is CRM?', to: '#' },
            // { text: 'What is Docker?', to: '#' },
            // { text: 'What is Kubernetes?', to: '#' },
            // { text: 'What is Python?', to: '#' },
            // { text: 'What is SaaS?', to: '#' },
        ],
    },
    // {
    //     id: 'whyLattice',
    //     heading: 'Explore',
    //     links: [
            // { text: 'Analyst Reports', to: '/lattice-reporting' },
            // { text: 'Best cloud-based SCM', to: '/supply-chain-manufacturing' },
            // { text: 'Cloud Economics', to: '/wealth-management-suite' },
            // { text: 'Social Impact', to: '#' },
            // { text: 'Culture and Inclusion', to: '#' },
            // { text: 'Security Practices', to: '#' },
    //     ],
    // },
    {
        id: 'newsEvents',
        heading: 'News',
        links: [
            { text: 'News', to: '/company-news' },
            { text: 'Lattice CloudWorld', to: '/lattice-infrastructure-compute' },
            // { text: 'Lattice CloudWorld Tour', to: '#' },
            { text: 'Lattice Health Summit', to: '/human-identity-profile-suite' },
            // { text: 'Lattice DevLive', to: '#' },
            { text: 'Search all events', to: '/events' },
        ],
    },
    {
        id: 'contactUs',
        heading: 'Contact Us',
        links: [
            // { text: 'IN Sales +91 80-37132100', to: '#' },
            // { text: 'US Sales: +1.800.633.0738', to: '#' },
            { text: 'How can we help?', to: '/contact' },
            // { text: 'Subscribe to emails', to: '#' },
            // { text: 'Integrity Helpline', to: '#' },
        ],
    },
];




// signIn-Content this is sign-in page content

export const signInContent = {
  heading: "Sign In to Lattice",
  emailPlaceholder: "Enter your email or username",
  forgotUsernameText: "Forgot Username?",
  accountText: "Don't have an account?",
  createAccountText: "Create Account",
  termsText: [
    { text: "© Lattice", isLink: false },
    { text: "Terms of Use", isLink: true, href: "/privacy" },
    { text: "Privacy Policy", isLink: true, href: "/privacy" },
  ],
};



  

// navbarContent heading-list  content 

export const navbarContent = {
    products: "Products",
    industries: "Industries",
    resources: "Resources",
    customers: "Customers",
    partners: "Partners",
    developers: "Developers",
    company: "Company",
    contact: "Contact Sales",
    viewAccounts: "View Accounts",
    signInCloud: "Sign In to Cloud",
    signUpCloud: "Sign Up for Free Cloud Tier",
    latticeCloud: "Lattice Cloud",
    signIn: "Sign-In",
    createAccount: "Create an Account",
  
    // Dropdown area content
    dropdownContent: {
      products: {
        title: "Products",
        sections: [
          {
            heading: "Lattice Cloud Infrastructure",
            links: [
              { text: "AI and Machine Learning", href: "/lattice-ai-ml" },
              { text: "Compute", href: "/lattice-infrastructure-compute" },
              { text: "LCI Overview", href: "/lci-overview" },
              // { text: "Analytics", href: "#" },
              // { text: "Billing and Cost Management", href: "#" },
              { text: "Networking", href: "/lattice-Networking" },
              // { text: "Database Services", href: "#" },
              // { text: "Developer Services", href: "#" },
              // { text: "Distributed Cloud and Multicloud", href: "#" },
              // { text: "Governance and Administration", href: "#" },
              // { text: "Identity and Security", href: "#" },
              // { text: "Integration", href: "#" },
              // { text: "Migration and Disaster Recovery Services", href: "#" },
              // { text: "Networking and Connectivity", href: "#" },
              // { text: "Observability and Management", href: "#" },
              { text: "Private Blockchain Configuration", href: "/lattice-blockchain-configuration" },
              { text: "Reporting", href: "/lattice-reporting" },           
              { text: "Storage", href: "/lattice-storage" },
            ]
          },
          {
            heading: "Lattice Cloud Applications",

            links: [
              { text: "Campaign & Elections Suite", href: "/campaign-elections-suite" },
              { text: "Charitable Institutions Suite", href: "/charitable-institutions-suite" },
              { text: "File Management Suite", href: "/file-management-suite" },
              { text: "Gamified Retail Sector Suite", href: "/gamified-retail-sector-suite" },          
              { text: " Health Suite", href: "/health-suite" },
              { text: " HR Suite", href: "/hr-suite" },
              { text: "Human Identity & Profile Suite", href: "/human-identity-profile-suite" },
              { text: "Real Estate Registry & Utility Management Suite", href: "/real-estate-registry-utility-management-suite" },
              { text: "Supply Chain & Manufacturing (SCM) ", href: "/supply-chain-manufacturing" },
              { text: "Wealth Management Suite", href: "/wealth-management-suite" },
            ],
            subLinks: {
              // "Supply Chain & Manufacturing (SCM)": [
              //   { text: "Supply Chain Planning", href: "#" },
              //   { text: "Inventory Management", href: "#" },
              //   { text: "Manufacturing", href: "#" },
              //   { text: "Maintenance", href: "#" },
              //   { text: "Product Lifecycle Management", href: "#" },
              //   { text: "More SCM applications", href: "#" }
              // ],
              "Tools": [
                { text: "Fungible Private Token Generator", href: "/fungible-private-token-generator" },
                { text: "Non Fungible Private Token Administration", href: "/non-fungible-private-token-administration" },
                { text: "Non Fungible Private Token Generator", href: "/non-fungible-private-token-generator" },
                { text: "Private Token Administration", href: "/private-token-administration" },
                { text: "User Identity Administration", href: "/user-identity" },
                { text: "Wallets-as-a-Service", href: "/wallet-services" },
              ]
            }
          }
        ]
      },
      industries: {
        title: "Industries",
        sections: [
          // {
          //   heading: "Industry Solutions and Resources",
          //   links: [
          //     // { text: "Lattice Industry Lab", href: "#" },
          //     // { text: "Customer Success", href: "#" },
          //     // { text: "Join the Community", href: "#" },
          //     // { text: "Product Documentation", href: "#" }
          //   ]
          // },
          {
            heading: "Industries",
            links: [
              // { text: "All Industries", href: "#" },
              // { text: "Automotive", href: "#" },
              // { text: "Communications", href: "#" },
              // { text: "Construction and Engineering", href: "#" },
              // { text: "Consumer Packaged Goods", href: "#" },
              // { text: "Defense and Intelligence", href: "#" },
              { text: "Education", href: "/education" },
              // { text: "Energy and Water", href: "#" },
              { text: "Financial Services", href: "/financial-services" },
              // { text: "Government", href: "#" },
              { text: "Healthcare", href: "/health" },
              // { text: "High Technology", href: "#" },
              // { text: "Hospitality", href: "#" },
              // { text: "Industrial Manufacturing", href: "#" },
              // { text: "Life Sciences", href: "#" },
              { text: "Media and Entertainment", href: "/media-entertainment" },
              // { text: "Oil and Gas", href: "#" },
              { text: "Professional Services", href: "/professional-services" },
              // { text: "Public Safety", href: "#" },
              // { text: "Restaurants", href: "#" },
              // { text: "Retail", href: "#" },
              // { text: "Travel and Transportation", href: "#" },
              // { text: "Wholesale Distribution", href: "#" }
            ]
          }
        ]
      },
    
    
  
      resources: {
        title: "Resources",
        sections: [
          {
            heading: "Support",
            links: [
              // { text: "Community", href: "#" },
              // { text: "Renew Support", href: "#" },
              // { text: "Critical Patch Updates", href: "#" },
              // { text: "Lattice Support Rewards", href: "#" },
              { text: "My Lattice Support Login", href: "/sign-in" },
              // { text: "Cloud Console Login", href: "#" }
            ]
          },
          // {
          //   heading: "Services",
          //   links: [
          //     { text: "Customer Success Services", href: "#" },
          //     { text: "Implementation and Migration Services", href: "#" },
          //     { text: "Run and Operate Services", href: "#" },
          //     { text: "Training and Certification", href: "#" }
          //   ]
          // },
          // {
          //   heading: "Help Center",
          //   links: [
          //     { text: "Documentation", href: "#" },
          //     { text: "Reference Architectures", href: "#" },
          //     { text: "Tutorials and Hands-On Labs", href: "#" },
          //     { text: "Step-by-Step Videos", href: "#" },
          //     { text: "Downloads", href: "#" }
          //   ]
          // },
          // {
          //   heading: "Downloads",
          //   links: [
          //     { text: "Customer Downloads", href: "#" },
          //     { text: "Developer Downloads", href: "#" },
          //     { text: "Java Downloads", href: "#" },
          //     { text: "Java Runtime Environment (JRE) Consumer Downloads", href: "#" }
          //   ]
          // },
          // {
          //   heading: "Working with Us",
          //   links: [
          //     { text: "Contracts and Policies", href: "#" },
          //     { text: "Global Licensing Advisory Services", href: "#" },
          //     { text: "Trust Center", href: "#" },
          //     { text: "Invoicing", href: "#" },
          //     { text: "Financing", href: "#" }
          //   ]
          // }
        ]
      },
      
  
      customers: {
        title: "Customers",
        sections: [
          {
            heading: "Customer Programs",
            links: [
              { text: "Become a Customer", href: "/become-customer" },
              // { text: "Join the community", href: "#" },
              { text: "Write a review", href: "/customer-review" },
              // { text: "Customer Awards", href: "#" },
              // { text: "Lattice Support Rewards", href: "#" },
              // { text: "My Lattice Rewards", href: "#" }
            ]
          },
          // {
          //   heading: "Customer Stories",
          //   links: [
          //     { text: "Explore all customer successes", href: "#" },
          //     { text: "New customer partnerships", href: "#" },
          //     { text: "Lattice’s business transformation success: The Lattice Playbook", href: "#" },
          //     { text: "CX customer success", href: "#" },
          //     { text: "ERP customer success", href: "#" },
          //     { text: "HCM customer success", href: "#" },
          //     { text: "Autonomous Database customer success", href: "#" },
          //     { text: "Lattice Cloud Infrastructure (LCI) customer success", href: "#" }
          //   ]
          // }
        ]
      },
      
  
      partners: {
        title: "Partners",
        sections: [
          {
            heading: "Resources for Partners",
            links: [
              { text: "Become a Partner", href: "/become-partners" },
            ]
          },
          {
            heading: "Our Partners",
            links: [
              { text: "Find a Partner", href: "#" },
              // { text: "Lattice Marketplace", href: "#" },
              // { text: "Resources for Partners", href: "#" },
              // { text: "Become a Partner", href: "#" },
              // { text: "Already an OPN Member? Login", href: "#" }
            ]
          }
        ]
      },
      
      developers: {
        title: "Developers",
        sections: [
          {
            heading: "Developers",
            links: [
              { text: "Developer Resource Center", href: "/developer-resource-center" },
              // { text: "Developer Community", href: "#" },
              // { text: "Developer Blog", href: "#" },
              // { text: "Lattice DevLive", href: "#" },
              { text: "Developer Events", href: "/developer-events" }
            ]
          }
        ]
      },
      
  
      company: {
        title: "Company",
        sections: [
          {
            heading: "Company Info",
            links: [
              { text: "About Lattice", href: '/about'  },
              { text: "Executive Leadership", href: "/leadership" },
              { text: "Investor Relations", href: "/investor-relationship" },
              // { text: "Analyst Reports", href: "#" },
              // { text: "Corporate Responsibility", href: "#" },
              // { text: "Trust Center", href: "#" },
              { text: "Careers", href: "/careers" },
              { text: "Lattice Merchandise", href: "https://merchandise.lattice.inc/" },
              // { text: "Culture and Inclusion", href: "#" },
              // { text: "Corporate Governance", href: "#" },
              // { text: "Preview/Beta Testing", href: "#" },
              { text: "Blogs", href: "/company-blogs" },
              { text: "Events", href: "/events" },
              { text: "News", href: "/company-news" },
              // { text: "Research", href: "#" },
              // { text: "Lattice Education Foundation", href: "#" },
              { text: "Lattice Academy", href: "https://developers.latticelabs.io/" },
              // { text: "Sustainability", href: "#" }
            ]
          }
        ]
      }
      
    }
  };

// customer page navbar content

// navbarContent.js
export const customerStoryNavbarContent = {
  shareYourStory: { name: "Share your story", link: "#" },
  leaveAReview: { name: "Leave a review", link: "#" },
  joinCloudCommunity: { name: "Join our cloud community", link: "#" },
  exploreCustomerAwards: { name: "Explore customer awards", link: "#" },
  viewReferenceBenefits: { name: "View reference benefits", link: "#" },
};

  
// developer page slide content 



export const developerSlides = [
  {   //1
      title: "Lattice Code Assist in beta",
      description: "Build applications faster with AI.",
      image: simg8, // Replace with developer-related image
      link: "#",  // Developer-specific link
      buttonText: "Learn more"
  },
  {   //2
      title: "AI Resource Hub",
      description: "Gain AI knowledge, discover Lattice’s AI strategy, and start building AI solutions.",
      image: simg9, // Replace with developer-related image
      link: "#", // Developer-specific link
      buttonText: "Explore"
  }
];


// cookieContent.js
export  const cookieText = {
  heading: "Your choices regarding cookies on this site",
  loading: "Loading...",
  paragraph1:
    "By using our sites, Lattice and our third-party partners may use cookies and similar technologies to collect user data for the following purposes:",
  listItems: [
    {
      title: "Required cookies",
      description:
        "are necessary for the site to function, for example, to remember your log-in details and provide secure log-in.",
    },
    {
      title: "Functional cookies",
      description:
        "help us to optimize the site, for example, to collect statistics, to save your preferences for social interactions or to enable you to post comments on our site.",
    },
    {
      title: "Advertising cookies",
      description:
        "allow Lattice and our third-party partners to provide you with interest-based advertising and tailored content across your browsers and devices.",
    },
    
  ],
  paragraph2: "Please visit our privacy policy for more information.",
  btn1:"  Accept All",
  btn2:"  Deny All",
};


