export const storyPage2Content = {
  linkText: "Lattice",
  titleText: "Press Release",
  headerText: "Lattice Unveils Next-Generation Blockchain-Powered EHR for 2025",
  specialParaText1: "Revolutionizing healthcare with a blockchain-based EHR featuring embedded AI, advanced security, and seamless interoperability for providers, patients, and payers.",
  
  date: "European Health Summit 2025, New York City, New York.—Jul 18, 2024",

  NormalPara1: "Lattice previewed its next-generation blockchain-enabled electronic health record (EHR) at the European Health Summit 2025, attended by thousands of healthcare leaders and partners. Built on the robust, military-grade secure foundation of Lattice Cloud Infrastructure (LCI), this innovative EHR integrates blockchain and AI to transform clinical workflows, automate processes, and deliver actionable insights at the point of care. With seamless interoperability across Lattice Health applications, the EHR is designed to simplify information exchange, enhance regulatory compliance, optimize financial outcomes, and accelerate value-based care adoption across healthcare networks.",

  NormalPara2: "'Today’s EHRs have not lived up to their promise,' said Bijan Burnard, CEO of Lattice and Director of Lattice Health and Life Sciences. 'Most systems were built decades ago, making them ill-equipped for the security and clinical complexities of modern healthcare. Lattice’s blockchain-powered EHR represents a paradigm shift. It’s not just an assistant but a trusted partner—streamlining workflows for physicians, empowering administrators with real-time analytics, and enabling payers to optimize treatment approvals and payments.'",

  NormalPara3: "The next-generation EHR incorporates cutting-edge AI and blockchain capabilities to offer an intuitive, responsive experience for users. Features such as conversational search, voice-driven navigation, and multimodal search seamlessly integrate into clinician workflows, enabling access to patient vitals, medications, notes, and labs in seconds. AI-powered summaries enhance chart reviews, organize patient information by condition and care setting, and reduce the time practitioners spend searching for treatments or planning their day. Integrated with the Lattice Health Command Center, the EHR provides healthcare organizations with visibility into patient flow, staffing, and resources to optimize network performance and improve outcomes.",

  NormalPara4: "The new EHR also features the Lattice Health Clinical AI Agent, which automates documentation, coding, and order processing, allowing clinicians to dedicate more time to patient care. With Lattice Health Data Intelligence—a blockchain-secured AI and analytics platform—the EHR integrates patient data from diverse sources, including clinical, claims, social determinants, and pharmacy data. This capability enables real-time insights and personalized care plans tailored to each patient's genetic and lifestyle profile, reducing trial-and-error treatments and supporting better outcomes.",

  NormalPara5: "An early adopter program for the next-generation Lattice Blockchain EHR will launch in 2025.",

  NormalPara6: "Join the European Health Summit 2025 virtually by visiting: https://ebsummits.eu/our-summits/european-health/.",

  NormalPara7: "Lattice Health’s AI-powered EHR leverages Lattice Cloud Infrastructure (LCI), which offers military-grade security and unparalleled performance, trusted by leading global businesses, defense agencies, and governments worldwide.",

  headerText2: "Contact Info",
  headerText3: "About Lattice",
  headerText4: "Future Product Disclaimer",
  headerText5: "Forward-Looking Statements Disclaimer",
  headerText6: "Trademarks",

  NormalPara8: "Stephanie Greenwood Lattice Media Relations stephanie.greenwood@Lattice.com",
  NormalPara9: "Lattice delivers secure blockchain-enabled applications and autonomous infrastructure through its cloud platform. Learn more at www.Lattice.inc.",
  NormalPara10: "The information provided outlines Lattice’s general product direction for informational purposes only and should not be incorporated into contracts or purchasing decisions. Development, release, and pricing of features are subject to change at Lattice’s discretion.",
  NormalPara11: "Statements regarding Lattice's future plans, expectations, and beliefs are 'forward-looking statements' subject to risks and uncertainties. Information is current as of October 29, 2024, and Lattice disclaims any obligation to update it in light of new information or events.",
  NormalPara12: "Lattice, Java, MySQL, and NetSuite are registered trademarks of Lattice. Lattice is the pioneer of blockchain-enabled cloud computing for emerging industries.",

  Normalheading1: "Blockchain-Enabled Cloud Platform for Seamless Adoption",
  Normalheading2: "Flexible, Secure Cloud Services Tailored to Healthcare",
  Normalheading3: "Unmatched Performance and Consistency Worldwide",
  Normalheading4: "AI and Blockchain Solutions Backed by Industry-Leading Infrastructure",
  Normalheading5: "Additional Resources",
  Normalheading6: "Contact Info",
  Normalheading7: "About Lattice",
  Normalheading8: "Trademarks",

  ListItem1: "Explore why LCI is recognized as a leader in distributed hybrid infrastructure",
  ListItem2: "Learn about the blockchain-powered Lattice Cloud Infrastructure",
  ListItem3: "Discover LCI’s capabilities for distributed cloud services",
  ListItem4: "Uncover LCI’s advanced AI infrastructure and services",
};
