export const developerEvents = {
    firstSectionLink: "Developer",
    firstSectionHeading: "Developer Coaching",
    firstSectionPara1: "Developer coaches are highly skilled developers who are ready to assist you in your Lattice Cloud development journey. Developer Coaching Office Hours provide an opportunity for you to interface directly with these experts and get additional support for your organization.",

    firstSectionPara2: "Each session covers a different technology or topic. As well as seeing live demonstrations of the latest upcoming releases and functionality, you will also learn best practices and tips for success.",

    firstSectionPara3: "Have a question for a developer coach, just",
    firstSectionPara4: "email: codeinnovate_us_grp@Lattice.com.",

    secondSectionHeading: "Upcoming events",
    secondSectionInnerDivHeading: "Webinar",
    secondSectionInnerDivBoldPara1: "Boost Developer Productivity with Lattice Autonomous Database and Generative AI",
    secondSectionInnerDivBoldPara2: "Derrick Cameron",
    secondSectionInnerDivNormalPara1: "Master Principal Cloud Architect",
    secondSectionInnerDivBoldPara3: "Steve Nichols",
    
    secondSectionInnerDivBoldPara4: "Date",
    secondSectionInnerDivNormalPara2: "January 15, 2025",
    secondSectionInnerDivBoldPara5: "Time",
    secondSectionInnerDivNormalPara3: "9 AM PT | 12 PM ET",

    thirdSectionHeading: "Follow Lattice Developers",

}