import React, { useState, useRef, useEffect } from 'react';
import '../nav-bar-product-pages/Lattice-cloud-infrasturcture-compute-template-page.css'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import img1 from '../image/product-section-2/yt-thumbnail-of-7-page.jpg'

import img2 from '../image/german-federal-ministry-logo.svg'
import img3 from '../image/imperial-college-london-logo3.svg'
import svg1 from '../image/The_Related_Companies-Logo.svg'
import svg2 from '../image/deliveroo-logo3.png'
import svg4 from '../image/Four_Seasons_logo3.png'
import svg5 from '../image/AlbaniaTech-logo.svg'
import svg6 from '../image/product-section-2/page-7-sec-2-5.svg'
import svg7 from '../image/product-section-2/page-7-sec-2-6.avif'
import svg8 from '../image/product-section-2/page-7-sec-2-7.svg'

import svg12 from '../image/svg/view_week_24dp_E8EAED.svg'
import svg13 from '../image/svg/speed_24dp_E8EAED.svg'
import svg14 from '../image/svg/lock_open_24dp_E8EAED.svg'

import img5 from '../image/product-section-2/card1-img-of-section-2-page-7.avif'
import img6 from '../image/product-section-2/card2-img-of-section-2-page-7.avif'
import img7 from '../image/product-section-2/card3-img-of-section-2-page-7.avif'
import Latticelogo from '../image/lattice-logo-img.svg'
import { wealthManagement } from '../Content/English-Content/WealthManagement';
import { useChatWidget } from "../ChatWidgetContext";




function ProductSection2Page7() {


    const { toggleWidget } = useChatWidget();

    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };





    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: wealthManagement.fourthSectionPage1Heading,
            description:
                '',
            link: wealthManagement.fourthSectionPage1Link,

            fastTrackList: [wealthManagement.fourthSectionPage1fastTrackList1, wealthManagement.fourthSectionPage1fastTrackList2, wealthManagement.fourthSectionPage1fastTrackList3, wealthManagement.fourthSectionPage1fastTrackList4],
            cardheading: [wealthManagement.fourthSectionPage1cardheading1, wealthManagement.fourthSectionPage1cardheading2, wealthManagement.fourthSectionPage1cardheading3, wealthManagement.fourthSectionPage1cardheading4],
        },
        documentation: {
            heading: wealthManagement.fourthSectionPage2Heading,
            description:
                '',
            link: wealthManagement.fourthSectionPage2Link,

            fastTrackList: [wealthManagement.fourthSectionPage2fastTrackList1, wealthManagement.fourthSectionPage2fastTrackList2, wealthManagement.fourthSectionPage2fastTrackList3, wealthManagement.fourthSectionPage2fastTrackList4],
            cardheading: [wealthManagement.fourthSectionPage2cardheading1, wealthManagement.fourthSectionPage2cardheading2, wealthManagement.fourthSectionPage2cardheading3, wealthManagement.fourthSectionPage2cardheading4],
            headingof3div: 'Kick-start your app development with SDKs and the command-line interface.',
        },
        // events: {
        //     heading: wealthManagement.fourthSectionPage3Heading,
        //     description:
        //     '',
        //     link: wealthManagement.fourthSectionPage3Link,

        //     fastTrackList: [wealthManagement.fourthSectionPage3fastTrackList1, wealthManagement.fourthSectionPage3fastTrackList2, wealthManagement.fourthSectionPage3fastTrackList3, wealthManagement.fourthSectionPage3fastTrackList4],
        //     cardheading: [wealthManagement.fourthSectionPage3cardheading1, wealthManagement.fourthSectionPage3cardheading2, wealthManagement.fourthSectionPage3cardheading3, wealthManagement.fourthSectionPage3cardheading4],
        // },
        // technologies: {
        //     heading: wealthManagement.fourthSectionPage4Heading,
        //                description:
        //                '',
        //                link: wealthManagement.fourthSectionPage4Link,

        //                fastTrackList: [wealthManagement.fourthSectionPage4fastTrackList1, wealthManagement.fourthSectionPage4fastTrackList2, wealthManagement.fourthSectionPage4fastTrackList3, wealthManagement.fourthSectionPage4fastTrackList4],
        //                cardheading: [wealthManagement.fourthSectionPage4cardheading1, wealthManagement.fourthSectionPage4cardheading2, wealthManagement.fourthSectionPage4cardheading3, wealthManagement.fourthSectionPage4cardheading4],

        // },
        // languages: {
        //     heading: wealthManagement.fourthSectionPage5Heading,
        //                description:
        //                '',
        //                link: wealthManagement.fourthSectionPage5Link,

        //                fastTrackList: [wealthManagement.fourthSectionPage5fastTrackList1, wealthManagement.fourthSectionPage5fastTrackList2, wealthManagement.fourthSectionPage5fastTrackList3],
        //                cardheading: [wealthManagement.fourthSectionPage5cardheading1, wealthManagement.fourthSectionPage5cardheading2, wealthManagement.fourthSectionPage5cardheading3],

        // },
        // learn: {
        //     heading: wealthManagement.fourthSectionPage6Heading,
        //                description:
        //                '',
        //                link: wealthManagement.fourthSectionPage6Link,

        //                fastTrackList: [wealthManagement.fourthSectionPage6fastTrackList1, wealthManagement.fourthSectionPage6fastTrackList2, wealthManagement.fourthSectionPage6fastTrackList3],
        //                cardheading: [wealthManagement.fourthSectionPage6cardheading1, wealthManagement.fourthSectionPage6cardheading2, wealthManagement.fourthSectionPage6cardheading3],
        // },

    };




    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];




    const [currentSlide, setCurrentSlide] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

    const cards = [
        {
            id: 1,
            title: wealthManagement.eightSectionCard1Title,
            link: wealthManagement.eightSectionCard1Link1,
            link2: wealthManagement.eightSectionCard1Link2,
            imgs: img5
        },
        {
            id: 2,
            title: wealthManagement.eightSectionCard2Title,
            link: wealthManagement.eightSectionCard2Link1,
            link2: wealthManagement.eightSectionCard2Link2,
            imgs: img6
        },
        {
            id: 3,
            title: wealthManagement.eightSectionCard3Title,
            link: wealthManagement.eightSectionCard3Link1,
            link2: wealthManagement.eightSectionCard3Link2,
            imgs: img7
        },
    ];

    const handleNext = () => {
        if (currentSlide < cards.length - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth <= 600;
            setIsSmallScreen(isSmall);

            // Reset slide index if switching back to large screen
            if (!isSmall) {
                setCurrentSlide(0);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const [activeSection, setActiveSection] = useState("Free trial");

    // Handle click to update active section
    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };






    return (
        <>

            <div className="product-infra-compute-first-div">



                <h1 className="product-infra-compute-first-div-heading">

                    {wealthManagement.firstSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                    <p className="product-infra-compute-first-div-paragraph">{wealthManagement.firstSectionPara}</p>

                    {/* <button className="product-infra-compute-first-div-btn-1">{wealthManagement.firstSectionButton1}</button> */}

                    <button className="product-infra-compute-first-div-btn-2" onClick={toggleWidget} >{wealthManagement.firstSectionButton2}</button>

                </h1>

                {/* <div className="product-infra-compute-first-div-img-and-para">

    <img src={img1} className="product-infra-compute-img-of-first-div"></img>

    <p className="product-infra-compute-title-of-img">{wealthManagement.firstSectionSide2TitleImg}</p>

</div> */}


            </div>



            {/* <div className="product-infra-compute-sec-div">

                <h1 className="product-infra-compute-sec-div-heading">Lattice Offers First Zettascale Cloud Computing Cluster

                    <p className="product-infra-compute-sec-div-para">
                        This new AI supercomputer, the largest in the cloud, will deliver up to 131,072 NVIDIA GPUs, allowing you to build, train, and inference AI at scale.
                    </p>

                </h1>

                <button className="product-infra-compute-sec-div-btn">Get the news </button>

            </div> */}



            <div className="product-infra-compute-third-div">

                <h1 className="product-infra-compute-third-div-heading">

                    {wealthManagement.thirdSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="product-infra-compute-third-div-cards-wrapper">

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg12} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {wealthManagement.thirdSectionSide1Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{wealthManagement.thirdSectionSide1Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg13} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {wealthManagement.thirdSectionSide2Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{wealthManagement.thirdSectionSide2Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg14} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {wealthManagement.thirdSectionSide3Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{wealthManagement.thirdSectionSide3Para}</p>

                        </div>

                    </div>



                </div>


            </div>



            <div className="product-infrs-div-four-content-of-resources">
                <h1 className="product-infrs-div-four-solution-heading">

                    {wealthManagement.fourthSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="product-infrs-div-four-content-wrapper-of-resource">
                    <div className="product-infrs-div-four-content-1-of-resource">
                        <ul>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('downloads')}
                                >
                                    {wealthManagement.fourthSectionButton1}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('documentation')}
                                >
                                    {wealthManagement.fourthSectionButton2}
                                </button>
                            </li>
                            <li>
                                {/* <button
                                                               className={`product-infrs-div-four-btn-resource ${selectedResource === 'events' ? 'active' : ''
                                                                   }`}
                                                               onClick={() => handleResourceClick('events')}
                                                           >
                                                               {wealthManagement.fourthSectionButton3}
                                                           </button> */}
                            </li>
                            <li>
                                {/* <button
                                                               className={`product-infrs-div-four-btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                                                   }`}
                                                               onClick={() => handleResourceClick('technologies')}
                                                           >
                                                               {wealthManagement.fourthSectionButton4}
                                                           </button> */}
                            </li>
                            <li>
                                {/* <button
                                                               className={`product-infrs-div-four-btn-resource ${selectedResource === 'languages' ? 'active' : ''
                                                                   }`}
                                                               onClick={() => handleResourceClick('languages')}
                                                           >
                                                               {wealthManagement.fourthSectionButton5}
                                                           </button> */}
                            </li>
                            <li>
                                {/* <button
                                                               className={`product-infrs-div-four-btn-resource ${selectedResource === 'learn' ? 'active' : ''
                                                                   }`}
                                                               onClick={() => handleResourceClick('learn')}
                                                           >
                                                               {wealthManagement.fourthSectionButton6}
                                                           </button> */}
                            </li>
                        </ul>
                    </div>

                    {/* Combined Content */}
                    <div className="product-infrs-div-four-combined-content">
                        {/* Top Section */}
                        <div className="product-infrs-div-four-top-content">
                            <h1 className="product-infrs-div-four-resource-heading">{currentResource.heading}</h1>
                            <p className="product-infrs-div-four-resource-description">{currentResource.description}</p>
                            <button className="product-infrs-div-four-resource-btn">{currentResource.link}</button>
                        </div>


                        {/* Grid Section */}
                        <div className="product-infrs-div-four-grid-content">
                            {currentResource.fastTrackList.map((item, index) => (
                                <div className="product-infrs-div-four-grid-item" key={index}>
                                    <h2 className="product-infrs-div-four-grid-heading">{currentResource.cardheading[index]}</h2>
                                    <p className="product-infrs-div-four-grid-para">{item}</p>
                                    <a href="#" className="product-infrs-div-four-grid-link">{wealthManagement.learnMoreButton}</a>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>



            <div className="product-infra-div-five">

                <h1 className="product-infrs-div-four-solution-heading">

                    {wealthManagement.fifthSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                {/* <p className="product-infra-div-five-small-para">While other cloud service providers have higher prices in almost every region outside the US, LCI customers enjoy the same services, performance, and prices everywhere.</p>

                <p className="product-infra-div-five-small-para">For a similar configuration, LCI Compute costs less.*</p> */}

                <div className="product-infra-div-five-cards-wrapper">

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            {wealthManagement.fifthSectionSide1Heading}</h1>

                        <p className="product-infra-div-five-all-card-para">{wealthManagement.fifthSectionSide1Para}</p>

                    </div>

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            {wealthManagement.fifthSectionSide2Heading}</h1>

                        <p className="product-infra-div-five-all-card-para">{wealthManagement.fifthSectionSide2Para}</p>

                    </div>

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">

                            {wealthManagement.fifthSectionSide3Heading}</h1>

                        <p className="product-infra-div-five-all-card-para">{wealthManagement.fifthSectionSide3Para}</p>

                    </div>

                </div>

                {/* <div className="product-infra-div-five-btns-wrapper">

                    <button className="product-infra-div-five-btn-1">Learn more about cloud economics</button>

                    <button className="product-infra-div-five-btn-2">See LCI Compute pricing</button>

                </div> */}

                {/* <p className="product-infra-div-five-very-small-para">* Compared with the monthly cost of a two OCPU (four vCPU) AMD (E4) with 16 GB in US eastern regions. On-demand prices are as of June 5, 2024.</p> */}


            </div>



            <div className="product-infra-div-six">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="product-infra-div-six-heading-oftxt-wrapper">{wealthManagement.sixthSectionHeading}</h1>

                    {/* <a className="product-infra-div-six-link-of-txt-wrapper">See more reference architectures</a> */}

                </div>

                <div className="product-infra-div-six-cards-wrapper">

                    <div className="product-infra-div-six-card-1">

                        {/* <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                                                    </p> */}

                        <a className="product-infra-div-six-card-1-all-links">
                            {wealthManagement.sixthSectionCard1Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{wealthManagement.sixthSectionCard1Para2}</p>

                    </div>

                    <div className="product-infra-div-six-card-1">

                        {/* <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                                                    </p> */}

                        <a className="product-infra-div-six-card-1-all-links">
                            {wealthManagement.sixthSectionCard2Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{wealthManagement.sixthSectionCard2Para2}</p>

                    </div>


                    <div className="product-infra-div-six-card-1">

                        {/* <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                                                    </p> */}

                        <a className="product-infra-div-six-card-1-all-links">
                            {wealthManagement.sixthSectionCard3Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{wealthManagement.sixthSectionCard3Para2}</p>

                    </div>

                    <div className="product-infra-div-six-card-1">

                        {/* <p className="product-infra-div-six-card-1-para-1">Solution Architecture
                                                    </p> */}

                        <a className="product-infra-div-six-card-1-all-links">
                            {wealthManagement.sixthSectionCard4Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{wealthManagement.sixthSectionCard4Para2}</p>

                    </div>

                </div>

            </div>


            <div className="product-infra-div-seven">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="product-infrs-div-four-solution-heading">

                        LCI Compute customer successes
                        <span
                            style={{
                                display: "block",
                                width: "40px",
                                height: "4px",
                                background: "#f0cc71",
                                marginBottom: "3vh",
                                marginTop: "3vh",
                            }}
                        ></span>

                    </h1>

                    <a href='/customer-story' className="product-infra-div-six-link-of-txt-wrapper">Explore all LCI customer stories</a>

                </div>

                <div className="product-infra-div-seven-img-wrapper">

                    <img src={svg1} className="product-infra-div-seven-img-1"></img>

                    <img src={svg2} className="product-infra-div-seven-img-1"></img>

                    <img src={svg4} className="product-infra-div-seven-img-1"></img>

                    <img src={svg5} className="product-infra-div-seven-img-1"></img>

                    <img src={img2} className="product-infra-div-seven-img-1"></img>

                    <img src={img3} className="product-infra-div-seven-img-1"></img>
                   

                </div>

            </div>


            <div className="product-infra-div-eight">
                <h1 className="product-infra-div-eight-heading">{wealthManagement.eightSectionHeading}</h1>
                <div className="product-infra-div-eight-cards-wrapper">
                    {cards.map((card, index) => (
                        <div
                            key={card.id}
                            className="product-infra-div-eight-card-1"
                            style={{
                                transform: isSmallScreen
                                    ? `translateX(-${currentSlide * 100}%)`
                                    : "none",
                                transition: "transform 0.5s ease-in-out",
                            }}
                        >
                            <div className="product-infra-div-eight-card-1-content-div">
                                <a className="product-infra-div-eight-card-1-content-div-all-links">
                                    {card.link2}
                                </a>
                                <h1 className="product-infra-div-eight-card-1-content-div-all-heading">
                                    {card.title}
                                </h1>
                                <a className="product-infra-div-eight-card-1-content-div-all-links">
                                    {card.link}
                                </a>
                            </div>
                            <div className="product-infra-div-eight-card-1-img-div">
                                <img className="product-infra-div-eight-card-1-img-div" src={card.imgs}></img>
                            </div>
                        </div>
                    ))}
                </div>

                {isSmallScreen && (
                    <div className="carousel-controls">
                        <button onClick={handlePrev} disabled={currentSlide === 0}>
                            &lt;
                        </button>
                        <span>
                            {currentSlide + 1} / {cards.length}
                        </span>
                        <button onClick={handleNext} disabled={currentSlide === cards.length - 1}>
                            &gt;
                        </button>
                    </div>
                )}
            </div>


            {/* <div className="product-infra-last-div">

                <div className="product-infra-last-div-wrapper">

                    <h1 className="product-infra-last-div-heading">Get started with LCI Compute services</h1>

                    <div className="become-partners-dynamic-island" style={{ marginTop: "5vh" }}>


                        <button
                            onClick={() => handleSectionClick("Free trial")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Free trial" ? "active-section" : ""}`}
                        >
                            Free trial
                        </button>

                        <button
                            onClick={() => handleSectionClick("Hands-on labs")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Hands-on labs" ? "active-section" : ""}`}
                        >
                            Hands-on labs
                        </button>

                        <button
                            onClick={() => handleSectionClick("Estimate your costs")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Estimate your costs" ? "active-section" : ""}`}
                        >
                            Estimate your costs
                        </button>

                        <button
                            onClick={() => handleSectionClick("Contact sales")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Contact sales" ? "active-section" : ""}`}
                        >

                            Contact sales
                        </button>


                    </div>


                    <div className="become-partners-dynamic-content-area">


                        <div className="become-partners-sec-div-cards">
                            {activeSection === "Free trial" && (
                                <>

                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">Try 20+ Always Free cloud services, with a 30-day trial for even more</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">Lattice offers a Free Tier with no time limits on more than 20 services such as Compute, Storage, and Autonomous Database, as well as US$300 in free credits to try additional cloud services. Get the details and sign up for your free account today.</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn">Try LCI for free</button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">What’s included with Lattice Cloud Free Tier?</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">AMD and Arm Compute VMs</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">200 GB total block storage</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">10 GB object storage</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> 2 Autonomous Databases, 20 GB each</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li>
                                            </ul>

                                        </div>



                                    </div>

                                </>
                            )}

                            {activeSection === "Hands-on labs" && (
                                <>

                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-2-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">Learn with a hands-on lab</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">The best way to learn is to try it yourself. Use our tutorials and hands-on labs with the Lattice Cloud Free Tier, your own Lattice Cloud tenancy, or for selected solutions in an Lattice-provided free lab environment.</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn">See all tutorial and labs</button>

                                        </div>

                                        <div className="product-infra-last-div-2-list-card-2">

                                            <div className="product-infra-last-div-2-list-card-2-child-1">

                                                <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">Deploy HA Applications Using Load Balancers</h1>

                                                <p className="product-infra-last-div-1-list-card-1-all-sub-para">In this lab you will deploy web servers on two compute instances in Lattice Cloud Infrastructure (LCI), configured in High Availability mode by using a Load Balancer.</p>

                                                <a className="product-infra-last-div-1-list-card-1-all-sub-link">Start this lab now</a>

                                            </div>

                                            <div className="product-infra-last-div-2-list-card-2-child-1">

                                                <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">Get started with LCI Core Services</h1>

                                                <p className="product-infra-last-div-1-list-card-1-all-sub-para">Explore basic LCI services, including Compute, Networking, and Storage.</p>

                                                <a className="product-infra-last-div-1-list-card-1-all-sub-link">Start this lab now</a>

                                            </div>

                                            <div className="product-infra-last-div-2-list-card-2-child-1">

                                                <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">Deploy Lattice Container Engine for Kubernetes</h1>

                                                <p className="product-infra-last-div-1-list-card-1-all-sub-para">In this lab you will deploy a Container Engine for Kubernetes cluster, connect to it, and run a sample application using LCI CLI.</p>

                                                <a className="product-infra-last-div-1-list-card-1-all-sub-link">Start this lab now</a>

                                            </div>

                                            <div className="product-infra-last-div-2-list-card-2-child-1">

                                                <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">Migrate Tomcat Java Applications to LCI</h1>

                                                <p className="product-infra-last-div-1-list-card-1-all-sub-para">Explore how to migrate Tomcat to Lattice Cloud Infrastructure and connect to an Autonomous Database.</p>

                                                <a className="product-infra-last-div-1-list-card-1-all-sub-link">Start this lab now</a>

                                            </div>



                                        </div>

                                    </div>

                                </>
                            )}


                            {activeSection === "Estimate your costs" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">See how much you can save with LCI</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">Lattice Cloud pricing is simple, with consistent low pricing worldwide, supporting a wide range of use cases. To estimate your low rate, check out the cost estimator and configure the services to suit your needs.</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn">Try Cost Estimator</button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">Experience the difference</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">1/4 the outbound bandwidth costs</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">3X the compute price-performance</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">Same low price in every region</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> Low pricing without long term commitments</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li>
                                            </ul>

                                        </div>



                                    </div>






                                </>
                            )}

                            {activeSection === "Contact sales" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">Contact sales</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">Interested in learning more about Lattice Cloud Infrastructure? Let one of our experts help.</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn">Get in touch</button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">They can answer questions such as</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">How do I get started with Lattice Cloud?</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">What kinds of workloads can I run on LCI?</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">What types of services does LCI offer?</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> 2 Autonomous Databases, 20 GB each</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li>
                                            </ul>

                                        </div>



                                    </div>




                                </>
                            )}


                        </div>

                    </div>


                </div>

            </div> */}













            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    );
};

export default ProductSection2Page7;
