export const blockchainCitizenIdentitySuite = {
    firstSectionHeading: "Lattice Blockchain Citizen Identity Suite",
    firstSectionPara: "Empower governments with secure, efficient, and decentralized citizen identification. The Lattice Blockchain Citizen Identity Suite leverages zero-knowledge proof credentials to ensure privacy, security, and seamless identity management for citizens. Transform public services with advanced blockchain-powered solutions that prioritize transparency and trust.",
    firstSectionButton1: "Request a Demo",
    firstSectionButton2: "Contact Our Team",
    firstSectionSide2TitleImg: "Revolutionizing Citizen Identification with Blockchain",

    thirdSectionSide1Heading: "Introducing Blockchain-Powered Identity Management",
    thirdSectionSide1Para: "Learn how governments can streamline citizen identification processes with the power of blockchain technology. Ensure data accuracy, reduce fraud, and improve service delivery.",
    thirdSectionSide2Heading: "Secure and Private Citizen Identity",
    thirdSectionSide2Para: "With zero-knowledge proof credentials, citizen data remains private while enabling seamless verification for government services.",
    thirdSectionSide3Heading: "Trusted by Governments Worldwide",
    thirdSectionSide3Para: "Discover how governments are using Lattice Blockchain Citizen Identity Suite to enhance transparency, security, and efficiency in identity management.",

    fourthSectionHeading: "Comprehensive Features for Citizen Identity Management",
    fourthSectionButton1: "Identity Verification",
    fourthSectionButton2: "Data Privacy",
    fourthSectionButton3: "Fraud Prevention",
    fourthSectionButton4: "Interoperable Systems",
    fourthSectionButton5: "Real-Time Analytics",
    
    fourthSectionPage1Heading: "Advanced Identity Verification",
    fourthSectionPage1Description: "Ensure secure and accurate citizen identity verification with blockchain-powered zero-knowledge proof credentials. Authenticate citizens without exposing sensitive personal data.",
    fourthSectionPage1Link: "Learn More About Identity Verification",
    fourthSectionPage1cardheading1: "Zero-Knowledge Proof Technology",
    fourthSectionPage1fastTrackList1: "Verify identity without revealing personal information.",
    fourthSectionPage1cardheading2: "Tamper-Proof Records",
    fourthSectionPage1fastTrackList2: "Immutable blockchain records for secure data management.",
    fourthSectionPage1cardheading3: "Decentralized Data Storage",
    fourthSectionPage1fastTrackList3: "Distributed data to minimize risk of breaches.",
    fourthSectionPage1cardheading4: "Real-Time Verification",
    fourthSectionPage1fastTrackList4: "Authenticate identities instantly across government systems.",
    
    fourthSectionPage2Heading: "Data Privacy and Protection",
    fourthSectionPage2Description: "Safeguard citizen data with blockchain encryption and zero-knowledge proofs. Meet regulatory requirements while ensuring privacy at every level.",
    fourthSectionPage2Link: "Explore Data Privacy Solutions",
    fourthSectionPage2cardheading1: "End-to-End Encryption",
    fourthSectionPage2fastTrackList1: "Protect data in transit and at rest.",
    fourthSectionPage2cardheading2: "Regulatory Compliance",
    fourthSectionPage2fastTrackList2: "Support GDPR, HIPAA, and other global standards.",
    fourthSectionPage2cardheading3: "Access Controls",
    fourthSectionPage2fastTrackList3: "Grant secure access to authorized personnel only.",

    fourthSectionPage3Heading: "Prevent Identity Fraud with Blockchain",
    fourthSectionPage3Description: "Eliminate identity fraud with tamper-proof records and decentralized verification processes. Strengthen trust in public services.",
    fourthSectionPage3Link: "Learn About Fraud Prevention",
    fourthSectionPage3cardheading1: "Immutable Records",
    fourthSectionPage3fastTrackList1: "Secure data storage to prevent manipulation.",
    fourthSectionPage3cardheading2: "Decentralized Verification",
    fourthSectionPage3fastTrackList2: "Prevent identity theft with blockchain validation.",
    fourthSectionPage3cardheading3: "Biometric Integration",
    fourthSectionPage3fastTrackList3: "Enhance security with biometric authentication.",
    fourthSectionPage3cardheading4: "Audit Trails",
    fourthSectionPage3fastTrackList4: "Enable comprehensive tracking for every transaction.",

    fourthSectionPage4Heading: "Interoperability Across Government Systems",
    fourthSectionPage4Description: "Integrate seamlessly with existing government systems to create a unified digital identity framework.",
    fourthSectionPage4Link: "Learn About System Integration",
    fourthSectionPage4cardheading1: "API Integration",
    fourthSectionPage4fastTrackList1: "Easily connect with legacy systems.",
    fourthSectionPage4cardheading2: "Cross-Border Collaboration",
    fourthSectionPage4fastTrackList2: "Support international identity verification.",
    fourthSectionPage4cardheading3: "Real-Time Data Sharing",
    fourthSectionPage4fastTrackList3: "Enable instant updates across all platforms.",

    fourthSectionPage5Heading: "Real-Time Insights and Analytics",
    fourthSectionPage5Description: "Gain actionable insights into citizen services with advanced analytics and blockchain data visualization.",
    fourthSectionPage5Link: "Explore Analytics Features",
    fourthSectionPage5cardheading1: "Custom Dashboards",
    fourthSectionPage5fastTrackList1: "Monitor key metrics in real-time.",
    fourthSectionPage5cardheading2: "Data Visualization",
    fourthSectionPage5fastTrackList2: "Simplify complex data with visual tools.",
    fourthSectionPage5cardheading3: "Predictive Analytics",
    fourthSectionPage5fastTrackList3: "Forecast trends and improve decision-making.",
    fourthSectionPage5cardheading4: "Secure Collaboration",
    fourthSectionPage5fastTrackList4: "Share insights securely with authorized users.",

    sixthSectionHeading: "Why Choose Lattice Blockchain Citizen Identity Suite?",
    sixthSectionLink: "See Customer Success Stories",
    sixthSectionCard1Para2: "Leverage cutting-edge blockchain technology to revolutionize citizen identity systems with zero-knowledge proof credentials.",
    sixthSectionCard1Heading: "Innovative Blockchain Technology",
    sixthSectionCard2Para2: "Enhance trust and efficiency in public services through secure and private citizen identification.",
    sixthSectionCard2Heading: "Unmatched Security and Privacy",
    sixthSectionCard3Para2: "Seamlessly integrate with legacy systems while meeting global compliance standards.",
    sixthSectionCard3Heading: "Interoperability and Compliance",
    sixthSectionCard4Para2: "Trusted by governments worldwide to transform citizen identity management.",
    sixthSectionCard4Heading: "Proven Success",

    seventhSectionHeading: "Explore Success Stories",
    seventhSectionLink: "View Case Studies",

    secondSectionHeading: "Learn More About Lattice Blockchain Citizen Identity Suite",
    secondSectionButton: "Download the Product Brochure (PDF)",

}