export const imperialCollege = {
    section1Link: "Lattice",
    section1Heading: "Imperial College London pioneers a blockchain curriculum with Lattice Cloud",
    section1Para1: "The world-renowned academic institution partners with Lattice to develop a cutting-edge curriculum centered on blockchain technology and cloud infrastructure.",
    share: "Share:",
    section1Para2: "We consolidated our various educational platforms and research data into Lattice Cloud. Now we can seamlessly offer labs, lectures, and hands-on projects around the most pressing and complex topics in blockchain. It’s possible today in a way it never has been before.",
    section1Para3: "Dr. Emily Hart, Program Director, Centre for Blockchain and Distributed Systems, Imperial College London",
    boxHeading: "Curriculum and Tools",

    section2Heading: "Academic challenges",
    section2Para1: "Headquartered in the heart of London, Imperial College is a globally recognized institution, excelling in science, engineering, medicine, and business. Imperial’s diverse body of students and researchers pursues groundbreaking innovations that address real-world issues.",
    section2Para2: "Before partnering with Lattice, Imperial College's blockchain instruction utilized multiple platforms and siloed databases. Student labs, teaching resources, and research initiatives were spread across several unconnected systems, making it difficult for faculty to coordinate projects and analyze outcomes.",
    section2Para3: "The college needed a unified solution for delivering robust blockchain curriculum content, powering lab environments, and providing secure, scalable infrastructures for research. Professors and course designers recognized that a centralized approach with leading-edge tools would enable deeper learning experiences.",
    section2Para4: "We can now provide on-demand resources and real-time labs with just a push of a button. It has revolutionized the way we teach and conduct research in blockchain.",

    section2Footer: "Professor James Pell, Chair of Cloud Computing and Decentralized Systems, Imperial College London",

    section3Heading: "Why Imperial College chose Lattice",
    section3Para1: "Having already explored various cloud solutions for research, Imperial College sought a platform that could unify course delivery, lab simulations, and student collaboration within a single environment. Lattice’s integrated blockchain cloud infrastructure stood out for its flexible, secure, and scalable design.",
    section3Para2: "Imperial started by integrating Lattice Fusion Cloud Services for foundational coursework in distributed ledgers. This was followed by specialized modules—Blockchain Security, Smart Contract Development, and Decentralized Finance (DeFi)—all supported by Lattice’s robust training environments. By leveraging Lattice’s platform, faculty gained a reliable way to demonstrate real-world blockchain scenarios and guide students through hands-on exercises.",
    section3Para3: "With the help of Lattice Cloud Infrastructure (LCI), professors could merge data, simulations, and real-time analytics in a single view for hundreds of students. Lattice Autonomous Data Warehouse and Lattice Analytics enable seamless performance at scale, secure data sharing, and in-depth reporting, reducing the burden on IT teams and allowing more time for innovative teaching methods.",

    section4Heading: "Results",
    section4Para1: "By implementing a unified curriculum and leveraging Lattice’s blockchain and analytics capabilities, Imperial College eliminated barriers between lectures, lab work, and research. Students worldwide can now engage with interactive tools, advanced simulations, and integrated datasets from a single, user-friendly platform.",
    section4Para2: "Previously, students and professors navigated multiple systems for labs and coursework, leading to inconsistencies and slowdowns. Consolidating everything on Lattice Cloud gives our academic community one central place to learn, innovate, and collaborate.",
    section4Para3: "Automation of course setups, data collection, and assignment grading simplifies daily tasks for faculty, freeing them to focus on teaching and mentorship. Migrating to a unified Lattice-based platform has also significantly reduced operational costs and centralized oversight of classroom technology for Imperial’s IT team.",
    section4Para4: "Moving on-premises research servers and disjointed lab environments to Lattice Cloud Infrastructure improved performance by up to 60x in certain simulations. Queries and data processing for blockchain-related projects run much faster, cutting compute costs and enhancing security. The comprehensive view of academic analytics also provides deeper insights into student progress, enabling more effective teaching strategies.",
    section4Para5: "Meanwhile, Lattice Autonomous Data Warehouse saves faculty countless hours each week by automating data management tasks, enhancing the overall security and reliability of the institution’s blockchain research data. Students benefit from real-time lab provisioning and streamlined feedback loops, further enhancing their learning experience.",

    section6Heading: "About the partner",
    section6Para1: "Imperial College London is a world-class university fostering excellence in science, engineering, medicine, and business. With a global reputation for innovation, Imperial leads cutting-edge research and education that address some of humanity’s greatest challenges.",
    section6Para2: "Published: December 8, 2023",

    section7Heading: "Learn more",
    section7Link1: "Imperial College London",
    section7Link2: "Video: Inside Imperial’s Blockchain Curriculum with Lattice Cloud",

    section8Heading: "Curriculum and Product Highlights",
    section8Link1: "Lattice Analytics",
    section8Link2: "Lattice Autonomous Data Warehouse",
    section8Link3: "Lattice Cloud Infrastructure",
    section8Link4: "Lattice Intelligent Advisor",
    section8Link5: "Lattice Fusion Services",
    section8Link6: "Blockchain Security Modules",
    section8Link7: "Smart Contract Development Labs",
    section8Link8: "Decentralized Finance Coursework",
};
