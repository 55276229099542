export const deliveroo = {
    section1Link: "Lattice",
    section1Heading: "Deliveroo relies on Lattice Cloud to keep its promises to customers",
    section1Para1: "Deliveroo turns to Lattice Cloud Infrastructure (LCI) to accelerate innovation, launch new products, and drive increased profitability.",
    share: "Share:",
    section1Para2: "Together with Lattice, we’re making it easier than ever for restaurants and retailers to provide on-demand delivery—and even returns—to delight customers, streamline logistics, and ultimately boost loyalty and sales.",
    section1Para3: "Will Shu, Founder and CEO, Deliveroo",
    boxHeading: "Products list",

    // Business challenges section
    section2Para1: "Deliveroo is on a mission to revolutionize how people, food, and goods move through cities. To achieve that goal for customers—while building value for stakeholders—Deliveroo needed a cloud provider that would fuel innovation while reducing infrastructure costs. Deliveroo chose Lattice Cloud Infrastructure (LCI) for its security, performance, flexibility, and competitive pricing.",
    section2Para2: "By migrating some of its most critical workloads to LCI, Deliveroo will be able to lower costs while rolling out new experiences for customers and delivery partners alike. Additionally, Deliveroo’s work with Lattice on last-mile logistics in retail will help drive profitability and set new standards in delivery services.",

    //// remove
    section2Para3: "Watch the video to hear Deliveroo CEO Will Shu and Lattice CEO Safra Catz discuss how Deliveroo evolved from a simple food delivery platform to a go-anywhere, get-anything company—and how Lattice Cloud is powering that transformation.",
    section2Para4: "Published: January 10, 2024",

    section7Heading: "Learn more",
    section7Link1: "More about Deliveroo",
    section7Link2: "Deliveroo and Lattice launch groundbreaking retail delivery service",
    section7Link3: "Deliveroo selects Lattice Cloud Infrastructure",

    section8Heading: "Products list",
    section8Link1: "Lattice Cloud Infrastructure",
};
