export const productAIML = {
    firstSectionHeading: "LCI AI Services Powered by Decentralized AI (D-AI)",
    firstSectionPara: "Harness the transformative power of decentralized AI with D-AI, a trailblazer in AI research and deployment. Built on Lattice Cloud Infrastructure (LCI), these services enable secure, efficient, and ethical AI-powered solutions that enhance human productivity and interaction.",
    firstSectionButton1: "Speak to a D-AI Expert",
    firstSectionSide2TitleImg: "Discover D-AI: Decentralizing AI Innovation (2:06)",

    thirdSectionHeading: "Decentralized AI in Action",
    thirdSectionSide1Heading: "Revolutionary Generative AI with Cotton",
    thirdSectionSide1Para: "Engage in natural, dynamic conversations with Cotton, a generative AI chatbot designed to enhance user experiences with decentralized AI technology.",
    thirdSectionSide2Heading: "Plug-and-Play AI Modules",
    thirdSectionSide2Para: "Simplify integration with D-AI’s modular tools, designed for instant use. Generate detailed documents, create business plans, and transform workflows effortlessly.",
    thirdSectionSide3Heading: "Try Decentralized AI for Free",
    thirdSectionSide3Para: "Explore Lattice AI services with a Free Tier and a 30-day free trial account. Access US$300 in credits to innovate with decentralized AI solutions.",

    sixthSectionHeading: "Discover the Power of Decentralized AI",
    sixthSectionCard1Para2: "Automate complex workflows with D-AI agents, performing multi-window tasks like email management, scheduling updates, and data synchronization—all through remote desktops on Lattice Cloud.",
    sixthSectionCard1Heading: "Intelligent Automation with D-AI Agents",
    sixthSectionCard2Para2: "Generate insights and outputs with D-AI tools like Cotton Summary. From spreadsheets to mind maps, business plans to contracts, create detailed and structured documents effortlessly.",
    sixthSectionCard2Heading: "Generative AI Tools by D-AI",
    sixthSectionCard3Para2: "Leverage D-AI's multifunctional machine learning analytics for predictive modeling, real-time analysis, and other applications. Designed to be decentralized, scalable, and user-friendly.",
    sixthSectionCard3Heading: "Multifunctional Machine Learning Analytics",
    sixthSectionCard4Para2: "Utilize Lattice Cloud’s scalable AI infrastructure, optimized for decentralized AI workloads. Enjoy elasticity, performance, and secure processing tailored to your needs.",
    sixthSectionCard4Heading: "Scalable AI Infrastructure",

    seventhSectionHeading: "Trusted Globally for Decentralized AI Solutions",
    seventhSectionLink: "Explore Customer Stories",

    eightSectionHeading: "What’s New with Decentralized AI?",
    eightSectionCard1Title: "Lattice Labs runs large-scale AI workloads on Lattice Cloud Infrastructure",
    eightSectionCard1Link1: "Read the Lattice Labs story",
    eightSectionCard1Link2: "Read the AI Use Cases eBook",
    eightSectionCard1redirectPath1: "/news/announcement/lattice-labs-runs-large-scale-ai-workloads-on-lattice-cloud-infrastructure",

    eightSectionCard1Link2: "Read the AI Use Cases eBook",
    eightSectionCard2Title: "AI aids open enrollment process",
    eightSectionCard2Link1: "Read aids story",
    eightSectionCard2Link2: "Sustainability with Decentralized AI",
    eightSectionCard1redirectPath2: "/human-capital-management/ai-agents-open-enrollment/",
    
    eightSectionCard3Title: "Lattice Health Clinical AI Agent helps doctors spend more time with patients",
    eightSectionCard3Link1: "Read the Lattice Health  story",
    eightSectionCard3Link2: "Enhance Video Streaming",
    eightSectionCard1redirectPath3: "/news/announcement/lattice-health-clinical-ai-agent",

    ninthSectionButton1: "Try D-AI Services Free",
    ninthSectionButton2: "Explore AI Labs",
    ninthSectionButton3: "Visit the AI Solutions Hub",
    ninthSectionButton4: "Contact Sales",

    ninthSectionHeading: "Get Started with Decentralized AI on LCI",
    ninthSectionPage1Side1Heading: "Try Decentralized AI Services for Free",
    ninthSectionPage1Side1Para: "Access a free tier for most decentralized AI services and receive US$300 in credits to explore advanced capabilities. Start building with Lattice AI today.",
    ninthSectionPage1Side1button: "Start Your Free Trial",
    ninthSectionPage1Side2Heading: "What Decentralized AI Services Are Included?",
    ninthSectionPage1Side2List1: "Cotton Chatbot",
    ninthSectionPage1Side2List2: "Cotton Summary for Summarization",
    ninthSectionPage1Side2List3: "Cotton Vision for Image Analysis",
    ninthSectionPage1Side2List4: "Cotton Document Understanding",
    ninthSectionPage1Side2List5: "Multifunctional Machine Learning Analytics",
    ninthSectionPage1Side2List6: "Plug-and-Play AI Modules",

    ninthSectionPage2Side1Heading: "Learn Decentralized AI with Hands-On Labs",
    ninthSectionPage2Side1Para: "Dive into the decentralized AI revolution with tutorials and labs designed to seamlessly integrate D-AI into your workflows.",
    ninthSectionPage2Side1button: "View All Labs",

    ninthSectionPage2Side2Heading1: "Build Natural Conversations with Cotton",
    ninthSectionPage2Side2Para1: "Discover how to create engaging chatbots with decentralized AI technology through Cotton.",
    ninthSectionPage2Side2Button: "Start This Lab",

    ninthSectionPage2Side2Heading2: "Automate Workflows with D-AI Agents",
    ninthSectionPage2Side2Para2: "Use D-AI agents to handle tasks like email replies, scheduling, and data updates across multiple windows.",
    ninthSectionPage2Side2Button2: "Try Workflow Automation",

    ninthSectionPage2Side2Heading3: "Summarize Media Content with Cotton Summary",
    ninthSectionPage2Side2Para3: "Instantly generate summaries from audio and video files with decentralized AI-powered tools.",
    ninthSectionPage2Side2Button3: "Start Media Summarization",

    ninthSectionPage2Side2Heading4: "Master Machine Learning Analytics with D-AI",
    ninthSectionPage2Side2Para4: "Analyze data for predictive insights and decision-making using D-AI’s decentralized machine learning capabilities.",
    ninthSectionPage2Side2Button4: "Explore ML Analytics",

    ninthSectionPage3Side1Heading: "Empower Your Business with Decentralized AI",
    ninthSectionPage3Side1Para: "Reimagine workflows with decentralized AI-powered generative tools, automation, and analytics. Experience the future of AI today.",
    ninthSectionPage3Side1button: "Visit AI Solutions Hub",
    ninthSectionPage3Side2Heading: "Unleash Decentralized AI’s Potential",
    ninthSectionPage3Side2List1: "Fine-Tune Decentralized AI Models",
    ninthSectionPage3Side2List2: "Automate Complex Workflows",
    ninthSectionPage3Side2List3: "Summarize Content Seamlessly",
    ninthSectionPage3Side2List4: "Develop AI-Powered Applications",

    ninthSectionPage4Side1Heading: "Speak with Decentralized AI Experts",
    ninthSectionPage4Side1Para: "Learn how decentralized AI solutions can redefine your business. Our team is ready to help.",
    ninthSectionPage4Side1button: "Contact Us",
    ninthSectionPage4Side2Heading: "We Can Help You With",
    ninthSectionPage4Side2List1: "Integrating decentralized AI into your workflows",
    ninthSectionPage4Side2List2: "Understanding D-AI's unique capabilities",
    ninthSectionPage4Side2List3: "Maximizing efficiency with decentralized AI automation",
};
