export const charitableInstitution = {
    // 1. First Section
    firstSectionHeading: "Empower Charitable Impact",
    firstSectionPara: "The Lattice Blockchain Charitable Foundation Suite provides secure, transparent, and scalable tools for managing donations, grants, and community programs. Harness blockchain technology to streamline operations, ensure fund integrity, and drive meaningful change.",
    firstSectionButton1: "Explore Our Suite",
    firstSectionButton2: "Join Our Global Initiative",
    firstSectionSide2TitleImg: "How One Nonprofit Amplified Giving with Lattice Blockchain (2:06)",

    // 2. Second Section
    secondSectionHeading: "Introducing the First Blockchain-Powered Charitable Network",
    secondSectionPara: "Our new philanthropic platform is built to support millions of transactions and interactions, empowering charities and foundations worldwide to build trust, maximize resources, and innovate on a global scale.",
    secondSectionButton: "Learn More",

    // 3. Third Section
    thirdSectionHeading: "Why Choose the Lattice Blockchain Charitable Foundation Suite?",
    thirdSectionSide1Heading: "Up to 60% More Efficiency",
    thirdSectionSide1Para: "Unlike traditional solutions, Lattice's blockchain suite enables you to automate administrative tasks, verify donations, and reduce overhead. Many organizations report up to a 60% increase in operational efficiency.",
    thirdSectionSide2Heading: "48% Faster Disbursements",
    thirdSectionSide2Para: "By using smart contracts, charities can drastically cut the time it takes to disburse grants and funds. In fact, a recent study shows a 48% reduction in delays, ensuring help reaches beneficiaries faster.",
    thirdSectionSide3Heading: "Highly Secure and Transparent",
    thirdSectionSide3Para: "Blockchain technology ensures tamper-proof records of donations, grant allocations, and beneficiary data—reinforcing donor trust and improving transparency within your organization.",

    // 4. Fourth Section (Service Overview)
    fourthSectionHeading: "Key Tools in the Lattice Charitable Suite",
    fourthSectionButton1: "Grant Management",
    fourthSectionButton2: "Donation Tracking",
    fourthSectionButton3: "Volunteer Coordination",
    fourthSectionButton4: "Reporting and Analytics",
    fourthSectionButton5: "Compliance and Governance",

    // Sub-sections for each tool
    fourthSectionPage1Heading: "Grant Management",
    fourthSectionPage1Description: "Automate grant applications, approvals, and fund distribution using blockchain smart contracts to minimize manual errors and optimize transparency.",
    fourthSectionPage1Link: "Learn more about Grant Management",
    fourthSectionPage1cardheading1: "Customizable Smart Contracts",
    fourthSectionPage1fastTrackList1: "Tailor contracts to your organization's unique requirements, from small community grants to large-scale global funding projects.",
    fourthSectionPage1cardheading2: "Instant Verification",
    fourthSectionPage1fastTrackList2: "Leverage the blockchain to verify documentation, ensuring secure and speedy approvals.",
    fourthSectionPage1cardheading3: "Automated Workflows",
    fourthSectionPage1fastTrackList3: "Configure approval processes and track key milestones, reducing administrative burden for staff.",
    fourthSectionPage1cardheading4: "Transparent Audits",
    fourthSectionPage1fastTrackList4: "Maintain a tamper-proof record of all grant-related activities for compliance and stakeholder confidence.",
    learnMoreButton: "Learn More",

    fourthSectionPage2Heading: "Donation Tracking",
    fourthSectionPage2Description: "Build donor trust with transparent, real-time insights into where and how donations are allocated, powered by blockchain’s immutable ledger.",
    fourthSectionPage2Link: "Learn more about Donation Tracking",
    fourthSectionPage2cardheading1: "Real-Time Dashboards",
    fourthSectionPage2fastTrackList1: "Monitor donations in a single view, tracking disbursements across various programs and campaigns.",
    fourthSectionPage2cardheading2: "Smart Allocation",
    fourthSectionPage2fastTrackList2: "Leverage algorithmic insights to direct funds where they’re needed most, ensuring maximum impact.",
    fourthSectionPage2cardheading3: "Automated Receipts",
    fourthSectionPage2fastTrackList3: "Generate and send encrypted proofs of donation to donors, enhancing transparency and loyalty.",

    fourthSectionPage3Heading: "Volunteer Coordination",
    fourthSectionPage3Description: "Organize and mobilize volunteers across regions and projects, backed by blockchain-based identity verification for secure, efficient scheduling.",
    fourthSectionPage3Link: "Learn more about Volunteer Coordination",
    fourthSectionPage3cardheading1: "Smart Scheduling",
    fourthSectionPage3fastTrackList1: "Use blockchain to match volunteer skills with project needs, optimizing time and resources.",
    fourthSectionPage3cardheading2: "Verified Credentials",
    fourthSectionPage3fastTrackList2: "Ensure volunteer identities and qualifications are valid, building trust for sensitive or high-impact projects.",

    fourthSectionPage4Heading: "Reporting and Analytics",
    fourthSectionPage4Description: "Leverage advanced analytics to measure outcomes, report impact, and showcase results to stakeholders—ensuring data-driven decisions for your organization.",
    fourthSectionPage4Link: "Learn more about Reporting and Analytics",
    fourthSectionPage4cardheading1: "Dynamic Dashboards",
    fourthSectionPage4fastTrackList1: "Track donation trends, volunteer engagement, and grant performance in real time.",
    fourthSectionPage4cardheading2: "Predictive Insights",
    fourthSectionPage4fastTrackList2: "Use machine learning to forecast funding requirements, identify growth areas, and optimize campaigns.",
    fourthSectionPage4cardheading3: "Open Data Access",
    fourthSectionPage4fastTrackList3: "Provide verified data to donors, partners, and auditors for maximum transparency.",
    fourthSectionPage4cardheading4: "Blockchain Audit Trails",
    fourthSectionPage4fastTrackList4: "Generate immutable, time-stamped records for internal reviews and regulatory compliance.",

    fourthSectionPage5Heading: "Compliance and Governance",
    fourthSectionPage5Description: "Keep your organization aligned with evolving regulations. Lattice’s suite simplifies compliance checks, ensuring accountability for every donation and grant.",
    fourthSectionPage5Link: "Learn more about Compliance and Governance",
    fourthSectionPage5cardheading1: "Regulatory Alignment",
    fourthSectionPage5fastTrackList1: "Stay current with local and international standards for charitable giving, safeguarding your nonprofit status.",
    fourthSectionPage5cardheading2: "Automated Policy Updates",
    fourthSectionPage5fastTrackList2: "Adopt new compliance protocols without disrupting existing workflows.",
    fourthSectionPage5cardheading3: "Risk Management",
    fourthSectionPage5fastTrackList3: "Identify anomalies in real time with built-in alerts and monitoring tools.",
    fourthSectionPage5cardheading4: "Audit-Ready Documentation",
    fourthSectionPage5fastTrackList4: "Generate detailed reports for regulators, donors, and boards with a single click.",

    // 5. Fifth Section: Value Proposition
    fifthSectionHeading: "Maximize Your Charitable Impact",
    fifthSectionPara1: "Cut overhead costs and manual operations, so more of every donation reaches those in need. Empower your charitable mission with Lattice’s blockchain-based solutions—no matter your organization’s size or location.",
    fifthSectionPara2: "Our transparent pricing ensures no hidden costs, helping you plan sustainable programs that truly make a difference.",
    fifthSectionCard1Heading: "Eliminate Fraud",
    fifthSectionCard1Para: "Blockchain-based records reduce the risk of tampering or unauthorized fund usage.",
    fifthSectionCard2Heading: "Enhance Donor Trust",
    fifthSectionCard2Para: "Secure, transparent processes encourage repeat donors and larger gifts.",
    fifthSectionCard3Heading: "Boost Efficiency",
    fifthSectionCard3Para: "Automate time-consuming tasks to focus on mission-critical initiatives.",
    fifthSectionButton1: "Explore Our Impact",
    fifthSectionButton2: "View Pricing",
    fifthSectionPara3: "* Compared with traditional legacy solutions for charitable foundations. Actual savings may vary based on program size and complexity.",

    // 6. Sixth Section: Reference Architectures
    sixthSectionHeading: "Lattice Blockchain Solutions in Action",
    sixthSectionLink: "See more success stories",
    sixthSectionCard1Para1: "Solution Showcase",
    sixthSectionCard1Para2: "Learn how an international nonprofit manages grants and donors seamlessly through our integrated blockchain suite.",
    sixthSectionCard1Heading: " Migrate Legacy Systems to Lattice Blockchain for Transparent Fund Management",

    sixthSectionCard2Para2: "Discover how to deploy advanced analytics for large-scale philanthropy campaigns, enabling informed decisions across multiple projects.",
    sixthSectionCard2Heading: " Deploy an AI-Driven Donor Engagement Platform with Lattice Blockchain",

    sixthSectionCard3Para2: "Explore how volunteer coordination is elevated through secure identity verification and scheduling automation, ensuring the right people are in the right places.",
    sixthSectionCard3Heading: " Orchestrate Global Volunteer Efforts via Decentralized Coordination Tools",

    sixthSectionCard4Para2: "Streamline compliance checks with automated audits, generating real-time reports for regulators and donors alike.",
    sixthSectionCard4Heading: " Build an End-to-End Compliance Framework Using Blockchain Smart Contracts",

    // 7. Seventh Section: Customer Successes
    seventhSectionHeading: "Global Charities Trust Lattice",
    seventhSectionLink: "Explore all success stories",

    // 8. Eighth Section: Latest Updates
    eightSectionHeading: "What’s New?",
    eightSectionCard1Title: "September 2024—IDC Highlights Lattice as a Key Innovator in Charitable Blockchain",
    eightSectionCard1Link1: "Access the full IDC Report",
    eightSectionCard1Link2: "See the Analysis",
    eightSectionCard2Title: "September 2024—New Smart Contract Tools for Transparent Grant Distribution",
    eightSectionCard2Link1: "Read the Announcement",
    eightSectionCard2Link2: "Announcement",
    eightSectionCard3Title: "September 2024—World’s First Blockchain-Powered Zettascale Charity Network",
    eightSectionCard3Link1: "Read the Blog",
    eightSectionCard3Link2: "Blog",

    // 9. Ninth Section: Getting Started
    ninthSectionHeading: "Get Started with the Lattice Charitable Foundation Suite",
    ninthSectionPage1Side1Heading: "Try 20+ Always Free Services, with a 30-Day Trial for More",
    ninthSectionPage1Side1Para: "Lattice offers a robust Free Tier with no time limits on several blockchain-enabled services, plus US$300 in free credits to explore additional solutions. Sign up today and discover the potential for your foundation.",
    ninthSectionPage1Side1button: "Create Your Free Account",
    ninthSectionPage1Side2Heading: "What’s Included in the Free Tier?",
    ninthSectionPage1Side2List1: "Basic Donation Management",
    ninthSectionPage1Side2List2: "Grant Application Tools",
    ninthSectionPage1Side2List3: "Volunteer Coordination Dashboard",
    ninthSectionPage1Side2List4: "Blockchain-Backed Database Access",
    ninthSectionPage1Side2List5: "Community Engagement Tools",
    ninthSectionPage1Side2List6: "10+ More No-Cost Services",
    ninthSectionPage1Side2List7: "US$300 Credits for 30 Days to Try Premium Services",

    ninthSectionPage2Side1Heading: "Hands-On Tutorials",
    ninthSectionPage2Side1Para: "Get comfortable with our suite through easy, step-by-step tutorials and labs. Practice in your own environment or leverage Lattice’s free lab resources to see real-world results.",
    ninthSectionPage2Side1button: "View All Tutorials",

    ninthSectionPage2Side2Heading1: "Set Up a Grant Distribution Workflow",
    ninthSectionPage2Side2Para1: "This tutorial walks you through creating and deploying a grant distribution process, complete with automated disbursements and blockchain verification.",
    ninthSectionPage2Side2Button: "Start This Tutorial",

    ninthSectionPage2Side2Heading2: "Get Started with Core Blockchain Services",
    ninthSectionPage2Side2Para2: "Learn to deploy the essential components for transparent donation tracking and volunteer management.",

    ninthSectionPage2Side2Heading3: "Launch Lattice Charitable Container Services",
    ninthSectionPage2Side2Para3: "Use the Lattice command line to create a container environment for your nonprofit’s custom applications.",

    ninthSectionPage2Side2Heading4: "Migrate Legacy Systems to Lattice",
    ninthSectionPage2Side2Para4: "Explore how to transition an existing solution to the Lattice Blockchain Charitable Foundation Suite.",

    ninthSectionPage3Side1Heading: "Calculate Your Savings with Lattice",
    ninthSectionPage3Side1Para: "Lattice Cloud offers consistent, competitive pricing. Use our cost estimator to determine your potential savings and plan your philanthropic projects with confidence.",
    ninthSectionPage3Side1button: "Try Cost Estimator",
    ninthSectionPage3Side2Heading: "Experience the Difference",
    ninthSectionPage3Side2List1: "1/4 the Transaction Fees",
    ninthSectionPage3Side2List2: "3X Faster Grant Processing",
    ninthSectionPage3Side2List3: "Transparent Pricing Worldwide",
    ninthSectionPage3Side2List4: "No Long-Term Commitments",

    ninthSectionPage4Side1Heading: "Contact Our Philanthropy Advisors",
    ninthSectionPage4Side1Para: "Interested in learning how Lattice can transform your charitable organization? Connect with our experts for personalized guidance.",
    ninthSectionPage4Side1button: "Get in Touch",
    ninthSectionPage4Side2Heading: "They can help answer questions like:",
    ninthSectionPage4Side2List1: "How do I start a pilot program?",
    ninthSectionPage4Side2List2: "How can blockchain improve donor trust?",
    ninthSectionPage4Side2List3: "Which services best fit my nonprofit’s needs?",

    ninthSectionButton1: "Free Charitable Suite Services",
    ninthSectionButton2: "Hands-On Labs",
    ninthSectionButton3: "Success Architectures",
    ninthSectionButton4: "Contact an Advisor",
};