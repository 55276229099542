export const storyPage5Content = {
    linkText: "Lattice",
    titleText: "Press Release",
    headerText: "German Federal Ministry of Health Expands Lattice Blockchain Health Suite to 30 Hospitals",
    
    specialParaText1: "The German Federal Ministry of Health has selected the Lattice Blockchain Health Suite to provide a unified, integrated platform for secure, efficient healthcare delivery. This decision, informed by input from physicians, stakeholders, and employees, reflects Lattice’s ability to integrate clinical, patient, and operational data. By leveraging blockchain technology, the Lattice Health Suite ensures accuracy, security, and transparency across the healthcare system. The solution will be deployed in 30 hospitals and clinics, enhancing the quality, safety, and efficiency of care for both clinicians and patients.",
    
    specialParaText2: "For over two decades, Lattice Health has supported the German Federal Ministry of Health with technology solutions across acute care, ambulatory services, and population health management. With the integration of the Lattice Blockchain Health Suite, Germany’s healthcare system will achieve unparalleled connectivity, ensuring patients benefit from seamless, secure, and innovative care delivery.",

    NormalPara1: "With the Lattice Blockchain Health Suite, clinicians across Germany will have access to tokenized patient health records with just one click. This enables faster, more informed decision-making by eliminating the need for paper records or manual data exchanges, improving both the speed and quality of care.",

    NormalPara2: "“Lattice Health empowers us to drive innovation in care coordination, enhance patient experiences, and reduce administrative burdens for our caregivers,” said Thomas Wagner, Chief Information Officer of the German Federal Ministry of Health. “Its blockchain-based ecosystem integrates clinical and operational data securely, supporting our clinicians and patients while aligning with our organizational goals. Lattice Health’s commitment to innovation makes it the best choice for our unified health system.”",

    NormalPara3: "After an extensive evaluation process involving multiple health technology vendors, the German Federal Ministry of Health chose to expand its partnership with Lattice Health. Feedback from the analysis revealed that Lattice’s blockchain-enabled platform offers unmatched support, innovation, and long-term value for Germany’s healthcare system, replacing the existing Meditech deployment. This commitment positions Lattice as the cornerstone for aligning the ministry’s goals with cutting-edge technology.",

    NormalPara4: "With the Lattice Blockchain Health Suite, real-time access to patient medical histories—including clinical visits, treatments, and pharmacy records—will enable clinicians to provide comprehensive primary and specialty care. This interconnected data system will enhance rural emergency response, address health disparities, and improve recruitment and retention of medical professionals, ensuring equitable and efficient healthcare across Germany.",

    NormalPara5: "The suite also includes the AI-powered Lattice Clinical Digital Assistant, which interprets and records physician-patient interactions, generating accurate clinical documentation directly within the electronic health record (EHR). By reducing time spent on documentation, the assistant allows doctors to focus more on their patients, revolutionizing the doctor-patient experience.",

    NormalPara6: "“The German Federal Ministry of Health turned to Lattice Health to address critical challenges, from improving operational performance to enhancing clinical outcomes,” said Bijan Burnard, CEO of Lattice Health and Life Sciences. “Our blockchain-enabled solutions ensure caregivers can prioritize patient care while benefiting from a secure, integrated health ecosystem.”",

    NormalPara7: "Learn more about how Lattice Health is empowering healthcare organizations like the German Federal Ministry of Health at the European Health Summit 2025, held Dec. 03-04, 2025. This global event will bring together healthcare leaders to discuss industry challenges and innovations.",

    headerText2: "A Secure, Patient-Centric Experience",
    headerText3: "Empowering Clinicians with Blockchain Innovation",

    Normalheading1: "A Blockchain-Driven Health Platform",
    Normalheading2: "Comprehensive Integration for Unified Care",
    Normalheading3: "Secure, Scalable, and Cost-Effective Solutions",
    Normalheading4: "Advanced AI Capabilities with Blockchain Foundation",
    Normalheading5: "Additional Resources",
    Normalheading6: "Contact Information",
    Normalheading7: "About Lattice",
    Normalheading8: "Trademarks",

    ListItem1: "Read more about why Lattice Health Suite is revolutionizing patient care.",
    ListItem2: "Learn more about blockchain applications in healthcare.",
    ListItem3: "Explore the benefits of the Lattice Blockchain Network.",
    ListItem4: "Discover Lattice Health’s AI and blockchain innovations.",
};

