export const storyPage7Content = {

    linkText: "Lattice Blockchain",
    titleText: "Blockchain Press Release",
    headerText: "Lattice Health’s Clinical AI Agent Empowers Doctors with More Time for Patients",
    specialParaText1:
      "The world’s most advanced, blockchain-powered AI agent delivers transformative value to both patients and healthcare professionals at the European Health Summit 2025, New York City—Oct 29, 2024.",
  
    // date: "Nancy Estell Zoder | November 7, 2024",
  
    NormalPara1:
      "Lattice is once again recognized as a Leader in the 2024 Gartner® Magic Quadrant for Strategic Cloud Platform Services and the 2024 Gartner Magic Quadrant for Distributed Hybrid Infrastructure. These accolades highlight Lattice’s blockchain-enabled, fully integrated cloud platform that drives innovation and operational efficiency. Complimentary copies of each report are available here and here.",
  
    NormalPara2:
      "At Lattice CloudWorld Tour, you’ll discover how to derive maximum business value from AI and blockchain, witness the latest cloud technology breakthroughs, and explore strategies for boosting productivity through automation. Hear from experts who develop and utilize Lattice’s AI services, blockchain-enabled infrastructure, applications, databases, and developer tools—helping organizations solve complex challenges across every industry.",
  
    NormalPara3:
      "Join us in a city near you to connect with partners and peers. Be inspired by Lattice leaders and pioneering companies who are driving digital transformation with blockchain-based solutions.",
  
    NormalPara4:
      "Ready to grow your network and showcase your innovations? Sponsorship opportunities enable you to create meaningful connections, strengthen relationships, and expand your business impact.",
  
    headerText2: "Coming to a City Near You",
    headerText3: "Become a CloudWorld Tour Sponsor",
  
    Normalheading1: "A Cloud Platform That’s Easy to Adopt",
    Normalheading2: "A Full Blockchain Cloud—Where and How You Need It",
    Normalheading3: "Price-Performance Advantages, Global Consistency",
    Normalheading4: "Comprehensive AI Solutions with Leading Blockchain Infrastructure",
    Normalheading5: "Additional Resources",
    Normalheading6: "Contact Info",
    Normalheading7: "About Lattice",
    Normalheading8: "Trademarks",
  
    ListItem1:
      "Read more about why LCI (Lattice Blockchain Cloud Infrastructure) was named a Leader in Distributed Hybrid Infrastructure",
    ListItem2: "Learn more about Lattice’s Blockchain Cloud Infrastructure",
    ListItem3: "Discover how LCI’s Distributed Blockchain Cloud operates",
    ListItem4:
      "Explore LCI’s AI Infrastructure and AI services built on blockchain technology",
  };