export const nonFungibleTokenAdministration = {
    firstSectionHeading: "Non-Fungible Private Token Generator",
        firstSectionPara: "Lattice's Non-Fungible Private Token Generator enables businesses to tokenize a wide range of assets, including retail products, services, documents, health data, charitable donations, and more. This powerful tool creates unique tokens for private blockchain ecosystems tailored to specific business needs.",
    
        secondSectionHeading: "Why Choose Lattice's Token Generator?",
        secondSectionButton: "Learn more",
    
        thirdSectionSide1Heading: "Comprehensive Tokenization Guide",
        thirdSectionSide1Para: "Dive into our step-by-step guide for creating and deploying tokens. Learn about token standards, implementation strategies, and practical applications for diverse industries.",
        thirdSectionSide1Button: "Download the guide (PDF)",
    
        thirdSectionSide2Heading: "Explore Real-World Use Cases",
        thirdSectionSide2Para: "See how leading businesses use tokenization for gamified customer experiences, secure data management, and supply chain optimization. Watch our case studies and testimonials.",
        thirdSectionSide2Button: "Watch case studies",
    
        bookingsSectionHeading: "Client Success Stories",
        bookingsSectionPara: "Lattice's Non-Fungible Private Token Generator is trusted by organizations worldwide to power their blockchain ecosystems. Discover how our clients innovate using tokenization.",
        bookingsSectionButton: "Read success stories",
    
        fourthSectionHeading: "Key Features",
        fourthSectionButton1: "Custom Token Standards",
        fourthSectionButton2: "Seamless Integration",
        fourthSectionButton3: "Enhanced Security",
        fourthSectionButton4: "Scalability",
        fourthSectionButton5: "Compliance Tools",
    
        fourthSectionPage1Heading: "Custom Token Standards",
        fourthSectionPage1cardheading1: "Versatile Token Creation",
        fourthSectionPage1fastTrackList1: "Create tokens following standards like ERC-721 or customize them for unique requirements, such as retail or supply chain management.",
        fourthSectionPage1cardheading2: "Tailored Attributes",
        fourthSectionPage1fastTrackList2: "Configure token properties, including metadata, ownership, and transfer permissions, to match your ecosystem's needs.",
    
        fourthSectionPage2Heading: "Seamless Integration",
        fourthSectionPage2cardheading1: "Plug-and-Play APIs",
        fourthSectionPage2fastTrackList1: "Easily integrate tokens into your existing systems using our robust API, ensuring a smooth transition to blockchain-based processes.",
    
        fourthSectionPage3Heading: "Enhanced Security",
        fourthSectionPage3cardheading1: "Fortified Smart Contracts",
        fourthSectionPage3fastTrackList1: "Leverage pre-audited smart contracts and cutting-edge encryption to secure tokenized assets and transactions.",
    
        fourthSectionPage4Heading: "Scalability",
        fourthSectionPage4cardheading1: "Built for Growth",
        fourthSectionPage4fastTrackList1: "Expand your tokenized ecosystem effortlessly with Lattice's high-performance, scalable infrastructure.",
    
        fourthSectionPage5Heading: "Compliance Tools",
        fourthSectionPage5cardheading1: "Regulatory-Ready",
        fourthSectionPage5fastTrackList1: "Integrate compliance tools, including KYC and AML protocols, to meet global regulatory standards effortlessly.",
    
        fifthSectionHeading: "Benefits of the Token Generator",
        fifthSectionCard1Heading: "Effortless Tokenization",
        fifthSectionCard1Para: "Simplify the token creation process with an intuitive interface and powerful backend tools.",
        fifthSectionCard2Heading: "Robust Security",
        fifthSectionCard2Para: "Protect your assets with enterprise-grade security and compliance-ready features.",
        fifthSectionCard3Heading: "Custom Solutions",
        fifthSectionCard3Para: "Tailor tokens for specific applications, including gamified customer experiences, HR management, and data monetization.",
    
        sixthSectionHeading: "Resources",
        sixthSectionSide1Heading: "Documentation",
        sixthSectionSide1Link1: "Token Generator User Manual",
        sixthSectionSide1Link2: "API Reference",
    
        sixthSectionSide2Heading: "Training",
        sixthSectionSide2Link1: "Tokenization 101",
        sixthSectionSide2Link2: "Advanced Blockchain Integration",
    
        sixthSectionSide3Heading: "On-Demand Webinars",
        sixthSectionSide3Link1: "Introduction to Custom Tokens",
        sixthSectionSide3Link2: "Best Practices for Token Security",
        sixthSectionSide3Link3: "Scaling Blockchain Ecosystems",
    
        sixthSectionSide4Heading: "Connect",
        sixthSectionSide4Link1: "Lattice Blog",
        sixthSectionSide4Link2: "Twitter",
        sixthSectionSide4Link3: "Facebook",
        sixthSectionSide4Link4: "YouTube",
        sixthSectionSide4Link5: "LinkedIn",
    
        seventhSectionHeading: "Get Started with the Non-Fungible Private Token Generator",
        seventhSectionBox1Heading: "Try it for Free",
        seventhSectionBox1Para: "Experience the power of tokenization firsthand with a free trial of our platform.",
        seventhSectionBox1Button: "Start Free Trial",
        seventhSectionBox2Heading: "Schedule a Demo",
        seventhSectionBox2Para: "Learn how our token generator can enhance your business. Request a personalized demo today.",
        seventhSectionBox2Button: "Request a Demo",
        seventhSectionBox3Heading: "Contact Us",
        seventhSectionBox3Para: "Have questions? Connect with our experts to explore possibilities for your private blockchain.",
        seventhSectionBox3Button: "Get in Touch",
    };
    