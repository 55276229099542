export const dai = {
    section1Link: "Lattice",
    section1Heading: "D-AI Revolutionizes AI Model Efficiency with Lattice Cloud Infrastructure",
    section1Para1:
        "D-AI’s cutting-edge AI model training and inference capabilities reach new heights with Lattice Cloud Infrastructure (LCI) AI solutions, backed by robust support and high-speed networking.",
    share: "Share:",
    section1Para2:
        "The combination of LCI’s AI infrastructure, advanced GPU configurations, and proactive support has been a game-changer. Lattice's commitment to our success aligns perfectly with our goals of delivering unparalleled AI solutions.",
    section1Para3: "Bijan Burnard, CEO and Founder of D-AI",
    boxHeading: "Lattice Products Empowering D-AI",

    section2Heading: "Driving Innovation in AI",
    section2Para1:
        "D-AI is pushing the boundaries of artificial intelligence by creating models capable of understanding and analyzing videos at a human-like level. These models empower developers and enterprises to search extensive video datasets for specific moments—whether identifying a player’s dunk from an entire basketball season or pinpointing a break-in from thousands of hours of surveillance footage.",
    section2Para2:
        "The unique challenge of training multimodal AI models lies in their demand for immense compute resources to process countless hours of video content. With Lattice Cloud Infrastructure AI solutions, including high-performance GPU configurations and low-latency RDMA networking, D-AI achieves unprecedented efficiencies. Leveraging LCI’s advanced capabilities, D-AI accelerated its model training by 5X to 10X, enabling faster time-to-market and exceeding performance expectations.",
    section2Para3: "Published: October 17, 2024",

    section7Heading: "Discover More",
    section7Link1: "Learn about D-AI",

    section8Heading: "Lattice-Powered D-AI Solutions",
    section8Link1: "LCI AI Infrastructure for High-Performance AI Training",
    section8Link2: "LCI Advanced GPU Solutions",
    section8Link3: "LCI Kubernetes Engine (LKE) for Scalable AI Deployments",
};
