export const becomePartner = {
    firstSectionHeading: "Join the Lattice Blockchain Partner Program",
    firstSectionPara:
      "Lattice is redefining collaboration with its enhanced Blockchain Partner Program, empowering organizations to lead innovation, drive customer success, and transform industries. Together, we can create cutting-edge blockchain solutions that enhance efficiency, security, and collaboration. Partner with us to unlock new opportunities and redefine what's possible.",
  
    firstSectionSide2TitleImg:
      "'Our program empowers partners with unparalleled flexibility and resources to achieve transformative outcomes.' — Leah Yomtovian, SVP, Partner and Operations Strategy, Lattice",
  
    secondSectionButton1: "Blockchain Cloud",
    secondSectionButton2: "Industry Solutions",
    secondSectionButton3: "Blockchain Infrastructure",

    cloudSection1Heading: "Why Partner with Lattice?",
    cloudSection1Para: "Empower your business with Lattice's blockchain-driven innovation through:",
    cloudSection1Card1Heading: "Comprehensive Training",
    cloudSection1Card1Para: "Equip your team with blockchain expertise for seamless sales, delivery, and customer success.",
    cloudSection1Card2Heading: "Collaborative Marketing",
    cloudSection1Card2Para: "Work with Lattice to position and market blockchain solutions effectively.",
    cloudSection1Card3Heading: "Technical Enablement",
    cloudSection1Card3Para:
      "Access development environments, integration validations, and technical accelerators to streamline blockchain deployments.",
    cloudSection1Card4Heading: "Dedicated Support",
    cloudSection1Card4Para: "Leverage Lattice’s extensive resources to drive customer success and business growth.",
  
    cloudSection2Heading: "Tailored Blockchain Partner Tiers",
    cloudSection2Para:
      "Choose a partnership level that aligns with your organization’s goals and unlock a suite of tailored benefits.",
    cloudSection2Card1Heading: "Level 1",
    cloudSection2Card1List1: "5 seats for blockchain training",
    cloudSection2Card1List2: "Development and test environment access",
    cloudSection2Card2Heading: "Level 2",
    cloudSection2Card2List1: "100 seats for advanced blockchain training",
    cloudSection2Card2List2: "125K partner credits for enhanced benefits",
    cloudSection2Card2List3: "Regional blockchain marketing collaboration",
    cloudSection2Card3Heading: "Level 3",
    cloudSection2Card3List1: "500 seats for advanced blockchain training",
    cloudSection2Card3List2: "750K partner credits for added benefits",
    cloudSection2Card3List3: "Global blockchain marketing collaboration",
    cloudSection2Card3List4: "Exclusive listing on Lattice Marketplace",
  
    cloudSection3Side1Heading1: "Core Benefits Across All Levels",
    cloudSection3Side1Heading2: "Blockchain Expertise",
    cloudSection3Side1Heading3: "Co-Marketing Opportunities",
    cloudSection3Side1Heading4: "Technical Assistance",
    cloudSection3Side1Heading5: "Customer Success Tools",
    cloudSection3Side1List1: "Sales-readiness blockchain training",
    cloudSection3Side1List2: "Foundational delivery training",
    cloudSection3Side1List3: "Marketing resources for blockchain solutions",
    cloudSection3Side1List4: "Funding for qualified technical assessments",
    cloudSection3Side1List5: "Sales enablement tools",
    cloudSection3Side1List6: "Pre-sales technical support",
    cloudSection3Side1List7: "Joint account planning and selling",
    cloudSection3Side1List8: "Blockchain solution listings on Lattice Marketplace",
    cloudSection3Side1List9: "Integration validation",
    cloudSection3Side1List10: "Customer success tools and resources",
    cloudSection3Side1Para: "+ Earn Partner Credits for Advanced Benefits",
  
    cloudSection3Side2Heading1: "Unlock More with Partner Credits",
    cloudSection3Side2Heading2: "Advanced Training",
    cloudSection3Side2Heading3: "Expanded Marketing",
    cloudSection3Side2Heading4: "Enhanced Technical Enablement",
    cloudSection3Side2Heading5: "Priority Support",
    cloudSection3Side2List1: "Advanced blockchain training",
    cloudSection3Side2List2: "Comprehensive marketing resources",
    cloudSection3Side2List3: "Dedicated development/test environments",
    cloudSection3Side2List4: "Solution integration validation",
    cloudSection3Side2List5: "Architecture and migration services",
    cloudSection3Side2List6: "Partner Success Manager",
    cloudSection3Side2List7: "Partner Technical Account Manager",
  
    cloudSection4Heading: "Transform Industries with Lattice Blockchain",
    cloudSection4Button: "Submit Your Interest",
  
    cloudSection5Heading: "What Our Partners Say About Us",
    cloudSection6Card1Quote:
      "Together with Lattice, we're delivering blockchain solutions that redefine industries and foster innovation.",
    cloudSection6Card2Quote:
      "Lattice's expertise and resources have enabled us to create exceptional value for our customers.",
    cloudSection6Card3Quote:
      "Partnering with Lattice empowers us to solve complex challenges with cutting-edge blockchain technology.",
    cloudSection6Card4Quote:
      "Lattice's blockchain solutions have been transformative for our clients and our business.",
  
    // cloudSection6Card1Para: "",
    // cloudSection6Card2Para: "",
    // cloudSection6Card3Para: "",
    // cloudSection6Card4Para: "",
  
    cloudSection7Heading: "Join Leading Brands in Blockchain Innovation",
    cloudSection7Para:
      "Learn how Lattice partners are transforming industries with blockchain-driven solutions that empower their customers.",
  
    cloudSection8Para:
      "Explore the Lattice Blockchain Partner Program and discover opportunities to innovate, grow, and lead in blockchain technology.",
  };
  