// page 1 of tools sections 


import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { footerSections, cookieText } from '../Content/eng_content';
import './products-section-tools-template-page.css';
import img1 from '../image/tools/yt-video-thumbnail-rj9p5tuzlmy.png'
import Latticelogo //     'All software downloads are free, and most come with a Developer License that allows you to use full versions of the products at no charge while developing and prototyping your applications, or for strictly self-educational purposes.',

    // link: 'All Download',
    from
    // link: 'All Download',
    '../image/lattice-logo-img.svg';
import { fungiblePrivate } from '../Content/English-Content/FungiblePrivate';
import { useChatWidget } from "../ChatWidgetContext";


function FungiblePrivateTokenGenerator() {

     const { toggleWidget } = useChatWidget();

    const latestNewsRef = useRef(null);
    const aiRef = useRef(null);
    const languagesRef = useRef(null);
    const solutionsRef = useRef(null);
    const communityRef = useRef(null);
    const resourcesRef = useRef(null);

    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const [activeSection, setActiveSection] = useState("Cloud");

    // Handle click to update active section
    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: fungiblePrivate.fourthSectionPage1Heading,
            description:
                '',
            link: '',

            fastTrackList: [fungiblePrivate.fourthSectionPage1fastTrackList1, fungiblePrivate.fourthSectionPage1fastTrackList2],
            cardheading: [fungiblePrivate.fourthSectionPage1cardheading1, fungiblePrivate.fourthSectionPage1cardheading2],
        },
        documentation: {
            heading: fungiblePrivate.fourthSectionPage2Heading,
            description:
                '',
            link: '',

            fastTrackList: [fungiblePrivate.fourthSectionPage2fastTrackList1],
            cardheading: [fungiblePrivate.fourthSectionPage2cardheading1],
            headingof3div: 'Kick-start your app development with SDKs and the command-line interface.',
        },
        events: {
            heading: fungiblePrivate.fourthSectionPage3Heading,
            description:
                '',
            link: '',

            fastTrackList: [fungiblePrivate.fourthSectionPage3fastTrackList1],
            cardheading: [fungiblePrivate.fourthSectionPage3cardheading1],
            // link: 'Explore the content',
            headingof3div: 'Access developer resources and learn about future events designed just for you. Hear from experts in their field and gain practical hands-on experience.',
        },
        technologies: {
            heading: fungiblePrivate.fourthSectionPage4Heading,
            description:
                '',
            link: '',

            fastTrackList: [fungiblePrivate.fourthSectionPage4fastTrackList1],
            cardheading: [fungiblePrivate.fourthSectionPage4cardheading1],
            headingof3div: 'Technologies',
        },
        languages: {
            heading: fungiblePrivate.fourthSectionPage5Heading,
            description:
                '',
            link: '',

            fastTrackList: [fungiblePrivate.fourthSectionPage5fastTrackList1],
            cardheading: [fungiblePrivate.fourthSectionPage5cardheading1],
            headingof3div: 'languages:',
        },
        // learn: {
        //     heading: 'AI Resource Hub',
        //     description:
        //         'Discover, learn and build. Access a range of AI resources to help you get ahead on your AI journey.',
        //     link: 'Learn more',
        //     cardheading: ['Flexible VMs', 'Dense I/O VMs', 'Optimized VMs', 'Dedicated VM hosts'],
        //     fastTrackList: ['VMs are customizable from 1–94 cores for AMD EPYC processors, 1–32 cores for Intel, and 1–80 cores for Ampere processors, with 1–1,094 GB RAM and network bandwidth that scales with the number of cores.', 'Dense I/O VMs, with 8–24 cores and 128–512 GB RAM, offer 6.8–27.2 TB high performance, locally attached NVMe storage for big data and analytics workloads.', 'Optimized VM shapes provide 1–18 high-frequency processor cores and support cluster networking with 4–40 Gb/sec of network bandwidth.', 'Dedicated VM host shapes offer bare metal instances with 36–128 cores and 512–2,048 GB memory per host with software to host VMs.'],
        //     headingof3div: 'Learn',
        // },

    };

    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };

    return (
        <>





            <div className="product-tools-tempalte-human-resource-first-div">

                <div className="product-tools-tempalte-human-resource-first-div-child-1">
                    <h1 className="product-tools-tempalte-human-resource-first-div-heading">

                        {fungiblePrivate.firstSectionHeading}

                        <span
                            style={{
                                display: "block",
                                width: "40px",
                                height: "4px",
                                background: "#f0cc71",
                                marginBottom: "3vh",
                                marginTop: "3vh",
                            }}
                        ></span>


                        <p className="product-tools-tempalte-human-resource-first-div-paragraph">
                            {fungiblePrivate.firstSectionPara}
                        </p>

                    </h1>

                </div>



                <div className="product-tools-tempalte-human-resource-first-div-child-2">

                    <img src={img1} className="product-tools-tempalte-human-resource-first-div-child-2-img">

                    </img>
                </div>
            </div>

            {/* <div className="product-tools-tempalte-human-resource-second-div-para-btn">

                <h1 className="product-tools-tempalte-human-resource-second-div-heading">{fungiblePrivate.secondSectionHeading}</h1>

                <button className="product-tools-tempalte-human-resource-second-div-btn">{fungiblePrivate.secondSectionButton}</button>

            </div> */}

            <div className="product-tools-tempalte-human-resource-third-div">

                <div className="product-tools-tempalte-human-resource-third-div-child-1">
                    <h1 className="product-tools-tempalte-human-resource-third-div-child-1-heading">{fungiblePrivate.thirdSectionSide1Heading}</h1>
                    <p className="product-tools-tempalte-human-resource-third-div-child-1-paragraph">
                        {fungiblePrivate.thirdSectionSide1Para}
                    </p>
                    {/* <button className="product-tools-tempalte-human-resource-third-div-child-1-btn">{fungiblePrivate.thirdSectionSide1Button}</button> */}

                </div>

                <div className="vertical-line"></div>

                <div className="product-tools-tempalte-human-resource-third-div-child-2">
                    <h1 className="product-tools-tempalte-human-resource-third-div-child-2-heading">{fungiblePrivate.thirdSectionSide2Heading}</h1>
                    <p className="product-tools-tempalte-human-resource-third-div-child-2-paragraph">
                        {fungiblePrivate.thirdSectionSide2Para}
                    </p>
                    {/* <button className="product-tools-tempalte-human-resource-third-div-child-2-btn">{fungiblePrivate.thirdSectionSide2Button}</button> */}

                </div>
            </div>

            <div className="product-tools-tempalte-human-resource-fourth-div">
                <h1 className="product-tools-tempalte-human-resource-fourth-div-heading">

                    {fungiblePrivate.bookingsSectionHeading}

                    <p className="product-tools-tempalte-human-resource-fourth-div-paragraph">
                        {fungiblePrivate.bookingsSectionPara}
                    </p>

                    <a href="/customer-story" style={{ color:"inherit" , textDecoration:"none"}}> <button className="product-tools-tempalte-human-resource-fourth-div-btn">{fungiblePrivate.bookingsSectionButton} </button></a>

                </h1>
            </div>

            <div className="product-human-resource-div-four-content-of-resources">
                <h1 className="product-human-resource-div-four-solution-heading">

                    {fungiblePrivate.fourthSectionHeading}

                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>


                <div className="product-human-resource-div-four-content-wrapper-of-resource">
                    <div className="product-human-resource-div-four-content-1-of-resource">
                        <ul>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('downloads')}
                                >
                                    {fungiblePrivate.fourthSectionButton1}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('documentation')}
                                >
                                    {fungiblePrivate.fourthSectionButton2}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'events' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('events')}
                                >
                                    {fungiblePrivate.fourthSectionButton3}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('technologies')}
                                >
                                    {fungiblePrivate.fourthSectionButton4}
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'languages' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('languages')}
                                >
                                    {fungiblePrivate.fourthSectionButton5}
                                </button>
                            </li>
                            <li>
                                {/* <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'learn' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('learn')}
                                >
                                    {fungiblePrivate.fourthSectionButton6}
                                </button> */}
                            </li>
                        </ul>
                    </div>

                    {/* Combined Content */}
                    <div className="product-human-resource-div-four-combined-content">
                        {/* Top Section */}
                        <div className="product-human-resource-div-four-top-content">
                            <h1 className="product-human-resource-div-four-resource-heading">{currentResource.heading}</h1>
                            <p className="product-human-resource-div-four-resource-description">{currentResource.description}</p>
                            {/* <button className="product-infrs-div-four-resource-btn">{currentResource.link}</button> */}
                        </div>


                        {/* Grid Section */}
                        <div className="product-human-resource-div-four-grid-content">
                            {currentResource.fastTrackList.map((item, index) => (
                                <div className="product-human-resource-div-four-grid-item" key={index}>
                                    <h2 className="product-human-resource-div-four-grid-heading">{currentResource.cardheading[index]}</h2>
                                    <p className="product-human-resource-div-four-grid-para">{item}</p>
                                    {/* <a href="#" className="product-infrs-div-four-grid-link">Learn More</a> */}
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>

            {/* Content-3 */}
            {/* <div className="content-3-of-resource">
                    <p className="res-3-div-para">{currentResource.headingof3div}</p>
                      <ul>
                        {currentResource.fastTrackList.map((item, index) => (
                          <li className="list-of-3-div" key={index}> <a className="link-of-com">
            
                            <span className="arrow-of-div-3">
                            <svg width="20" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 4l4 4-4 4" stroke="#006B8F" strokeWidth="1.5" fill="none"/>
                            </svg>
                            </span>
                            
                             {item} </a></li>
                        ))}
                      </ul>
                    </div> */}
            {/* </div>
                </div> */}

            <div className="product-tools-tempalte-human-resource-sixth-div">
                <h1 className="product-tools-tempalte-human-resource-sixth-div-heading">
                    {fungiblePrivate.fifthSectionHeading}
                </h1>
                <h3 className="product-tools-tempalte-human-resource-sixth-div-sub-heading">
                    {fungiblePrivate.fifthSectionCard1Heading}
                </h3>
                <p className="product-tools-tempalte-human-resource-sixth-div-para">
                    {fungiblePrivate.fifthSectionCard1Para}
                </p>

                <h3 className="product-tools-tempalte-human-resource-sixth-div-sub-heading">
                    {fungiblePrivate.fifthSectionCard2Heading}
                </h3>
                <p className="product-tools-tempalte-human-resource-sixth-div-para">
                    {fungiblePrivate.fifthSectionCard2Para}
                </p>

                <h3 className="product-tools-tempalte-human-resource-sixth-div-sub-heading">
                    {fungiblePrivate.fifthSectionCard3Heading}
                </h3>
                <p className="product-tools-tempalte-human-resource-sixth-div-para">
                    {fungiblePrivate.fifthSectionCard3Para}
                </p>
            </div>

            <div className="product-tools-tempalte-human-resource-seventh-div">
                <h1 className="product-tools-tempalte-human-resource-seventh-div-heading">

                    {fungiblePrivate.sixthSectionHeading}
                </h1>
            </div>

            <div className="product-tools-tempalte-human-resource-eight-div">

                <div className="product-tools-tempalte-human-resource-eight-div-child-1">
                    <h1 className="product-tools-tempalte-human-resource-third-div-child-1-heading">{fungiblePrivate.sixthSectionSide1Heading}</h1>
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-1-paragraph">
                        {fungiblePrivate.sixthSectionSide1Link1}
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-1-paragraph">
                        {fungiblePrivate.sixthSectionSide1Link2}
                    </a>

                </div>

                <div className="eight-div-child-1-vertical-line"></div>

                <div className="product-tools-tempalte-human-resource-eight-div-child-2">
                    <h1 className="product-tools-tempalte-human-resource-eight-div-child-2-heading">{fungiblePrivate.sixthSectionSide2Heading}</h1>

                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-2-paragraph">
                        {fungiblePrivate.sixthSectionSide2Link1}
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-2-paragraph">
                        {fungiblePrivate.sixthSectionSide2Link2}
                    </a>

                </div>

                <div className="eight-div-child-1-vertical-line"></div>

                <div className="product-tools-tempalte-human-resource-eight-div-child-3">
                    <h1 className="product-tools-tempalte-human-resource-eight-div-child-3-heading">{fungiblePrivate.sixthSectionSide3Heading}
                    </h1>
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph">
                        {fungiblePrivate.sixthSectionSide3Link1}
                    </a>
                    <br />
                    <a
                        href="#"
                        className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph"
                        style={{ width: "15rem" }}>
                        {fungiblePrivate.sixthSectionSide3Link2}
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph" style={{ width: "20rem", marginTop: "0" }}>
                        {fungiblePrivate.sixthSectionSide3Link3}
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph">
                        {fungiblePrivate.sixthSectionSide3Link4}
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-3-paragraph">
                        {fungiblePrivate.sixthSectionSide3Link5}
                    </a>
                </div>

                <div className="eight-div-child-3-vertical-line"></div>

                <div className="product-tools-tempalte-human-resource-eight-div-child-4">
                    <h1 className="product-tools-tempalte-human-resource-eight-div-child-4-heading">{fungiblePrivate.sixthSectionSide4Heading}</h1>

                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                        {fungiblePrivate.sixthSectionSide4Link1}
                    </a>
                    <br />
                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                        {fungiblePrivate.sixthSectionSide4Link2}
                    </a>
                    <br />

                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                        {fungiblePrivate.sixthSectionSide4Link3}
                    </a>
                    <br />

                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                        {fungiblePrivate.sixthSectionSide4Link4}
                    </a>
                    <br />

                    <a href="#" className="product-tools-tempalte-human-resource-eight-div-child-4-paragraph">
                        {fungiblePrivate.sixthSectionSide4Link5}
                    </a>


                </div>
            </div>

            <div class="mysql-section">
                <h2 class="mysql-heading">{fungiblePrivate.seventhSectionHeading}</h2>
                <div class="mysql-cards">
                    <div class="mysql-card">
                        <div class="card-top">
                            <i class="icon-download"></i>
                            <h3>{fungiblePrivate.seventhSectionBox1Heading}</h3>
                        </div>
                        <div class="card-bottom">
                            <p>{fungiblePrivate.seventhSectionBox1Para}</p>
                            <a href="/create-account" style={{ color:"inherit" , textDecoration:"none"}}><button>{fungiblePrivate.seventhSectionBox1Button}</button></a>
                        </div>
                    </div>
                    <div class="mysql-card">
                        <div class="card-top">
                            <i class="icon-subscription"></i>
                            <h3>{fungiblePrivate.seventhSectionBox2Heading}</h3>
                        </div>
                        <div class="card-bottom">
                            <p>{fungiblePrivate.seventhSectionBox2Para}</p>
                            <button>{fungiblePrivate.seventhSectionBox2Button}</button>
                        </div>
                    </div>
                    <div class="mysql-card">
                        <div class="card-top">
                            <i class="icon-contact"></i>
                            <h3>{fungiblePrivate.seventhSectionBox3Heading}</h3>
                        </div>
                        <div class="card-bottom">
                            <p>{fungiblePrivate.seventhSectionBox3Para}</p>
                            <button onClick={toggleWidget} >{fungiblePrivate.seventhSectionBox3Button}</button>
                        </div>
                    </div>
                </div>
            </div>






            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    );

}
export default FungiblePrivateTokenGenerator; 
