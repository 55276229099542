export const healthSuitePage = {
    // 1. First Section
    firstSectionHeading: "Transforming Healthcare with Blockchain",
    firstSectionPara: "The Lattice Blockchain Health Suite provides patients with tokenized health records that are shared securely with patient permission and validated through zero-knowledge proof credentials. By leveraging blockchain technology, we ensure accuracy, transparency, and synchronization across the healthcare ecosystem.",
    firstSectionButton1: "Explore Blockchain Health Solutions",
    firstSectionButton2: "Discover Patient Tokenization Benefits",
    firstSectionSide2TitleImg: "How Lattice Blockchain is Empowering Healthcare (2:06)",

    // 2. Second Section
    secondSectionHeading: "Synchronizing Healthcare Stakeholders",
    secondSectionPara: "Lattice Blockchain connects patients, hospitals, doctors, consultants, pharmacies, health insurance companies, and drug manufacturers to ensure secure and synchronized data sharing. Our developed Lattice Network enables compliance, safety, and security across the entire healthcare supply chain.",
    secondSectionButton: "Learn More",

    // 3. Third Section: Why Lattice Blockchain Health Suite?
    thirdSectionHeading: "Why Choose the Lattice Blockchain Health Suite?",
    thirdSectionSide1Heading: "Verified Data Integrity",
    thirdSectionSide1Para: "Authenticate patient, doctor, prescription, and consultant data with blockchain, ensuring accuracy across the healthcare system.",
    thirdSectionSide2Heading: "Enhanced Collaboration",
    thirdSectionSide2Para: "Seamlessly share verified health records and scheduling data between healthcare stakeholders while maintaining compliance and privacy.",
    thirdSectionSide3Heading: "End-to-End Security",
    thirdSectionSide3Para: "Utilize blockchain’s immutable ledger and zero-knowledge proofs to safeguard sensitive health information, reduce fraud, and enhance patient trust.",

    // 4. Fourth Section: Use Cases
    fourthSectionHeading: "Comprehensive Use Cases for Blockchain in Healthcare",
    fourthSectionButton1: "Patient Data Authentication",
    fourthSectionButton2: "Doctor and Consultant Analysis",
    fourthSectionButton3: "Prescription and Scheduling Verification",
    fourthSectionButton4: "Supply Chain Validation",
    fourthSectionButton5: "Health Insurance Claims Processing",

    // Sub-sections for each use case
    fourthSectionPage1Heading: "Patient Data Authentication",
    fourthSectionPage1Description: "Securely tokenize and authenticate patient health records with blockchain, ensuring only authorized access and sharing.",
    fourthSectionPage1Link: "Learn More about Patient Data Authentication",
    fourthSectionPage1cardheading1: "Tokenized Records",
    fourthSectionPage1fastTrackList1: "Enable patients to securely control their own health data through blockchain-based tokenization.",
    fourthSectionPage1cardheading2: "Zero-Knowledge Proofs",
    fourthSectionPage1fastTrackList2: "Validate health records without exposing sensitive patient details.",
    fourthSectionPage1cardheading3: "Secure Sharing",
    fourthSectionPage1fastTrackList3: "Share patient health records securely with healthcare providers, insurers, and pharmacies.",
    learnMoreButton: "Learn More",

    fourthSectionPage2Heading: "Doctor and Consultant Analysis",
    fourthSectionPage2Description: "Leverage blockchain to securely share and analyze doctor and consultant data for accurate diagnosis and treatment plans.",
    fourthSectionPage2Link: "Learn More about Doctor Analysis",
    fourthSectionPage2cardheading1: "Secure Insights",
    fourthSectionPage2fastTrackList1: "Streamline access to verified consultant reports and analysis using blockchain.",
    fourthSectionPage2cardheading2: "Immutable Records",
    fourthSectionPage2fastTrackList2: "Ensure data accuracy with a tamper-proof blockchain ledger.",
    
    fourthSectionPage3Heading: "Prescription and Scheduling Verification",
    fourthSectionPage3Description: "Streamline prescription approvals and scheduling processes through blockchain-enabled verification.",
    fourthSectionPage3Link: "Learn More about Verification",
    fourthSectionPage3cardheading1: "Real-Time Validation",
    fourthSectionPage3fastTrackList1: "Verify prescriptions and appointments instantly through blockchain.",
    fourthSectionPage3cardheading2: "Fraud Prevention",
    fourthSectionPage3fastTrackList2: "Eliminate errors and fraud in scheduling and prescription data.",

    fourthSectionPage4Heading: "Supply Chain Validation",
    fourthSectionPage4Description: "Track and verify the integrity of pharmaceuticals across the supply chain with blockchain.",
    fourthSectionPage4Link: "Learn More about Supply Chain Validation",
    fourthSectionPage4cardheading1: "Drug Authentication",
    fourthSectionPage4fastTrackList1: "Ensure that medicines are authentic and safely distributed.",
    fourthSectionPage4cardheading2: "Transparent Tracking",
    fourthSectionPage4fastTrackList2: "Trace the entire lifecycle of pharmaceutical products using blockchain records.",

    fourthSectionPage5Heading: "Health Insurance Claims Processing",
    fourthSectionPage5Description: "Simplify claims processing with blockchain for faster, more secure insurance verification.",
    fourthSectionPage5Link: "Learn More about Claims Processing",
    fourthSectionPage5cardheading1: "Automated Verification",
    fourthSectionPage5fastTrackList1: "Validate patient claims through blockchain-based smart contracts.",
    fourthSectionPage5cardheading2: "Reduced Disputes",
    fourthSectionPage5fastTrackList2: "Ensure accurate claims with a transparent ledger accessible to stakeholders.",

    // 5. Fifth Section: Value Proposition
    fifthSectionHeading: "Unlock Efficiency and Trust in Healthcare",
    fifthSectionPara1: "The Lattice Blockchain Health Suite reduces inefficiencies and fraud, enhancing trust and collaboration across the healthcare ecosystem.",
    fifthSectionPara2: "By synchronizing data securely, we empower stakeholders to focus on delivering better patient outcomes.",
    fifthSectionCard1Heading: "100% Transparency",
    fifthSectionCard1Para: "Blockchain ensures a tamper-proof ledger for health records and transactions.",
    fifthSectionCard2Heading: "Faster Processes",
    fifthSectionCard2Para: "Smart contracts streamline approvals, claims, and data sharing.",
    fifthSectionCard3Heading: "Enhanced Compliance",
    fifthSectionCard3Para: "Meet regulatory requirements with secure, transparent data tracking.",
    fifthSectionButton1: "Explore Solutions",
    fifthSectionButton2: "Learn More",

    // 6. Sixth Section: Customer Success Stories
    sixthSectionHeading: "Healthcare Organizations Trust Lattice Blockchain",
    sixthSectionLink: "See all success stories",
    sixthSectionCard1Heading: "Enabling Patient-First Care with Blockchain",
    sixthSectionCard1Para2: "Learn how a global hospital chain leverages Lattice Blockchain to securely manage patient data.",
    sixthSectionCard2Heading: "Transforming Drug Supply Chain Validation",
    sixthSectionCard2Para2: "Discover how blockchain ensures drug authenticity and compliance.",
    sixthSectionCard3Heading: "Streamlining Health Insurance Claims Processing",
    sixthSectionCard3Para2: "Explore how insurers reduce disputes and speed up claims with Lattice Blockchain.",

    // 7. Seventh Section: Get Started
    seventhSectionHeading: "Revolutionize Healthcare with Lattice Blockchain",
    seventhSectionPage1Side1Heading: "Take the First Step Towards Blockchain-Powered Healthcare",
    seventhSectionPage1Side1Para: "Our experts are ready to guide you in implementing blockchain solutions tailored to your healthcare needs.",
    seventhSectionPage1Side1button: "Get Started",
    seventhSectionPage1Side2Heading: "Learn with Interactive Demos",
    seventhSectionPage1Side2Para: "Experience the Lattice Blockchain Health Suite with hands-on tutorials and use case demonstrations.",
    seventhSectionPage1Side2button: "View Tutorials",
};
