import React, { useState, useRef, useEffect } from 'react';
import './Lattice-cloud-infrasturcture-compute-template-page.css'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import img1 from '../image/cloud/rc24inline-cloud-storage-services.avif'
import img2 from '../image/cloud/customerlogo-phenix-clr.png'
import img3 from '../image/cloud/customerlogo-adept-clr.png'
import svg1 from '../image/The_Related_Companies-Logo.svg'
import svg2 from '../image/Four_Seasons_logo3.png'
import svg4 from '../image/german-federal-ministry-logo.svg'
import svg5 from '../image/imperial-college-london-logo3.svg'
// import svg6 from '../image/cloud/customerlogo-zoom-clr.svg'
import svg7 from '../image/deliveroo-logo3.png'
import svg8 from '../image/AlbaniaTech-logo.svg'
import svg12 from '../image/svg/view_week_24dp_E8EAED.svg'
import svg13 from '../image/svg/speed_24dp_E8EAED.svg'
import svg14 from '../image/svg/lock_open_24dp_E8EAED.svg'


import img5 from '../image/cloud/rc06-cloud-compute-flexible-instances.avif'
import img6 from '../image/cloud/rc06-cloud-compute-nvidia-v1.jpg'
import img7 from '../image/cloud/rc06-cloud-compute-amd-v2.webp'
import Latticelogo from '../image/lattice-logo-img.svg';
import { storage } from '../Content/English-Content/ProductInfraStorage';
import { useChatWidget } from "../ChatWidgetContext";




function ProductInfraStorage() {

     const { toggleWidget } = useChatWidget(); 
    


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };





    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: 'Virtual machines (VMs)',
            description:
                'Run a wide range of workloads, from small development projects to large-scale global applications, on LCI Compute VMs securely and achieve improved price-performance.',
            link: 'Learn more about VMs',

            fastTrackList: ['VMs are customizable from 1–94 cores for AMD EPYC processors, 1–32 cores for Intel, and 1–80 cores for Ampere processors, with 1–1,094 GB RAM and network bandwidth that scales with the number of cores.', 'Dense I/O VMs, with 8–24 cores and 128–512 GB RAM, offer 6.8–27.2 TB high performance, locally attached NVMe storage for big data and analytics workloads.', 'Optimized VM shapes provide 1–18 high-frequency processor cores and support cluster storage with 4–40 Gb/sec of network bandwidth.', 'Dedicated VM host shapes offer bare metal instances with 36–128 cores and 512–2,048 GB memory per host with software to host VMs.'],
            cardheading: ['Flexible VMs', 'Dense I/O VMs', 'Optimized VMs', 'Dedicated VM hosts'],
        },
        documentation: {
            heading: 'Bare metal',
            description:
                'Bare metal instances provide customers with isolation and control with dedicated physical server access. Customers can run demanding workloads on instances that scale up to an industry-leading 192 cores, 2.3 TB of RAM, and up to 1 PB of block storage.',
            link: 'Learn more about bare metal',
            cardheading: ['Standard bare metal', 'Dense I/O bare metal', 'HPC bare metal'],
            fastTrackList: ['Bare metal instances provide a balance of cores (64–192 cores), memory (1,024–2,304 GB), and network bandwidth (up to 1x100 Gb/sec).', 'These bare metal instances are equipped with high performance local storage (54.4 TB of NVMe SSD) and 2,048 GB of memory, optimized for large databases and big data workloads.', 'These bare metal instances are for HPC workloads that require high-frequency processor cores with 3.84 TB of NVMe SSD and cluster storage (up to 2x50 Gb/sec of network bandwidth and 1x100 Gb/sec of RDMA bandwidth).'],
            headingof3div: 'Kick-start your app development with SDKs and the command-line interface.',
        },
        events: {
            heading: "GPU instances",
            description:
                'Run your demanding AI workloads on bare metal and virtual machine instances powered by NVIDIA GPUs. GPUs can be clustered using a microsecond-latency network with the most local storage per node for checkpointing during AI training.',
            fastTrackList: ['GPU-accelerated VMs include NVIDIA graphics processors (NVIDIA A10, A100,* H100,* V100, and P100 GPUs), which can be clustered to achieve up to 30 cores, 16 to 64 GB of GPU memory, and 8 to 48 Gb/sec of network bandwidth.', 'Bare metal instances equipped with NVIDIA graphics processors (NVIDIA H100, A100, L40S, H200,** and B200 GPUs**), NVIDIA Superchips (GH200 Grace Hopper and GB200 Grace Blackwell**) and AMD graphics processors (AMD MI300X) can be clustered, leveraging an ultrahigh-bandwidth RDMA network.'],
            link: 'Learn more about GPU instances',
            headingof3div: 'Access developer resources and learn about future events designed just for you. Hear from experts in their field and gain practical hands-on experience.',
            cardheading: ['GPU-accelerated VMs', 'GPU-accelerated bare metal instances'],
        },
        technologies: {
            heading: ' Cloud native and containers',
            description:
                'LCI offers a comprehensive set of managed services across containers, serverless, APIs, and DevOps with support for open source to help customers build cloud native applications.',
            link: 'Learn more about cloud native and containers',
            cardheading: ['Functions', 'Containers', 'Kubernetes', 'Container images'],
            fastTrackList: ['LCI Functions is a serverless platform that lets developers create, run, and scale applications without managing infrastructure.', 'LCI Container Instances is a serverless compute service that lets you instantly run containers without managing any servers.', 'LCI Kubernetes Engine is a managed Kubernetes service for operating containerized applications at scale while reducing the time, cost, and operational burden of managing a Kubernetes infrastructure.', 'LCI Container Registry is an open standards–based, Lattice-managed Docker registry service for securely storing and sharing container images.'],
            headingof3div: 'Technologies',
        },
        languages: {
            heading: ' VMware and other services',
            description:
                'Lattice Cloud VMware Solution enables customers to move VMware estates to the cloud without changing operations or processes.',
            link: 'Learn more about Lattice Cloud VMware Solution',
            cardheading: ['VMware-based compute', 'Operating system (OS) images', 'Virtual desktops', 'Lattice OS Management Service'],
            fastTrackList: ['Lattice Cloud VMware Solution lets you create and manage VMware-enabled, software-defined data centers in LCI.', 'LCI supports several prebuilt Lattice-provided images and user-created custom images.', 'Virtual desktops deliver an improved experience and a lower administration cost.', 'Lattice OS Management Service lets you manage updates and patches for the OS on your instances.'],
            headingof3div: 'languages:',
        },
        // learn: {
        //     heading: 'AI Resource Hub',
        //     description:
        //         'Discover, learn and build. Access a range of AI resources to help you get ahead on your AI journey.',
        //     link: 'Learn more',
        //     cardheading: ['Flexible VMs', 'Dense I/O VMs', 'Optimized VMs', 'Dedicated VM hosts'],
        //     fastTrackList: ['VMs are customizable from 1–94 cores for AMD EPYC processors, 1–32 cores for Intel, and 1–80 cores for Ampere processors, with 1–1,094 GB RAM and network bandwidth that scales with the number of cores.', 'Dense I/O VMs, with 8–24 cores and 128–512 GB RAM, offer 6.8–27.2 TB high performance, locally attached NVMe storage for big data and analytics workloads.', 'Optimized VM shapes provide 1–18 high-frequency processor cores and support cluster storage with 4–40 Gb/sec of network bandwidth.', 'Dedicated VM host shapes offer bare metal instances with 36–128 cores and 512–2,048 GB memory per host with software to host VMs.'],
        //     headingof3div: 'Learn',
        // },

    };




    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];




    const [currentSlide, setCurrentSlide] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

    const cards = [
        {
            id: 1,
            title: "September 2024—IDC Determines LCI Can Provide a 393% Five-Year ROI",
            link: "Access the complete report",
            link2: "Analysis report",
            imgs: img5
        },
        {
            id: 2,
            title: "September 2024—AMD MI300X Accelerators Available on LCI for Demanding AI Applications",
            link: "Read the announcement",
            link2: "Announcement",
            imgs: img6
        },
        {
            id: 3,
            title: "September 2024—Announcing World’s Largest, First Zettascale AI Supercomputer in the Cloud",
            link: "Read the blog",
            link2: "Blog",
            imgs: img7
        },
    ];

    const handleNext = () => {
        if (currentSlide < cards.length - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth <= 600;
            setIsSmallScreen(isSmall);

            // Reset slide index if switching back to large screen
            if (!isSmall) {
                setCurrentSlide(0);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const [activeSection, setActiveSection] = useState("Free trial");

    // Handle click to update active section
    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };






    return (
        <>

            <div className="product-infra-compute-first-div">



                <h1 className="product-infra-compute-first-div-heading">

                    {storage.firstSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                    <p className="product-infra-compute-first-div-paragraph">{storage.firstSectionPara}</p>

                    <button className="product-infra-compute-first-div-btn-1"><a href="/create-account" style={{ color: "inherit", textDecoration: "none" }}>{storage.firstSectionButton1}</a></button>

                    {/* <button className="product-infra-compute-first-div-btn-2">{storage.firstSectionButton2}</button> */}

                </h1>

                <div className="product-infra-compute-first-div-img-and-para">

                    <img src={img1} className="product-infra-compute-img-of-first-div"></img>

                    <p className="product-infra-compute-title-of-img">{storage.firstSectionSide2TitleImg}</p>

                </div>


            </div>



            {/* <div className="product-infra-compute-sec-div">
                       
                                       <h1 className="product-infra-compute-sec-div-heading">Lattice Offers First Zettascale Cloud Computing Cluster
                       
                                           <p className="product-infra-compute-sec-div-para">
                                               This new AI supercomputer, the largest in the cloud, will deliver up to 131,072 NVIDIA GPUs, allowing you to build, train, and inference AI at scale.
                                           </p>
                       
                                       </h1>
                       
                                       <button className="product-infra-compute-sec-div-btn">Get the news </button>
                       
                                   </div> */}



            <div className="product-infra-compute-third-div">

                <h1 className="product-infra-compute-third-div-heading">

                    {storage.thirdSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="product-infra-compute-third-div-cards-wrapper">

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg12} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {storage.thirdSectionSide1Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{storage.thirdSectionSide1Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg13} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {storage.thirdSectionSide2Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{storage.thirdSectionSide2Para}</p>

                        </div>

                    </div>

                    <div className="product-infra-compute-third-div-card-1">

                        <img src={svg14} className="product-infra-compute-third-div-card-1-logo-all"></img>

                        <div className="product-infra-compute-third-div-card-1-content">

                            <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                {storage.thirdSectionSide3Heading}</h1>

                            <p className="product-infra-compute-third-div-card-1-all-para">{storage.thirdSectionSide3Para}</p>

                        </div>

                    </div>



                </div>


            </div>



            {/* <div className="product-infrs-div-four-content-of-resources">
                <h1 className="product-infrs-div-four-solution-heading">

                    LCI Compute services
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <div className="product-infrs-div-four-content-wrapper-of-resource">
                    <div className="product-infrs-div-four-content-1-of-resource">
                        <ul>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('downloads')}
                                >
                                    Virtual machines (VMs)
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('documentation')}
                                >
                                    Bare metal
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'events' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('events')}
                                >
                                    GPU instances
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('technologies')}
                                >
                                    Cloud native and containers
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`product-infrs-div-four-btn-resource ${selectedResource === 'languages' ? 'active' : ''
                                        }`}
                                    onClick={() => handleResourceClick('languages')}
                                >
                                    VMware and other services
                                </button>
                            </li>
                            
                        </ul>
                    </div>

                    
                    <div className="product-infrs-div-four-combined-content">
                       
                        <div className="product-infrs-div-four-top-content">
                            <h1 className="product-infrs-div-four-resource-heading">{currentResource.heading}</h1>
                            <p className="product-infrs-div-four-resource-description">{currentResource.description}</p>
                            <button className="product-infrs-div-four-resource-btn">{currentResource.link}</button>
                        </div>


                        
                        <div className="product-infrs-div-four-grid-content">
                            {currentResource.fastTrackList.map((item, index) => (
                                <div className="product-infrs-div-four-grid-item" key={index}>
                                    <h2 className="product-infrs-div-four-grid-heading">{currentResource.cardheading[index]}</h2>
                                    <p className="product-infrs-div-four-grid-para">{item}</p>
                                    <a href="#" className="product-infrs-div-four-grid-link">Learn More</a>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div> */}



            {/* <div className="product-infra-div-five">

                <h1 className="product-infrs-div-four-solution-heading">

                    Save as much as 60% with LCI Compute
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>

                </h1>

                <p className="product-infra-div-five-small-para">While other cloud service providers have higher prices in almost every region outside the US, LCI customers enjoy the same services, performance, and prices everywhere.</p>

                <p className="product-infra-div-five-small-para">For a similar configuration, LCI Compute costs less.*</p>

                <div className="product-infra-div-five-cards-wrapper">

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            57%</h1>

                        <p className="product-infra-div-five-all-card-para">less than AWS m6a.xlarge</p>

                    </div>

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            57%</h1>

                        <p className="product-infra-div-five-all-card-para">less than AWS m6a.xlarge</p>

                    </div>

                    <div className="product-infra-div-five-card-1">

                        <h1 className="product-infra-div-five-all-card-heading">
                            51%</h1>

                        <p className="product-infra-div-five-all-card-para">less than Google Cloud n2d-standard-4</p>

                    </div>

                </div>

                <div className="product-infra-div-five-btns-wrapper">

                    <button className="product-infra-div-five-btn-1">Learn more about cloud economics</button>

                    <button className="product-infra-div-five-btn-2">See LCI Compute pricing</button>

                </div>

                <p className="product-infra-div-five-very-small-para">* Compared with the monthly cost of a two OCPU (four vCPU) AMD (E4) with 16 GB in US eastern regions. On-demand prices are as of June 5, 2024.</p>


            </div> */}



            <div className="product-infra-div-six">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="product-infra-div-six-heading-oftxt-wrapper">{storage.sixthSectionHeading}</h1>

                    {/* <a className="product-infra-div-six-link-of-txt-wrapper">See more reference architectures</a> */}

                </div>

                <div className="product-infra-div-six-cards-wrapper">

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">{storage.sixthSectionCard1Para1}
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                            {storage.sixthSectionCard1Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{storage.sixthSectionCard1Para2}</p>

                    </div>

                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">{storage.sixthSectionCard1Para1}
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                            {storage.sixthSectionCard2Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{storage.sixthSectionCard2Para2}</p>

                    </div>


                    <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">{storage.sixthSectionCard1Para1}
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                            {storage.sixthSectionCard3Heading}
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">{storage.sixthSectionCard3Para2}</p>

                    </div>

                    {/* <div className="product-infra-div-six-card-1">

                        <p className="product-infra-div-six-card-1-para-1">Reference Architecture
                        </p>

                        <a className="product-infra-div-six-card-1-all-links">
                        Archive Storage
                        </a>

                        <p className="product-infra-div-six-card-1-all-para">OCI Archive Storage is a highly durable, cost-effective solution that can securely store any type of data in its native format for long periods of time.</p>

                    </div> */}

                </div>

            </div>


            <div className="product-infra-div-seven">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="product-infrs-div-four-solution-heading">

                        {storage.seventhSectionHeading}
                        <span
                            style={{
                                display: "block",
                                width: "40px",
                                height: "4px",
                                background: "#f0cc71",
                                marginBottom: "3vh",
                                marginTop: "3vh",
                            }}
                        ></span>

                    </h1>

                    <a href='/customer-story' className="product-infra-div-six-link-of-txt-wrapper">{storage.seventhSectionLink}</a>

                </div>

                <div className="product-infra-div-seven-img-wrapper">

                    <img src={svg1} className="product-infra-div-seven-img-1"></img>

                    <img src={svg2} className="product-infra-div-seven-img-1"></img>

                    {/* <img src={img2} className="product-infra-div-seven-img-1"></img> */}

                    <img src={svg4} className="product-infra-div-seven-img-1"></img>

                    <img src={svg5} className="product-infra-div-seven-img-1"></img>

                    {/* <img src={svg6} className="product-infra-div-seven-img-1"></img> */}

                    <img src={svg7} className="product-infra-div-seven-img-1"></img>
                    <img src={svg8} className="product-infra-div-seven-img-1"></img>



                </div>

            </div>


            {/* <div className="product-infra-div-eight">
                <h1 className="product-infra-div-eight-heading">What’s new?</h1>
                <div className="product-infra-div-eight-cards-wrapper">
                    {cards.map((card, index) => (
                        <div
                            key={card.id}
                            className="product-infra-div-eight-card-1"
                            style={{
                                transform: isSmallScreen
                                    ? `translateX(-${currentSlide * 100}%)`
                                    : "none",
                                transition: "transform 0.5s ease-in-out",
                            }}
                        >
                            <div className="product-infra-div-eight-card-1-content-div">
                                <a className="product-infra-div-eight-card-1-content-div-all-links">
                                    {card.link2}
                                </a>
                                <h1 className="product-infra-div-eight-card-1-content-div-all-heading">
                                    {card.title}
                                </h1>
                                <a className="product-infra-div-eight-card-1-content-div-all-links">
                                    {card.link}
                                </a>
                            </div>
                            <div className="product-infra-div-eight-card-1-img-div">
                                <img  className="product-infra-div-eight-card-1-img-div" src= {card.imgs}></img>
                            </div>
                        </div>
                    ))}
                </div>

                {isSmallScreen && (
                    <div className="carousel-controls">
                        <button onClick={handlePrev} disabled={currentSlide === 0}>
                            &lt;
                        </button>
                        <span>
                            {currentSlide + 1} / {cards.length}
                        </span>
                        <button onClick={handleNext} disabled={currentSlide === cards.length - 1}>
                            &gt;
                        </button>
                    </div>
                )}
            </div> */}


            <div className="product-infra-last-div">

                <div className="product-infra-last-div-wrapper">

                    <h1 className="product-infra-last-div-heading">{storage.ninthSectionHeading}</h1>

                    <div className="become-partners-dynamic-island" style={{ marginTop: "5vh" }}>


                        <button
                            onClick={() => handleSectionClick("Free trial")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Free trial" ? "active-section" : ""}`}
                        >
                            {storage.ninthSectionButton1}
                        </button>

                        <button
                            onClick={() => handleSectionClick("Hands-on labs")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Hands-on labs" ? "active-section" : ""}`}
                        >
                            {storage.ninthSectionButton2}
                        </button>

                        <button
                            onClick={() => handleSectionClick("Estimate your costs")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Estimate your costs" ? "active-section" : ""}`}
                        >
                            {storage.ninthSectionButton3}
                        </button>

                        <button
                            onClick={() => handleSectionClick("Contact sales")}
                            className={`become-partner-dynamic-island-item-1 ${activeSection === "Contact sales" ? "active-section" : ""}`}
                        >

                            {storage.ninthSectionButton4}
                        </button>


                    </div>


                    <div className="become-partners-dynamic-content-area">


                        <div className="become-partners-sec-div-cards">
                            {activeSection === "Free trial" && (
                                <>

                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{storage.ninthSectionPage1Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{storage.ninthSectionPage1Side1Para}</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn"><a href="/create-account" style={{ color:"inherit" , textDecoration:"none"}}>{storage.ninthSectionPage1Side1button}</a></button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{storage.ninthSectionPage1Side2Heading}</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{storage.ninthSectionPage1Side2List1}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{storage.ninthSectionPage1Side2List2}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{storage.ninthSectionPage1Side2List3}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> {storage.ninthSectionPage1Side2List4}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{storage.ninthSectionPage1Side2List5}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> {storage.ninthSectionPage1Side2List6}</li>

                                            </ul>

                                        </div>



                                    </div>

                                </>
                            )}

                            {activeSection === "Hands-on labs" && (
                                <>

                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-2-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{storage.ninthSectionPage2Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{storage.ninthSectionPage2Side1Para}</p>

                                            {/* <button className="product-infra-last-div-1-list-card-1-all-main-btn">{storage.ninthSectionPage2Side1button}</button> */}

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{storage.ninthSectionPage2Side2Heading1}</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{storage.ninthSectionPage2Side2List1}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{storage.ninthSectionPage2Side2List2}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{storage.ninthSectionPage2Side2List3}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> {storage.ninthSectionPage2Side2List4}</li>
                                                {/* <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                                           <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                                           <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li> */}
                                            </ul>

                                        </div>

                                    </div>

                                </>
                            )}


                            {activeSection === "Estimate your costs" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{storage.ninthSectionPage3Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{storage.ninthSectionPage3Side1Para}</p>

                                            {/* <button className="product-infra-last-div-1-list-card-1-all-main-btn">{storage.ninthSectionPage3Side1button}</button> */}

                                        </div>

                                        {/* <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">Experience the difference</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">1/4 the outbound bandwidth costs</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">3X the compute price-performance</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">Same low price in every region</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> Low pricing without long term commitments</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li>
                                            </ul>

                                        </div> */}



                                    </div>






                                </>
                            )}

                            {activeSection === "Contact sales" && (
                                <>
                                    <div className="product-infra-last-div-1-list-card-wrapper">

                                        <div className="product-infra-last-div-1-list-card-1">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-main-heading">{storage.ninthSectionPage4Side1Heading}</h1>

                                            <p className="product-infra-last-div-1-list-card-1-all-main-para">{storage.ninthSectionPage4Side1Para}</p>

                                            <button className="product-infra-last-div-1-list-card-1-all-main-btn" onClick={toggleWidget} >{storage.ninthSectionPage4Side1button}</button>

                                        </div>

                                        <div className="product-infra-last-div-1-list-card-2">

                                            <h1 className="product-infra-last-div-1-list-card-1-all-sub-heading">{storage.ninthSectionPage4Side2Heading}</h1>

                                            <ul>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{storage.ninthSectionPage4Side2List1}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{storage.ninthSectionPage4Side2List2}</li>
                                                <li className="product-infra-last-div-list-1-card-1-list-all">{storage.ninthSectionPage4Side2List3}</li>
                                                {/* <li className="product-infra-last-div-list-1-card-1-list-all"> 2 Autonomous Databases, 20 GB each</li>
                                                                                                       <li className="product-infra-last-div-list-1-card-1-list-all">10 TB outbound data transfer per month</li>
                                                                                                       <li className="product-infra-last-div-list-1-card-1-list-all"> 10+ more Always Free services</li>
                                                                                                       <li className="product-infra-last-div-list-1-card-1-list-all">US$300 in free credits for 30 days for even more</li> */}
                                            </ul>

                                        </div>



                                    </div>




                                </>
                            )}


                        </div>

                    </div>


                </div>

            </div>













            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    );
};

export default ProductInfraStorage;
