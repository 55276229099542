export const professionalServices = {

    firstSectionHeading: "Professional Services",
    firstSectionPara: "Whether you’re a consulting business, accounting firm, law firm, staffing provider, or business services provider, Lattice Fusion Cloud Applications Suite has the global reach and portfolio depth of financial management, human resources, project management, and customer experience that you need to exceed client expectations every time, everywhere.",
    firstSectionButton: "Talk with an Lattice expert",

    secondSectionHeading: "Consulting services",
    secondSectionSide1Heading: "Accounting",
    secondSectionSide1Para: "Expand your accounting firm with confidence by maintaining compliance with evolving regulations, enhancing financial performance with a holistic view of your business, and empowering your accountants to optimize workflows, communicate more effectively with clients, and automate tedious tasks.",

    secondSectionSide2Heading: "Consulting services",
    secondSectionSide2Para: "Grow your consulting organization at scale by delivering profitable projects from start to finish. Automate project planning and scheduling processes, optimize resource utilization, and provide clients with flexible billing experiences.",

    secondSectionSide3Heading: "Legal",
    secondSectionSide3Para: "Transform your law firm by automating business processes. Let your management focus on strategic goals and your lawyers excel at client service. Track performance metrics, such as billable hours, collections, and realization. Improve operational efficiency to meet jurisdictional and global tax and reporting requirements with ease.",


    fifthSectionHeading: "See how healthcare organizations partner with Lattice",
    fifthSectionlink: "See all customer stories",

    sixthSectionHeading: "Connect your people, finances, and processes with Lattice Cloud Applications",

    sixthSectionSide1Heading: "Maximize profits and automate financial processes",
    sixthSectionSide1Para: "Gain a clear understanding of your financials, automate billing experiences, and adapt quickly to market changes.",

    sixthSectionSide2Heading: "Engage, nurture, and retain employees",
    sixthSectionSide2Para: "Unify human capital management and operations to build a thriving workforce.",

    seventhSectionHeading: "Get started with Lattice Health",

    seventhSectionSide1Para: "Talk to a team member about Lattice solutions for healthcare.",
    seventhSectionSide1Button: "Contact Lattice Health today",

    seventhSectionSide2Para: "Customers can contact our support team 24/7 for help.",
    seventhSectionSide2Button: "Contact us for help",
    


}