// contact-content.js
import logo1 from '../image/con1.avif';
import logo2 from '../image/con2.avif';
import logo3 from '../image/con3.png';

export const contactContent = {
    intro: {
        heading: "Contact Lattice",
        buttons: ["Contact", "Lattice Support"],
    },
    connectOptions: [
        {
            logo: logo1,
            altText: "Lattice Logo",
            title: "Send a message to Lattice Sales",
            description: "Send us a message here and we’ll get back to you. For a faster response, please provide your contact information.",
            linkText: "Send us a message",
            linkHref: "#",
        },
        {
            logo: logo2,
            altText: "Lattice Logo",
            title: "Chat with Lattice Sales",
            description: "",
            linkText: "Open a live chat",
            linkHref: "#",
        },
        {
            logo: logo3,
            altText: "Lattice Logo",
            title: "Call Lattice Sales",
            description: "",
            linkText: "+1.800.633.0553 (US) Find phone numbers for your region",
            linkHref: "#",
        },
    ],

    assistance: {
        heading: "How can we help?",
        categories: [
            {
                key: "training",
                label: "Training and Certifications",
                content: `
                <div class="content-of-contactPage"> 
                <h3 class="heading-of-dyanamic-contet"> Use the following information to get technical help</h3>
                <ul>
                    <li>Customers with active support contracts should access the My Lattice Support portal to find answers to their technical questions in the Lattice Support knowledgebase, participate in the My Lattice Support Community, or submit and update service requests. Please refer to the Lattice Support Contacts Global Directory for information on renewing Premier Support, regional support sales contacts, and support policies.</li>
                    <li>Cloud subscription customers should go to the Lattice Cloud Portal to access Getting Started guides for their cloud products, contact their cloud customer success manager, ask operational questions, or join a discussion forum.</li>
                    <li>Customers with products from businesses acquired by Lattice should go to the Acquired Product Support Directory for their support needs.</li>
                    <li>Customers with Lattice University training and certification questions should use the Education support form for their support needs.</li>
                </ul>
                <p>Additional customized services</p>
                </div>
                `,
            },
            {
                key: "technical",
                label: "Technical support",
                content: `
                <div class="content-of-contactPage"> 
                <p> Billing support: 000 800 050 1015 (India) | Global billing support</p>
                </div>
                `,
            },
            {
                key: "billing",
                label: "Billing",
                content: `
                <div class="content-of-contactPage"> 
                <h3 class="heading-of-dyanamic-contet"> I need help signing in to Lattice Cloud</h3>
                <p> Learn how to sign in to your Lattice Account so you can access your cloud instance on the Cloud Console.</p>
                <h3 class="heading-of-dyanamic-contet"> I need help signing in to my Lattice SSO Account</h3>
                <p> Learn how to sign in to your Lattice Account to access public/protected resources hosted by Lattice, including My Lattice Support, Lattice Education Learning Subscriptions, Lattice Store, and Lattice Communities, and to download software.</p>
                </div>
                `,
            },
            {
                key: "account",
                label: "Account support",
                content: "Content for Account Support",
            },
            {
                key: "cloud",
                label: "Cloud trial support",
                content: "Content for Cloud Trial Support",
            },
        ],
    },
    team: {
        partners: {
            title: "Location",
            content: [
                "US mailing address",
                "Lattice Corporation",
                "Austin, TX 78741",
            ],
        },
        careers: {
            title: "Careers",
            content: [
                "Explore Lattice Careers to get to know us, learn more about what we do, and see if your ideal role awaits you.",
                "Questions? Contact Partner Assistance.",
                "Are you a student or recent graduate? Go to our college recruiting page.",
            ],
        },
    },
};
