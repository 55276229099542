export const fungiblePrivate = {
firstSectionHeading: "Fungible Private Token Generator",
firstSectionPara: "Lattice's Fungible Private Token Generator allows businesses to create custom ecosystem tokens specifically designed for their private blockchain networks. This innovative tool simplifies token generation, enabling seamless integration of blockchain-based assets into diverse business operations.",

secondSectionHeading: "Why Choose Lattice's Token Generator?",
secondSectionButton: "Learn more",

thirdSectionSide1Heading: "Comprehensive Tokenization Guide",
thirdSectionSide1Para: "Get access to our detailed guide to learn how to design and deploy ecosystem tokens using Lattice's Fungible Private Token Generator. Explore token standards, applications, and best practices for private blockchains.",
thirdSectionSide1Button: "Download the guide (PDF)",

thirdSectionSide2Heading: "Explore Real-World Use Cases",
thirdSectionSide2Para: "See how businesses are utilizing custom tokens for applications like loyalty programs, supply chain management, and secure data sharing. Watch case studies and read testimonials from our satisfied clients.",
thirdSectionSide2Button: "Watch case studies",

bookingsSectionHeading: "Client Success Stories",
bookingsSectionPara: "Businesses across multiple industries trust Lattice's Fungible Private Token Generator to power their blockchain ecosystems. From financial services to logistics, see how our clients have unlocked new opportunities with custom tokens.",
bookingsSectionButton: "Read success stories",

fourthSectionHeading: "Key Features",
fourthSectionButton1: "Custom Token Standards",
fourthSectionButton2: "Seamless Integration",
fourthSectionButton3: "Enhanced Security",
fourthSectionButton4: "Scalability",
fourthSectionButton5: "Compliance Tools",

fourthSectionPage1Heading: "Custom Token Standards",
fourthSectionPage1cardheading1: "ERC-20 and Beyond",
fourthSectionPage1fastTrackList1: "Create tokens that comply with widely-used standards like ERC-20 or customize specifications to match unique business requirements.",
fourthSectionPage1cardheading2: "Flexible Configuration",
fourthSectionPage1fastTrackList2: "Easily configure token attributes, such as supply limits, transfer restrictions, and governance settings.",

fourthSectionPage2Heading: "Seamless Integration",
fourthSectionPage2cardheading1: "API-Driven Deployment",
fourthSectionPage2fastTrackList1: "Quickly deploy tokens through our robust API, ensuring smooth integration with existing blockchain infrastructures.",

fourthSectionPage3Heading: "Enhanced Security",
fourthSectionPage3cardheading1: "Smart Contract Audits",
fourthSectionPage3fastTrackList1: "Utilize pre-audited smart contracts to guarantee the highest level of security for your tokens.",

fourthSectionPage4Heading: "Scalability",
fourthSectionPage4cardheading1: "Built for Growth",
fourthSectionPage4fastTrackList1: "Support large-scale ecosystems with tokens optimized for high transaction throughput and scalability.",

fourthSectionPage5Heading: "Compliance Tools",
fourthSectionPage5cardheading1: "Regulatory Alignment",
fourthSectionPage5fastTrackList1: "Incorporate tools for KYC, AML, and other regulatory requirements to ensure compliance across various jurisdictions.",

fifthSectionHeading: "Benefits of the Token Generator",
fifthSectionCard1Heading: "Streamlined Token Creation",
fifthSectionCard1Para: "Easily launch tokens with an intuitive interface and powerful backend, saving time and reducing complexity.",
fifthSectionCard2Heading: "Enterprise-Grade Security",
fifthSectionCard2Para: "Secure your digital assets with advanced encryption, smart contract security, and built-in compliance tools.",
fifthSectionCard3Heading: "Customizable Solutions",
fifthSectionCard3Para: "Design tokens tailored to your specific needs, from loyalty programs and internal transactions to external integrations.",

sixthSectionHeading: "Resources",
sixthSectionSide1Heading: "Documentation",
sixthSectionSide1Link1: "Token Generator User Manual",
sixthSectionSide1Link2: "API Reference",

sixthSectionSide2Heading: "Training",
sixthSectionSide2Link1: "Tokenization 101",
sixthSectionSide2Link2: "Advanced Blockchain Integration",

sixthSectionSide3Heading: "On-Demand Webinars",
sixthSectionSide3Link1: "Introduction to Custom Tokens",
sixthSectionSide3Link2: "Best Practices for Token Security",
sixthSectionSide3Link3: "Scaling Blockchain Ecosystems",

sixthSectionSide4Heading: "Connect",
sixthSectionSide4Link1: "Lattice Blog",
sixthSectionSide4Link2: "Twitter",
sixthSectionSide4Link3: "Facebook",
sixthSectionSide4Link4: "YouTube",
sixthSectionSide4Link5: "LinkedIn",

seventhSectionHeading: "Get Started with the Fungible Private Token Generator",
seventhSectionBox1Heading: "Try it for Free",
seventhSectionBox1Para: "Experience the power of custom token generation with a free trial of our platform.",
seventhSectionBox1Button: "Start Free Trial",
seventhSectionBox2Heading: "Schedule a Demo",
seventhSectionBox2Para: "Discover how our token generator can transform your business. Schedule a personalized demo today.",
seventhSectionBox2Button: "Request a Demo",
seventhSectionBox3Heading: "Contact Us",
seventhSectionBox3Para: "Have questions? Connect with our experts to explore the possibilities for your private blockchain.",
seventhSectionBox3Button: "Get in Touch",
};
