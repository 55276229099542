export const blockchainHRSuite = {
    firstSectionHeading: "Lattice Blockchain Human Resources Suite",
    firstSectionPara: "Revolutionize your HR processes with the Lattice Blockchain Human Resources Suite. Designed to streamline workforce management, enhance data security, and drive efficiency, this suite integrates blockchain technology to transform how organizations manage their human capital.",
    firstSectionButton1: "Explore HR Solutions",
    firstSectionButton2: "Contact an Expert",

    secondSectionHeading: "Blockchain-Powered HR Innovations",
    secondSectionButton: "Learn more about our solutions",

    thirdSectionSide1Heading: "Transformative HR Use Cases",
    thirdSectionSide1Para: "Explore how blockchain enhances employee onboarding, payroll, benefits administration, and more. Discover solutions tailored to address modern workforce challenges.",
    thirdSectionSide1Button: "Learn More",

    thirdSectionSide2Heading: "Webinar: The Future of HR with Blockchain",
    thirdSectionSide2Para: "Watch experts discuss how blockchain technology is reshaping HR management, offering unparalleled transparency, accuracy, and security.",
    thirdSectionSide2Button: "Watch the Webinar",

    bookingsSectionHeading: "Case Study: A Leading Tech Firm Automates HR Operations with Blockchain",
    bookingsSectionPara: "Learn how a multinational tech company uses Lattice Blockchain HR Suite to streamline onboarding, manage payroll, and enhance employee engagement.",
    bookingsSectionButton: "Read the Case Study",

    fourthSectionHeading: "Core Features of the Blockchain HR Suite",
    fourthSectionButton1: "Blockchain Payroll",
    fourthSectionButton2: "Employee Onboarding",
    fourthSectionButton3: "Time and Attendance",
    fourthSectionButton4: "Performance Management",
    fourthSectionButton5: "Benefits Administration",

    fourthSectionPage1Heading: "Blockchain Payroll",
    fourthSectionPage1cardheading1: "Automated and Secure Payroll Processing",
    fourthSectionPage1fastTrackList1: "Leverage blockchain for accurate, real-time payroll calculations and transfers.",
    fourthSectionPage1cardheading2: "Compliance Management",
    fourthSectionPage1fastTrackList2: "Ensure adherence to tax regulations and labor laws with blockchain’s immutable record-keeping.",
    fourthSectionPage1cardheading3: "Cross-Border Transactions",
    fourthSectionPage1fastTrackList3: "Facilitate cost-effective, transparent international payments with blockchain.",
    fourthSectionPage1cardheading4: "Data Security",
    fourthSectionPage1fastTrackList4: "Protect sensitive payroll information with encrypted, blockchain-powered systems.",

    fourthSectionPage2Heading: "Employee Onboarding",
    fourthSectionPage2cardheading1: "Streamlined Onboarding Process",
    fourthSectionPage2fastTrackList1: "Digitize and automate the onboarding journey with blockchain-secured documentation.",
    fourthSectionPage2cardheading2: "Identity Verification",
    fourthSectionPage2fastTrackList2: "Authenticate employee credentials and work history instantly through blockchain.",
    fourthSectionPage2cardheading3: "Contract Management",
    fourthSectionPage2fastTrackList3: "Generate, sign, and store contracts securely with smart contracts.",

    fourthSectionPage3Heading: "Time and Attendance",
    fourthSectionPage3cardheading1: "Accurate Time Tracking",
    fourthSectionPage3fastTrackList1: "Utilize blockchain for transparent, tamper-proof time and attendance records.",
    fourthSectionPage3cardheading2: "Integration with Payroll",
    fourthSectionPage3fastTrackList2: "Seamlessly integrate time data with payroll processing to ensure accuracy.",
    fourthSectionPage3cardheading3: "Real-Time Analytics",
    fourthSectionPage3fastTrackList3: "Gain insights into workforce productivity and attendance trends with real-time blockchain analytics.",

    fourthSectionPage4Heading: "Performance Management",
    fourthSectionPage4cardheading1: "Transparent Reviews",
    fourthSectionPage4fastTrackList1: "Ensure unbiased evaluations with blockchain-verified performance records.",
    fourthSectionPage4cardheading2: "Goal Setting and Tracking",
    fourthSectionPage4fastTrackList2: "Track progress against objectives with a blockchain-backed system for transparency.",
    fourthSectionPage4cardheading3: "Reward and Recognition",
    fourthSectionPage4fastTrackList3: "Incentivize employees with blockchain-secured rewards programs.",

    fourthSectionPage5Heading: "Benefits Administration",
    fourthSectionPage5cardheading1: "Simplified Benefits Management",
    fourthSectionPage5fastTrackList1: "Use blockchain to manage benefits enrollment, eligibility, and tracking.",
    fourthSectionPage5cardheading2: "Secure Healthcare Records",
    fourthSectionPage5fastTrackList2: "Integrate healthcare benefits securely with blockchain for better data privacy.",
    fourthSectionPage5cardheading3: "Customized Employee Benefits",
    fourthSectionPage5fastTrackList3: "Offer tailored benefits packages supported by blockchain analytics.",

    fifthSectionHeading: "Key Benefits of the Blockchain HR Suite",
    fifthSectionCard1Heading: "Enhanced Security",
    fifthSectionCard1Para: "Blockchain encryption ensures the confidentiality and integrity of HR data, protecting it from breaches.",
    fifthSectionCard2Heading: "Transparency and Compliance",
    fifthSectionCard2Para: "Track all HR transactions with an immutable blockchain ledger to maintain compliance with labor laws and regulations.",
    fifthSectionCard3Heading: "Operational Efficiency",
    fifthSectionCard3Para: "Automate repetitive tasks like payroll and attendance, freeing HR teams to focus on strategic initiatives.",

    sixthSectionHeading: "Resources for Your HR Transformation",
    sixthSectionSide1Heading: "Documentation",
    sixthSectionSide1Link1: "Blockchain HR Implementation Guide",
    sixthSectionSide1Link2: "Benefits of Blockchain in HR",

    sixthSectionSide2Heading: "Training and Events",
    sixthSectionSide2Link1: "HR Blockchain Training Programs",
    sixthSectionSide2Link2: "Upcoming Webinars",

    sixthSectionSide3Heading: "Case Studies and Insights",
    sixthSectionSide3Link1: "Blockchain HR in Action",
    sixthSectionSide3Link2: "Leveraging Blockchain for Workforce Management",

    sixthSectionSide4Heading: "Stay Connected",
    sixthSectionSide4Link1: "Lattice HR Blog",
    sixthSectionSide4Link2: "Twitter",
    sixthSectionSide4Link3: "LinkedIn",
    sixthSectionSide4Link4: "YouTube",

    seventhSectionHeading: "Get Started with the Lattice Blockchain HR Suite",
    seventhSectionBox1Heading: "Try it for Free",
    seventhSectionBox1Para: "Explore the transformative potential of blockchain in HR with a free trial.",
    seventhSectionBox1Button: "Start Free Trial",
    seventhSectionBox2Heading: "Request a Demo",
    seventhSectionBox2Para: "Experience how the Blockchain HR Suite can elevate your workforce management.",
    seventhSectionBox2Button: "Schedule a Demo",
    seventhSectionBox3Heading: "Contact Us",
    seventhSectionBox3Para: "Have questions? Speak with one of our experts today.",
    seventhSectionBox3Button: "Get in Touch",
   
}