export const privateBlockchainConfigPage = {
    firstSectionHeading: "Lattice Cloud Private Blockchain Configuration",
    firstSectionPara: "Empower your organization with the ability to create and configure a private blockchain infrastructure tailored to your business needs. With Lattice Cloud, select the protocols, consensus mechanisms, and tools that best suit your ecosystem, ensuring a seamless transition to blockchain-enabled operations.",
    firstSectionButton1: "Create Your Private Blockchain",
    firstSectionButton2: "Learn More About Private Chains",
    firstSectionSide2TitleImg: "Explore how Lattice simplifies private blockchain setup and integration.",

    secondSectionHeading: "Tailored Blockchain Infrastructure at Your Fingertips",
    secondSectionPara: "Leverage user-driven configuration tools to design your private blockchain infrastructure. From selecting protocols to defining consensus mechanisms, Lattice provides the flexibility to meet your unique requirements.",
    secondSectionButton: "Start Configuring Your Chain",

    thirdSectionHeading: "Why Choose Lattice Private Blockchain Configuration?",
    thirdSectionSide1Heading: "Custom Protocols and Consensus Mechanisms",
    thirdSectionSide1Para: "Select from a range of blockchain protocols and consensus algorithms, including Proof of Work, Proof of Stake, and Delegated Proof of Stake, to ensure optimal performance for your ecosystem.",
    thirdSectionSide2Heading: "User-Driven Configuration Tools",
    thirdSectionSide2Para: "Utilize intuitive tools to design your blockchain infrastructure, configure network parameters, and deploy nodes with minimal effort.",
    thirdSectionSide3Heading: "Seamless Vertical Integration",
    thirdSectionSide3Para: "Transition existing operations to your private blockchain with built-in tools for mapping, migration, and integration of workflows.",

    fourthSectionHeading: "Private Blockchain Features",
    fourthSectionButton1: "Protocol Selection",
    fourthSectionButton2: "Consensus Mechanisms",
    fourthSectionButton3: "Custom Node Deployment",
    fourthSectionButton4: "Vertical Workflow Transition Tools",

    fourthSectionPage1Heading: "Protocol Selection",
    fourthSectionPage1Description: "Choose from industry-standard blockchain protocols or create custom implementations to suit your specific use cases.",
    fourthSectionPage1Link: "Learn More About Protocol Options",
    fourthSectionPage1cardheading1: "Industry-Standard Protocols",
    fourthSectionPage1fastTrackList1: "Support for Ethereum, Hyperledger Fabric, and more.",
    fourthSectionPage1cardheading2: "Custom Protocol Support",
    fourthSectionPage1fastTrackList2: "Define your own blockchain protocol to align with business needs.",
    fourthSectionPage1cardheading3: "Scalable Architecture",
    fourthSectionPage1fastTrackList3: "Ensure scalability with configurable block sizes and transaction throughput.",

    fourthSectionPage2Heading: "Consensus Mechanisms",
    fourthSectionPage2Description: "Optimize your private blockchain with the right consensus mechanism for security, scalability, and speed.",
    fourthSectionPage2Link: "Learn More About Consensus Mechanisms",
    fourthSectionPage2cardheading1: "Diverse Options",
    fourthSectionPage2fastTrackList1: "Choose from PoW, PoS, DPoS, and other consensus methods.",
    fourthSectionPage2cardheading2: "Configurable Parameters",
    fourthSectionPage2fastTrackList2: "Adjust parameters like block intervals and validation rules.",
    fourthSectionPage2cardheading3: "Security Enhancements",
    fourthSectionPage2fastTrackList3: "Incorporate mechanisms for fraud prevention and decentralized governance.",

    fourthSectionPage3Heading: "Custom Node Deployment",
    fourthSectionPage3Description: "Deploy and manage nodes with ease, tailoring network topology to your organizational needs.",
    fourthSectionPage3Link: "Learn More About Node Deployment",
    fourthSectionPage3cardheading1: "Flexible Deployment",
    fourthSectionPage3fastTrackList1: "Deploy nodes on-premises, in the cloud, or in hybrid environments.",
    fourthSectionPage3cardheading2: "Node Management Tools",
    fourthSectionPage3fastTrackList2: "Monitor, scale, and manage nodes through an intuitive dashboard.",
    fourthSectionPage3cardheading3: "Performance Optimization",
    fourthSectionPage3fastTrackList3: "Optimize node performance with configurable resource allocation.",

    fourthSectionPage4Heading: "Vertical Workflow Transition Tools",
    fourthSectionPage4Description: "Migrate your existing operations seamlessly onto the blockchain with built-in tools and features.",
    fourthSectionPage4Link: "Learn More About Workflow Tools",
    fourthSectionPage4cardheading1: "Automated Workflow Mapping",
    fourthSectionPage4fastTrackList1: "Map existing processes to blockchain workflows with minimal manual intervention.",
    fourthSectionPage4cardheading2: "Data Integration",
    fourthSectionPage4fastTrackList2: "Integrate legacy systems with blockchain nodes using APIs and connectors.",
    fourthSectionPage4cardheading3: "Enhanced Insights",
    fourthSectionPage4fastTrackList3: "Monitor transitions and performance with real-time analytics.",

    fifthSectionHeading: "Unlock the Potential of Private Blockchain",
    fifthSectionPara1: "Lattice Cloud simplifies the process of building and configuring private blockchains, enabling organizations to focus on innovation and growth. Start building your custom blockchain today.",
    fifthSectionPara2: "Learn more about how Lattice can accelerate your blockchain journey.",
    fifthSectionButton1: "Get Started",
    fifthSectionButton2: "Contact Sales",

    sixthSectionHeading: "Success Stories",
    sixthSectionLink: "Explore Customer Success Stories",

    ninthSectionHeading: "Start Your Private Blockchain Journey",
    ninthSectionPage1Side1Heading: "Try Lattice Cloud for Free",
    ninthSectionPage1Side1Para: "Start configuring your private blockchain today with Lattice Cloud Free Tier. Enjoy $300 in free credits to explore advanced features.",
    ninthSectionPage1Side1button: "Try for Free",
    ninthSectionPage1Side2Heading: "What’s Included in the Free Tier?",
    ninthSectionPage1Side2List1: "Custom Blockchain Protocol Setup",
    ninthSectionPage1Side2List2: "Basic Node Deployment",
    ninthSectionPage1Side2List3: "Consensus Mechanism Configuration",
    ninthSectionPage1Side2List4: "Vertical Workflow Mapping Tools",

    ninthSectionPage4Side1Heading: "Contact Blockchain Experts",
    ninthSectionPage4Side1Para: "Need help configuring your private blockchain? Our experts are here to guide you through every step.",
    ninthSectionPage4Side1button: "Get in Touch",
    ninthSectionPage4Side2Heading: "Frequently Asked Questions",
    ninthSectionPage4Side2List1: "How do I choose the right protocol and consensus mechanism?",
    ninthSectionPage4Side2List2: "What are the steps to migrate my operations to blockchain?",
    ninthSectionPage4Side2List3: "Can I integrate legacy systems with my private blockchain?",

    ninthSectionButton1: "Start for Free",
    ninthSectionButton2: "Explore Features",
    ninthSectionButton3: "Contact Experts",
    

   
}