export const userIdentity = {
    // 1. First Section
    firstSectionHeading: "Lattice Administration for User Identity",
    firstSectionPara: "Prove once, use forever. Lattice’s identity administration module allows users to reuse their credentials across different applications, reducing friction while delivering best-in-class user experiences. Our zero knowledge proof credentials ensure fast, secure, and private verification—offering a seamless solution for individuals and businesses alike.",

    // 2. Second Section
    secondSectionHeading: "Discover the key to trusted, privacy-centric interactions—and see how it can work for you.",
    secondSectionButton: "Learn More",

    // 3. Third Section (Side 1)
    thirdSectionSide1Heading: "Modernize Identity and Access",
    thirdSectionSide1Para: "Dive into how leading organizations are leveraging 'Prove once, use forever' credentials to streamline onboarding, reduce fraud, and enhance user trust.",
    thirdSectionSide1Button: "Explore the eBook (PDF)",

    // 3. Third Section (Side 2)
    thirdSectionSide2Heading: "Accelerate User Verification with Zero Knowledge Proof",
    thirdSectionSide2Para: "Understand how Lattice’s privacy-focused module uses cryptographic proofs to validate identities without revealing sensitive information, meeting global compliance standards.",
    thirdSectionSide2Button: "Read the Analysis (PDF)",

    // 4. Fourth Section (Main)
    fourthSectionHeading: "Lattice Identity Capabilities",
    fourthSectionButton1: "Prove Once, Use Forever",
    fourthSectionButton2: "Age Verification",
    fourthSectionButton3: "Anti-Sybil Protection",
    fourthSectionButton4: "Authenticity Solutions",

    // 4a. Fourth Section (Page 1)
    fourthSectionPage1cardheading1: "Revolutionary Credential Reuse",
    fourthSectionPage1fastTrackList1: 
      "Lattice’s user identity solution enables a one-time verification process that can be leveraged across multiple services and applications. With zero knowledge proof credentials, users maintain full privacy while reducing repetitive steps. This not only lessens friction but also accelerates user onboarding and increases trust throughout your ecosystem.",

    learnMoreButton: "Learn More",

    // 4b. Fourth Section (Page 2)
    fourthSectionPage2cardheading1: "Age and Compliance Verification",
    fourthSectionPage2fastTrackList1: 
      "Ensure compliance with global regulations using a seamless, privacy-focused solution for age checks. Lattice’s identity module verifies user eligibility without exposing personal data—enhancing trust, meeting legal requirements, and delivering a frictionless user experience.",

    // 4c. Fourth Section (Page 3)
    fourthSectionPage3cardheading1: "Stop Deepfakes at the Source",
    fourthSectionPage3fastTrackList1: 
      "Lattice Authenticity Solutions link verified identities to content, preventing deepfake creation and manipulation. By offering tamper-proof credentials and Anti-Sybil technology, you can safeguard rewards distribution, maintain ecosystem integrity, and protect users from malicious or fraudulent activity.",

    // 4d. Fourth Section (Page 4)
    fourthSectionPage4cardheading1: "Global, Interoperable Digital Identities",
    fourthSectionPage4fastTrackList1: 
      "Lattice’s secure digital identity infrastructure connects citizens, enterprises, and government services worldwide. Our module simplifies cross-border authentication, ensuring each identity is verified, genuine, and tamper-proof—enabling seamless global operations with unmatched privacy and security.",

    // 5. Fifth Section (Use Cases)
    fifthSectionHeading: "Lattice Identity Use Cases",
    fifthSectionCard1Heading: "Deliver a Frictionless User Experience",
    fifthSectionCard1Para: 
      "Minimize repetitive steps with a one-time enrollment process and enable users to authenticate instantly across multiple applications. Enhance overall satisfaction and build user loyalty.",

    fifthSectionCard2Heading: "Comply with Global Regulations",
    fifthSectionCard2Para: 
      "Simplify identity checks for KYC, AML, and age restrictions with zero knowledge proof credentials—allowing instant, privacy-safe verification across borders and industries.",

    fifthSectionCard3Heading: "Prevent Sybil Attacks and Fraud",
    fifthSectionCard3Para: 
      "Combine Anti-Sybil technology with cryptographic proof to secure user accounts, block malicious actors, and preserve trust within your applications or communities.",

    fifthSectionCard4Heading: "Defend Against Deepfakes and Misinformation",
    fifthSectionCard4Para: 
      "Use authenticity solutions to ensure content is linked to verified identities, stopping deepfakes at the source while enabling tamper-proof creation and sharing of media.",

    fifthSectionCard5Heading: "Seamless Integration with Existing Systems",
    fifthSectionCard5Para: 
      "Easily incorporate Lattice’s identity module into existing platforms, enabling modern user verification methods without overhauling your entire infrastructure.",

    // 7. Seventh Section
    seventhSectionHeading: "Get Started",
    seventhSectionBox1Heading: "Connect with a Lattice Identity Specialist",
    seventhSectionBox1Para: 
      "Learn how Lattice’s administration module for user identity can meet your organization’s unique needs, from age verification to zero knowledge proof credentials.",
    seventhSectionBox1Button: "Get in Touch",
};
