import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


import m1 from '../src/image/middle-img-1.svg'
import m2 from '../src/image/SMI-middle-img.svg'
import m3 from '../src/image/Four-Season-middle-img.svg'

import home1 from '../src/image/green-bg-img.jpg'
import home2 from '../src/image/blue bg.jpg'

import brand1 from '../src/image/deliveroo-bg-img1.jpg'
import brand2 from '../src/image/imperial-college-london-bg-img.jpg'
import brand3 from '../src/image/four_seasons-bg-img.jpg'
import brand4 from '../src/image/SMI-bg-img.jpg'
import brand5 from '../src/image/GFMH-bg-img.jpg'
import brand6 from '../src/image/Albina-tech-bg-img.jpg'

import Latticelogo from "../src/image/lattice-logo-img.svg";

import { slides, blockchainSolutions, customerSection, customersList, customerDetails, footerSections, getCustomer,cookieText } from './Content/eng_content';

function HomePage() {


    const blockchainImages = [m1, m2, m3]; // Images for blockchain solutions

    const [openSections, setOpenSections] = useState({});
    const [activeItem, setActiveItem] = useState('training');

    const handleItemClick = (item) => {
        setActiveItem(item);
    };

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };
    const backgroundImages = {
        training: `url(${brand1})`,
        technical: `url(${brand2})`,
        billing: `url(${brand3})`,
        account: `url(${brand4})`,
        cloud: `url(${brand5})`,
        innovation: `url(${brand6})`,
    };


    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(false);

    const nextSlide = () => {
        setFade(false); // Reset animation
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length); // Move to the next slide
            setFade(true); // Trigger fade-in for new content
        }, 100); // Ensure a slight delay for animation reset
    };

    const prevSlide = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
            setFade(true);
        }, 100);
    };


    const [isExpanded, setIsExpanded] = useState(true);

    const toggleWidget = () => {
        setIsExpanded(!isExpanded);
    };


    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };




    return (
        <div className="all">




            {/* <div
                className={`chat-widget ${isExpanded ? "expanded" : "collapsed"}`}
            >
                {isExpanded ? (
                    <div className="chat-widget-content">
                 
                        <div className="chat-widget-header">
                            <div className="chat-widget-header-left">
                             
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    width="35"
                                    fill="red"
                                    className="chat-icon"
                                >
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path
                                        d="M12 3C6.48 3 2 6.82 2 11c0 2.28 1.33 4.31 3.44 5.74L5 20l4.28-2.07C10.36 18.64 11.16 19 12 19c5.52 0 10-3.82 10-8.5S17.52 3 12 3zm-1 11H8v-2h3v2zm5 0h-3v-2h3v2zm0-3H8V9h8v2z"
                                    />
                                </svg>
                                <h3 className="chat-title">Chat now</h3>
                            </div>
                     
                            <button onClick={toggleWidget} className="close-btn">
                                &#x2715;
                            </button>
                        </div>

                     
                        <div className="chat-widget-body">
                            <p className='chat-heading'>Call us sales</p>
                            <h3 className="contact-number">+1-234-567-890</h3>
                        </div>
                    </div>
                ) : (
                  
                    <div
                        onClick={toggleWidget}
                        className="chat-widget-collapsed"
                    >
                   
                        <div className="call-icon-container">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="25"
                                viewBox="0 0 24 24"
                                width="25"
                                fill="currentColor"
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path
                                    d="M6.62 10.79a15.05 15.05 0 006.59 6.59l2.2-2.2a1.005 1.005 0 011.11-.24 9.03 9.03 0 003.4.64c.55 0 1 .45 1 1v3.01c0 .55-.45 1-1 1C11.4 21.97 2 12.56 2 4.01 2 3.46 2.45 3 3 3H6c.55 0 1 .45 1 1 0 1.18.21 2.32.64 3.4.17.39.09.85-.24 1.11l-2.2 2.2z"
                                />
                            </svg>
                        </div>
                   
                        <div className="separator" />
                   
                        <div className="chat-icon-container">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="25"
                                viewBox="0 0 24 24"
                                width="25"
                                fill="red"
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path
                                    d="M12 3C6.48 3 2 6.82 2 11c0 2.28 1.33 4.31 3.44 5.74L5 20l4.28-2.07C10.36 18.64 11.16 19 12 19c5.52 0 10-3.82 10-8.5S17.52 3 12 3zm-1 11H8v-2h3v2zm5 0h-3v-2h3v2zm0-3H8V9h8v2z"
                                />
                            </svg>
                        </div>
                    </div>
                )}
            </div> */}







            <div className="scroller-img-bg">
                <div className="slider">
                    {/* Image slides */}
                    <div className="slides">
                        {slides.map((slide, index) => (
                            <div key={index} className={`slide ${index === currentIndex ? "active" : ""}`}>
                                <div className="row">
                                    <div className="column img-container">
                                        <img src={slide.image} alt={slide.title} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Info content with fade-in animation */}
                    <div className={`column content ${fade ? "fade-in" : ""}`}>
                        <h1>{slides[currentIndex].title}</h1>
                        <span
                            style={{
                                display: "block",
                                width: "10%",
                                height: "4px",
                                backgroundColor: "rgba(255, 140, 0, 1)",
                                marginBottom: "2vh",
                            }}
                        ></span>
                        <p>{slides[currentIndex].description}</p>
                        <a href={slides[currentIndex].link} className="btns">
                            {slides[currentIndex].buttonText}
                        </a>
                    </div>

                    {/* Navigation buttons */}
                    <div className="navigation">
                        <button className="prev" onClick={prevSlide}>
                            <svg width="85" height="100" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4l-4 4 4 4" stroke="#3A3631" strokeWidth="1" fill="none" />
                            </svg>

                        </button>
                        <button className="next" onClick={nextSlide}>
                            <svg width="85" height="100" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 4l4 4-4 4" stroke="#3A3631" strokeWidth="1" fill="none" />
                            </svg>
                        </button>
                    </div>

                    {/* Bullet points for slide navigation */}
                    <div className="bullets">
                        {slides.map((_, index) => (
                            <span
                                key={index}
                                className={`bullet ${index === currentIndex ? "active" : ""}`}
                                onClick={() => setCurrentIndex(index)}
                            ></span>
                        ))}
                    </div>
                </div>
            </div>




            <div className="img-middle">
                <div className="middle">
                    {blockchainSolutions.map((solution, index) => (
                        <div className="m1" key={index}>
                            <img
                                src={blockchainImages[index]}
                                alt="brand"
                                className="mimg"
                            />
                            <h1 className="mh1">{solution.title}</h1>
                            {/* Render raw HTML for the description */}
                            <div
                                className="mp1"
                                dangerouslySetInnerHTML={{ __html: solution.description }}
                            />
                            <a className="ma" href={solution.link}>
                                {solution.linkText}
                            </a>
                        </div>
                    ))}
                </div>
            </div>





            <div className="App">
                <div className="banner">
                    <div className="b1">
                        <img src={home1} alt="brand" className="b1img" />
                        <h1 className="bh1">
                            Lattice Cloud <br />
                            <b className="bh1a">Applications</b>
                        </h1>
                        <p className="bp">
                            A complete suite of cloud applications delivering consistent <br />
                            processes and data across your business.
                        </p>
                        <a href="/lattice-infrastructure-compute" style={{ color:"inherit" , textDecoration:"none"}}>  <button className="button1">Explore </button> </a>
                    </div>

                    <div className="b2">
                        <img src={home2} alt="brand" className="b2img" />
                        <h1 className="bh12">
                            Lattice Cloud <br />
                            <b className="bh1a2">Infrastructure</b>
                        </h1>
                        <p className="bp2">
                            An automated, secure platform for migrating enterprise <br />
                            workloads and building new cloud native apps.
                        </p>
                        <a href="/lci-overview" style={{ color:"inherit" , textDecoration:"none"}}> <button className="button2">Explore </button> </a>
                    </div>
                </div>
            </div>




            <div className="dpage">
                {/* Header Section */}
                <div className="A">
                    <p className="hpt">
                        <b>{customerSection.title}</b>
                    </p>
                    <span className="customer-story">
                        <a className='customer-story-a' href={customerSection.link}>{customerSection.linkText}</a>
                    </span>
                </div>

                {/* Horizontal List */}
                <div className="list-container">
                    <ul className="list">
                        {customersList.map((customer, index) => (
                            <li
                                key={customer.id}
                                className={`list-item ${activeItem === customer.id ? 'active' : ''}`}
                                onClick={() => handleItemClick(customer.id)}
                            >
                                <img
                                    src={customer.image}
                                    alt={customer.id}
                                    className={`list-item-image img-${index + 1}`}
                                />
                            </li>
                        ))}
                    </ul>
                </div>



                {/* Content Section with Dynamic Background */}
                <div
                    className="flex-container"
                    style={{
                        backgroundImage: backgroundImages[activeItem],
                        // backgroundSize: "cover",
                    }}
                >
                    {/* Render content dynamically */}
                    <div className="first-div">
                        <h2>{customerDetails[activeItem].heading}</h2>
                        <button className="fist_div-button">
                            <Link to={getCustomer(activeItem)} style={{ textDecoration: 'none', color: 'inherit' }}>
                                {customerDetails[activeItem].buttonText}
                            </Link>
                        </button>
                    </div>
                </div>
            </div>





            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                    {cookieText.listItems.map((item, index) => (
                                        <li key={index} className="div-2-cookies-list-text">
                                            <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                        </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                       {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                    {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}



        </div>
    );
}

export default HomePage;
