import React, { useState, useRef } from 'react';
import img1 from '../image/cloud/yt-video-thumbnail-tRnXMKJWqqc.jpg'
import "./Healthcare.css"
import img from '../image/rc77-edu-explore-finance-hr.avif'
import img2 from '../image/rc77-edu-explore-analytics.avif'
import img3 from '../image/rc77-edu-student-cloud-financial-planning.webp'
import img4 from '../image/rc66-edu-read-idaho-story.avif'

import svg1 from '../image/deliveroo-logo3.png'
import svg2 from '../image/AlbaniaTech-logo.svg'
import svg4 from '../image/german-federal-ministry-logo.svg'
import svg5 from '../image/Four_Seasons_logo3.png'
import svg6 from '../image/The_Related_Companies-Logo.svg'
import svg7 from '../image/imperial-college-london-logo3.svg'

import arrow from '../image/arrow-of-industry-page.svg'
import { fungiblePrivate } from '../Content/English-Content/FungiblePrivate';
import { useChatWidget } from "../ChatWidgetContext";
import { education } from '../Content/English-Content/industry/education';



export default function Education() {


     const { toggleWidget } = useChatWidget();


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };




    // State to track the selected resource
    const [selectedResource, setSelectedResource] = useState('downloads');

    // Resource content mapping
    const resources = {
        downloads: {
            heading: education.fourthSectionPage1Heading,
            description: education.fourthSectionPage1Para,
            // link: "Learn how Lattice helps provider organizations",
            imgSrc: img,
        },
        documentation: {
            heading: education.fourthSectionPage2Heading,
            description: education.fourthSectionPage2Para,
            // link: "Learn about Lattice and private education",
            imgSrc: img2,
        },
        events: {
            heading: education.fourthSectionPage3Heading,
            description: education.fourthSectionPage3Para,
            // link: "Learn how Lattice helps public health organizations",
            imgSrc: img3,
        },
        technologies: {
            heading: education.fourthSectionPage4Heading,
            description: education.fourthSectionPage4Para,
            // link: "Learn how Lattice helps life sciences organizations",
            imgSrc: img4,
        },

    };

    // Handler to set the selected resource based on the clicked button
    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    // Get the content for the currently selected resource
    const currentResource = resources[selectedResource];


    return (


        <>



            <div className="product-infra-compute-first-div">



                <h1 className="industry-health-care-first-div-heading">
                
                                    {education.firstSectionHeading}
                                    <span
                                        style={{
                                            display: "block",
                                            width: "40px",
                                            height: "4px",
                                            background: "#f0cc71",
                                            marginBottom: "3vh",
                                            marginTop: "3vh",
                                        }}
                                    ></span>
                
                                    <p className="industry-health-care--first-div-paragraph">{education.firstSectionPara}</p>
                
                                    {/* <a href="/create-account" style={{ color: "inherit", textDecoration: "none" }}><button className="product-infra-compute-first-div-btn-1">{education.firstSectionButton}</button></a> */}
                
                
                                </h1>


            </div>

            {/* 2-div */}

            <div className="last-flat-img-industry"></div>



            {/* 3-div */}


            <div className="product-infra-compute-third-div">
            
                            <h1 className="product-infra-compute-third-div-heading">{education.secondSectionHeading}
                                <span
                                    style={{
                                        display: "block",
                                        width: "40px",
                                        height: "4px",
                                        background: "#f0cc71",
                                        marginBottom: "3vh",
                                        marginTop: "3vh",
                                    }}
                                ></span>
                            </h1>
            
                            <div className="product-infra-compute-third-div-cards-wrapper">
            
                                <div className="product-infra-compute-third-div-card-1">
            
                                    <div className="product-infra-compute-third-div-card-1-content">
            
                                        <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                        {education.secondSectionSide1Heading}
                                        </h1>
            
                                        <p className="product-infra-compute-third-div-card-1-all-para">{education.secondSectionSide1Para}</p>
            
                                    </div>
            
                                </div>
            
                                <div className="product-infra-compute-third-div-card-1">
            
                                    <div className="product-infra-compute-third-div-card-1-content">
            
                                        <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                        {education.secondSectionSide2Heading}</h1>
            
                                        <p className="product-infra-compute-third-div-card-1-all-para">{education.secondSectionSide2Para}</p>
            
                                    </div>
            
                                </div>
            
                                <div className="product-infra-compute-third-div-card-1">
                                    <div className="product-infra-compute-third-div-card-1-content">
            
                                        <h1 className="product-infra-compute-third-div-card-1-all-heading">
                                        {education.secondSectionSide3Heading}</h1>
            
                                        <p className="product-infra-compute-third-div-card-1-all-para">{education.secondSectionSide3Para}</p>
            
                                    </div>
            
                                </div>
            
                            </div>
            
                        </div>









            {/* 4-div */}



            <div className="content-of-resources-industry-page" >
                            <h1 className="solution-heading">{education.fourthSectionHeading}</h1>
            
                            <div className="content-wrapper-of-resource">
                                <div className="content-1-of-resource">
                                    <ul>
                                        <li>
                                            <button
                                                className={`btn-resource ${selectedResource === 'downloads' ? 'active' : ''
                                                    }`}
                                                onClick={() => handleResourceClick('downloads')}
                                            >
                                                {education.fourthSectionButton1}
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                className={`btn-resource ${selectedResource === 'documentation' ? 'active' : ''
                                                    }`}
                                                onClick={() => handleResourceClick('documentation')}
                                            >
                                                {education.fourthSectionButton2}
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                className={`btn-resource ${selectedResource === 'events' ? 'active' : ''
                                                    }`}
                                                onClick={() => handleResourceClick('events')}
                                            >
                                                {education.fourthSectionButton3}
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                className={`btn-resource ${selectedResource === 'technologies' ? 'active' : ''
                                                    }`}
                                                onClick={() => handleResourceClick('technologies')}
                                            >
                                                {education.fourthSectionButton4}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
            
                                {/* Content-2 */}
                                <div className="content-2-of-resource-of-industry-section">
                                    <div className="img-of-resource-and-para">
                                        <div className="img-of-resource-industry-page">
                                            <img className="img-of-resource-industry-page" src={currentResource.imgSrc} alt={currentResource.heading} />
                                        </div>
                                        <div className="para-of-res-content-industry-page">
                                            <h1 className="resource-heading-of-card">{currentResource.heading}</h1>
                                            <p className="para-of-resource">{currentResource.description}</p>
                                        </div>
                                    </div>
                                </div>
            
                            </div>
                        </div>



            {/* div-5 */}



            <div className="product-infra-div-seven">

                <div className="product-infra-div-six-text-wrapper">

                    <h1 className="become-partners-list-1-sec-last-div-heading">
                    {education.fifthSectionHeading}
                    </h1>

                    <a href='/customer-story' className="product-infra-div-six-link-of-txt-wrapper">{education.fifthSectionlink}</a>

                </div>

                <div className="product-infra-div-seven-img-wrapper">

                    <img src={svg1} className="product-infra-div-seven-img-1"></img>

                    <img src={svg2} className="product-infra-div-seven-img-1"></img>

                    <img src={svg4} className="product-infra-div-seven-img-1"></img>

                    <img src={svg5} className="product-infra-div-seven-img-1"></img>

                    <img src={svg6} className="product-infra-div-seven-img-1"></img>

                    <img src={svg7} className="product-infra-div-seven-img-1"></img>


                </div>

            </div>



            {/* div-6 */}

            <div className="industry-div-6-all-wrapper">
            
                            <h1 className="become-partners-list-1-sec-last-div-heading">
                            {education.sixthSectionHeading}
                            </h1>
            
                            <div className="childs-of-industry-div-6">
            
                                <div className="first-child-of-industry-6-div">
                                    <p className="para-one-of-6-div-industry">{education.sixthSectionSide1Heading}</p>
            
                                    <p className="para-two-of-6-div-industry">{education.sixthSectionSide1Para}</p>
            
                                </div>
            
                                <div className="sec-child-of-industry-6-div">
                                    <p className="para-one-of-6-div-industry">{education.sixthSectionSide2Heading}</p>
            
                                    <p className="para-two-of-6-div-industry">{education.sixthSectionSide2Para}</p>
            
                                </div>
            
                            </div>
            
                        </div>


            {/* div-7 */}

            <div class="mysql-section">
                <h2 class="mysql-heading">{education.seventhSectionHeading}</h2>
                <div class="mysql-cards">
                    <div class="mysql-card">
                        <div class="card-top">
                            <i class="icon-download"></i>
                            <h3>{education.seventhSectionSide1Heading}</h3>
                        </div>
                        <div class="card-bottom">
                            <p>{education.seventhSectionSide1Para}</p>
                            <a href="/create-account" style={{ color:"inherit" , textDecoration:"none"}}><button>Subscribe now</button></a>
                        </div>
                    </div>
                    <div class="mysql-card">
                        <div class="card-top">
                            <i class="icon-subscription"></i>
                            <h3>{education.seventhSectionSide2Heading}</h3>
                        </div>
                        <div class="card-bottom">
                            <p>{education.seventhSectionSide2Para}</p>
                            <button>Get started</button>
                        </div>
                    </div>
                    <div class="mysql-card">
                        <div class="card-top">
                            <i class="icon-contact"></i>
                            <h3>{education.seventhSectionSide3Heading}</h3>
                        </div>
                        <div class="card-bottom">
                            <p>{education.seventhSectionSide3Para}</p>
                            <button onClick={toggleWidget} >Get in touch</button>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="last-div-wrapper-of-industry">

                <h1 className="become-partners-list-1-sec-last-div-heading">
                    Get started with Lattice Health
                </h1>

                <div className="childs-of-7-div-industry">

                    <div className="first-child-of-7-div-industry">
                        <div className="fist-child-7-div-section-1-industry"></div>

                        <div className="fist-child-7-div-section-2-industry">
                            <p className="para-1-of-seven-div-industry">Talk to a team member about Lattice solutions for education.</p>

                            <button className="contact-btn-2">Contact Lattice Health today</button>
                        </div>
                    </div>

                    <div className="first-child-of-7-div-industry">
                        <div className="fist-child-7-div-section-1-industry"></div>

                        <div className="fist-child-7-div-section-2-industry">
                            <p className="para-1-of-seven-div-industry">Customers can contact our support team 24/7 for help.</p>

                            <button className="contact-btn-2">Contact us for help</button>
                        </div>
                    </div>

                </div>

            </div> */}





        </>


    )
}
