import React, { useState, useRef } from 'react';
import './become-a-customer.css'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import logo from '../image/customer/customer-logo-dow-jones.svg'
import Latticelogo from '../image/lattice-logo-img.svg';
import { becomeCustomer } from '../Content/English-Content/BecomeCustomer';
import { useChatWidget } from "../ChatWidgetContext";



function BecomeCustomer() {


    const { toggleWidget } = useChatWidget();

    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };





    return (
        <>



            <div className="upper-div-become-customer">

                <div className="upper-div-content-become-customer">

                    <Link className="Link-of-event" to="/customer-story">
                        <span>
                            {becomeCustomer.firstSectionLink}
                            <span className="arrow-eventpage">
                                <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 4l4 4-4 4" stroke="#FFF" strokeWidth="1.5" fill="none" />
                                </svg>
                            </span>
                        </span>
                    </Link>

                    <h1 className="main-heading-of-become-customer">

                        {becomeCustomer.firstSectionHeading}


                        <p className="side-heading-become-customer-para">{becomeCustomer.firstSectionPara}
                        </p>

                    </h1>

                    <button className="become-customer-btns" onClick={toggleWidget} >{becomeCustomer.firstSectionButton1} </button>

                </div>

            </div>





            <div className="become-customer-sec-div">

                <h1 className="become-customer-sec-div-heading-uper">

                    {becomeCustomer.thirdSectionHeading}
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span></h1>

                <div className="become-customer-card-wrapper">

                    <div className="become-cutomer-card-1">

                        <h1 className="become-customer-card-all-heading">
                            {becomeCustomer.thirdSectionSide1Heading}
                        </h1>

                        <p className="become-customer-card-para-all">
                            {becomeCustomer.thirdSectionSide1Para}
                        </p>

                    </div>

                    <div className="become-cutomer-card-1">


                        <h1 className="become-customer-card-all-heading">
                            {becomeCustomer.thirdSectionSide2Heading}
                        </h1>

                        <p className="become-customer-card-para-all">
                            {becomeCustomer.thirdSectionSide2Para}
                        </p>

                    </div>

                    <div className="become-cutomer-card-1">


                        <h1 className="become-customer-card-all-heading">
                            {becomeCustomer.thirdSectionSide3Heading}
                        </h1>

                        <p className="become-customer-card-para-all">
                            {becomeCustomer.thirdSectionSide3Para}
                        </p>

                    </div>

                    <div className="become-cutomer-card-1">


                        <h1 className="become-customer-card-all-heading">
                            {becomeCustomer.thirdSectionSide4Heading}
                        </h1>

                        <p className="become-customer-card-para-all">
                            {becomeCustomer.thirdSectionSide4Para}
                        </p>

                    </div>

                </div>

            </div>



            {/* <div className="become-customer-sec-last-div">

            <div className="become-customer-sec-last-div-child-1">

            <span className="become-customer-side-img-of-para"><img src={logo} className="become-customer-side-img-of-para" ></img></span>

            </div>

            <div className="become-customer-sec-last-div-child-2">

            <p className="become-customer-special-heading">

           {becomeCustomer.fourthSectionSpecialHeading}

            </p>

            <h3 className="become-customer-small-heading">
            {becomeCustomer.fourthSectionPara}
            </h3>

            <p className="become-customer-small-para">
            {becomeCustomer.fourthSectionSmallPara}
            </p>

            </div>

        </div> */}


            <div className="become-customer-sec-last-div-flex-box">

                <h1 className="become-customer-sec-last-div-heading">{becomeCustomer.fifthSectionHeading}</h1>

                <p className="become-customer-sec-last-div-all-para">{becomeCustomer.fifthSectionPara}</p>


                <div className="become-customer-sec-last-div-crad-wrapper">

                    <div className="become-customer-sec-last-div-card-1">

                        <h1 className="become-customer-sec-last-div-card-all-heading">
                            {becomeCustomer.fifthSectionCard1Heading}
                        </h1>

                        <p className="become-customer-sec-last-div-all-para">
                            {becomeCustomer.fifthSectionCard1Para}
                        </p>

                    </div>

                    <div className="become-customer-sec-last-div-card-1">

                        <h1 className="become-customer-sec-last-div-card-all-heading">
                            {becomeCustomer.fifthSectionCard2Heading}
                        </h1>

                        <p className="become-customer-sec-last-div-all-para">
                            {becomeCustomer.fifthSectionCard2Para}
                        </p>

                    </div>

                    <div className="become-customer-sec-last-div-card-1">

                        <h1 className="become-customer-sec-last-div-card-all-heading">
                            {becomeCustomer.fifthSectionCard3Heading}
                        </h1>

                        <p className="become-customer-sec-last-div-all-para">
                            {becomeCustomer.fifthSectionCard3Para}
                        </p>

                    </div>

                    <div className="become-customer-sec-last-div-card-1">

                        <h1 className="become-customer-sec-last-div-card-all-heading">
                            {becomeCustomer.fifthSectionCard4Heading}
                        </h1>

                        <p className="become-customer-sec-last-div-all-para">
                            {becomeCustomer.fifthSectionCard4Para}
                        </p>

                    </div>

                    <div className="become-customer-sec-last-div-card-1">

                        <h1 className="become-customer-sec-last-div-card-all-heading">
                            {becomeCustomer.fifthSectionCard5Heading}
                        </h1>

                        <p className="become-customer-sec-last-div-all-para">
                            {becomeCustomer.fifthSectionCard5Para}
                        </p>

                    </div>



                    <div className="become-customer-sec-last-div-card-1">

                        <h1 className="become-customer-sec-last-div-card-all-heading">
                            {becomeCustomer.fifthSectionCard6Heading}
                        </h1>

                        <p className="become-customer-sec-last-div-all-para">
                            {becomeCustomer.fifthSectionCard7Para}
                        </p>

                    </div>



                    <div className="become-customer-sec-last-div-card-1">

                        <h1 className="become-customer-sec-last-div-card-all-heading">
                            {becomeCustomer.fifthSectionCard7Heading}
                        </h1>

                        <p className="become-customer-sec-last-div-all-para">
                            {becomeCustomer.fifthSectionCard7Para}
                        </p>

                    </div>

                    <div className="become-customer-sec-last-div-card-1">

                        <h1 className="become-customer-sec-last-div-card-all-heading">
                            {becomeCustomer.fifthSectionCard8Heading}
                        </h1>

                        <p className="become-customer-sec-last-div-all-para">
                            {becomeCustomer.fifthSectionCard8Para}
                        </p>

                    </div>





                </div>

            </div>


            <div className="become-customer-last-div-para-and-btn">

                <h1 className="become-customer-last-div-para-and-btn-heading">{becomeCustomer.sixthSectionHeading}</h1>

                <a href="/customer-story" style={{ color: "inherit", textDecoration: "none" }}> <button className="become-customer-last-div-para-and-btn-button">{becomeCustomer.sixthSectionButton}</button> </a>

            </div>











            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    );
};

export default BecomeCustomer;
