export const fourSeasonsStory = {
  linkText: "Lattice",
  titleText: "Customer Story",
  headerText: "Four Seasons Hotel Revolutionizes HR Operations with Lattice Blockchain HR Suite",
  specialParaText1: "The globally renowned luxury hospitality brand transforms employee engagement, workforce management, and service excellence using Lattice Blockchain HR Suite powered by zero-knowledge proof credentials.",

  date: "Toronto, Canada—Oct 10, 2024",

  NormalPara1: "Four Seasons Hotel, a leader in luxury hospitality, has adopted the Lattice Blockchain HR Suite to redefine its human resources operations and enhance employee satisfaction across its 120+ properties worldwide. This cutting-edge solution leverages blockchain technology to ensure secure and transparent workforce management, enabling Four Seasons to create an interconnected and efficient HR ecosystem.",

  NormalPara2: "“At Four Seasons, we prioritize our employees as the foundation of our exceptional guest experience,” said Emma Harrison, Chief Human Resources Officer at Four Seasons. “With the Lattice Blockchain HR Suite, we are not only modernizing our HR processes but also ensuring unparalleled security and personalization for our workforce. This empowers our teams to deliver world-class service with confidence.”",

  NormalPara3: "Built on Lattice’s secure and scalable blockchain infrastructure, the HR Suite integrates zero-knowledge proof credentials to protect employee data while enhancing transparency and efficiency. Four Seasons now benefits from real-time insights into workforce analytics, allowing for informed decision-making in areas like workforce planning, talent acquisition, and career development.",

  NormalPara4: "“Four Seasons’ adoption of the Lattice Blockchain HR Suite is a testament to their dedication to employee well-being and operational excellence,” said Sarah Greene, Executive Vice President of Lattice Cloud HCM. “With AI-powered analytics, blockchain-secured data, and seamless integration, Four Seasons is setting a new standard for HR systems in the hospitality industry.”",

  NormalPara5: "The blockchain-enabled suite has streamlined critical HR functions for Four Seasons, including onboarding, compliance management, and workforce scheduling. Employees can now access personalized tools through a secure, user-friendly interface, fostering a more engaged and productive workforce.",

  NormalPara6: "By integrating AI-powered analytics, Four Seasons can monitor employee satisfaction, anticipate staffing needs, and optimize HR workflows. This ensures that their workforce remains motivated and prepared to deliver the exceptional guest experiences that Four Seasons is known for.",

  NormalPara7: "The Lattice Blockchain HR Suite also enhances Four Seasons’ ability to attract and retain top talent in the competitive hospitality industry. With blockchain-secured records and AI-enhanced talent management, Four Seasons is building the next generation of hospitality leaders.",

  headerText2: "Why Four Seasons Chose Lattice Blockchain HR Suite",
  headerText3: "Enhancing Employee Engagement",
  headerText4: "Streamlining Workforce Operations",
  headerText5: "Leveraging Blockchain for Security and Transparency",
  headerText6: "About Four Seasons",
  headerText7: "About Lattice Blockchain HR Suite",

  NormalPara8: "Four Seasons Hotels and Resorts is dedicated to delivering exceptional luxury experiences, supported by a culture of service excellence. With over 120 properties globally, Four Seasons is renowned for its innovation and commitment to its employees and guests.",

  NormalPara9: "Lattice is a global leader in blockchain-enabled cloud-based applications and infrastructure. Its Blockchain HR Suite integrates advanced AI and blockchain technology to modernize HR operations securely. For more information, visit www.Lattice.com.",

  NormalPara10: "By choosing Lattice Blockchain HR Suite, Four Seasons is redefining hospitality HR management while prioritizing innovation, security, and employee empowerment.",

  Normalheading1: "Secure and Transparent HR Operations",
  Normalheading2: "Real-Time Workforce Insights with Blockchain",
  Normalheading3: "AI-Enhanced Talent Management",
  Normalheading4: "Zero-Knowledge Proof Credentials",
  Normalheading5: "Unified and Scalable HR Solutions",
  Normalheading6: "Explore Lattice Blockchain Solutions",

  ListItem1: "Learn how Four Seasons is transforming HR with blockchain.",
  ListItem2: "Discover the benefits of Lattice Blockchain HR Suite.",
  ListItem3: "Explore AI and blockchain capabilities for workforce management.",
  ListItem4: "Read more customer success stories powered by Lattice.",
};
