export const mediaEntertainment = {

    firstSectionHeading: "Media and Entertainment",
    firstSectionPara: "Streaming, gaming, on-demand, and second-screen experiences are becoming standard for media and entertainment companies as audiences consume what they want, when they want—wherever they are. Exceed consumer expectations and drive innovation at global scale with Lattice. From content development to production, broadcasting, over the top (OTT) services, and more, Lattice helps you uncover efficiencies and optimize resources to provide the best possible customer experience.",

    secondSectionHeading: "Create impactful experiences consumers remember and return to",
    secondSectionSide1Heading: "Understand who your audiences are and how to reach them",
    secondSectionSide1Para: "Make sure your digital ads are seen by people—not bots—so you know for certain if your advertising is meeting your objectives.",

    secondSectionSide2Heading: "Create a single, dynamic view of your consumers",
    secondSectionSide2Para: "Combine online, offline, and third-party data with AI and machine learning to create a consistent brand experience across marketing, sales, and service.",

    secondSectionSide3Heading: "Fuel growth through direct-to-consumer models at scale",
    secondSectionSide3Para: "Monetize any business model at scale with the flexibility and capabilities of Lattice Cloud Infrastructure.",


    fourthSectionHeading: "Media and Entertainment features",

    fourthSectionButton1: "Digital Experience",
    fourthSectionButton2: "Digital Home Service",
    fourthSectionButton3: "Finance Transformation in the Digital Age",
    fourthSectionButton4: "Smart Media Production Planning",

    fourthSectionPage1Heading: "Digital Experience for Media & Entertainment",
    fourthSectionPage1Para: "Lattice’s Digital Experience for M&E solution enables media companies to increase share of wallet by launching new digital services and creating bundled offerings. It provides an agile, unified, and intelligent platform that allows M&E companies to personalize every interaction using their audiences’ digital profile, acquire customers with intelligent offers, engage them with an omnichannel experience, and empower them with full control over their usage experience.",

    fourthSectionPage2Heading: "Digital Home Service",
    fourthSectionPage2Para: "A breakthrough, cloud-based solution for set-top box and service-intensive pay-TV operators, Lattice’s Digital Home Service solution combines technologies such as the Internet of Things (IoT), artificial intelligence (AI), mobile, chatbot, and remote video support with modern digital customer management to provide next-generation digital services for the home.",

    fourthSectionPage3Heading: "Empower M&E Finance Teams with Lattice Fusion ERP Analytics",
    fourthSectionPage3Para: "Learn how Lattice Fusion ERP Analytics empowers M&E finance teams and executives with best-practice, prebuilt KPIs to easily monitor financial performance. With the power of AI, Lattice Fusion ERP Analytics can accelerate processes and streamline the steps from data to decisions, delivering a unified view of the entire organization.",

    fourthSectionPage4Heading: "Smart Media Production Planning",
    fourthSectionPage4Para: "Lattice’s Smart Media Production Planning solution helps entertainment studios and broadcasters efficiently manage studio crews and the end-to-end content production journey within the media enterprise.",

    fifthSectionHeading: "See how healthcare organizations partner with Lattice",
    fifthSectionlink: "See all customer stories",

    sixthSectionHeading: "Unlock innovation through data-driven, direct-to-consumer models",

    sixthSectionSide1Heading: "Get more value from your consumer data investment",
    sixthSectionSide1Para: "Generate actionable data across every customer touchpoint to personalize the consumer experience.",

    sixthSectionSide2Heading: "Innovate and automate with emerging technologies",
    sixthSectionSide2Para: "Transform consumer engagement and open up new business models with IoT, AI, blockchain, machine learning, and chatbots.",

    seventhSectionHeading: "Get started with Lattice Health",

    seventhSectionSide1Para: "Talk to a team member about Lattice solutions for healthcare.",
    seventhSectionSide1Button: "Contact Lattice Health today",

    seventhSectionSide2Para: "Customers can contact our support team 24/7 for help.",
    seventhSectionSide2Button: "Contact us for help",
    



}