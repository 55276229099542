export const investorRelationPage = {
    firstSectionHeading: "Lattice Investor Relations",
    firstSectionButton: "About Lattice",
    secondSectionInvestorText: "NYSE:",
    secondSectionBoldInvestorText: "LATC", // Updated stock symbol
    secondSectionBoldInvestorHeading: "$169.66",
    secondSectionBoldInvestorPara: "+0.87 (0.52%)",
    secondSectionNormalPara1: "Current Valuation",
    secondSectionNormalPara2: "Seed Round Raised",
    secondSectionNormalPara3: "Current Round",
    secondSectionBoldPara1: "132,776,857",
    secondSectionBoldPara2: "$ 50.000,000",
    secondSectionBoldPara3: "$ Series A",
    secondSectionSmallPara1: "Series A annouced December 2024.",
    secondSectionButton: "Detailed fianncials view",

    thirdSectionBoldInvestorText: "Q2 FY2025",
    thirdSectionHeading: "Latest quarter",
    thirdSectionBox1Text: "Earnings Release",
    thirdSectionBox2Text: "Financial Tables",
    thirdSectionBox3Text: "Customer Wins",
    thirdSectionBox4Text: "Webcast",
    thirdSectionBox5Text: "10-Q/K",

    fourthSectionSide1Heading: "Recent Investor News",
    fourthSectionSide1SubHeading: "December 9, 2024",
    fourthSectionSide1Para: "Lattice Announces Fiscal 2025 Second Quarter Financial Results",
    seeAll: "See all",
    fourthSectionSide2Heading: "Latest Events",
    fourthSectionSide2Para: "Q2 FY25 Earnings",
    fourthSectionSide2SubHeading: "December 9, 2024 04:00 PM CT",
    fourthSectionSide2SubHeading2: "September 12, 2024 12:00 PM CT",
    fourthSectionSide2Para2: "Lattice Financial Analyst Meeting",

    fifthSectionHeading: "Lattice Cloud Infrastructure: Powering the Future",
    fifthSectionSide1Heading: "Global Presence",
    fifthSectionSide1Para: "Lattice currently operates in 32 Availability Zones across 8 geographic regions, with plans to expand to 15 more Availability Zones and 5 additional regions by 2026, including Mexico, New Zealand, Saudi Arabia, and Taiwan.",
    fifthSectionSide2Heading: "Sustainability Commitment",
    fifthSectionSide2Para: "All Lattice data centers will run on 100% renewable energy by 2026, reinforcing our commitment to sustainable cloud infrastructure.",
    fifthSectionSide3Heading: "Edge and Latency Solutions",
    fifthSectionSide3Para: "With 50+ CloudFront POPs, 4 Regional Edge Caches, 12 Local Zones, and 9 Wavelength Zones, Lattice provides ultralow-latency solutions for real-time applications worldwide.",
    fifthSectionSide4Heading: "AI and Advanced Computing",
    fifthSectionSide4Para: "Lattice operates 2 AI data centers, each powered by 50 Nvidia GPUs, enabling high-performance computing and advanced AI workloads.",
    fifthSectionSide5Heading: "Data Center Expansion",
    fifthSectionSide5Para: "Our business model includes transitioning to fully Lattice-owned data centers by Q1 2026, with over 10 global data centers currently in operation, each capable of hosting up to 2,000 servers.",
    learnMoreButton: "Learn more about our growth",

    sixthSectionHeading: "Financial Milestones",
    sixthSectionSide1Heading: "Recent Funding Rounds",
    sixthSectionSide1Para: "Lattice raised $50M in a seed round December 2023, valuing today at 132+m A Series A funding round just started November 2024, aiming to raise $1 billion to drive global expansion and innovation.",
    sixthSectionSide2Heading: "Operational Reach",
    sixthSectionSide2Para: "Serving 123 countries and territories with 30 Direct Connect locations, Lattice Cloud Infrastructure is built to meet global business needs.",

    seventhSectionHeading: "Additional Resources",
    seventhSectionSide1Heading: "Corporate",
    seventhSectionSide1List1: "Lattice Corporate Facts",
    seventhSectionSide1List2: "Dividends and Splits",
    seventhSectionSide1List3: "Environmental and Social Impact",
    seventhSectionSide1List4: "Investor Presentations",
    seventhSectionSide2Heading1: "Governance",
    seventhSectionSide2List1: "Corporate Governance",
    seventhSectionSide2List2: "Board of Directors",
    seventhSectionSide2List3: "Annual Meeting of Stockholders",

    eighthSectionHeading: "Contact Investor Relations",
    eighthSectionSubHeading: "Receive Email Alerts",
    signInButton: "Sign In",
    eighthSectionSubHeading2: "Contact Information",
    eighthSectionPara1: "+1-800-LATTICE",
    eighthSectionPara2: "+1-800-000-000",
    eighthSectionCheckbox1: "Press Releases",
    eighthSectionCheckbox2: "Events and Presentations",
    eighthSectionCheckbox3: "Anual Filings",
    eighthSectionCheckbox4: "Investors News",
    eighthSectionUnsubscribeButton: "Unsubscribe",
};
