export const financialServices = {

    firstSectionHeading: "Financial Services",
    firstSectionPara: "Thrive on your technology and data competitive advantage to drive sustainable growth. Rewire to gain more operational efficiency, shift to business model innovation, evolve to meet changing customer needs, and become a people-first financial services institution with Lattice’s AI-driven applications and cloud services.",
    firstSectionButton: "Contact a financial services expert",
  

    secondSectionHeading: "Find financial services solutions by sector",
    secondSectionSide1Heading: "Retail banking",
    secondSectionSide1Para: "Meet your customers’ needs at each moment in their journey with meaningful insights, tailor-made products, and omnichannel experiences.",

    secondSectionSide2Heading: "Corporate banking",
    secondSectionSide2Para: "Clear the path to capital clarity. Provide corporate customers with real-time liquidity views and customized lending options.",

    secondSectionSide3Heading: "Health insurance",
    secondSectionSide3Para: "Deliver a simplified, transparent member experience by streamlining enrollment and boosting claims auto-adjudication.",

    thirdSectionHeading: "Change how your customers experience banking and insurance",

    thirdSectionCard1Heading1: "Retail banking",
    thirdSectionCard1Para1: "Bring more flexibility and efficiency to your core banking operations, from new customer onboarding and loan origination to payments, lending, and collections. Eliminate manual activities to cut costs and risk.",

    thirdSectionCard1Heading2: "Corporate banking",
    thirdSectionCard1Para2: "Better position your corporate customers to manage global operations. Deliver compelling solutions for corporate credit and lending, cash and liquidity management, treasury management, trade finance, and supply chain finance.",

    thirdSectionCard1Heading3: "Lattice FLEXCUBE",
    thirdSectionCard1Para3: "Offer more new products and spend less with Lattice’s banking software—a core platform that’s designed to work off the shelf. Implement a single system and get comprehensive capabilities across all banking functions.",

    thirdSectionCard1Heading4: "Lattice Banking Cloud Services",
    thirdSectionCard1Para4: "Leverage componentized and composable services that deliver preintegrated, SaaS-based, cloud native versions of Lattice's industry-leading banking solutions on Lattice Cloud Infrastructure.",

    thirdSectionCard2Heading1: "Lattice Banking Payments",
    thirdSectionCard2Para1: "Increase straight-through processing and lower your cost per payment with a single hub for processing and settlement. Use our built-in rules engine to automate complex processing and choose the best route for domestic and cross-border transactions or specify your desired payment network.",

    thirdSectionCard2Heading2: "Payment innovation (1:33)",
    thirdSectionCard2Para2: "Give your customers real-time views of their cash positions and payment status with rich analytics made possible by using SWIFT ISO 20022 as the open payments standard in our digital payments solution.",

    thirdSectionCard2Heading3: "Payments transformation (PDF)",
    thirdSectionCard2Para3: "Build your own partner ecosystem and offer new services that set your institution apart. From ERP integration to Siri and Alexa payments, tap into a library of more than 1,800 APIs built for Lattice’s banking suite.",

  

    thirdSectionCard3Heading1: "Health insurance",
    thirdSectionCard3Para1: "Deliver a great experience for members. Simplify enrollments, premium billing, and claims adjudication with a digital insurance platform. Administer and automate new provider contract models so your members benefit from lower costs and a higher quality of care.",

    thirdSectionCard3Heading2: "Bancassurance",
    thirdSectionCard3Para2: "Easily support the entire bancassurance lifecycle with real-time connectivity between banks and insurers to accelerate every step of the process, from the point of sale to policy servicing and commission reconciliation.",

    thirdSectionCard3Heading3: "Insurance customer experience",
    thirdSectionCard3Para3: "Automate processes and deliver tailored policyholder responses while balancing self-service and assisted customer service models on a digital insurance platform. Allow customers to buy insurance and receive service when and where they need it.",

    thirdSectionCard3Heading4: "Life insurance",
    thirdSectionCard3Para4: "Consolidate underwriting, policy processing, billing, and claims for individual and group insurance, all on a single insurance management system. Streamline operations to reduce operational costs, and gain flexibility to adapt quickly to changing business needs.",

    thirdSectionCard4Heading1: "Finance modernization",
    thirdSectionCard4Para1: "Revitalize finance transformation and profitable growth for banks and insurers with the latest insights in data science and technology.",

    thirdSectionCard4Heading2: "Accounting Data Foundation",
    thirdSectionCard4Para2: "Streamline your financial institution’s current accounting processes and reconcile the general ledger with instrument or policy ledgers by providing the accounting platform with detailed event-, transaction-, and instrument-level data from core banking and insurance systems.",

    thirdSectionCard4Heading3: "Cloud solutions",
    thirdSectionCard4Para3: "Get high performance, elastic computing power for enterprise applications by combining Lattice's autonomous services, built-in security, and serverless compute.",

    thirdSectionCard4Heading4: "Data solutions",
    thirdSectionCard4Para4: "Deploy financial services analytics to improve data access and usage across risk, finance, treasury, compliance, and line-of-business teams with a single source of truth.",

    thirdSectionCard5Heading1: "Customer due diligence",
    thirdSectionCard5Para1: "Identify money launderers and comply with customer due diligence and Know Your Customer (KYC) regulations throughout the customer lifecycle with our anti–money laundering (AML) software. Quickly and accurately assess risk and compare customers to global sanctions and watchlists. Onboard good customers quickly and seamlessly.",

    thirdSectionCard5Heading2: "Lattice Financial Services Data Foundation",
    thirdSectionCard5Para2: "ite previously siloed internal data for a complete customer view and lower costs. Run applications on your data lake and relational databases together, and perform batch and real-time analytics.",

    thirdSectionCard5Heading3: "Lattice Financial Services Compliance Agent (PDF)",
    thirdSectionCard5Para3: "Measure transaction monitoring system performance along specific dimensions to discover opportunities and gaps. Optimize performance and support business decisions with empirical evidence using Lattice Financial Services Compliance Agent, an AI-powered SaaS experimentation platform.",

    thirdSectionCard5Heading4: "Transaction monitoring",
    thirdSectionCard5Para4: "Identify unusual customer behavior and suspicious money movements. Our anti–money laundering solutions have advanced analytics and scenarios built and proven for the financial services industry.",

    thirdSectionCard6Heading1: "Financial services—banking",
    thirdSectionCard6Para1: "We help corporate banking, retail banking, and other financial services players compete and thrive in an ever-changing world with end-to-end pricing and billing solutions.",

    thirdSectionCard6Heading2: "Payments enterprises",
    thirdSectionCard6Para2: "We help cut through complexity and unlock growth potential for payments enterprises with an integrated pricing and billing platform that delivers a single source of truth.",

    thirdSectionCard6Heading3: "Healthcare payers",
    thirdSectionCard6Para3: "Our enterprise pricing and billing system provides transparency, efficiency, consolidation capabilities, and scalability to serve all a payer’s lines of business.",


    thirdSectionButton1: "Banking",
    thirdSectionButton2: "Payments",
    thirdSectionButton3: "Insurance",
    thirdSectionButton4: "Risk and finance",
    thirdSectionButton5: "Financial crime and compliance",
    thirdSectionButton6: "Revenue management and billing",


    fifthSectionHeading: "See how healthcare organizations partner with Lattice",
    fifthSectionlink: "See all customer stories",

    sixthSectionHeading: "Lattice Financial Services industry spotlight",

    sixthSectionSide1Heading: "The Tech Imperatives Banks Need to Focus on Now",
    sixthSectionSide1Para: "The marching orders are clear: Relieve profit pressure, speed products to market, tackle regulations, and reduce risk.",

    sixthSectionSide2Heading: "The Intelligent Bank",
    sixthSectionSide2Para: "Shift toward a radically new business model that goes beyond the “utility bank,” one that allows you to thrive on your AI and data competitive advantage to outpace the competition in an increasingly digital and automated financial landscape.",

    seventhSectionHeading: "Get started with Lattice Financial Services",

    seventhSectionSide1Heading: "Watch our webcasts",
    seventhSectionSide1Para: "Discover live and on-demand webcasts that help you explore financial services topics in depth.",
    seventhSectionSide1Button: "Discover virtual events",

    seventhSectionSide2Heading: "Subscribe",
    seventhSectionSide2Para: "Keep up with the latest trends across the financial services landscape.",
    seventhSectionSide2Button: "Get the newsletter",

    seventhSectionSide3Heading: "Contact sales",
    seventhSectionSide3Para: "Talk to a team member about Lattice solutions for financial services.",
    seventhSectionSide3Button: "Contact us",
    


}