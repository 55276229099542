export const gamifiedRetailPage = {
    firstSectionHeading: "Lattice Blockchain Gamified Retail Suite",
    firstSectionPara: "Transform retail, events, and sports ecosystems with the Lattice Blockchain Gamified Retail Suite. Leverage blockchain technology to gamify your ecosystem, tokenize products and services, and integrate digital twins of retail stores, event spaces, and sports facilities for interactive customer experiences.",
    firstSectionButton1: "Request a Demo",
    firstSectionButton2: "Learn More About Our Suite",
    firstSectionSide2TitleImg: "Gamify Your Retail Ecosystem with Lattice Blockchain",

    secondSectionHeading: "Revolutionize Customer Engagement",
    secondSectionPara: "With advanced tools for tokenization, private blockchain creation, and seamless wallet integration, businesses can redefine customer interaction. Create immersive, gamified experiences that keep your audience engaged and coming back for more.",
    secondSectionButton: "Explore Features",

    thirdSectionHeading: "Key Benefits of the Suite",
    thirdSectionSide1Heading: "Gamify Your Ecosystem",
    thirdSectionSide1Para: "Introduce gamification into your retail environment, event spaces, and sports facilities to enhance customer engagement. Reward loyalty and encourage interaction with tokenized experiences.",
    thirdSectionSide2Heading: "Tokenize Products and Services",
    thirdSectionSide2Para: "Easily tokenize your offerings, enabling customers to buy, trade, and interact using blockchain-powered digital assets.",
    thirdSectionSide3Heading: "Integrate Digital Twins",
    thirdSectionSide3Para: "Bring retail stores, event spaces, and sports facilities into the digital world. Use interactive digital twins to create a connected, immersive experience for customers.",

    fourthSectionHeading: "Comprehensive Features for Seamless Integration",
    fourthSectionButton1: "Tokenization Tools",
    fourthSectionButton2: "Private Blockchain Creation",
    fourthSectionButton3: "Wallet Integration",
    fourthSectionButton4: "Digital Twin Integration",
    fourthSectionButton5: "Interactive Customer Analytics",
    
    fourthSectionPage1Heading: "Tools to Tokenize Products and Services",
    fourthSectionPage1Description: "Easily convert your physical and digital offerings into blockchain-based tokens, creating new opportunities for engagement and monetization.",
    fourthSectionPage1Link: "Learn About Tokenization",
    fourthSectionPage1cardheading1: "Create and Manage Tokens",
    fourthSectionPage1fastTrackList1: "Tokenize products and services with ease.",
    fourthSectionPage1cardheading2: "Enable Customer Interactions",
    fourthSectionPage1fastTrackList2: "Facilitate buying, trading, and redeeming tokens.",
    fourthSectionPage1cardheading3: "Incorporate Loyalty Rewards",
    fourthSectionPage1fastTrackList3: "Build loyalty programs using blockchain tokens.",
    
    fourthSectionPage2Heading: "Configure Private Blockchains",
    fourthSectionPage2Description: "Set up private blockchains tailored to your business needs. Ensure security, scalability, and seamless integration with your existing operations.",
    fourthSectionPage2Link: "Explore Private Blockchain Creation",
    fourthSectionPage2cardheading1: "Custom Blockchain Networks",
    fourthSectionPage2fastTrackList1: "Deploy secure, scalable private blockchains.",
    fourthSectionPage2cardheading2: "Seamless Integration",
    fourthSectionPage2fastTrackList2: "Connect blockchain solutions to your systems.",
    fourthSectionPage2cardheading3: "Enhanced Security",
    fourthSectionPage2fastTrackList3: "Leverage blockchain for tamper-proof operations.",

    fourthSectionPage3Heading: "Wallet Integration for Smooth Transactions",
    fourthSectionPage3Description: "Enable customers to manage digital assets and transactions effortlessly with built-in wallet support.",
    fourthSectionPage3Link: "Learn About Wallet Integration",
    fourthSectionPage3cardheading1: "Secure Wallets",
    fourthSectionPage3fastTrackList1: "Provide secure, user-friendly wallets.",
    fourthSectionPage3cardheading2: "Streamlined Payments",
    fourthSectionPage3fastTrackList2: "Facilitate tokenized payments and exchanges.",
    fourthSectionPage3cardheading3: "Cross-Platform Compatibility",
    fourthSectionPage3fastTrackList3: "Support wallets across devices and platforms.",

    fourthSectionPage4Heading: "Interactive Digital Twins",
    fourthSectionPage4Description: "Bring your physical spaces to life in the digital realm. Enhance customer engagement with interactive digital twins of stores, events, and facilities.",
    fourthSectionPage4Link: "Learn About Digital Twin Integration",
    fourthSectionPage4cardheading1: "Create Immersive Experiences",
    fourthSectionPage4fastTrackList1: "Develop digital replicas of physical spaces.",
    fourthSectionPage4cardheading2: "Real-Time Interactivity",
    fourthSectionPage4fastTrackList2: "Enable customers to interact with your spaces digitally.",
    fourthSectionPage4cardheading3: "Drive Engagement",
    fourthSectionPage4fastTrackList3: "Use gamified digital twins to captivate audiences.",

    fifthSectionHeading: "Why Choose Lattice Blockchain Gamified Retail Suite?",
    fifthSectionPara1: "Harness the power of blockchain to transform retail and event experiences. Create secure, gamified ecosystems that integrate seamlessly with existing platforms.",
    fifthSectionPara2: "Drive customer engagement and loyalty through tokenization and gamification.",
    fifthSectionCard1Heading: "Innovative Tokenization",
    fifthSectionCard1Para: "Empower customers with blockchain-based tokens.",
    fifthSectionCard2Heading: "Immersive Digital Twins",
    fifthSectionCard2Para: "Bring spaces to life digitally for interactive experiences.",
    fifthSectionCard3Heading: "Seamless Wallet Integration",
    fifthSectionCard3Para: "Enable smooth, secure transactions with integrated wallets.",

    sixthSectionHeading: "Customer Success Stories",
    sixthSectionLink: "Explore Success Stories",
    
    seventhSectionHeading: "Get Started with Lattice Blockchain Gamified Retail Suite",
    seventhSectionBox1Heading: "Request a Demo",
    seventhSectionBox1Para: "See how the suite can transform your business.",
    seventhSectionBox1Button: "Get a Demo",
    seventhSectionBox2Heading: "Contact Us",
    seventhSectionBox2Para: "Reach out to learn more from our experts.",
    seventhSectionBox2Button: "Contact Us",
};
