import React, { useState } from 'react';
import "./Leadership.css";
import { Link } from 'react-router-dom';
import { footerSections, cookieText } from '../Content/eng_content';
import emp1 from '../image/slider-helth-care-img.jpg';
import { employees } from '../Content/Content-of-employ';
import Latticelogo from '../image/lattice-logo-img.svg';
import { executiveLeadership } from '../Content/English-Content/ExecutiveLeadership';

const Leadership = () => {


    const [selected, setSelected] = useState(0); // Keep track of the selected item

    // const menuItems = [executiveLeadership.menuItem1, executiveLeadership.menuItem2];


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

     // cookies
    
    
        const [showCookieModal, setShowCookieModal] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
    
        const handleCookieClick = () => {
            setShowCookieModal(true);
            setIsLoading(true);
    
    
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        };
    
        const handleAcceptAll = () => {
            console.log("Cookies Accepted");
            setShowCookieModal(false);
        };
    
        const handleDenyAll = () => {
            console.log("Cookies Denied");
            setShowCookieModal(false);
        };

    return (
        <>
            {/* <div className="sec-nav-bar">
                <ul>
                    {menuItems.map((item, index) => (
                        <li
                            key={index}
                            className={selected === index ? "selected" : ""}
                            onClick={() => setSelected(index)}
                        >
                            <a href="#">{item}</a>
                        </li>
                    ))}
                </ul>
            </div> */}


            <div className="leadership-img1"></div>

            {/* Header Section */}
            <div className="leadership-header-div">
                <Link className="Link-of-leadership " to="/">
                    <span>
                        {executiveLeadership.linkText}
                        <span className="arrow-leadership-page">
                            <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 4l4 4-4 4" stroke="#fff" strokeWidth="1.5" fill="none" />
                            </svg>
                        </span>
                    </span>
                </Link>


                <h1 className="leadership-header-text">
                    {executiveLeadership.headerText}
                    <span
                        style={{
                            display: "block",
                            width: "80px",
                            height: "4px",
                            background: "#f0cc71",
                            marginTop: "2vh",
                            marginBottom: "2vh",
                        }}
                    ></span>
                </h1>


            </div>


            <div className="employ-info">
                <h1 className="heading-of-employ-page">{executiveLeadership.firstSectionHeading}</h1>

                {employees.map((employee) => (
                    <div className="employ-card" key={employee.id}>
                        <div className="image-of-employ">
                            <Link to={`/employdetails/${employee.id}`}>
                                <img className="image-of-employ" src={employee.img} alt={employee.name} />
                            </Link>
                        </div>
                        <div className="info-of-employ">
                            <Link className="redirect-link" to={`/employdetails/${employee.id}`}>
                                <h1 className="employ-name">{employee.name}</h1>
                                <p className="employ-details">{employee.role}</p>
                                <p className="employ-read-page"> {employee.read}'s bio</p>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>



            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <p href={link.to}>{link.text}</p>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                 <div className="footer-bottom">
                              
                                                  <span className="footer-separator"> © 2024 Lattice</span> |
                              
                                                  <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>
                              
                                                  <span className="footer-separator"
                                                      style={{ cursor: "pointer", color: "inherit" }}
                                                      onClick={handleCookieClick}> Cookie Preferences  </span>
                              
                                                  <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>
                              
                                                  <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                                              </div>
                              
                                          </div>
                              
                              
                                          {showCookieModal && (
                                              <div
                                                  className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                                                      }`}
                                              >
                                                  <div className="cookie-modal">
                              
                                                      {isLoading ? (
                                                          <div className="loading-spinner">{cookieText.loading}</div>
                                                      ) : (
                                                          <div className="cookie-content">
                              
                                                              <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                                                  &times;
                                                              </button>
                                                              <div className="heading-of-cookies-with-logo">
                              
                                                                  <h3 className="heading-of-cookies">{cookieText.heading}</h3>
                              
                                                                  <img src={Latticelogo} className="img-logo-of-cookies-header"></img>
                              
                                                              </div>
                              
                                                              <div className="div-2-heading-below-text-and-list">
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>
                              
                                                                  <ul>
                                                                  {cookieText.listItems.map((item, index) => (
                                                                      <li key={index} className="div-2-cookies-list-text">
                                                                          <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                                                      </li>
                                                                      ))}
                                                                  </ul>
                              
                                                                  <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>
                              
                                                              </div>
                              
                                                              <div className="button-group">
                                                                  <button className="btn accept-btn" onClick={handleAcceptAll}>
                                                                     {cookieText.btn1}
                                                                  </button>
                                                                  <button className="btn deny-btn" onClick={handleDenyAll}>
                                                                  {cookieText.btn2}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          )}


        </>
    );
};

export default Leadership;
