export const campaignElections = {
    firstSectionHeading: "Election Security & Integrity",
    firstSectionPara: "Lattice Government Cloud (LGC) offers high-performance, secure computing infrastructure to support election campaigns, ensuring data integrity and transparency with robust security features for voter data and election results.",
    firstSectionButton1: "Try LGC for election security",
    firstSectionButton2: "Introducing Advanced Election Analytics with LGC",
    firstSectionSide2TitleImg: "AI-Driven Election Insights with Lattice Cloud (2:06)",
    secondSectionHeading: "Lattice Deploys Secure Election Systems",
    secondSectionPara: "Our platform enables governments and election authorities to deploy secure, scalable systems that ensure the integrity of election processes, including voter registration, ballot processing, and results tabulation.",
    secondSectionButton: "Read more about election security",
    thirdSectionHeading: "Why Choose LGC for Election Integrity?",
    thirdSectionSide1Heading: "Up to 70% cost savings",
    thirdSectionSide1Para: "LGC offers flexible, customizable computing instances with up to 70% savings compared to traditional infrastructure, without compromising on security or scalability for election needs.",
    thirdSectionSide2Heading: "Enhanced data protection",
    thirdSectionSide2Para: "LGC's secure cloud infrastructure ensures that all election data, including votes and sensitive information, are encrypted and protected from tampering, ensuring trust in election results.",
    thirdSectionSide3Heading: "Scalable election infrastructure",
    thirdSectionSide3Para: "LGC allows for rapid scaling of infrastructure to meet the demands of large-scale elections, from local polling stations to national election systems.",
    fourthSectionHeading: "LGC Election Services",
    fourthSectionButton1: "Voter Registration Systems",
    fourthSectionButton2: "Ballot Processing Systems",
    fourthSectionButton3: "Election Analytics and Reporting",
    fourthSectionButton4: "Election Security Solutions",
    fourthSectionButton5: "Election Audit Trail Services",
    fourthSectionPage1Heading: "Voter Registration Systems",
    fourthSectionPage1Description: "Easily register voters, verify identities, and ensure secure access to voting. LGC supports large-scale voter registration systems with integrated security protocols.",
    fourthSectionPage1Link: "Learn more about voter registration",
    fourthSectionPage1cardheading1: "Secure Voter Authentication",
    fourthSectionPage1fastTrackList1: "Implement multi-factor authentication (MFA) to safeguard against fraudulent voter registrations.",
    fourthSectionPage1cardheading2: "Real-Time Voter Data Synchronization",
    fourthSectionPage1fastTrackList2: "Synchronize voter data in real-time across multiple platforms for accurate and up-to-date records.",
    fourthSectionPage1cardheading3: "Scalable Data Storage",
    fourthSectionPage1fastTrackList3: "Store and manage large volumes of voter data with encrypted, scalable cloud storage solutions.",
    fourthSectionPage1cardheading4: "Audit Trails for Transparency",
    fourthSectionPage1fastTrackList4: "Create tamper-proof audit trails to ensure transparency and accountability in the registration process.",
    learnMoreButton: "Learn More",
    fourthSectionPage2Heading: "Ballot Processing Systems",
    fourthSectionPage2Description: "Our secure ballot processing solutions ensure seamless, fast, and accurate handling of votes during elections, with advanced features to prevent tampering and ensure data integrity.",
    fourthSectionPage2Link: "Learn more about ballot processing",
    fourthSectionPage2cardheading1: "Fast Vote Tabulation",
    fourthSectionPage2fastTrackList1: "Efficiently process and tally votes with AI-driven algorithms to ensure accurate results in real-time.",
    fourthSectionPage2cardheading2: "Transparent Reporting",
    fourthSectionPage2fastTrackList2: "Generate and distribute election results securely with real-time updates and detailed reports.",
    fourthSectionPage2cardheading3: "Tamper-Proof Systems",
    fourthSectionPage2fastTrackList3: "Implement blockchain technology to ensure ballots and votes cannot be altered after submission.",
    fourthSectionPage3Heading: "Election Analytics and Reporting",
    fourthSectionPage3Description: "Leverage advanced analytics to gain real-time insights into voting patterns, trends, and other key metrics during the election process.",
    fourthSectionPage3Link: "Learn more about analytics and reporting",
    fourthSectionPage3cardheading1: "Real-Time Election Monitoring",
    fourthSectionPage3fastTrackList1: "Monitor voting activity and trends in real time with AI-powered analytics.",
    fourthSectionPage3cardheading2: "Election Outcome Prediction",
    fourthSectionPage3fastTrackList2: "Predict election outcomes based on voting patterns and trends to enhance decision-making.",
    fourthSectionPage3cardheading3: "Post-Election Analysis",
    fourthSectionPage3fastTrackList3: "Analyze voter behavior and election results to improve future campaigns and election strategies.",
    fourthSectionPage4Heading: "Election Security Solutions",
    fourthSectionPage4Description: "LGC provides end-to-end election security solutions that protect the voting process from cyber threats, fraud, and other malicious activities.",
    fourthSectionPage4Link: "Learn more about election security",
    fourthSectionPage4cardheading1: "End-to-End Encryption",
    fourthSectionPage4fastTrackList1: "Encrypt all data, from voter registration to vote tabulation, ensuring no unauthorized access.",
    fourthSectionPage4cardheading2: "Secure Voting Infrastructure",
    fourthSectionPage4fastTrackList2: "Deploy secure voting infrastructure that resists tampering and guarantees data integrity.",
    fourthSectionPage4cardheading3: "DDoS Protection for Election Websites",
    fourthSectionPage4fastTrackList3: "Protect election-related websites from Distributed Denial of Service (DDoS) attacks with advanced security measures.",
    fifthSectionHeading: "Save on Election Infrastructure Costs",
    fifthSectionPara1: "With LGC, election authorities can save significantly on infrastructure costs while ensuring high-performance, secure systems for election management.",
    fifthSectionPara2: "Our cloud-based solutions offer flexibility, scalability, and the ability to handle peak loads during elections.",
    fifthSectionCard1Heading: "50%",
    fifthSectionCard1Para: "lower than traditional election infrastructure costs",
    fifthSectionCard2Heading: "60%",
    fifthSectionCard2Para: "less than competitor election security solutions",
    fifthSectionCard3Heading: "40%",
    fifthSectionCard3Para: "reduced costs for election data storage",
    fifthSectionButton1: "Learn more about cost savings",
    fifthSectionButton2: "See pricing for election services",
    fifthSectionPara3: "* Compared to traditional election infrastructure and services.",
    sixthSectionHeading: "LGC Election Architectures",
    sixthSectionLink: "See more election architecture solutions",
    sixthSectionCard1Para1: "Solution Architecture",
    sixthSectionCard1Para2: "This architecture shows how LGC can support a secure election system from registration to vote tabulation.",
    sixthSectionCard1Heading: "Deploy Secure Voting Infrastructure with LGC",
    sixthSectionCard2Para2: "This architecture shows a distributed, multi-region election system with real-time results and secure reporting.",
    sixthSectionCard2Heading: "Deploy a Global Election System with LGC Cloud",
    sixthSectionCard3Para2: "This architecture shows a hybrid election system with secure voting machines, data encryption, and tamper-proof audit trails.",
    sixthSectionCard3Heading: "Hybrid Election Infrastructure for Security and Scalability",
    seventhSectionHeading: "LGC Election Success Stories",
    seventhSectionLink: "Explore all election-related customer stories",
    eighthSectionHeading: "What’s New in Election Tech?",
    eighthSectionCard1Title: "2024—LGC Enhances Election Security with AI",
    eighthSectionCard1Link1: "Read the full report",
    eighthSectionCard1Link2: "Learn more about AI in election tech",
    eighthSectionCard2Title: "2024—LGC Election Analytics Helps Predict Outcomes",
    eighthSectionCard2Link1: "See the analytics in action",
    eighthSectionCard2Link2: "Read the announcement",
    eighthSectionCard3Title: "2024—LGC Secures Major Election with Blockchain Technology",
    eighthSectionCard3Link1: "Learn more about our blockchain solution",
    eighthSectionCard3Link2: "Read the blog post",
    ninthSectionHeading: "Get Started with LGC Election Services",
    ninthSectionPage1Side1Heading: "Try our Election Security and Infrastructure Solutions",
    ninthSectionPage1Side1Para: "Lattice offers a comprehensive suite of election services with security and reliability at the forefront. Get started with our free trial and secure election management tools.",
    ninthSectionPage1Side1button: "Try LGC for free",
    ninthSectionPage1Side2Heading: "What’s included with LGC Election Suite?",
    ninthSectionPage1Side2List1: "Voter Registration System",
    ninthSectionPage1Side2List2: "Ballot Processing System",
    ninthSectionPage1Side2List3: "Election Analytics Tools",
    ninthSectionPage1Side2List4: "Election Security Services",
    ninthSectionPage1Side2List5: "Blockchain-Powered Voting Systems",
    ninthSectionPage1Side2List6: "Real-Time Election Reporting",
    ninthSectionPage1Side2List7: "Free Election Tech Consultation",
    ninthSectionPage2Side1Heading: "Learn with Election Tech Labs",
    ninthSectionPage2Side1Para: "Explore hands-on labs and tutorials that teach you how to set up and manage secure election systems with LGC.",
    ninthSectionPage2Side1button: "See election tutorials and labs",
    ninthSectionPage2Side2Heading1: "Deploy Secure Election Infrastructure",
    ninthSectionPage2Side2Para1: "Learn how to deploy secure voter registration systems and ballot processing with LGC infrastructure.",
    ninthSectionPage2Side2Button: "Start this lab now",
    ninthSectionPage2Side2Heading2: "Set Up Blockchain Voting Systems",
    ninthSectionPage2Side2Para2: "Explore how to set up a blockchain voting system for secure, transparent elections.",
    ninthSectionPage2Side2Heading3: "Get Started with Election Analytics",
    ninthSectionPage2Side2Para3: "Learn how to use LGC election analytics tools to predict results and monitor trends.",
    ninthSectionPage2Side2Heading4: "Migrate Your Election Systems to LGC",
    ninthSectionPage2Side2Para4: "Discover how to migrate existing election systems to LGC for improved security and scalability.",
    ninthSectionPage3Side1Heading: "Estimate Election Infrastructure Costs",
    ninthSectionPage3Side1Para: "LGC offers transparent pricing for election services, helping you budget effectively for secure, scalable systems.",
    ninthSectionPage3Side1button: "Try Cost Estimator",
    ninthSectionPage3Side2Heading: "Experience LGC Election Solutions",
    ninthSectionPage3Side2List1: "1/3 the cost of traditional election infrastructure",
    ninthSectionPage3Side2List2: "Secure, tamper-proof election systems",
    ninthSectionPage3Side2List3: "Flexible, scalable election platforms",
    ninthSectionPage3Side2List4: "Real-time, transparent election results",
    ninthSectionPage4Side1Heading: "Contact Sales for Election Solutions",
    ninthSectionPage4Side1Para: "Want to learn more about LGC's election solutions? Get in touch with one of our experts.",
    ninthSectionPage4Side1button: "Get in touch",
    ninthSectionPage4Side2Heading: "They can answer questions such as",
    ninthSectionPage4Side2List1: "How do I secure my election data?",
    ninthSectionPage4Side2List2: "What types of election systems can I deploy with LGC?",
    ninthSectionPage4Side2List3: "How do I ensure election transparency?",
    ninthSectionButton1: "Free election services",
    ninthSectionButton2: "Election labs",
    ninthSectionButton3: "Election reference architectures",
    ninthSectionButton4: "Contact sales",
}
