export const customerFourSeason = {
    section1Link: "Lattice",
    section1Heading: "Four Seasons Transforms Guest Experiences with Lattice Cloud Technology",
    section1Para1: "Four Seasons, one of the world’s leading luxury hotel groups, uses Lattice Cloud Infrastructure (LCI) to enhance operational efficiency and deliver unforgettable guest experiences.",
    share: "Share:",
    section1Para2: "Lattice’s integrated cloud platform allowed us to seamlessly adopt new digital services across our properties. From handling reservation spikes to personalizing amenities, the flexibility of LCI has enabled our teams to innovate swiftly without compromising on service quality or brand standards.",
    section1Para3: "John Damon, Vice President of Digital Services, Four Seasons",
    boxHeading: "Products list",

    // section2Heading: "Business challenges",
    section2Para1: "Four Seasons prides itself on delivering impeccable hospitality experiences worldwide. As guest expectations evolved—ranging from personalized services to efficient digital channels—Four Seasons turned to Lattice Cloud Infrastructure to modernize their core systems. Leveraging LCI’s robust compute capabilities, they built AI-driven recommendations, automated property management tasks, and streamlined data across global properties, ensuring guests receive personalized experiences in real time.",
    section2Para2: "Published: December 20, 2023",

    section7Heading: "Learn more",
    section7Link1: "Four Seasons",

    section8Heading: "Products list",
    section8Link1: "Lattice Cloud Infrastructure",
    section8Link2: "Lattice Cloud Infrastructure Block Storage Classic",
    section8Link3: "LCI Compute Bare Metal",
    section8Link4: "LCI Compute",
};