export const fileManagementSuite = {
    firstSectionHeading: "Lattice Blockchain File Management Suite",
    firstSectionPara: "Revolutionize how you manage and secure your data with the Lattice Blockchain File Management Suite. Built on cutting-edge blockchain technology, it ensures seamless, secure, and efficient management of files for businesses of all sizes.",
    firstSectionButton1: "Request a Demo",
    firstSectionButton2: "Talk to Sales",
    firstSectionSide2TitleImg: "Discover how Lattice simplifies file management (2:06)",

    secondSectionHeading: "Redefine File Management with Blockchain",
    secondSectionPara: "Lattice Blockchain File Management Suite leverages distributed ledger technology to provide unparalleled data integrity, secure sharing, and streamlined file operations. Say goodbye to traditional file vulnerabilities and hello to the future of file management.",
    secondSectionButton: "Learn More",

    thirdSectionHeading: "Why Choose Lattice Blockchain File Management?",
    thirdSectionSide1Heading: "Unmatched Data Security",
    thirdSectionSide1Para: "Every file is encrypted and secured with blockchain technology, ensuring protection against unauthorized access and tampering.",
    thirdSectionSide2Heading: "Decentralized Storage",
    thirdSectionSide2Para: "Benefit from a decentralized file storage system that eliminates single points of failure, providing unparalleled reliability.",
    thirdSectionSide3Heading: "Seamless Collaboration",
    thirdSectionSide3Para: "Enable real-time collaboration with verifiable changes and a complete audit trail, powered by blockchain for transparency and trust.",

    fourthSectionHeading: "Core Features of Lattice Blockchain File Management Suite",
    fourthSectionButton1: "Secure File Sharing",
    fourthSectionButton2: "Audit Trails",
    fourthSectionButton3: "Custom Blockchain Configurations",
    fourthSectionButton4: "Digital Twin Integration",
    fourthSectionButton5: "Seamless API Access",

    fourthSectionPage1Heading: "Secure File Sharing",
    fourthSectionPage1Description: "Empower your teams with secure, blockchain-backed file sharing, ensuring data integrity and confidentiality.",
    fourthSectionPage1Link: "Learn More About Secure Sharing",
    fourthSectionPage1cardheading1: "Blockchain Encryption",
    fourthSectionPage1fastTrackList1: "Every file is encrypted and stored on a blockchain for enhanced security.",
    fourthSectionPage1cardheading2: "Zero-Knowledge Proofs",
    fourthSectionPage1fastTrackList2: "Verify access permissions without exposing sensitive data.",
    fourthSectionPage1cardheading3: "Global Accessibility",
    fourthSectionPage1fastTrackList3: "Access your files securely from anywhere in the world.",
    fourthSectionPage1cardheading4: "Role-Based Access",
    fourthSectionPage1fastTrackList4: "Manage permissions seamlessly with blockchain-enabled roles.",
    learnMoreButton: "Learn More",

    fourthSectionPage2Heading: "Audit Trails for Compliance",
    fourthSectionPage2Description: "Track every interaction with your files to meet compliance requirements and ensure accountability.",
    fourthSectionPage2Link: "Learn About Audit Trails",
    fourthSectionPage2cardheading1: "Immutable Logs",
    fourthSectionPage2fastTrackList1: "Maintain a tamper-proof record of every file operation.",
    fourthSectionPage2cardheading2: "Regulatory Compliance",
    fourthSectionPage2fastTrackList2: "Ensure adherence to global data protection standards.",
    fourthSectionPage2cardheading3: "Easy Reporting",
    fourthSectionPage2fastTrackList3: "Generate detailed compliance reports with ease.",

    fourthSectionPage3Heading: "Custom Blockchain Configurations",
    fourthSectionPage3Description: "Tailor the blockchain framework to suit your organization's file management needs.",
    fourthSectionPage3Link: "Explore Custom Configurations",
    fourthSectionPage3cardheading1: "Private and Public Blockchains",
    fourthSectionPage3fastTrackList1: "Choose between private or public blockchains for your files.",
    fourthSectionPage3cardheading2: "Interoperability",
    fourthSectionPage3fastTrackList2: "Seamlessly integrate with other blockchain systems.",
    fourthSectionPage3cardheading3: "Scalable Architecture",
    fourthSectionPage3fastTrackList3: "Scale effortlessly as your file management demands grow.",

    fourthSectionPage4Heading: "Digital Twin Integration",
    fourthSectionPage4Description: "Integrate digital twins of your data repositories for enhanced visualization and management.",
    fourthSectionPage4Link: "Learn About Digital Twins",
    fourthSectionPage4cardheading1: "Real-Time Synchronization",
    fourthSectionPage4fastTrackList1: "Keep your digital twins updated in real time.",
    fourthSectionPage4cardheading2: "Interactive Dashboards",
    fourthSectionPage4fastTrackList2: "Visualize data interactions and dependencies seamlessly.",
    fourthSectionPage4cardheading3: "Improved Decision-Making",
    fourthSectionPage4fastTrackList3: "Gain insights from digital twin analytics to inform strategy.",

    fifthSectionHeading: "Customer Success with Lattice Blockchain File Management Suite",
    fifthSectionPara1: "Organizations worldwide trust Lattice for their file management needs, ensuring data security, compliance, and operational efficiency.",
    fifthSectionCard1Heading: "Enhanced Collaboration",
    fifthSectionCard1Para: "Seamless real-time file sharing across global teams.",
    fifthSectionCard2Heading: "Regulatory Compliance",
    fifthSectionCard2Para: "Meet the toughest data protection regulations with ease.",
    fifthSectionCard3Heading: "Operational Efficiency",
    fifthSectionCard3Para: "Streamlined workflows reduce overhead and errors.",
    fifthSectionButton1: "See Customer Stories",
    fifthSectionButton2: "Request a Demo",

    sixthSectionHeading: "Get Started with Lattice Blockchain File Management Suite",
    sixthSectionButton1: "Explore Features",
    sixthSectionButton2: "Contact Sales",
    sixthSectionButton3: "Try Free Demo",
};