export const developerResourceCenter = {
    firstSectionHeading: "Lattice Developer Center",
    firstSectionPara: "Resources for developers – Discover. Learn. Build.",

    navBarHeading: "jump to:",
    navBarButton1: "Latest News",
    navBarButton2: "AI",
    navBarButton3: "Languages",
    navBarButton4: "Solutions",
    navBarButton5: "Community and events",
    navBarButton6: "Resources",

    thirdSectionHeading: "Artificial Intelligence",
    thirdSectionLink: "Explore Lattice AI for developers",
    thirdSectionCard1Heading: "Build AI solutions, fast",
    thirdSectionCard2Heading: "Grow with generative AI",
    thirdSectionCard3Heading: "Enjoy unmatched capabilities",

    thirdSectionCard1List1: "Use real-world scenarios.",
    thirdSectionCard1List2: "See example code on GitHub repositories.",
    thirdSectionCard1List3: "Get step-by-step instructions.",

    thirdSectionCard2List1: "Embed AI in cloud applications.",
    thirdSectionCard2List2: "Refine LLMs with your own data.",
    thirdSectionCard2List3: "Train smarter with NVIDIA GPUs.",

    thirdSectionCard3List1: "Flex with JSON Relational Duality.",
    thirdSectionCard3List2: "Generate faster, leaner code with Java and GraalVM.",
    thirdSectionCard3List3: "Accelerate semantic search with AI Vector Search.",

    thirdSectionCard1Button: "Visit the AI Solutions Hub",
    thirdSectionCard2Button: "See the GenAI documentation",
    thirdSectionCard3Button: "Explore AI Vector Search",

    fourthSectionHeading: "Languages",

    fifthSectionHeading: "Featured solutions",

    fifthSectionCard1Heading: "Create a Chatbot with Unstructured Data",
    fifthSectionCard2Heading: "Automate Invoice Processing with AI",
    fifthSectionCard3Heading: "Low-code modular LLM app engine",
    fifthSectionCard4Heading: "Simplify AI Integration in Java Projects",
    fifthSectionCard5Heading: "Create a question and answer app using custom documents with HeatWave GenAI",
    fifthSectionCard6Heading: "Transform Database Management with AI",

    fifthSectionCard1Para: "Learn how to build an AI chatbot with unstructured data using Lattice Database 23ai, LCI AI services, and RAG.",
    fifthSectionCard2Para: "Automate invoice extraction and simplify document processing in ERP systems using AI.",
    fifthSectionCard3Para: "Ingest live data into a RAG-based knowledge search engine store using an Lattice low-code modular LLM app engine.",
    fifthSectionCard4Para: "Integrate AI into Java with LangChain’s Java framework. Accelerate development with Lattice AI.",
    fifthSectionCard5Para: "Quickly answer domain-specific questions using HeatWave GenAI and Vector Store.",
    fifthSectionCard6Para: "Discover how Lattice AI simplifies database provisioning, migration, patching, and troubleshooting with LCI",

    fifthSectionButton: "view solution",

    sixthSectionHeading: "Community and events",
    sixthSectionSubHeading: "Developer Coaching series",
    sixthSectionPara: "Developer coaches are highly skilled developers, ready to assist you in your Lattice Cloud development journey. Developer Coaching office hours provide an opportunity for you to engage directly with experts and find additional help for your organization.",
    sixthSectionButton: "learn more",

    sixthSectionCard1Heading: "Lattice Developer Community",
    sixthSectionCard1Para: "Share your questions and answers.",
    sixthSectionCard1Button: "Join the conversation",
    sixthSectionCard2Heading: "Lattice ACE Program",
    sixthSectionCard2Para: "Go that step further. Become an Lattice Ace.",
    sixthSectionCard2Button: "Learn more",
    sixthSectionCard3Heading: "See all developer events",
    sixthSectionCard3Para: "Search for developer events in your region.",
    sixthSectionCard3Button: "Explore upcoming events",

    seventhSectionHeading: "Resources",
    seventhSectionButton1: "Downloads",
    seventhSectionButton2: "Documentation",
    seventhSectionButton3: "Events",
    seventhSectionButton4: "Technologies",
    seventhSectionButton5: "Languages",
    seventhSectionButton6: "Learn",

    seventhSectionPage1Heading: "Downloads",
    seventhSectionPage1Description: "All software downloads are free, and most come with a Developer License that allows you to use full versions of the products at no charge while developing and prototyping your applications, or for strictly self-educational purposes.",
    seventhSectionPage1Link: "All Download",
    seventhSectionPage1DivHeading: "Fast-track to the following events:",
    seventhSectionPage1FastrackList1: "All Java downloads",
    seventhSectionPage1FastrackList2: "Database",
    seventhSectionPage1FastrackList3: "Drivers & Utilities",


    seventhSectionPage2Heading: "Documentation",
    seventhSectionPage2Description: "Explore getting started guides, documentation, tutorials, reference architectures, and other content for Lattice products and services.",
    seventhSectionPage2Link: "Explore the content",
    seventhSectionPage2DivHeading: "Kick-start your app development with SDKs and the command-line interface.",
    seventhSectionPage2FastrackList1: "SDK for Java",
    seventhSectionPage2FastrackList2: "SDK for Python",
    seventhSectionPage2FastrackList3: "SDK for TypeScript and JavaScript",
    seventhSectionPage2FastrackList4: "SDK for .NET",
    seventhSectionPage2FastrackList5: "SDK for Go",
    seventhSectionPage2FastrackList6: "SDK for Ruby",
    seventhSectionPage2FastrackList7: "SDK for PL/SQL",
    seventhSectionPage2FastrackList8: "Command-line interface (CLI)",


    seventhSectionPage3Heading: "Developer Events and Webinars - " +
              "replays",
    seventhSectionPage3Description: "Explore Lattice Developer event replays and recent webinars, hosted by technical experts.",
    seventhSectionPage3Link: "Learn more",
    seventhSectionPage3DivHeading: "Access developer resources and learn about future events designed just for you. Hear from experts in their field and gain practical hands-on experience.",
    seventhSectionPage3FastrackList1: "Developer Coaching series",
    seventhSectionPage3FastrackList2: "Search all events",

    seventhSectionPage4Heading: "Technologies",
    seventhSectionPage4Description: "Join events, webinars, and conferences to stay up-to-date with the latest trends and developments.",
    seventhSectionPage4Link: "All Events",
    seventhSectionPage4DivHeading: "Technologies",
    seventhSectionPage4FastrackList1: "Lattice gives you the power, flexibility, and reliability needed to create the best products and services available.",
   
    seventhSectionPage5Heading: "Languages",
    seventhSectionPage5Description: "Join events, webinars, and conferences to stay up-to-date with the latest trends and developments.",
    seventhSectionPage5Link: "All Events",
    seventhSectionPage5DivHeading: "languages:",
    seventhSectionPage5FastrackList1: "Upcoming Events",
    seventhSectionPage5FastrackList2: "Webinars",
    seventhSectionPage5FastrackList3: "Conferences",

    seventhSectionPage6Heading: "AI Resource Hub",
    seventhSectionPage6Description: "Discover, learn and build. Access a range of AI resources to help you get ahead on your AI journey.",
    seventhSectionPage6Link: "Learn more",
    seventhSectionPage6DivHeading: "Learn",
    seventhSectionPage6FastrackList1: "Certifications",
    seventhSectionPage6FastrackList2: "LiveLabs",
    seventhSectionPage6FastrackList3: "Use Cases",

}


  